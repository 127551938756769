<template>
    <div v-if="modelValue" class="coupon-select-class">
        <div>优惠券选择</div>
        <div style="width: 100%;border-bottom: #bcbcbc solid 1px;margin: 10px"></div>
        <div style="display: flex;flex-direction: row">
            <div style="display: flex;flex-direction: row;align-items: center">
                <span style="width: 150px">优惠券编号</span>
                <el-input v-model="id"></el-input>
            </div>
            <div style="display: flex;flex-direction: row;align-items: center">
                <span style="width: 150px">优惠券名称</span>
                <el-input v-model="name">></el-input>
            </div>
            <el-button icon="search" style="margin-left: 10px" type="primary" @click="getdata">搜索</el-button>
        </div>
        <div style="padding: 40px;height: 500px;overflow: scroll">
            <el-checkbox-group v-model="checkList">
                <table>
                    <thead>
                    <th>优惠券编号</th>
                    <th>优惠券名称</th>
                    <th>优惠券类型</th>
                    </thead>
                    <tr v-for="it in groupData">
                        <td><el-checkbox :label="it.id"/></td>
                        <td>{{it.name}}</td>
                        <td>{{it.coupon_type_name}}</td>
                    </tr>
                </table>
            </el-checkbox-group>
        </div>
        <div style="padding: 5px;text-align: right">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" style="margin-right: 20px;margin-left: 20px" @click="confirm">确定</el-button>
        </div>
    </div>
</template>

<script>
    import {postRequest} from "../../utils/api";

    export default {
        name: "coupon-group-select",
        emits: ['confirm','update:modelValue'],
        props:['multipleSelect','modelValue'],
        data(){
            return{
                id:'',
                name: '',
                groupData:[],
                checkList: []
            }
        },
        methods:{
            getdata(){
                var pr={'stop_use': 0}
                if (this.id.length>0){
                    pr['id']=this.id
                }
                if (this.name.length>0){
                    pr['name']=this.name
                }
                postRequest('coupon/getCouponGroup',pr).then(res=>{
                    this.groupData = res.data.data
                })
            },
            cancel(){
                this.checkList = []
                this.$emit('update:modelValue',false)
            },
            confirm(){
                var temp=[]
                for (var i=0;i<this.groupData.length;i++){
                    for (var x=0;x<this.checkList.length;x++){
                        if (this.checkList[x]==this.groupData[i].id){
                            temp.push(JSON.parse(JSON.stringify(this.groupData[i])))
                        }
                    }
                }
                if (temp.length>0){
                    this.$emit('confirm',temp)
                }
                this.checkList = []
                this.$emit('update:modelValue',false)

            }
        },
        updated() {
            this.getdata()
        }
    }
</script>

<style scoped>
    .coupon-select-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 700px;
        width: 1000px;
        background-color: white;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
    }
    table, td, tr, th {
        font-size: small;
        line-height: 30px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        padding: 10px;
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
</style>