import { createApp } from 'vue';
import App from "@/App";
import router from "@/router";
import './assets/icon/iconfont.css'
import { Swipe, SwipeItem,Tab, Tabs } from 'vant'
import store from "@/store";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import VueClipBoard from 'vue-clipboard2'
import dayjs from 'dayjs'

const app = createApp(App)
app.use(router)
    .use(store)
    .use(ElementPlus,{locale: zhCn,})
    .use(Swipe)
    .use(SwipeItem)
    .use(Tab)
    .use(Tabs)
    .use(VueClipBoard)
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
    app.config.globalProperties.$dayjs = dayjs
}
export default app;
