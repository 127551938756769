<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>客服管理</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <div style="background-color: #eeeeee;padding: 10px">
        <div style="background-color: white;text-align: left;margin-top: 10px;padding-bottom: 10px;padding: 10px">
            <el-button type="success" style="padding: 10px" @click="toAdd">添加客服</el-button>
        </div>
    </div>
    <div>
        <table>
            <thead>
            <th>代码</th>
            <th>用户名</th>
            <th>操作</th>
            </thead>
            <tbody>
            <tr v-for="(it,index) in csm">
                <td>{{it.user_id}}</td>
                <td>{{it.user_name}}</td>
                <td style="color: blue;">
                    <span @click="del(it.user_id)" style="cursor: pointer;color: red">删除</span>
                </td>
            </tr>
            </tbody>
        </table>
        <el-dialog v-model="showUserList" title="选择用户">
<!--            <div style="display: flex;flex-direction: row">-->
<!--                <el-input size="small" style="width: 300px"></el-input>-->
<!--                <el-button type="primary" size="small" style="margin-left: 30px" @click="getAllUser">搜索</el-button>-->
<!--            </div>-->
            <table>
                <thead>
                <th>代码</th>
                <th>用户名</th>
                <th>选择</th>
                </thead>
                <tbody>
                <tr v-for="(it,index) in userList">
                    <td>{{it.userid}}</td>
                    <td>{{it.username}}</td>
                    <td style="color: blue;">
                        <span @click="add(it.userid)" style="cursor: pointer">选择</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </el-dialog>
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";

    export default {
        name: "CsmSetting",
        data(){
            return{
                csm:[],
                showUserList:false,
                userList:[]
            }
        },
        methods:{
            getList(){
                getRequest('mall/getCsmList').then(res=>{
                    if (res.data.flag){
                        this.csm=res.data.data
                    }else {
                        this.$msgbox.alert(res.data.message)
                    }

                })
            },
            del(user_id){
                getRequest('mall/deleteCsm?user_id='+user_id).then(res=>{
                    this.getList()
                    this.$msgbox.alert(res.data.message)
                })
            },
            add(user_id){
                getRequest('mall/addCsm?user_id='+user_id).then(res=>{
                    this.$msgbox.alert(res.data.message)
                    this.getList()
                    this.showUserList=false
                })
            },
            toAdd(){
                this.showUserList=true
                this.getAllUser()
            },
            getAllUser(){
                getRequest('user/getAllUsers').then(res => {
                    console.log(res)
                    this.userList = res.data.data
                })
            },
        },
        mounted() {
            this.getList()
            // this.getAllUser()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        font-size: small;
        line-height: 30px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        padding: 10px;
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
</style>