<template>
    <div class="notice-bar-box" :style="{color: comData.textColor,backgroundColor: comData.backgroundColor}" >
        <li class="iconfont mmx-iconlaba"></li>
        <div style="margin-left: 10px;height: 20px;overflow: hidden">{{comData.text}}</div>
    </div>
</template>

<script>
    export default {
        name: "notice-item",
        props:{
            comData: {
                textColor: String,
                backgroundColor: String,
                text: String
            }
        }
    }
</script>

<style scoped>
    li{
        list-style: none;
        font-size: 23px;
    }
    .notice-bar-box{
        width: 100%;
        height: 23px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px;
    }
    ::-webkit-scrollbar {
        height: 1px;
    }
</style>