<template>
    <div style="padding: 10px;text-align: left">
        <h4>广播模板配置</h4>
        <div>广播内容:<span style="color: #a7a7a7;font-size: 10px">长文本在小程序上会滚动显示</span></div>
        <textarea v-model="value.text" placeholder="请输入广播内容,长文本在小程序上会滚动显示" style="margin: 10px;width: 350px;min-height: 200px;outline: rgba(0,216,216,0.16) solid 1px"/>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px;margin-top: 5px">
            <div>字体颜色</div>
            <input v-model="value.textColor" style="width: 150px;height: 18px;margin-left: 10px">
            <el-color-picker color-format="hex"  v-model="value.textColor" style="margin-left: 20px"></el-color-picker>
        </div>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px">
            <div>背景颜色</div>
            <input v-model="value.backgroundColor" style="width: 150px;height: 18px;margin-left: 10px">
            <el-color-picker color-format="hex" v-model="value.backgroundColor"  style="margin-left: 20px"></el-color-picker>
        </div>
    </div>
</template>

<script>
    export default {
        name: "notice-bar-config",
        props:{
            comData:{
                textColor: String,
                backgroundColor: String,
                text: String
            }
        },
        watch:{
            // value(){
            //     this. = this.comData_
            // }
        },
        data(){
            return{
                value:{
                    textColor: '#219dac',
                    backgroundColor: '',
                    text: ''
                }

            }

        },
        mounted() {
            this.value = this.comData
        }

    }
</script>

<style scoped>

</style>