<template>
    <div style="background-color: whitesmoke;padding: 10px;font-size: 13px">
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="margin-top: 10px;background-color: white;padding: 10px;text-align: left" >
            <div style="font-size: 14px">
                <div>订单单号: {{order.order_id}}</div>
                <div style="margin-top: 10px">订单状态: {{order.status_name}}</div>
            </div>
        </div>
        <div style="background-color: white;margin-top: 20px" v-loading="showloading">
            <table>
                <thead>
                <th>订单信息</th>
                <th>收货信息</th>
                <th>会员信息</th>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <mmx-label-com lable="商品金额" :value="order.goods_real_amount"></mmx-label-com>
                        <mmx-label-com lable="运费金额" :value="order.freight"></mmx-label-com>
                        <mmx-label-com lable="合计金额" :value="order.total_real_amount"></mmx-label-com>
                        <mmx-label-com lable="订单日期" :value="order.create_time"></mmx-label-com>
                        <mmx-label-com lable="支付日期" :value="order.pay_time"></mmx-label-com>
                    </td>
                    <td>
                        <mmx-label-com lable="配送方式" :value="order.receiver_type"></mmx-label-com>
                        <mmx-label-com lable="收货地址" :value="order.receiver_province_name+order.receiver_city_name+order.receiver_county_name+order.receiver_address"></mmx-label-com>
                        <mmx-label-com lable="收货人" :value="order.receiver_name"></mmx-label-com>
                        <mmx-label-com lable="收货人电话" :value="order.receiver_mobile"></mmx-label-com>
                    </td>
                    <td>
                        <mmx-label-com lable="姓名" :value="order.vip_name"></mmx-label-com>
                        <mmx-label-com lable="VIP卡号" :value="order.vip_code"></mmx-label-com>
                        <mmx-label-com lable="顾客手机号" :value="order.vip_mobile"></mmx-label-com>
                    </td>
                </tr>
                </tbody>
            </table>

            <div>
                <el-tabs  v-model="activeName"  @tab-change="tabchange" style="min-width: 400px">
                    <el-tab-pane label="订单商品" name="order">
                        <table>
                            <thead>
                            <th>图片</th>
                            <th>商品代码</th>
                            <th>颜色</th>
                            <th>尺码</th>
                            <th>sku</th>
                            <th>购买数量</th>
                            <th>吊牌价</th>
                            <th>支付价</th>
                            <th>支付金额</th>
                            </thead>
                            <tbody>
                            <tr v-for="(it,inex) in order.details">
                                <td><el-image :src="it.pic_url" style="width: 70px;height: 70px;border-radius: 3px"></el-image></td>
                                <td>{{it.goods_code}}</td>
                                <td>{{it.goods_attribute1_name}}</td>
                                <td>{{it.goods_attribute2_name}}</td>
                                <td>{{it.sku}}</td>
                                <td>{{it.num}}</td>
                                <td>{{it.real_price}}</td>
                                <td>{{it.pay_price}}</td>
                                <td>{{it.pay_amount}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </el-tab-pane>
                    <el-tab-pane label="支付信息" name="payInfo">
                        <table>
                            <thead>
                            <th>支付类型</th>
                            <th>支付时间</th>
                            <th>支付金额</th>
                            <th>说明</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>线上支付</td>
                                <td>{{order.pay_time}}</td>
                                <td>{{order.pay_amount}}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>优惠券</td>
                                <td>{{order.pay_time}}</td>
                                <td>{{order.discount_amount}}</td>
                                <td>{{order.coupon_code}}</td>
                            </tr>
                            <tr>
                                <td>支付积分</td>
                                <td>{{order.pay_time}}</td>
                                <td>{{order.points_amount}}</td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </el-tab-pane>
                    <el-tab-pane label="操作日志" name="log">
                        <table>
                            <thead>
                            <th>操作人ID</th>
                            <th>操作人名字</th>
                            <th>操作人时间</th>
                            <th>操作内容</th>
                            <th>操作备注</th>
                            </thead>
                            <tbody>
                            <tr v-for="(it,inex) in order.operations_records">
                                <td>{{it.operator_id}}</td>
                                <td>{{it.operator_name}}</td>
                                <td>{{it.create_time}}</td>
                                <td>{{it.action}}</td>
                                <td>{{it.notes}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div style="background-color: white;margin-top: 20px;text-align: left;font-size: 12px">
            <div style="margin-bottom: 10px;padding-top: 20px;font-size: 15px;color: rgba(57,128,255,0.68)">发货单:</div>
            <div style="max-width: 80%;padding: 20px">
                <div class="m-tr">
                    <div class="m-td" style="flex: 2;border: none">
                        <div class="m-td">商品信息</div>
                        <div class="m-td cell-max-width">数量</div>
                    </div>
                    <div class="m-td cell-max-width">派单人</div>
                    <div class="m-td cell-max-width">发货店铺</div>
                    <div class="m-td cell-max-width">发货人</div>
                    <div class="m-td cell-max-width">发货状态</div>
                    <div class="m-td">发货信息</div>
                </div>
                <div v-for="(it,index) in order.shipping_detail">
                    <div style="margin-top: 10px;border-bottom: #d3d3d3 solid 1px;background-color: rgba(0,167,167,0.05);height: 30px;display: flex;align-items: center">
                        <span>发货单号:</span>
                        <span>{{it.shipping_id}}</span>
                    </div>
                    <div class="m-tr">
                        <div style="display: flex;flex-direction: column;flex: 2;">
                            <div v-for="it2 in it.detials" style="display: flex;flex-direction: row;height: 100%">
                                <div class="m-td" style="display: flex;flex-direction: row">
                                    <div><el-image :src="it2.pic_url" style="width: 50px;height: 50px;border-radius: 3px;margin: 5px"></el-image></div>
                                    <div style="display: flex;flex-direction: column">
                                        <div>{{it2.goods_code}}</div>
                                        <div>{{it2.attribute1_name}} / {{it2.attribute2_name}}</div>
                                    </div>
                                </div>
                                <div class="m-td cell-max-width">{{it2.num}}</div>
                            </div>
                        </div>
                        <div class="m-td cell-max-width">{{it.creator_name}}</div>
                        <div class="m-td cell-max-width">{{it.shipping_store_name}}</div>
                        <div class="m-td cell-max-width">{{it.shipping_guider_name}}</div>
                        <div class="m-td cell-max-width" style="display: flex;flex-direction: column">
                            <span v-if="it.shipped==0">未发货</span>
                            <span v-if="it.shipped==1">已发货</span>
                            <span v-if="it.shipped==2">拒单</span>
<!--                            <div v-if="it.shipped==0" style="margin-top: 10px;color: #999999;cursor: pointer">发货</div>-->
                        </div>
                        <div class="m-td" style="text-align: left;flex-direction: column;justify-items: left;">
                            <div><mmx-label-com lable="发货日期" :value="it.shipping_time"></mmx-label-com></div>
                            <div><mmx-label-com lable="快递公司" :value="it.express_company"></mmx-label-com></div>
                            <div><mmx-label-com  lable="快递单号" :value="it.logistic_code"></mmx-label-com></div>
                            <span @click="showTraces(it)" style="color: blue;cursor: pointer;text-decoration:underline">查看物流信息</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {getUserId} from "../../utils/auth";
    import {getRequest} from "../../utils/api";
    import MmxLabelCom from "./coms/mmx-label-com";
    import T from "../mall/MallDecoration";
    import QueryTracesApi from "../../api-com/query-traces";

    export default {
        name: "OrderDetail",
        components: {T, MmxLabelCom},
        data(){
            return{
                order:{
                    order_info:{},
                    shipping_detail:[]
                },
                order_id:"",
                activeName:'order',
                showloading:false
            }
        },
        methods:{
            get(){
                this.showloading=true
                getRequest('mall/getOrderDetailById?order_id=' + this.order_id).then(res=>{
                    console.log(res)
                    this.showloading=false
                    this.order=res.data.data
                })
            },
            tabchange(){},
            showTraces(it){
                QueryTracesApi(8899,it.express_company,it.logistic_code)
            }
        },
        mounted() {
            this.order_id=this.$route.params.order_id
            this.get()
            console.log(getUserId())
        }
    }
</script>

<style scoped>
    table, td, th {
        border: 1px solid #f2f2f2;
        font-size: x-small;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    th {
        height:30px;

    }
    td{
        height: 30px;
    }
    .m-tr{
        display: flex;
        word-break: break-all;
    }
    .m-td{
        flex: 1;
        border: #d3d3d3 solid 1px;
        border-top: none;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
        word-break: break-all;
    }
    .m-td:first-child{
        border-left: #d3d3d3 solid 1px;
    }
    .m-tr:first-child .m-td{
        /*border: red solid 1px;*/
        border-top: #d3d3d3 solid 1px;
    }
    .cell-max-width{
        max-width: 70px;
    }
</style>