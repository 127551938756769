<template>
    <div class="dialog-class" v-loading="showloading">
        <div>发起退款</div>
        <div style="width: 100%;border-bottom: #bcbcbc solid 1px;margin-top: 10px;margin-bottom: 10px"></div>
        <div>
            <div class="item-class">
                <div><span>订单编号:</span><el-input :value="return_order.order_id" style="width: 200px" disabled/></div>
                <div><span>订单状态:</span><el-input style="width: 200px" disabled/></div>
            </div>
            <div class="item-class">
                <div><span>退款金额:</span><el-input :value="return_order.return_amount" style="width: 200px" disabled/></div>
                <div><span>运费:</span><el-input :value="return_order.freight" style="width: 200px" disabled/></div>
                <el-checkbox v-model="return_order.return_freight" :disabled="return_order.disable_return_freight" label="退运费" size="large"  style="margin-left: 50px" @change="returnFreightChange"/>
            </div>
            <div class="item-class">
                <div>
                    <span style="color: red">需要退货:</span>
                    <el-select v-model="return_order.return_goods" style="width: 115px">
                        <el-option label="否" :value="0" />
                        <el-option label="是" :value="1" />
                    </el-select>
                </div>
            </div>
            <div class="item-class">
                <div>
                    <span>退款原因:</span>
                    <el-input v-model="return_order.refund_reason" style="width: 500px;"></el-input>
                </div>
            </div>
            <div class="item-class">
                <div>
                    <span>退款备注:</span>
                    <el-input v-model="return_order.refund_note" style="width: 500px;"></el-input>
                </div>
            </div>
        </div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="商品详情" name="goods" >
                <table>
                    <thead>
                    <th>图片</th>
                    <th>商品代码</th>
                    <th>颜色</th>
                    <th>尺码</th>
                    <th>sku</th>
                    <th>购买数量</th>
                    <th>已退数量</th>
                    <th>退货数量</th>
                    <th>吊牌价</th>
                    <th>支付价</th>
                    <th>退款金额</th>
                    </thead>
                    <tbody>
                    <tr v-for="(it,inex) in return_order.details">
                        <td><el-image :src="it.pic_url" style="width: 70px;height: 70px;border-radius: 3px"></el-image></td>
                        <td>{{it.goods_code}}</td>
                        <td>{{it.attribute1_name}}</td>
                        <td>{{it.attribute2_name}}</td>
                        <td>{{it.sku}}</td>
                        <td>{{it.buy_num}}</td>
                        <td>{{it.returned_num}}</td>
                        <td ><el-input-number v-model="it.return_num" :max="it.buy_num-it.returned_num" :min="0" @change="returnOrderCalculate"></el-input-number></td>
                        <td>{{it.real_price}}</td>
                        <td>{{it.pay_price}}</td>
                        <td>{{it.return_amount}}</td>
                    </tr>
                    </tbody>
                </table>
            </el-tab-pane>
            <el-tab-pane label="支付信息" name="payInfo">
                <table>
                    <thead>
                        <th>支付类型</th>
                        <th>支付时间</th>
                        <th>支付金额</th>
                        <th>说明</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>微信</td>
                            <td>{{return_order.order_info.pay_time}}</td>
                            <td>{{return_order.order_info.pay_amount}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>优惠券</td>
                            <td>{{return_order.order_info.pay_time}}</td>
                            <td>{{return_order.order_info.discount_amount}}</td>
                            <td>{{return_order.order_info.coupon_code}}</td>
                        </tr>
                    </tbody>
                </table>
            </el-tab-pane>
        </el-tabs>
        <div style="position: absolute;width: 98%;text-align: right;bottom: 0;border-top: #bcbcbc solid 1px">
            <el-button style="margin-top: 10px;margin-bottom: 10px;margin-right: 20px" @click="cancelCreate">取消</el-button>
            <el-button type="primary" @click="submit">确认</el-button>
        </div>
        <div style="width: 100px;height: 100px;position: absolute;left: 50%; top: 50%;margin-top: -50px;margin-left: -50px;" v-loading="showCalculate"></div>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../../utils/api";
    import {getUserId} from "../../../utils/auth";

    export default {
        name: "create-return-com",
        emits:['close','update:modelValue'],
        props:['orderid'],
        data(){
            return{
                returnFreight:false,
                return_goods:0,
                refund_reason:'',
                refund_note:'',
                return_order:{
                    order_info:{}
                },
                showloading:false,
                showCalculate:false,
                activeName:'goods'
            }
        },
        methods:{
            cancelCreate(){
                this.$emit('update:modelValue',false)
            },
            returnFreightChange(e){
                this.returnOrderCalculate()
            },
            returnOrderCalculate(){
                this.showCalculate = true
                postRequest('mall/returnOrderCalculate',this.return_order).then(res=>{
                    console.log(res.data)
                    this.showCalculate = false
                    if (res.data.flag){
                        this.return_order=res.data.data
                    }
                })
            },
            submit(){
                if (this.return_order.return_goods==null){
                    this.$alert('请填写是否需要退货!')
                    return
                }
                this.showloading = true
                this.return_order.creator=getUserId()
                console.log(this.return_order)
                postRequest('mall/submitReturnOrder',this.return_order).then(res=>{
                    console.log(res.data)
                    this.showloading = false
                    if (res.data.flag){
                        this.$alert(res.data.message)
                        this.$emit('update:modelValue',false)
                    }else{
                        this.$alert(res.data.message)
                    }
                })
            }
        },
        mounted() {
            this.showloading=true
            getRequest('mall/getNewReturnOrder?order_id='+this.orderid).then(res=>{
                this.showloading=false
                console.log(res.data)
                this.return_order=res.data.data
            })
        }
    }
</script>

<style scoped>
    .dialog-class{
        position: fixed;
        left: 0;
        right: 0;
        top:10px;
        margin:0 auto;
        height: 800px;
        width: 900px;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
        padding: 10px;
        text-align: left;
        background-color: #fdfdfd;
    }
    .item-class >div{
        display: flex;
        flex-direction: row;
        text-align: right;
        align-items: center
    }
    .item-class >div >span{
        width: 100px;
        margin-right: 10px
    }
    .item-class{
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    }
    table, td, tr, th {
        border-bottom:solid #b2b2b2;
        border-width: 1px;
        font-size: x-small;
        word-break: break-all;
        text-align: center;
    }
    table {
        border-collapse: collapse;
        width: 100%;
        border-radius: 25px;
    }
    td{
        height: 50px;
    }
    /deep/ .el-input.is-disabled .el-input__inner {
        -webkit-text-fill-color: #606060 !important;
        cursor: pointer;
    }
</style>