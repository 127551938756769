<template>
    <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
        <span style="margin-right: auto">自助领券:{{act.id}}</span>
        <el-button :disabled="readonly" type="primary" style="width: 90px" @click="saveEdit">保存</el-button>
        <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
    </div>
    <div style="background-color: whitesmoke;padding: 10px" v-loading="showLoading">
       <div style="background-color: white;padding: 10px;">
           <div class="row-class">
               <span>活动名称</span>
               <el-input v-model="act.activity_name"></el-input>
           </div>
           <div class="row-class" style="width: 600px;">
               <span>活动时间</span>
               <el-date-picker
                       v-model="act.validity_date"
                       type="daterange"
                       value-format="YYYY-MM-DD HH:mm:ss"
                       unlink-panels
                       range-separator="-"
                       start-placeholder="起始日期"
                       end-placeholder="截至日期"
               />
           </div>
           <div class="row-class">
               <span>活动规则</span>
               <el-input v-model="act.activity_note" type="textarea" style="width: 500px;"></el-input>
           </div>
       </div>
        <div style="background-color: white;padding: 10px;margin-top: 10px;text-align: left">
            <div style="text-align: left">活动内容</div>
            <vip-level-select v-model="showVipLevelSelect" v-if="showVipLevelSelect" @confirm="vipLevelSeleced"></vip-level-select>
            <coupon-group-select v-model="showCouponSelect" multiple-select="true" @confirm="couponSeleced"></coupon-group-select>
            <div v-for="(item,index) in act.detail" style="border: rgba(8,174,177,0.84) solid 1px;background-color: rgba(8,250,253,0.02);border-radius: 10px;margin-top: 10px;padding: 10px;position: relative">
                <div @click="delDetail(index)" style="position: absolute;right: 20px;cursor: pointer">删除</div>
                <div class="row-class">
                    <span>会员等级:</span>
                    <el-input readonly :model-value="getlevelshow(item)">
                        <template #append>
                            <el-button @click="toSelectLevel(index)" icon="ArrowDown" >选择</el-button>
                        </template>
                    </el-input>
                </div>
                <div class="row-class">
                    <span>领换限制:</span>
                    <div style="width: 600px;text-align: left">
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            <span>每人共可领取</span>
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="item.max_total_num" :min="0"></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">次</span>
                        </div>
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            每人每天可领
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="item.pick_num_per_day" :min="0" ></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">次</span>
                        </div>
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            每人每月可领
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="item.pick_num_per_month" :min="0"></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">次</span>
                        </div>
                        <div style="text-align: left;color: #8a8a8a">每一项限制条件不填或者添0则不限制</div>
                    </div>
                </div>
                <div class="row-class">
                    <span>优惠券:</span>
                    <div style="width: 70%;">
                        <div style="margin-top: 10px;background-color: white;">
                            <table>
                                <thead>
                                <th>券ID</th>
                                <th>优惠券名称</th>
                                <th>发券后X天内有效</th>
                                <th>发券数量</th>
                                <th v-if="!readonly">删除</th>
                                </thead>
                                <tbody>
                                <tr v-for="(it,index2) in item.coupons">
                                    <td>{{it.coupon_group_id}}</td>
                                    <td>{{it.coupon_group_name}}</td>
                                    <td><el-input-number v-model="it.valid_days" :min="1"></el-input-number></td>
                                    <td><el-input-number v-model="it.num" :min="1"></el-input-number></td>
                                    <td v-if="!readonly">
                                        <span @click="delCoupon(index,index2)">删除</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="text-align: left;margin-top: 20px;"><el-button @click="toSelectCoupon(index)" style="border: rgba(0,0,255,0.5) solid 1px;color: rgba(0,0,255,0.5)">添加优惠券</el-button></div>
                    </div>
                </div>
            </div>
            <el-button @click="addCoupon" style="margin-top: 20px;color: rgba(0,0,255,0.59);border: blue solid 1px">添加活动</el-button>
        </div>
    </div>
</template>

<script>
    import VipLevelSelect from "../common/vip-level-select";
    import CouponGroupSelect from "../common/coupon-group-select";
    import {getRequest, postRequest} from "../../utils/api";
    import {ElMessageBox} from "element-plus";
    export default {
        name: "PickupCouponEdit",
        components: {CouponGroupSelect, VipLevelSelect},
        data(){
            return{
                readonly:false,
                act:{
                    id:-1,
                    activity_name:'',
                    activity_note:'',
                    detail:[
                    ]
                },
                dtl:{

                },
                showLoading:false,
                selectLevelId:0,
                CouponSelectId:0,
                showVipLevelSelect:false,
                showCouponSelect:false
            }
        },
        methods:{
            backHome(){
                this.$router.back()
            },
            saveEdit(){
                if (this.act.validity_date==null){
                    this.$alert('请填写活动时间！')
                    return
                }
                for (var i=0;i<this.act.detail.length;i++){
                    if (this.act.detail[i].level_code==''){
                        this.$alert('请填写会员等级！')
                        return
                    }
                }
                this.act.start_time=this.act.validity_date[0]
                this.act.end_time=this.act.validity_date[1]
                if (this.act.id==-1){
                    postRequest('activity/submitPickupCoupon',this.act).then(res=>{
                        console.log(res)
                        if (res.data.flag){
                            this.$alert(res.data.message)
                            this.$router.back()
                        }else {
                            ElMessageBox.alert(res.data.message)
                        }
                    })
                }
            },
            getDetail(){
                this.showLoading=true
                getRequest('activity/getPickupCouponDetailById?id='+this.act.id).then(res=>{
                    this.showLoading=false
                    this.act=res.data.data
                    this.act.validity_date=[this.act.start_time,this.act.end_time]
                })
            },
            toSelectLevel(id){
                this.selectLevelId=id
                this.showVipLevelSelect=true
            },
            vipLevelSeleced(e){
                console.log(e)
                var flag=false
                for (var i=0;i<this.act.detail.length;i++){
                    if (this.act.detail[i].level_code==e[0].level_code){
                        flag=true
                    }
                }
                if (!flag){
                    this.act.detail[this.selectLevelId].level_code=e[0].level_code
                    this.act.detail[this.selectLevelId].level_name=e[0].level_name
                }

            },
            getlevelshow(item){
                if (item.level_code==''){
                    return ''
                }else {
                    return   item.level_code+'|'+item.level_name
                }
            },
            addCoupon() {
                this.act.detail.push(
                    {
                        id:'',
                        level_code:'',
                        level_name:'',
                        max_total_num:0,
                        pick_num_per_day:0,
                        pick_num_per_month:0,
                        coupons:[]
                    }
                )
            },
            toSelectCoupon(index){
                this.CouponSelectId=index
                this.showCouponSelect=true
            },
            couponSeleced(e){
                var flag=false
                for (var i=0;i<e.length;i++){
                    flag=false
                    for (var a=0;a<this.act.detail[this.CouponSelectId].coupons.length;a++){
                        if (e[i].id==this.act.detail[this.CouponSelectId].coupons[a].coupon_group_id){
                            flag=true
                        }
                    }
                    if (!flag){
                        this.act.detail[this.CouponSelectId].coupons.push({
                            coupon_group_id:e[i].id,
                            coupon_group_name:e[i].name,
                            valid_days:31,
                            num:1
                        })
                    }
                }
            },
            delCoupon(index,index2){
                var t=[]
                for (var i=0;i<this.act.detail[index].coupons.length;i++){
                    if (i!=index2){
                        t.push(this.act.detail[index].coupons[i])
                    }
                }
                this.act.detail[index].coupons=t
            },
            delDetail(index){
                var t=[]
                for (var i=0;i<this.act.detail.length;i++){
                    if (i!=index){
                        t.push(this.act.detail[i])
                    }
                }
                this.act.detail=t
            }
        },
        mounted() {
            // this.getVipLevel()
            this.act.id=this.$route.params.id
            if (this.act.id!=-1){
                this.readonly=true
                this.getDetail()
            }
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 10px;
        flex-direction: row;
        text-align: center;
    }
    .row-class span{
        width: 200px;
    }
    .row-class .el-input{
        width: 400px;
    }
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>