<template>
    <div class="welcome-class"/>
</template>

<script>
    export default {
        name: "WelcomePage"
    }
</script>

<style scoped>
    .welcome-class{
        width: 100%;
        height: 400px;
    }
    .body{
        height: 100%;
    }
</style>