<template>
    <div style="background-color: whitesmoke;padding: 10px">
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>售后管理</el-breadcrumb-item>
            <el-breadcrumb-item>退单详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="margin-top: 10px;background-color: white;padding: 10px;text-align: left" >
            <div style="font-size: 14px">
                <div>退货单号: {{return_order.return_id}}</div>
                <div style="margin-top: 10px">受理状态: {{return_order.status_name}}</div>
            </div>
        </div>
        <div style="background-color: white;margin-top: 20px" v-loading="showloading">
            <table>
                <thead>
                    <th>退单信息</th>
                    <th>订单信息</th>
                    <th>物流信息</th>
                    <th>顾客信息</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <mmx-label-com lable="商品金额" :value="return_order.goods_amount"></mmx-label-com>
                            <mmx-label-com lable="运费金额" :value="return_order.freight"></mmx-label-com>
                            <mmx-label-com lable="合计退款" :value="return_order.refund_amount"></mmx-label-com>
                            <mmx-label-com lable="申请日期" :value="return_order.create_time"></mmx-label-com>
                            <mmx-label-com lable="退款原因" :value="return_order.refund_reason"></mmx-label-com>
                            <mmx-label-com lable="退款方式" :value="return_order.return_goods==1?'退货退款':'仅退款'"></mmx-label-com>
                        </td>
                        <td>
                            <mmx-label-com lable="订单编号" :value="return_order.order_info.order_id"></mmx-label-com>
                            <mmx-label-com lable="付款日期" :value="return_order.order_info.pay_time"></mmx-label-com>
                            <mmx-label-com lable="运费金额" :value="return_order.order_info.freight"></mmx-label-com>
                            <mmx-label-com lable="商品金额" :value="return_order.order_info.goods_real_amount"></mmx-label-com>
                            <mmx-label-com lable="合计金额" :value="return_order.order_info.total_real_amount"></mmx-label-com>
                        </td>
                        <td>
                            <mmx-label-com lable="快递公司" :value="return_order.express_company"></mmx-label-com>
                            <mmx-label-com lable="快递单号" :value="return_order.express_code"></mmx-label-com>
                            <mmx-label-com lable="发货备注" :value="return_order.shipped_notes"></mmx-label-com>
                            <mmx-label-com @click="queryTraces" lable="物流详情"  value="查看" style="color: blue;cursor: pointer"></mmx-label-com>
                        </td>
                        <td>
                            <mmx-label-com lable="姓名" :value="return_order.vip_name"></mmx-label-com>
                            <mmx-label-com lable="VIP卡号" :value="return_order.vip_code"></mmx-label-com>
                            <mmx-label-com lable="顾客手机号" :value="return_order.vip_mobile"></mmx-label-com>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div>
                <el-tabs  v-model="activeName"  @tab-change="tabchange">
                    <el-tab-pane label="退单商品" name="return">
                        <table>
                            <thead>
                            <th>图片</th>
                            <th>商品代码</th>
                            <th>颜色</th>
                            <th>尺码</th>
                            <th>sku</th>
                            <th>退货数量</th>
                            <th>吊牌价</th>
                            <th>支付价</th>
                            <th>退款金额</th>
                            </thead>
                            <tbody>
                            <tr v-for="(it,inex) in return_order.details">
                                <td><el-image :src="it.pic_url" style="width: 70px;height: 70px;border-radius: 3px"></el-image></td>
                                <td>{{it.goods_code}}</td>
                                <td>{{it.attribute1_name}}</td>
                                <td>{{it.attribute2_name}}</td>
                                <td>{{it.sku}}</td>
                                <td>{{it.num}}</td>
                                <td>{{it.real_price}}</td>
                                <td>{{it.pay_price}}</td>
                                <td>{{it.pay_amount}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </el-tab-pane>
                    <el-tab-pane label="订单商品" name="order">
                        <table>
                            <thead>
                            <th>图片</th>
                            <th>商品代码</th>
                            <th>颜色</th>
                            <th>尺码</th>
                            <th>sku</th>
                            <th>购买数量</th>
                            <th>吊牌价</th>
                            <th>支付价</th>
                            <th>支付金额</th>
                            </thead>
                            <tbody>
                            <tr v-for="(it,inex) in return_order.order_info.details">
                                <td><el-image :src="it.pic_url" style="width: 70px;height: 70px;border-radius: 3px"></el-image></td>
                                <td>{{it.goods_code}}</td>
                                <td>{{it.goods_attribute1_name}}</td>
                                <td>{{it.goods_attribute2_name}}</td>
                                <td>{{it.sku}}</td>
                                <td>{{it.num}}</td>
                                <td>{{it.real_price}}</td>
                                <td>{{it.pay_price}}</td>
                                <td>{{it.pay_amount}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </el-tab-pane>
                    <el-tab-pane label="操作日志" name="log">
                        <table>
                            <thead>
                            <th>操作人ID</th>
                            <th>操作人名字</th>
                            <th>操作人时间</th>
                            <th>操作内容</th>
                            <th>操作备注</th>
                            </thead>
                            <tbody>
                            <tr v-for="(it,inex) in return_order.operations_records">
                                <td>{{it.operator_id}}</td>
                                <td>{{it.operator_name}}</td>
                                <td>{{it.create_time}}</td>
                                <td>{{it.action}}</td>
                                <td>{{it.notes}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import {getUserId} from "../../utils/auth";
    import {getRequest} from "../../utils/api";
    import MmxLabelCom from "./coms/mmx-label-com";
    import QueryTraces from "../../api-com/query-traces";

    export default {
        name: "ReturnOrderDetail",
        components: {MmxLabelCom},
        data(){
            return{
                return_order:{
                    order_info:{}
                },
                return_id:"",
                activeName:'return',
                showloading:false
            }
        },
        methods:{
            get(){
                this.showloading=true
                getRequest('mall/getReturnOrderDetailById?return_id=' + this.return_id).then(res=>{
                    console.log(res)
                    this.showloading=false
                    this.return_order=res.data.data
                })
            },
            tabchange(){},
            queryTraces(){
                console.log(1)
                QueryTraces(8899,this.return_order.express_company,this.return_order.express_code)
            }
        },
        mounted() {
            this.return_id=this.$route.params.return_id
            this.get()
            console.log(getUserId())
        }
    }
</script>

<style scoped>
    table, td, th {
        border: 1px solid #f2f2f2;
        font-size: x-small;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    th {
        height:30px;

    }
    td{
        height: 30px;
    }
</style>