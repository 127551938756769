<template>
    <div :style="comdData.singleLine==true?'display: flex;flex-direction: row;flex-wrap: nowrap;overflow: scroll':'display: flex;flex-direction: row;flex-wrap: wrap;'">
        <div class="comd-card-box" :style="getItemWidth" v-for="(item,index) in commditys" :key="index">
            <div class="lable-class" :style="{color: comdData.lableColor,backgroundColor: comdData.lableBackgroundColor}">{{comdData.lableTxt}}</div>
            <img style="object-fit: fill;"  :style="getImgH" :src="item.pic_url"/>
            <div class="comdinfo">
                <div style="text-indent: 5px;text-align: left;font-size: 13px;height: 15px;overflow: hidden;">{{item.goods_name}}</div>
                <div style="text-indent: 5px;text-align: left;font-size: 12px;margin-top: 10px;">{{item.goods_code}}</div>
                <div class="price">
                    <div  :style="{color: comdData.priceColor}" style="display: flex;flex-direction: column">
                        <div v-if="comdData.showPrice" style="text-align: left;text-decoration:line-through;color: #bcbcbc" :style="{fontSize: comdData.priceFontSize + 'px'}">
                            ￥
                            <i :style="{fontSize: comdData.priceFontSize + 'px'}">{{item.price}}</i>
                        </div>
                        <p :style="{fontSize: comdData.priceFontSize + 'px'}">
                            ￥
                            <i :style="{fontSize: comdData.priceFontSize + 'px'}">{{item.real_price}}</i>
                        </p>
                    </div>
                    <li class="iconfont mmx-iconicon_caigou" :style="{fontSize: comdData.priceFontSize + 'px',color: comdData.priceColor}" style="margin-left: auto;"></li>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {getRequest} from "../../../utils/api";

    export default {
        name: "commodity-group-card-item",
        props:{
            active: Number,
            comdData:{
                changeNum:Number,
                showType: Number,
                priceColor: String,
                priceFontSize: Number,
                showLable: Number,
                showPrice: Boolean,
                lableColor: String,
                lableBackgroundColor: String,
                lableTxt: String,
                margin: Number,
                data:Array
            }
        },
        watch:{
            active(){
                this.get()
            },
            'comdData.changeNum':{
                handler(){
                    console.log(12)
                    this.get()
                }

            }
        },
        computed:{
            getImgH () {
                return 'height:'+ this.imgHeight[this.comdData.showType-1] + 'px;'
            },
            getItemWidth(){
                return 'width: ' + 100/this.comdData.showType +'%;border: white solid 5px;box-sizing: border-box;border-radius: 10px'
            }
        },
        data(){
            return{
                imgHeight:[350,165,106],
                commditys:[],
                comdData_:null
            }
        },
        methods:{
            get(){
                if (this.active>-1 && this.comdData_.data!=null && this.comdData_.data.length>0){
                    var v=this.comdData_.data[this.active]
                    console.log(v)
                    getRequest('goods/getTopGoods?lv2=' + v.tabValue +'&topNum=' +v.commdityNum).then(res=>{
                        this.commditys=res.data.data
                    })
                }else {
                    this.commditys=[]
                }

            }
        },
        created() {
            this.comdData_=this.comdData
            this.get()
        }
    }
</script>

<style scoped>
    .comd-card-box{
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        border-radius: 6px;
        background-color: white;
    }
    .lable-class{
        position: absolute;
        left: 1px;
        top: 1px;
        font-size: 13px;
    }
    li{
        list-style-type:none;
    }
    .price{
        text-align: left;
        display: flex;
        list-style: none;
        align-items: center;
        color: red;
        margin-top: auto;
        margin-bottom: 10px;
    }
</style>