<template>
    <div style="max-width: 800px">
        <table>
            <thead>
            <th>日期</th>
            <th>创建人</th>
            <th>原等级</th>
            <th>变更等级</th>
            <th>备注</th>
            </thead>
            <tbody>
            <tr v-for="it in tableData">
                <td>{{it.create_time}}</td>
                <td>{{it.creator_name}}</td>
                <td>{{it.level1_name}}</td>
                <td>{{it.level2_name}}</td>
                <td>{{it.note}}</td>
            </tr>
            </tbody>
        </table>
        <el-pagination
                v-model:current-page="pageNum"
                v-model:page-size="pageSize"
                :page-sizes="[10,50,100, 200]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
                :pager-count="15"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
    import {getRequest} from "../../../utils/api";

    export default {
        name: "vip-level-change-com",
        props:["vipCode"],
        data(){
            return{
                activeName:'all',
                pageNum:1,
                pageSize: 10,
                totalNum:1,
                tableData:[],
                showLoading:false
            }
        },
        methods:{
            handleChange(e){
                this.pageNum=1
                this.totalNum=1
                this.tableData=[]
                this.get()
            },
            get(){
                getRequest('vip/getVipLevelChangeRecord?vip_code='+this.vipCode+'&pageNum='+this.pageNum+'&pageSize='+this.pageSize).then(res=>{
                    this.totalNum=res.data.data.total
                    this.tableData=res.data.data.list
                })
            },
            handleSizeChange(){
                this.pageNum = 1
                this.get()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.get()
            },
        },
        mounted() {
            this.get()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 30px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>