<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="display: flex;flex-direction: row">
            <el-input size="small" style="width: 300px"></el-input>
            <el-button type="primary" size="small" style="margin-left: 30px" @click="getAllUser">搜索</el-button>
            <el-button type="success" size="small" style="margin-left: 30px" @click="toAddUser">添加用户</el-button>
        </div>
        <div style="border-bottom: #cdcdd5 solid 1px;width: 100%;margin-top: 20px;margin-bottom: 20px"></div>
        <div>
            <el-table :data="tableData" height="500" border style="width: 800px">
                <el-table-column prop="userid" label="用户iD" width="100px"/>
                <el-table-column prop="username" label="用户名" width="150px"/>
                <el-table-column label="密码" width="100px">****</el-table-column>
                <el-table-column prop="enabled" label="启用" width="100px">
                    <template #default="scope">
                        <el-switch v-model=scope.row.enabled :disabled="true"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" >
                    <template #default="scope">
<!--                        <el-button @click="userEdit(scope.row)" type="primary" icon="EditPen" size="small">信息</el-button>-->
                        <el-button v-if="scope.row.enabled" @click="disabledUser(scope.row)" type="danger" icon="Warning" size="small">停用</el-button>
                        <el-button v-if="!scope.row.enabled" @click="disabledUser(scope.row)" type="success" icon="Warning" size="small">启用</el-button>
                        <el-button @click="userRoleEdit(scope.row)" type="warning" icon="User" size="small">角色</el-button>
                        <el-button @click="changePassword(scope.row)" type="warning" icon="View" size="small">重置密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="z-index: 100;width: 100%;position: fixed;top:0;bottom: 0;background-color: #999999;opacity: 0.5;left: 0" v-show="showAddUserDialog">
        </div>
        <div class="user-add-class" v-show="showAddUserDialog" width="50%" >
            <div style="padding: 20px">
                <div>添加用户</div>
                <div>
                    <div  class="user-info-box">
                        <div class="add-line-class">用户名</div>
                        <el-input size="small" v-model="userName"></el-input>
                    </div>

                    <div  class="user-info-box">
                        <div class="add-line-class">用户iD</div>
                        <el-input size="small"  v-model="userId"></el-input>
                    </div>

                    <div  class="user-info-box">
                        <div class="add-line-class">密码</div>
                        <el-input size="small" v-model="password"></el-input>
                    </div>
                </div>
                <div style="padding-left: 80px;padding-right: 40px; justify-content: space-between ;display: flex;flex-direction: row;text-align: center;margin-top: 30px">
                    <el-button size="small" @click="showAddUserDialog = false">取消</el-button>
                    <el-button size="small"  type="success" @click="submitAddUser">添加</el-button>
                </div>

            </div>

        </div>
        <el-dialog
                v-model="showUserRoleEdit"
                :title="editUserRole.role_name"
                width="400px"
                style="height: 600px;"
        >
            <div style="height: 500px;overflow: scroll" v-loading="showUserRoleEditLoding">
                <el-checkbox-group v-model="CheckedRoleList" style="text-align: left">
                    <el-checkbox v-for="(it) in allRole" :label="it.role_id" style="display: block">{{it.role_name}}</el-checkbox>
                </el-checkbox-group>
            </div>
            <div style="text-align: right;position: absolute;bottom: 0px;width: 100%;background-color: rgba(0,201,255,0.12);left: 0"><el-button @click="submitUserRoelUpdate" style="right: 30px">保存</el-button></div>
        </el-dialog>
        <el-dialog
                v-model="showChangePassword"
                :title="changePassworIt.username"
                width="400px"
                style="height: 200px;"
        >
        <div style="text-align: left">
            <div>新密码</div>
            <el-input type="password" v-model="newPwd"/>
        </div>
            <div style="margin-top: 20px">
                <el-button @click="showChangePassword=false">取消</el-button>
                <el-button @click="submitChangePassword" style="margin-left: 50px" type="primary">修改</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import { ElMessage, ElMessageBox } from 'element-plus'
    export default {
        name: "UserManage",
        data(){
            return{
                showAddUserDialog:false,
                userId: '',
                userName: '',
                password: '',
                tableData:[],
                showUserRoleEdit:false,
                editUserRole:{},
                allRole:[],
                CheckedRoleList:[],
                showUserRoleEditLoding:false,
                showChangePassword:false,
                changePassworIt:{},
                newPwd:''
            }
        },
        methods:{
            changePassword(e){
                this.showChangePassword=true
                this.changePassworIt=e
            },
            submitChangePassword(){
                if (this.newPwd.length<1){
                    this.$msgbox.alert("请输入密码")
                }else {
                    getRequest('user/changeOthersPassword?user_id='+this.changePassworIt.userid+'&new_password='+this.newPwd).then(res=>{
                        this.showChangePassword=false
                        this.$msgbox.alert(res.data.message)
                    })
                }

            },
            userEdit(){},
            userRoleEdit(it){
                this.CheckedRoleList=[]
                this.editUserRole=it
                this.showUserRoleEdit=true
                this.showUserRoleEditLoding=true
                this.getAllRole()
            },
            getUserRole(){
                getRequest('user/getUserRoles?user_id=' + this.editUserRole.userid).then(res=>{
                    var r=res.data.data
                    for (var i=0;i<r.length;i++){
                        this.CheckedRoleList.push(r[i].role_id)
                    }
                    this.showUserRoleEditLoding=false
                })
            },
            disabledUser(e){
                getRequest('user/updateUserEnabled?userid=' + e.userid+ '&enabled=' + !e.enabled).then(res=>{
                    ElMessageBox.alert(res.data.message)
                    this.getAllUser()
                })
            },
            submitUserRoelUpdate(){
                console.log(this.CheckedRoleList)
                var r=[]
                for (var i=0;i<this.CheckedRoleList.length;i++){
                    r.push({role_id:this.CheckedRoleList[i]})
                }
                postRequest('user/updateUserRole?user_id=' + this.editUserRole.userid,r).then(res=>{
                    ElMessageBox.alert(res.data.message)
                    this.showUserRoleEdit=false
                })
            },
            getAllRole(){
                getRequest('user/getAllRole').then(res=>{
                    console.log(res.data.data)
                    this.allRole=res.data.data
                    this.getUserRole()
                })
            },
            getAllUser(){
                getRequest('user/getAllUsers').then(res => {
                    // console.log(res)
                    this.tableData = res.data.data
                })
            },
            toAddUser(){
                this.showAddUserDialog = true
            },
            submitAddUser(){
                postRequest('user/addUser',{'userid': this.userId,'username': this.userName,'password': this.password}).then(res=>{
                    console.log(res)
                    if (res.data.flag){
                        this.showAddUserDialog = false
                        this.userId = ''
                        this.userName = ''
                        this.password = ''
                        ElMessageBox.alert(res.data.message)
                        this.getAllUser()
                    }else {
                        ElMessageBox.alert(res.data.data,res.data.message)
                    }

                })
            }
        },
        mounted() {
            this.getAllUser()
        }
    }
</script>

<style scoped>
    .user-add-class{
        border: #ffffff solid 1px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 400px;
        height: 300px;
        margin: auto;
        background-color: #ffffff;
        border-radius: 5px;
        z-index: 100;
    }
    .add-line-class{
        width: 70px;
        text-align-last: justify;
        margin-right: 20px;
    }
    .user-info-box{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>