<template>
    <div v-loading="showLoading">
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>公众号</el-breadcrumb-item>
            <el-breadcrumb-item>公众号菜单设置</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
            <el-button type="primary" style="width: 90px" @click="saveGiftEdit">保存及发布</el-button>
            <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
        </div>
        <div style="border-bottom: rgba(0,0,0,0.2) solid 1px;width: 100%"></div>
        <div style="display: flex;flex-direction: row">
            <div style="width: 400px;border: #c8c8c8 solid 1px;height: 600px;position: relative;background-color: whitesmoke;">
                <img :src="phoneTop" style="width: 400px"/>
                <div style="position: absolute;bottom: 0;display: flex;flex-direction: row;width: 100%">
                    <div class="menus-class" style="flex: none;width: 40px">
                        <div class="menu-bt-class" style="border: #939393 solid 1px;">
                            <el-icon><ChatLineRound /></el-icon>
                        </div>
                    </div>
                    <div class="menus-class" v-for="(it,index) in button">
                        <div @click="btClick(index)" class="menu-bt-class" :style="selectedBtIndex==index?'border: blue solid 1px':''">{{it.name}}</div>
                        <div v-if="selectedBtIndex==index" class="sub-menu-class">
                            <div v-for="(it2,index2) in it.sub_button">
                                <div @click="subMenuClick(index2)" class="sub-menu-bt-class">{{it2.name}}</div>
                            </div>
                            <div v-if="it.sub_button.length<5" @click="addSubMenu" class="sub-menu-bt-class" style="font-size: 25px;color: #979797">+</div>
                        </div>
                    </div>
                    <div class="menus-class" v-if="this.button.length<3">
                        <div @click="addBt" class="menu-bt-class">+添加菜单</div>
                    </div>
                </div>
            </div>
            <div v-if="selectedBtIndex>-1" style="flex: 1;background-color: whitesmoke;padding: 10px;margin-left: 20px">
                <div style="border: whitesmoke solid 1px;background-color: white">
                    <div style="display: flex;border-bottom: whitesmoke solid 1px;padding: 10px">
                        <div>{{selectedIt.name}}</div>
                        <div @click="delBt" style="margin-left: auto;color: red;cursor: pointer">删除</div>
                    </div>
                    <div style="display: flex;margin-top: 20px;margin-bottom: 20px;align-items: center">
                        <div style="width: 100px;color: #919191">菜单名称:</div>
                        <el-input v-model="selectedIt.name" style="width: 200px"/>
                    </div>
                    <div v-if="selectedIt.sub_button.length<1">
                        <div style="display: flex;margin-top: 20px;margin-bottom: 20px;align-items: center">
                            <div style="width: 100px;color: #919191">菜单类型</div>
                            <el-radio-group v-model="selectedIt.type">
                                <el-radio label="view">跳转网页</el-radio>
                                <el-radio label="miniprogram">跳转小程序</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="selectedIt.type=='view'" style="display: flex;margin-top: 20px;margin-bottom: 20px;align-items: center">
                            <div style="width: 100px;color: #919191">跳转网址</div>
                            <el-input v-model="selectedIt.url" style="width: 200px"/>
                        </div>
                        <div v-if="selectedIt.type=='miniprogram'" >
                            <div style="display: flex;margin-top: 20px;margin-bottom: 20px;align-items: center">
                                <div style="width: 150px;color: #919191">小程序AppId</div>
                                <el-input v-model="selectedIt.appid" style="width: 200px"/>
                            </div>
                            <div style="display: flex;margin-top: 20px;margin-bottom: 20px;align-items: center">
                                <div style="width: 150px;color: #919191">小程序Page</div>
                                <el-input v-model="selectedIt.pagepath" style="width: 200px"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";

    export default {
        name: "OfaMenu",
        data(){
            return{
                showLoading:false,
                phoneTop: require('/src/assets/image/phoneTop.png'),
                selectedBtIndex:-1,
                selectedIt:{},
                selectedSubBtIndex:-1,
                button: []
            }
        },
        methods:{
            backHome(){
                this.$router.back()
            },
            saveGiftEdit(){
                postRequest('wecom/submitWxofaMenu',this.button).then(res=>{
                    console.log(res)
                    this.$msgbox.alert(res.data.message)
                })
            },
            btClick(id){
                this.selectedBtIndex=id
                this.selectedSubBtIndex=-1
                this.selectedIt=this.button[id]
            },
            addSubMenu(){
                this.button[this.selectedBtIndex].sub_button.push({
                    name:'子菜单',
                    type:'view',
                    url:'1',
                    appid:'',
                    pagepath:'',
                    sub_button: [],
                })
            },
            delBt(){
                if (this.selectedSubBtIndex<0){
                    this.$msgbox.confirm("确认删除本菜单及子菜单?!!!").then(() => {
                        var t=[]
                        for (var i=0;i<this.button.length;i++){
                            if (i!==this.selectedBtIndex){
                                t.push(this.button[i])
                            }
                        }
                        this.selectedBtIndex=-1
                        this.button=t
                    })
                        .catch(() => {
                            // catch error
                        })
                }else {
                    var t=[]
                    console.log(this.button[this.selectedBtIndex].sub_button)
                    console.log(this.selectedSubBtIndex)
                    for (var i=0;i<this.button[this.selectedBtIndex].sub_button.length;i++){
                        if (i!==this.selectedSubBtIndex){
                            t.push(this.button[this.selectedBtIndex].sub_button[i])
                        }
                    }
                    this.button[this.selectedBtIndex].sub_button=t
                    if (t.length>0){
                        this.selectedSubBtIndex=t.length-1
                        this.selectedIt=this.button[this.selectedBtIndex].sub_button[t.length-1]
                    }else {
                        this.selectedSubBtIndex=-1
                        this.selectedIt=this.button[this.selectedBtIndex]
                    }

                }

            },
            addBt(){
                this.button.push({
                    name:'菜单名称',
                    sub_button: [],
                    type:'view',
                    url:'1',
                    appid:'',
                    pagepath:'',
                })
            },
            subMenuClick(id){
                this.selectedSubBtIndex=id
                this.selectedIt=this.button[this.selectedBtIndex].sub_button[id]
            }
        },
        mounted() {
            this.showLoading=true
            getRequest('wecom/getWxOfaMenu').then(res=>{
                console.log(res)
                if (res.data.flag){
                    this.button=res.data.data
                }else {
                    this.$msgbox.alert(res.data.message)
                }
                this.showLoading=false
            })
        }
    }
</script>

<style scoped>
    .menus-class{
        display: flex;
        flex-direction: column-reverse;
        flex: 1;
    }
    .menu-bt-class{
        height: 35px;
        border: #939393 solid 1px;
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-left: none;
    }
    .menu-bt-class:hover{
        cursor: pointer;
    }
    .sub-menu-class{
        display: flex;
        flex-direction: column;
        margin-bottom: 2px;
        background-color: white;
    }
    .sub-menu-class:hover{
        cursor: pointer;
    }
    .sub-menu-bt-class{
        border-bottom: whitesmoke solid 1px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>