<template>
    <div class="login-box-class">
        <div class="login-form-class">
            <div style="flex: 1">
                <div id="qrCodeBox"></div>
            </div>
            <div style="border-left: #bcbcbc solid 1px;height: 400px;"></div>
            <form @submit="submit" style="flex: 1;display: flex;flex-direction: column;align-items: center;justify-content: center">
                <div class="form-row-class">
                    <div class="el-icon-user-solid">账号</div>
                    <input v-model="userid" name="userid" type="text" placeholder="请输入账号"/>
                </div>
                <div class="form-row-class">
                    <div class="el-icon-view">密码</div>
                    <input v-model="password" name="password" type="password"  autocomplete='off' placeholder="请输入账号"/>
                </div>
                <button type="submit">登录</button>
            </form>
        </div>
    </div>
    <div style="position: absolute;bottom: 10px;display: flex;align-items: center;justify-content: center;width: 100%">
        <el-link href="https://beian.miit.gov.cn/">备案号：滇ICP备2023010981号-1</el-link>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "./utils/api";
    import {getUserId, setAuths, setLoginFlag, setToken, setUserId, setUserName} from "./utils/auth";
    import {ElMessageBox } from 'element-plus'

    export default {
        name: "login",
        data(){
            return{
                userid: '',
                password: '',
                auth2LoginKey: '',
                loginFlag:false
            }
        },
        methods:{
            submit(e){
                e.preventDefault();
                this.toLogin()
            },
            toLogin(){
                postRequest('/user/login',{'userid': this.userid,'password': this.password}).then((res) => {
                    console.log(res)
                    if (res.data.flag){
                        this.loginFlag=true
                        setLoginFlag(true)
                        setAuths(res.data.data.auths)
                        setUserName(res.data.data.username)
                        setUserId(res.data.data.userid)
                        setToken(res.data.data.token)
                        this.$router.replace('/WelcomePage')
                    }else {
                        ElMessageBox.alert(res.data.message+res.data.data)
                    }
                })
            },
            getLoginQr(){
                getRequest('wxapi/weofa/getOauth2LoginUrl').then(res=>{
                    console.log(res)
                    this.auth2LoginKey=res.data.data.auth2LoginKey
                    this.getLoginStatus()
                    this.toDrawQr()
                })
            },
            toDrawQr(){
                try {
                    var obj = new WxLogin({
                        self_redirect:true,
                        id: 'qrCodeBox',
                        appid: 'wxbf6ff8db9ad7ad0e',
                        scope: 'snsapi_login',
                        redirect_uri: encodeURIComponent('https://www.mmxfs.shop/adminApi/wxapi/weofa/oauth2Login'),
                        state:  this.auth2LoginKey,
                        style: 'black',
                        href: ''
                    })
                }catch (e) {
                    console.log(e)
                    var that=this
                    setTimeout(that.toDrawQr,100)
                }
            },
            getLoginStatus(){
                var that=this
                if (this.loginFlag){
                    return
                }
                // console.log(that.auth2LoginKey)
                getRequest('wxapi/weofa/getOauth2LoginStatus?bindKey='+that.auth2LoginKey).then(res=>{
                    // console.log(res)
                    if (res.data.data.flag){
                        this.userid=res.data.data.data
                        this.password=that.auth2LoginKey
                        this.toLogin()
                    }
                    if (res.data.data.flag==false && res.data.data.code!==401){
                        console.log(res.data.data.message)
                    }
                    if (res.data.data.code==401){
                        setTimeout(()=>{
                            that.getLoginStatus()
                        },100)
                    }
                })
            }
        },
        mounted() {

            // var userAgent = window.navigator.userAgent.toLowerCase();
            // console.log(userAgent)
            // this.toLogin()
            // const script = document.createElement('script')
            // script.type = 'text/javascript'
            // script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
            // document.body.appendChild(script)
            this.getLoginQr()
        }
    }
</script>

<style scoped>
    .login-box-class{
        display: flex;
        flex-direction: column;
        background: linear-gradient(rgba(0, 255, 252, 0.14),rgba(0, 190, 187, 0.96));
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        align-items: center;
        /*justify-content: center;*/
        background-image: url("../src/assets/image/background.gif");
    }
    .login-form-class{
        margin-top: 200px;
        height: 400px;
        width: 800px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        background-color: rgba(255,255,255,0.4);
    }
    .form-row-class:first-child{
        margin-top: -20px;
    }
    .form-row-class{
        text-align: left;
        opacity: 1;
        margin-top: 30px;
    }
    input{
        height: 25px;
        /*margin-left: 20px;*/
        border-radius: 5px;
        border: #bcbcbc solid 1px;
        text-indent: 20px;
        background:rgba(255,255,255,0.1);
    }
    input:focus{
        outline: #68fffd solid 1px;
    }
    button{
        margin-top: 50px;
        width: 230px;
        margin-left: 20px;
        height: 30px;
        background: linear-gradient(to top, #2bb3ab, rgba(0, 163, 95, 0.49));
        border: none;
        border-radius: 5px;
    }
    button:hover{
        background: #3afff7;
    }
</style>