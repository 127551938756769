<template>
    <div>
        <div style="display: flex;flex-direction: row;">
            <div @click="activeChange(index)" v-for="(item,index) in comdData.data" :key="index"
            :style="active==index?'border-bottom: orange solid 2px;flex: 1;margin:10px':'border: none;flex: 1;margin:10px'">
                {{item.showName}}
            </div>
        </div>
        <commodity-group-card-item :comd-data="comdData" :active="active"></commodity-group-card-item>
    </div>
</template>

<script>
    import CommodityGroupCardItem from "./commodity-group-card-item";
    export default {
        name: "commodity-group-item",
        components: {CommodityGroupCardItem},
        props:{
            comdData: {
                singleLine:Boolean,
                changeNum:Number,
                showType: Number,
                priceColor: String,
                priceFontSize: Number,
                showLable: Number,
                showPrice: Boolean,
                lableColor: String,
                lableBackgroundColor: String,
                lableTxt: String,
                data:Array
            },
        },
        computed:{
        },
        watch:{
            // active(newActive){
            //     this.tabCom_.data.forEach((item,index) =>{
            //         if (newActive === index){
            //             var dc=document.getElementById('tab_' + index)
            //             dc.setAttribute('style','border-bottom: orange solid 2px;flex: 1;margin:10px')
            //         }else {
            //             var dc2=document.getElementById('tab_' + index)
            //             dc2.setAttribute('style','border: none;flex: 1;margin:10px')
            //         }
            //
            //     })
            // }
        },
        data(){
            return{
                tabCom_: null,
                active:-1
            }
        },
        methods:{
            getTabId(index){
                return 'tab_'+index
            },
            activeChange(index){
                this.active=index
            }
        },
        mounted() {
            this.tabCom_ = this.comdData
            this.active = 0
        }
    }
</script>

<style scoped>

</style>