<template>
    <div class="dialog-class">
        <div style="padding: 10px;font-size: 13px;background-color: white;margin: 10px;color: rgb(110, 110, 110);">
            <div style="text-align: center;color: black;border-bottom: whitesmoke solid 1px;"><text>快递单号({{shipperCode}}): </text><text>{{logisticCode}}</text></div>
            <div style="height: 500px;overflow: scroll">
                <div v-for= "(item,index) in traces"  style="display: flex;flex-direction: row;position: relative;">
                    <div style="position: relative;min-width: 40px;">
                        <div class="icon-class" :style="index==0?'background-color:black':''"></div>
                        <div class="after-line-class" :style="index==0?'border-color:black':''"></div>
                    </div>
                    <div :style="index==0?'color:black':''">
                        <div style="margin-top: 20px;">[{{item.location}}]{{item.acceptStation}}</div>
                        <div>{{item.acceptTime}}</div>
                    </div>
                </div>
            </div>
            <div style="width: 100%;border-bottom: #bcbcbc solid 1px;margin-bottom: 20px"></div>
            <div style="text-align: center"><div class="bt-class" @click="toclose">确定</div></div>
        </div>
    </div>
</template>

<script>
    import {postRequest} from "../../utils/api";
    export default {
        name: "QueryTraces",
        emits:['tclose'],
        props:['customerName','shipperCode','logisticCode'],
        data(){
            return{
                traces:[],
                showloading:false
            }
        },
        methods:{
            get(){
                postRequest('kdniao/queryExpressTraces',{customerName:this.customerName,shipperCode:this.shipperCode,logisticCode:this.logisticCode}).then(res=>{
                    this.traces=res.data.data.traces
                })
            },
            toclose(){
                // console.log(1)
                this.$emit('tclose')
            }
        },
        mounted() {
            this.get()
        }
    }
</script>

<style scoped>
    .dialog-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 600px;
        width: 600px;
        background-color: #ffffff;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
        padding: 20px;
        z-index: 1000;
    }
    .icon-class{
        background-color: rgb(161, 160, 160);
        width: 5px;
        height: 5px;
        border-radius: 200px;
        margin-right: 200px;
        position: absolute;
        top: 40px;
    }
    .after-line-class{
        border-left: rgb(161, 160, 160) solid 1px;
        position: absolute;
        top: 60px;
        bottom: -30px;
        left: 2px;
    }
    ::-webkit-scrollbar {
        display:none
    }
    .bt-class{
        text-align: center;
        margin-left: auto;
        width: 80px;
        height: 25px;
        background-color: #4cb4ff;
        color: white;
        line-height: 25px;
        border-radius: 3px;
        cursor: pointer;
    }
</style>