<template>
    <div style="background-color: whitesmoke">
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>导购任务详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
            <span style="margin-right: auto">导购任务</span>
            <el-button :disabled="readonly" type="primary" style="width: 90px" @click="toSave">保存</el-button>
            <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
        </div>
        <div style="background-color: whitesmoke;padding: 10px" v-loading="showLoading">
            <div style="background-color: white;padding: 10px">
                <div class="row-class">
                    <span>任务名称</span>
                    <el-select
                            v-model="tsk.task_name"
                            placeholder="任务名称"
                            style="width: 240px"
                            @change="namechange"
                    >
                        <el-option
                                v-for="item in task_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        />
                    </el-select>
                </div>
                <div class="row-class" style="width: 600px;">
                    <span>任务时间</span>
                    <el-date-picker
                            v-model="tsk.validity_date"
                            type="daterange"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="起始日期"
                            end-placeholder="截至日期"
                    />
                </div>
                <div class="row-class">
                    <span>任务备注</span>
                    <el-input v-model="tsk.task_note" type="textarea" style="width: 500px;"></el-input>
                </div>
                <div class="row-class">
                    <span>回访条件</span>
                    <div style="text-align: left">
                        <div v-if="tsk.task_name=='生日问候'">
                            <div v-for="(it,id) in tsk.triggers">
                                <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                                    <span>生日前</span>
                                    <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="tsk.triggers[id]" :min="0"></el-input-number>
                                </span>
                                    <span style="color: black;margin-left: 20px">天问候</span>
                                    <span @click="delTrigger(id)" class="del-class"><el-icon style="font-size: 20px;"><Close /></el-icon></span>
                                </div>
                            </div>
                        </div>
                        <div v-if="tsk.task_name=='休眠激活'">
                            <div v-for="(it,id) in tsk.triggers">
                                <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                                    <span style="width: 500px;">
                                        <el-input-number type="number" v-model="tsk.triggers[id]" :min="0"></el-input-number>
                                    </span>
                                    <span style="color: black;margin-left: 20px">天问以上未消费</span>
                                    <span @click="delTrigger(id)" class="del-class"><el-icon style="font-size: 20px;"><Close /></el-icon></span>
                                </div>
                            </div>
                        </div>
                        <el-button v-if="tsk.task_name!=''" @click="addTrigger" icon="Plus" style="color: blue;width: 100px">添加</el-button>
                    </div>

                </div>
                <div class="row-class">
                    <span>任务过期时间</span>
                    <el-input-number type="number" v-model="tsk.expire_days"  style="width: 200px;"></el-input-number>
                    <span style="color: black;margin-left: 20px">天</span>
                </div>
                <div style="text-align: left;margin-left: 30px;margin-top: 30px">赠送优惠券:</div>
                <div style="margin-top: 10px;background-color: white;">
                    <table style="max-width: 800px;margin-left: 30px">
                        <thead>
                        <th>券ID</th>
                        <th>优惠券名称</th>
                        <th>发券后X天内有效</th>
                        <th>最大可发数量</th>
                        <th v-if="!readonly">删除</th>
                        </thead>
                        <tbody>
                        <tr v-for="it in tsk.coupons">
                            <td>{{it.coupon_group_id}}</td>
                            <td>{{it.coupon_group_name}}</td>
                            <td><el-input-number v-model="it.valid_days" :min="1"></el-input-number></td>
                            <td><el-input-number v-model="it.num" :min="1"></el-input-number></td>
                            <td v-if="!readonly">
                                <span @click="delCoupon(it)">删除</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <coupon-group-select v-model="showCouponSelect" multiple-select="true" @confirm="couponSelectConfirm"></coupon-group-select>
                <div style="text-align: left;margin-left: 30px;margin-top: 20px;"><el-button @click="toSelectCoupon" style="border: rgba(0,0,255,0.5) solid 1px;color: rgba(0,0,255,0.5)">添加优惠券</el-button></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import {getUserId} from "../../utils/auth";
    import CouponGroupSelect from "../common/coupon-group-select";

    export default {
        name: "GuiderTaskEdit",
        components: {CouponGroupSelect},
        data(){
            return{
                showCouponSelect:false,
                readonly:false,
                showLoading:false,
                tsk:{
                    validity_date:[],
                    task_name:'',
                    task_note:'',
                    triggers:[],
                    expire_days:7,
                    coupons:[]
                },
                select:{},
                task_options:[
                    {value:"生日问候",lable: '生日问候'},
                    {value:"休眠激活",lable: '休眠激活'},
                ]
            }
        },
        methods:{
            backHome(){this.$router.back()},
            getDetail(){
                this.showLoading=true
                getRequest('vip/getGuiderTaskById?id='+this.tsk.id).then(res=>{
                    this.showLoading=false
                    this.tsk=res.data.data
                    this.tsk.validity_date=[this.tsk.start_time,this.tsk.end_time]
                })
            },
            toSave(){
                this.showLoading=true
                this.tsk.creator=getUserId()
                if (this.tsk.validity_date !=null && this.tsk.validity_date.length>1){
                    this.tsk.start_time=this.tsk.validity_date[0]
                    this.tsk.end_time=this.tsk.validity_date[1]
                }else {
                    this.$alert("请填写任务时间")
                    return
                }
                postRequest("vip/updateGuiderTask",this.tsk).then(res=>{
                    this.showLoading=false
                    this.$alert(res.data.message)
                    if (res.data.flag==true && this.tsk.id==-1){
                        this.$router.back()
                    }
                })
            },
            addTrigger(){
                if (this.tsk.task_name=='生日问候'){
                    this.tsk.triggers.push(7)
                }
                if (this.tsk.task_name=='休眠激活'){
                    this.tsk.triggers.push(180)
                }

            },
            namechange(v){
               this.tsk.triggers=[]
            },
            delTrigger(id){
                var t=[]
                for (var i=0;i<this.tsk.triggers.length;i++) {
                    if (i !==id){
                        t.push(this.tsk.triggers[i])
                    }
                }
                this.tsk.triggers=t
            },
            toSelectCoupon(){
                this.showCouponSelect=true
            },
            couponSelectConfirm(e){
                console.log(e)
                var flag=flag
                for (var i=0;i<e.length;i++){
                    flag=false
                    for (var a=0;a<this.tsk.coupons.length;a++){
                        if (e[i].id==this.tsk.coupons[a].coupon_group_id){
                            flag=true
                        }
                    }
                    if (!flag){
                        this.tsk.coupons.push({
                            coupon_group_id:e[i].id,
                            coupon_group_name:e[i].name,
                            valid_days:30,
                            num:1
                        })
                    }
                }
            },
            delCoupon(it){
                var t=[]
                for (var i=0;i<this.tsk.coupons.length;i++){
                    if (this.tsk.coupons[i].coupon_group_id!=it.coupon_group_id){
                        t.push(this.tsk.coupons[i])
                    }
                }
                this.tsk.coupons=t

            },
        },
        mounted() {
            console.log(this.tsk.id=this.$route.params.id)
            this.tsk.id=this.$route.params.id
            if (this.tsk.id!=-1){
                // this.readonly=false
                this.getDetail()
            }
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 10px;
        flex-direction: row;
        text-align: center;
    }
    .row-class span{
        width: 200px;
    }
    .row-class .el-input{
        width: 400px;
    }
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
    .del-class{
        margin-left: 10px;
    }
    .del-class:hover{
        color: red;
    }
</style>