<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员升级策略</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <div style="background-color: #eeeeee;padding: 10px">
        <div style="background-color: white;text-align: left;margin-top: 10px;padding-bottom: 10px;padding: 10px">
            <el-button type="success" style="padding: 10px" @click="toAdd">新增策略</el-button>
            <el-button type="primary" style="padding: 10px" @click="getList">查询</el-button>
        </div>
        <div style="margin-top: 10px;background-color: white;height: 550px;overflow: scroll">
            <table>
                <thead>
                <th>ID</th>
                <th>原等级</th>
                <th>升级等级</th>
                <th>条件</th>
                <th>状态</th>
                <th>操作</th>
                </thead>
                <tbody>
                <tr v-for="it in tableData">
                    <td>{{it.id}}</td>
                    <td>{{it.level1_code+' ' + it.level1_name}}</td>
                    <td>{{it.level2_code+' ' + it.level2_name}}</td>
                    <td >
                        <span style="color: black;margin: 10px">{{it.buy_cycle}}</span>
                        <span>天内累计消费</span>
                        <span style="color: blue;margin: 10px">{{it.cycle_amount}}</span>
                        <span >元或者单笔消费满</span>
                        <span style="color: red;margin: 10px">{{it.once_amount}}</span>
                        <span >元可升级</span>
                    </td>
                    <td>{{it.enabled==true?'已启用':'未启用'}}</td>
                    <td>
                        <span @click="toEnabled(it.id)" v-if="!it.enabled" style="cursor: pointer;color: blue">启用</span>
                        <span @click="toDisabled(it.id)" v-if="it.enabled" style="cursor: pointer;color: red">停用</span>
                        <span @click="toEdit(it)" style="margin-left: 20px;cursor: pointer;color: blue">编辑</span>
                        <span @click="todel(it.id)" style="margin-left: 20px;color: #b7b7b7;cursor: pointer">删除</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <el-dialog v-model="showEdit" width="900" style="padding-left: 20px;padding-right: 20px" title="升级策略">
       <div v-loading="showLoadingEdit">
           <div class="row-class">
               <span>原始等级：</span>
               <el-input :model-value="dto.level1_code + ' ' + dto.level1_name">
                   <template #append>
                       <el-button @click="toSelectLevel(1)" icon="ArrowDown" />
                   </template>
               </el-input>
           </div>
           <vip-level-select v-model="showLevelSelect" v-if="showLevelSelect" @confirm="levelConfirm"></vip-level-select>
           <div class="row-class">
               <span>升级等级：</span>
               <el-input :model-value="dto.level2_code+ ' ' +dto.level2_name">
                   <template #append>
                       <el-button @click="toSelectLevel(2)" icon="ArrowDown" />
                   </template>
               </el-input>
           </div>
           <div class="row-class">
               <span>升级条件：</span>
               <el-input-number v-model="dto.buy_cycle" style="width: 140px"></el-input-number>
               <span style="width: auto;margin: 5px">天内累计消费</span>
               <el-input-number v-model="dto.cycle_amount" style="width: 140px"></el-input-number>
               <span style="width: auto;margin: 5px">元或者单笔消费满</span>
               <el-input-number v-model="dto.once_amount" style="width: 140px"></el-input-number>
               <span style="width: auto;margin: 5px">元可升级</span>
           </div>
           <div style="padding: 5px;text-align: right;margin-top: 50px">
               <el-button @click="showEdit=false">取消</el-button>
               <el-button type="primary" style="margin-right: 20px;margin-left: 20px" @click="saveEdit">保存</el-button>
           </div>
       </div>
    </el-dialog>
</template>

<script>
    import VipLevelSelect from "../common/vip-level-select";
    import {getRequest, postRequest} from "../../utils/api";
    export default {
        name: "LevelUpConditionList",
        components: {VipLevelSelect},
        data(){
            return{
                showEdit:false,
                showLevelSelect:false,
                showLoadingEdit:false,
                dto:{
                    id:-1,
                    level1_code:'',
                    level1_name:'',
                    level2_code:'',
                    level2_name:'',
                },
                selectLv:1,
                tableData:[]
            }
        },
        methods:{
            toAdd(){
                this.dto={
                    id:-1,
                    level1_code:'',
                    level1_name:'',
                    level2_code:'',
                    level2_name:'',
                }
                this.showEdit=true
            },
            toSelectLevel(lv){
               this.selectLv=lv
                this.showLevelSelect=true
            },
            levelConfirm(e){
                if (this.selectLv===1){
                    this.dto.level1_code=e[0].level_code
                    this.dto.level1_name=e[0].level_name
                }else {
                    this.dto.level2_code=e[0].level_code
                    this.dto.level2_name=e[0].level_name
                }
            },
            saveEdit(){
                if (this.dto.id==-1){
                    postRequest('vip/submitLevelUpCondition',this.dto).then(res=>{
                        if (res.data.flag){
                            this.showEdit=false
                            this.getList()
                        }
                        this.$msgbox.alert(res.data.message)
                    })
                }else {
                    postRequest('vip/updateLevelUpCondition',this.dto).then(res=>{
                        if (res.data.flag){
                            this.showEdit=false
                            this.getList()
                        }
                        this.$msgbox.alert(res.data.message)
                    })
                }
            },
            getList(){
                getRequest('vip/getLevelUpConditionList').then(res=>{
                    if (res.data.flag){
                        this.tableData=res.data.data
                    }else {
                        this.$msgbox.alert(res.data.message)
                    }

                })
            },
            toEdit(it){
                this.showEdit=true
                this.showLoadingEdit=true
                getRequest('vip/getLevelUpConditionById?id='+it.id).then(res=>{
                    this.showLoadingEdit=false
                    if (res.data.flag){
                        this.dto=res.data.data
                    }else {
                        this.$msgbox(res.data.massage)
                    }
                })
            },
            toEnabled(id) {
                getRequest('vip/updateLevelUpConditionEnabled?id='+id+'&enabled=true').then(res=>{
                    this.$msgbox.alert(res.data.message)
                    this.getList()
                })
            },
            toDisabled(id) {
                getRequest('vip/updateLevelUpConditionEnabled?id='+id+'&enabled=false').then(res=>{
                    this.$msgbox.alert(res.data.message)
                    this.getList()
                })
            },
            todel(id){
                this.$msgbox.confirm("确认删除该策略?!").then(()=>{
                    getRequest('vip/delLevelUpCondition?id='+id).then(res=>{
                        this.$msgbox.alert(res.data.message)
                        this.getList()
                    })
                }).catch(()=>{})
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        text-align: left;
        align-items: center;
    }
    .row-class span{
        width: 100px;
    }
    .row-class .el-input{
        width: 400px;
    }
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>