
import { createRouter, createWebHistory } from 'vue-router'
import AdminHome from "@/AdminHome";
import login from "@/login";
import {getLoginFlag} from "@/utils/auth";
import UserManage from "@/components/system/UserManage";
import WelcomePage from "@/WelcomePage";
import RoleManage from "@/components/system/RoleManage";
import MenuManage from "@/components/system/MenuManage";
import GoodsManage from "@/components/mall/GoodsManage";
import GoodsEdit from "@/components/mall/GoodsEdit";
import GoodsGroupMange from "@/components/mall/GoodsGroupMange";
import WePageList from "@/components/mall/WePageList";
import WePageEdit from "@/components/mall/WePageEdit";
import TabBarManage from "@/components/mall/TabBarManage";
import FreightTempleteList from "@/components/mall/FreightTempleteList";
import FreightTempleteEdit from "@/components/mall/FreightTempleteEdit";
import VipList from "@/components/vip/VipList";
import VipDetail from "@/components/vip/VipDetail";
import Market from "@/components/vip/Market";
import SendCoupon from "@/components/vip/SendCoupon";
import SendCouponList from "@/components/vip/SendCouponList";
import OrderManage from "@/components/order/OrderManage";
import ReturnManage from "@/components/order/ReturnManage";
import ReturnConfig from "@/components/mall/ReturnConfig";
import ExpressCompany from "@/components/mall/ExpressCompany";
import ReturnOrderDetail from "@/components/order/ReturnOrderDetail";
import OrderDetail from "@/components/order/OrderDetail";
import ScheduleManage from "@/components/system/ScheduleManage";
import GiftList from "@/components/mall/GiftList";
import GiftEidt from "@/components/mall/GiftEidt";
import SendCouponDetail from "@/components/vip/SendCouponDetail";
import RegisterGiftList from "@/components/vip/RegisterGiftList";
import RegisterGiftEdit from "@/components/vip/RegisterGiftEdit";
import BirthdayGiftList from "@/components/vip/BirthdayGiftList";
import BirthdayGiftEdit from "@/components/vip/BirthdayGiftEdit";
import AutometicMarketingList from "@/components/vip/AutometicMarketingList";
import AutometicMarketingEdit from "@/components/vip/AutometicMarketingEdit";
import IntegralBuyCouponList from "@/components/vip/IntegralBuyCouponList";
import IntegralBuyCouponEdit from "@/components/vip/IntegralBuyCouponEdit";
import PickupCouponList from "@/components/vip/PickupCouponList";
import PickupCouponEdit from "@/components/vip/PickupCouponEdit";
import WelcomeList from "@/components/wecom/WelcomeList";
import WelcomeEdit from "@/components/wecom/WelcomeEdit";
import QwOauth2 from "@/components/system/QwOauth2";
import GuiderList from "@/components/wecom/GuiderList";
import LevelUpConditionList from "@/components/vip/LevelUpConditionList";
import LevelUpGiftList from "@/components/vip/LevelUpGiftList";
import LevelUpGiftEdit from "@/components/vip/LevelUpGiftEdit";
import OfaMenu from "@/components/wecom/OfaMenu";
import ExportTask from "@/components/system/ExportTask";
import CsmSetting from "@/components/mall/CsmSetting";
import GuiderTask from "@/components/vip/GuiderTask";
import GuiderTaskEdit from "@/components/vip/GuiderTaskEdit";
import Wxlink from "@/components/system/Wxlink";
import IntegralDraw from "@/components/vip/IntegralDraw";
import IntegralDrawEdit from "@/components/vip/IntegralDrawEdit";
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path:'/login',
            name: 'login',
            component: login,
            meta:{
                requireAuth: false
            }
        },
        {
            path:'/home',
            name: 'AdminHome',
            component: AdminHome,
            meta:{
                requireAuth: true
            },
            children: [
                {
                    path:'/WelcomePage',
                    name: 'WelcomePage',
                    component: WelcomePage,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/UserManage',
                    name: 'UserManage',
                    component: UserManage,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/RoleManage',
                    name: 'RoleManage',
                    component: RoleManage,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/ScheduleManage',
                    name: 'ScheduleManage',
                    component: ScheduleManage,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/ExportTask',
                    name: 'ExportTask',
                    component: ExportTask,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/MenuManage',
                    name: 'MenuManage',
                    component: MenuManage,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/FreightTempleteList',
                    name: 'FreightTempleteList',
                    component: FreightTempleteList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/FreightTempleteEdit/:id',
                    name: 'FreightTempleteEdit',
                    component: FreightTempleteEdit,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/WePageList',
                    name: 'WePageList',
                    component: WePageList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/GoodsManage',
                    name: 'GoodsManage',
                    component: GoodsManage,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/GoodsEdit/:goodscode',
                    name: 'GoodsEdit',
                    component: GoodsEdit,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/GoodsGroupMange',
                    name: 'GoodsGroupMange',
                    component: GoodsGroupMange,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/TabBarManage',
                    name: 'TabBarManage',
                    component: TabBarManage,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/VipList',
                    name: 'VipList',
                    component: VipList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/VipDetail/:vip_code',
                    name: 'VipDetail',
                    component: VipDetail,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/LevelUpConditionList',
                    name: 'LevelUpConditionList',
                    component: LevelUpConditionList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/Market',
                    name: 'Market',
                    component: Market,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/SendCoupon',
                    name: 'SendCoupon',
                    component: SendCoupon,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/SendCouponDetail/:activity_id',
                    name: 'SendCouponDetail',
                    component: SendCouponDetail,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/SendCouponList',
                    name: 'SendCouponList',
                    component: SendCouponList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/RegisterGiftList',
                    name: 'RegisterGiftList',
                    component: RegisterGiftList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/RegisterGiftEdit/:activity_id',
                    name: 'RegisterGiftEdit',
                    component: RegisterGiftEdit,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/LevelUpGiftList',
                    name: 'LevelUpGiftList',
                    component: LevelUpGiftList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/LevelUpGiftEdit/:id',
                    name: 'LevelUpGiftEdit',
                    component: LevelUpGiftEdit,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/BirthdayGiftList',
                    name: 'BirthdayGiftList',
                    component: BirthdayGiftList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/BirthdayGiftEdit/:id',
                    name: 'BirthdayGiftEdit',
                    component: BirthdayGiftEdit,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/IntegralDraw',
                    name: 'IntegralDraw',
                    component: IntegralDraw,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/IntegralDrawEdit/:id',
                    name: 'IntegralDrawEdit',
                    component: IntegralDrawEdit,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/GuiderTask',
                    name: 'GuiderTask',
                    component: GuiderTask,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/GuiderTaskEdit/:id',
                    name: 'GuiderTaskEdit',
                    component: GuiderTaskEdit,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/AutometicMarketingList',
                    name: 'AutometicMarketingList',
                    component: AutometicMarketingList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/AutometicMarketingEdit/:id',
                    name: 'AutometicMarketingEdit',
                    component: AutometicMarketingEdit,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/OrderManage',
                    name: 'OrderManage',
                    component: OrderManage,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/ReturnManage',
                    name: 'ReturnManage',
                    component: ReturnManage,
                    meta:{
                        requireAuth: true,
                        keepAlive: true
                    }
                },
                {
                    path:'/OrderDetail/:order_id',
                    name: 'OrderDetail',
                    component: OrderDetail,
                    meta:{
                        requireAuth: true,
                    }
                },
                {
                    path:'/ReturnOrderDetail/:return_id',
                    name: 'ReturnOrderDetail',
                    component: ReturnOrderDetail,
                    meta:{
                        requireAuth: true,
                    }
                },
                {
                    path:'/ReturnConfig',
                    name: 'ReturnConfig',
                    component: ReturnConfig,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/IntegralBuyCouponList',
                    name: 'IntegralBuyCouponList',
                    component: IntegralBuyCouponList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/IntegralBuyCouponEdit/:id',
                    name: 'IntegralBuyCouponEdit',
                    component: IntegralBuyCouponEdit,
                    meta:{
                        requireAuth: true,
                    }
                },
                {
                    path:'/PickupCouponList',
                    name: 'PickupCouponList',
                    component: PickupCouponList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/PickupCouponEdit/:id',
                    name: 'PickupCouponEdit',
                    component: PickupCouponEdit,
                    meta:{
                        requireAuth: true,
                    }
                },
                {
                    path:'/GiftList',
                    name: 'GiftList',
                    component: GiftList,
                    meta:{
                        requireAuth: true,
                    }
                },
                {
                    path:'/GiftEidt/:giftcode',
                    name: 'GiftEidt',
                    component: GiftEidt,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/ExpressCompany',
                    name: 'ExpressCompany',
                    component: ExpressCompany,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/CsmSetting',
                    name: 'CsmSetting',
                    component: CsmSetting,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/WelcomeList',
                    name: 'WelcomeList',
                    component: WelcomeList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/WelcomeEdit/:id',
                    name: 'WelcomeEdit',
                    component: WelcomeEdit,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/GuiderList',
                    name: 'GuiderList',
                    component: GuiderList,
                    meta:{
                        requireAuth: true
                    }
                },
                {
                    path:'/OfaMenu',
                    name: 'OfaMenu',
                    component: OfaMenu,
                    meta:{
                        requireAuth: true
                    }
                },

            ]
        },
        {
            path:'/WePageEdit/:id',
            name: 'WePageEdit',
            component: WePageEdit,
            meta:{
                requireAuth: true
            }
        },
        {
            path:'/QwOauth2',
            name: 'QwOauth2',
            component: QwOauth2,
            meta:{
                requireAuth: false
            }
        },
        {
            path:'/Wxlink',
            name: 'Wxlink',
            component: Wxlink,
            meta:{
                requireAuth: false
            }
        },

    ], // `routes: routes` 的缩写
})
export default router

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth && !getLoginFlag()==true){
        next({
            path: '/login'
        })
    }else {
        next()
    }
})