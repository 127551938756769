<template>
    <div v-loading="showloading">
        <vip-basic-information-com :vip-info="vipInfo"></vip-basic-information-com>
        <div style="margin-top: 10px;display: flex;">
            <el-button @click="updatePb(true)" type="info" v-if="!vipInfo.pontis_blacklist">加入积分黑名单</el-button>
            <el-button @click="updatePb(false)" type="info" v-if="vipInfo.pontis_blacklist"><el-icon><Lock /></el-icon>解除积分黑名单</el-button>
            <el-button @click="updateCb(true)" type="warning" v-if="!vipInfo.consumption_blacklist">加入消费黑名单</el-button>
            <el-button @click="updateCb(false)" type="warning" v-if="vipInfo.consumption_blacklist"><el-icon><Lock /></el-icon>解除消费黑名单</el-button>
            <el-button>积分调整</el-button>
            <el-button @click="unBindWx">解绑微信</el-button>
        </div>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="优惠券" name="coupon"><vip-coupon-com v-if="ismounted" :vip-code="vip_code"></vip-coupon-com></el-tab-pane>
            <el-tab-pane label="消费记录" name="purchase "><vip-buy-record-com v-if="ismounted" :vip-code="vip_code"></vip-buy-record-com></el-tab-pane>
            <el-tab-pane label="积分流水" name="points"><vip-integral-bill-com v-if="ismounted" :vip-code="vip_code"></vip-integral-bill-com></el-tab-pane>
            <el-tab-pane label="等级变更记录" name="levelChange"><vip-level-change-com v-if="ismounted" :vip-code="vip_code"></vip-level-change-com></el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import VipBasicInformationCom from "./com/vip-basic-information-com";
    import {getRequest} from "../../utils/api";
    import VipCouponCom from "./com/vip-coupon-com";
    import VipBuyRecordCom from "./com/vip-buy-record-com";
    import VipIntegralBillCom from "./com/vip-integral-bill-com";
    import VipLevelChangeCom from "./com/vip-level-change-com";
    import { ElMessageBox } from 'element-plus'
    export default {
        name: "VipDetail",
        components: {VipLevelChangeCom, VipIntegralBillCom, VipBuyRecordCom, VipCouponCom, VipBasicInformationCom},
        data(){
            return{
                activeName:'coupon',
                vip_code:'',
                vipInfo:{},
                ismounted:false,
                showloading:false
            }
        },
        methods:{
            handleClick(e){
                console.log(e)
                console.log(this.activeName)
            },
            getVipInfo(){
                this.showloading=true
                getRequest('vip/getVipInfoByCode?vip_code='+this.vip_code).then(res=>{
                    this.showloading=false
                    console.log(res)
                    this.vipInfo=res.data.data
                })
            },
            updatePb(s){
                var msg=''
                if (s==false){
                    msg="确认将该顾客加入积分黑名单？！"
                }else {
                    msg="确认将该顾客解除积分黑名单？！"
                }
                console.log(msg)
                var that=this
                ElMessageBox.confirm( msg,
                    '重要提示',
                    {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(()=>{
                        getRequest('vip/updateVipPontisBlacklist?vip_code='+this.vip_code+'&status='+s).then(res=>{
                            ElMessageBox.alert(res.data.message)
                            if (res.data.flag){
                                that.getVipInfo()
                            }
                        })
                    }
                ).catch(()=>{})
            },
            updateCb(s){
                var msg=''
                if (s==false){
                    msg="确认将该顾客加入消费黑名单？！"
                }else {
                    msg="确认将该顾客解除消费黑名单？！"
                }
                console.log(msg)
                var that=this
                ElMessageBox.confirm( msg,
                    '重要提示',
                    {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(()=>{
                        getRequest('vip/updateVipConsumptionBlacklist?vip_code='+this.vip_code+'&status='+s).then(res=>{
                            ElMessageBox.alert(res.data.message)
                            if (res.data.flag){
                                that.getVipInfo()
                            }
                        })
                    }
                ).catch(()=>{})
            },
            unBindWx(){
                var msg='解绑后将无法恢复,确认解绑?!'
                var that=this
                ElMessageBox.confirm( msg,
                    '重要提示',
                    {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(()=>{
                        getRequest('vip/unbindVipWechat?vip_code='+this.vip_code).then(res=>{
                            ElMessageBox.alert(res.data.message)
                            if (res.data.flag){
                                that.getVipInfo()
                            }
                        })
                    }
                ).catch(()=>{})
            }
        },
        mounted() {
            this.vip_code=this.$route.params.vip_code
            this.getVipInfo()
            this.ismounted=true
        }
    }
</script>

<style scoped>

</style>