<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>角色管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="display: flex;flex-direction: row">
            <el-input size="small" style="width: 300px"></el-input>
            <el-button type="primary" size="small" style="margin-left: 30px" @click="getAllRole">搜索</el-button>
            <el-button type="success" size="small" style="margin-left: 30px" @click="toAddRole">添加角色</el-button>
        </div>
        <div style="border-bottom: #cdcdd5 solid 1px;width: 100%;margin-top: 20px;margin-bottom: 20px"></div>
        <div>
            <el-table :data="tableData" height="500" border style="width: 800px">
                <el-table-column prop="role_id" label="角色iD" width="100px"/>
                <el-table-column prop="role_name" label="角色名" width="150px"/>
                <el-table-column prop="enabled" label="启用" width="100px">
                    <template #default="scope">
                        <el-switch v-model=scope.row.enabled :disabled="true"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" >
                    <template #default="scope">
<!--                        <el-button @click="roleEdit(scope.row)" type="primary" icon="EditPen" size="small">信息</el-button>-->
                        <el-button v-if="scope.row.enabled" type="danger" icon="Delete" size="small" @click="updateEnabled(scope.row)">停用</el-button>
                        <el-button v-if="!scope.row.enabled" type="success" icon="Check" size="small" @click="updateEnabled(scope.row)">启用</el-button>
                        <el-button @click="roleAuthEdit(scope.row)" type="warning" icon="View" size="small">权限</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="z-index: 100;width: 100%;position: fixed;top:0;bottom: 0;background-color: #999999;opacity: 0.5;left: 0" v-show="showAddRole">
        </div>
        <div class="user-add-class" v-show="showAddRole" width="50%" >
            <div style="padding: 20px">
                <div>添加角色</div>
                <div>
                    <div  class="user-info-box">
                        <div class="add-line-class">角色名称</div>
                        <el-input size="small" v-model="roleName"></el-input>
                    </div>
                </div>
                <div style="padding-left: 80px;padding-right: 40px; justify-content: space-between ;display: flex;flex-direction: row;text-align: center;margin-top: 30px">
                    <el-button size="small" @click="showAddRole = false">取消</el-button>
                    <el-button size="small"  type="success" @click="subAdd">添加</el-button>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="showAuthEdit"
            :title="editRow.role_name"
            width="400px"
            :before-close="handleClose"
            style="height: 600px;"
        >
            <div style="height: 460px;overflow: scroll;" v-loading="showLoadingAuth">
                <el-tree
                        ref="treeRef"
                        :data="authData"
                        show-checkbox
                        @check="checkChange"
                        node-key="menu_id"
                        highlight-current
                        :props="defaultProps"
                        :default-checked-keys="authedMenu"
                        style="margin-left: 60px;"
                />
            </div>

            <div style="text-align: right;position: absolute;bottom: 0px;width: 100%;background-color: rgba(0,201,255,0.12);left: 0"><el-button @click="submitAuthUpdate" style="right: 30px">保存</el-button></div>
        </el-dialog>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import { ElMessage, ElMessageBox } from 'element-plus'

    export default {
        name: "UserManage",
        data(){
            return{
                editRow: {},
                AuthUpdatArr:[],
                showAddRole:false,
                showAuthEdit: false,
                roleName: '',
                tableData:[],
                authData:[],
                authedMenu:[],
                authedMenuTemp:[],
                defaultProps: {
                    children: 'childrenList',
                    label: 'menu_name',
                },
                showLoadingAuth:false
            }
        },
        methods:{
            roleEdit(){},
            roleAuthEdit(row){
                this.showLoadingAuth=true
                this.authedMenu = []
                this.authedMenuTemp = []
                this.editRow = row
                getRequest('user/getAllMenus').then(res => {
                    this.authData = res.data.data
                    getRequest('/user/getRoleAuth?roleid=' + row.role_id).then(res => {
                        this.showLoadingAuth=false
                        this.authedMenuTemp = res.data.data
                        this.removeParent(this.authData)
                        this.authedMenu = this.authedMenuTemp
                    })
                })

                this.showAuthEdit = true
            },
            removeParent(temp){
                for(var i1=0;i1<temp.length;i1++){
                    var t=temp[i1]
                    if (t.childrenList!=null){
                        var t2=[]
                        for (var i=0;i<this.authedMenuTemp.length;i++){
                            if (t.menu_id!=this.authedMenuTemp[i]){
                                t2.push(this.authedMenuTemp[i])
                            }
                        }
                        this.authedMenuTemp = t2
                        this.removeParent(t.childrenList)
                    }
                }
            },
            disabledRole(){},
            getAllRole(){
                getRequest('user/getAllRole').then(res => {
                    if (res.data.flag){
                        this.tableData = res.data.data
                    }else {
                        ElMessageBox.alert(res.data.message)
                    }

                })
            },
            updateEnabled(e){
                getRequest('user/updateRoleEnabled?roleid=' + e.role_id+ '&enabled=' + !e.enabled).then(res=>{
                    ElMessageBox.alert(res.data.message)
                    this.getAllRole()
                })
            },
            toAddRole(){
                this.showAddRole = true
            },
            handleClose(){
                this.authedMenu = []
                this.showAuthEdit = false
            },
            checkChange(e1,e2){
                // console.log(e1)
                console.log(e2)
                this.AuthUpdatArr=e2.checkedKeys.concat(e2.halfCheckedKeys)
                console.log(this.AuthUpdatArr)
            },
            subAdd(){
                if (this.roleName.length<1){
                    ElMessageBox.alert('请填写角色名称', {
                        confirmButtonText: 'OK'
                    })
                    return
                }
                postRequest('user/addRole',{'role_name': this.roleName}).then(res=>{
                    if (res.data.flag){
                        this.roleName=''
                        this.showAddRole = false
                        ElMessageBox.alert(res.data.data, res.data.message, {
                            confirmButtonText: 'OK'
                        })
                        this.getAllRole()
                    }else {
                        ElMessageBox.alert(res.data.message)
                    }
                })
            },
            submitAuthUpdate(){
                postRequest('user/updateRoleMenu',{'role_id': this.editRow.role_id,'menu_id': this.AuthUpdatArr}).then(res=>{
                    if (res.data.flag){
                        this.AuthUpdatArr=[]
                        this.showAuthEdit = false
                        ElMessageBox.alert(res.data.data, res.data.message, {
                            confirmButtonText: 'OK'
                        })
                    }
                })
            }
        },
        mounted() {
            this.getAllRole()
        }
    }
</script>

<style scoped>
    .user-add-class{
        border: #ffffff solid 1px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 400px;
        height: 200px;
        margin: auto;
        background-color: #ffffff;
        border-radius: 5px;
        z-index: 100;
    }
    .add-line-class{
        width: 90px;
        text-align-last: justify;
        margin-right: 20px;
    }
    .user-info-box{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>