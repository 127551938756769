<template>
    <div style="padding: 10px;text-align: left">
        <h4>带图商品配置</h4>
        <el-button icon="el-icon-plus" style="width: 100%;margin-top: 20px" type="primary" @click="toAdd">背景图片</el-button>
        <div  style="display: flex;align-items: center;margin-top: 10px">
            <div style="margin-right: 20px">背景图高度</div>
            <el-input-number :min="1" size="small"  v-model="imgHeight_" />
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;border: #eeeeee solid 1px;margin-top: 20px;position: relative;">
            <img :src="comData.background_image_url" style="width: 100px;height: 100px"/>
        </div>
        <div>
            <div style="display: flex;align-items: center;margin-top: 20px;margin-bottom: 20px">
                <div>热区</div>
                <el-button @click="toAddImgHot(index)" style="margin-left: 30px" size="small">新增热区</el-button>
            </div>
        </div>
        <div>
            <div v-for="(ht,index2) in comData.hot">
                <div style="display: flex;align-items: center">
                    <div style="color: red;margin-right: 5px">热区{{index2+1}}</div>
                    <div style="word-break:break-all;">链接:  {{ht.typeName +' | ' + ht.linkValue}}</div>
                    <el-dropdown split-button style="min-width: 100px;margin-left: auto">
                        选择
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="toSelectGoods(index2,2)">商品</el-dropdown-item>
                                <el-dropdown-item @click="toEditUrl(index2)">小程序</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <div @click="delHot(index2)" style="color: rgba(35,39,35,0.57);margin-left: auto;margin-right: 0px;cursor: pointer">删除</div>
                </div>
                <div style="border-bottom: #bcbcbc solid 1px;width: 100%;margin-bottom: 20px;margin-top: 10px"></div>
            </div>
        </div>
        <media-material-com v-model="showAdd" @confirm="addConfirm"></media-material-com>
        <div>添加商品</div>
        <div>
            <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div v-for="(item,index) in commditys" :key="index" style="margin: 10px">
                    <div class="close-icon-class">
                        <div class="el-icon-close" v-on:click="removeCommdity(index)" style="z-index: 1000"><el-icon><Close /></el-icon></div>
                    </div>
                    <img style="width: 70px;height: 70px" :src="item.pic_url"/>
                    <div style="font-size: 12px">{{item.goods_code}}</div>
                </div>
            </div>
            <el-button icon="el-icon-plus" style="width: 100%" @click="toSelectGoods(0,1)">添加商品</el-button>
            <div>
                <div>显示原价</div>
                <el-switch v-model="showPrice_" />
            </div>
            <div style="margin-top: 20px;">售价格式</div>
            <div style="background-color: rgba(122,224,205,0.22);border-radius: 5px">
                <div style="padding: 10px;display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-top: 10px">
                    <div>颜色值</div>
                    <input v-model="priceColorSelect"  style="width: 150px;height: 18px;margin-left: 10px">
                    <el-color-picker color-format="hex" @change="colorchange" v-model="priceColorSelect" style="margin-left: 20px"></el-color-picker>
                </div>
                <div class="slider-class" style="padding: 10px;">
                    <div>字体大小</div>
                    <el-slider style="width: 80%;margin-left: 20px" v-model="priceFontSize_" show-input :max=40></el-slider>
                </div>
            </div>

            <div style="margin-top: 20px;display: flex;align-items: center">
                <div>标签设置</div>
                <div style="margin-left: 10px;margin-right: 20px">显示标签: </div>
                <el-switch v-model="showLable_" />
            </div>
            <div style=";border-radius: 5px;margin-top: 10px;background-color: #eeeeee;padding: 20px;font-size: 13px">
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px">
                    <div>标签内容</div>
                    <input v-model="lableText" style="width: 150px;height: 18px;margin-left: 10px">
                </div>
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px;margin-top: 5px">
                    <div>标签颜色</div>
                    <input v-model="labelColorSelect" style="width: 150px;height: 18px;margin-left: 10px">
                    <el-color-picker color-format="hex" @change="labecolorchange" v-model="labelColorSelect" style="margin-left: 20px"></el-color-picker>
                </div>
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px">
                    <div>背景颜色</div>
                    <input v-model="labelBackgroundColorSelect" style="width: 150px;height: 18px;margin-left: 10px">
                    <el-color-picker color-format="hex" @change="labelBackgoundColorchange" v-model="labelBackgroundColorSelect"  style="margin-left: 20px"></el-color-picker>
                </div>
            </div>
        </div>
        <goods-select-com v-model="showGoodsSelect" @confirm="goodsSelected" :multiple-select="true"></goods-select-com>
        <wepage-url-select v-model="showUrlEdit" @confirm="urlConfirm"></wepage-url-select>
        <div v-show="showHotEdit" class="hot-img-class">
            <div style="text-align: right;width: 100%;display: flex;border-bottom: whitesmoke solid 1px;padding: 10px">
                <div style="margin-left: 50px">按住鼠标左键拖动选择热区</div>
                <el-button @click="showHotEdit=false" style="margin-right: 20px;margin-left: auto">关闭</el-button>
            </div>
            <div style="width: 800px;height: 700px;overflow: scroll;text-align: center"><canvas @mouseout="mouseout" @mousedown="onmousedown" @mouseup="mouseup" @mousemove="mousemove"  id="canvas" style="z-index: 102;opacity: 1"></canvas></div>
            <div style="text-align: right;width: 100%;margin-top: 20px;">
                <el-button @click="hotConfirm" type="primary" style="margin-right: 50px;margin-bottom: 20px">确认</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import GoodsSelectCom from "../../common/goods-select-com";
    import {postRequest} from "../../../utils/api";
    import MediaMaterialCom from "../../common/media-material-com";
    import WepageUrlSelect from "../../common/wepage-url-select";

    export default {
        name: "img-commodity-config",
        components: {WepageUrlSelect, MediaMaterialCom, GoodsSelectCom},
        props:{
            comData:{
                priceColor: String,
                priceFontSize: Number,
                showLable: Boolean,
                showPrice: Boolean,
                lableFontColor: String,
                lableBackgroundColor: String,
                lableTxt: String,
                background_image_id:String,
                background_image_url:String,
                imgHeight:Number,
                data:Array,
                dataNum:Number,
                hot:Array
            }
        },
        data(){
            return{
                showUrlEdit:false,
                showHotEdit:false,
                showAdd:false,
                showGoodsSelect:false,
                priceFontSize_: 12,
                lableText: '',
                showPrice_:false,
                showLable_:false,
                labelColorSelect: null,
                priceColorSelect: null,
                labelBackgroundColorSelect: null,
                comData_: null,
                btText:['一行一个','一行两个','一行三个'],
                commditys:[],
                hotGoodsSelectIndex:0,
                goodsSelectFor:1,
                hotUrlIndex:0,
                url:'',
                imgHeight_:600
            }
        },
        mounted() {
            console.log(1)
            console.log(this.comData)
            this.comData_ = this.comData
            this.showPrice_=this.comData.showPrice
            this.showLable_=this.comData.showLable
            this.priceColorSelect = this.comData.priceColor
            this.lableText = this.comData.lableTxt
            this.labelColorSelect = this.comData.lableColor
            this.labelBackgroundColorSelect = this.comData.lableBackgroundColor
            this.priceFontSize_ = this.comData.priceFontSize
            this.imgHeight_=this.comData.imgHeight
            this.getComdityData()
        },
        watch :{
            priceColorSelect(){
                this.comData_.priceColor = this.priceColorSelect
            },
            lableText(){
                this.comData_.lableTxt = this.lableText
            },
            labelColorSelect(){
                this.comData_.lableColor = this.labelColorSelect
            },
            labelBackgroundColorSelect(){
                this.comData_.lableBackgroundColor = this.labelBackgroundColorSelect
            },
            priceFontSize_(){
                this.comData_.priceFontSize = this.priceFontSize_
            },
            showPrice_(){
                this.comData_.showPrice = this.showPrice_
            },
            showLable_(){
                this.comData_.showLable = this.showLable_
            },
            imgHeight_(){
                this.comData_.imgHeight = this.imgHeight_
            },
            'comData.dataNum':{
                handler(){
                    // console.log(1)
                    this.getComdityData()
                }
            }
        },
        methods:{
            getComdityData(){
                // console.log(this.commdity.data)
                postRequest('goods/getCommdityInfoListByCodes',{'codes':this.comData_.data}).then(res=>{
                    this.commditys=res.data.data
                })
            },
            commdityLineStyleSelect(index){
                this.comData_.showType = index+1
                this.setBtBorder(index+1)
                console.log(this.comData_)
            },
            getCommdityLineStyleBt(index){
                return 'commdityLineStyleBt_' + (index+1)
            },
            removeCommdity(id){
                var temp = []
                var td=[]
                this.commditys.forEach((item,index)=>{
                    if (index !== id) {
                        temp.push(item)
                        td.push(item.goods_code)
                    }
                })
                this.commditys = temp
                this.comData_.dataNum=td.length
                this.comData_.data=td
            },
            colorchange(e){
                // console.log(e)
                this.comData_.priceColor=e
            },
            labecolorchange (e){
                this.comData_.lableColor = e
            },
            labelBackgoundColorchange(e){
                this.comData_.lableBackgroundColor = e
            },
            goodsSelected(e){
                // console.log(e)
                if (this.goodsSelectFor==1){
                    var t=this.comData_.data
                    // console.log(t)
                    for (var i=0;i<e.length;i++){
                        var ex=false
                        for (var j=0;j<t.length;j++){
                            if (e[i].goods_code==t[j]){
                                ex=true
                            }
                        }
                        if (!ex){
                            t.push(e[i].goods_code)
                        }
                    }
                    console.log(t)
                    this.comData_.data=t
                    this.comData_.dataNum=t.length
                    console.log(this.comData_.data)
                }else {
                    this.comData_.hot[this.hotGoodsSelectIndex].linkType=1
                    this.comData_.hot[this.hotGoodsSelectIndex].typeName='商品'
                    this.comData_.hot[this.hotGoodsSelectIndex].linkValue=e[0].goods_code
                }

            },
            toAddGoods(){
                this.showGoodsSelect=true
            },
            toAdd(){
                this.showAdd = true
            },
            addConfirm(e){
                console.log(e)
                this.comData_.hot=[]
                this.comData_.background_image_id= e[0].file_id
                this.comData_.background_image_url= e[0].url
            },
            hotConfirm(){
                if (this.tempHot!=null){
                    if (this.comData_.hot==undefined){
                        this.comData_.hot=[]
                    }
                    this.comData_.hot.push(this.tempHot)
                }
                this.showHotEdit=false
            },
            toAddImgHot(){
                var w=600
                this.tempHot=null
                var canvas = document.getElementById("canvas");
                // var img = document.getElementById("img");
                // img.src=this.comData_.data[id].pic_url
                this.img2=new Image()
                this.img2.src=this.comData_.background_image_url
                var iw=this.img2.width
                var ih=this.img2.height

                this.img2.width=w
                this.img2.height=ih*(w/iw)

                canvas.width=this.img2.width
                canvas.height=this.img2.height
                canvas.style.width=this.img2.width+'px'
                canvas.style.height=this.img2.height+'px'
                this.ctx = canvas.getContext("2d");
                console.log(canvas)
                canvas.style.backgroundColor="#accdee"
                this.drawExist()
                this.showHotEdit=true
            },
            onmousedown(e){
                if (this.ctx!=null){
                    this.startDraw=true
                    this.startX=e.offsetX
                    this.startY=e.offsetY
                    console.log(this.startY,this.startX)
                }
            },
            mouseup(e){
                // this.ctx.strokeStyle = '#db0009';
                // this.ctx.strokeRect(this.startY,this.startX, e.offsetY-this.startY,e.offsetX-this.startX )
                this.startDraw=false
            },
            mouseout(){
                this.startDraw=false
            },
            mousemove(e){
                if (this.startDraw){
                    this.drawExist()
                    this.ctx.strokeStyle = 'red';
                    this.ctx.lineWidth = 1
                    this.ctx.strokeRect(this.startX,this.startY, e.offsetX-this.startX,e.offsetY-this.startY )
                    this.tempHot={
                        startXp:this.startX/this.img2.width,
                        startYp:this.startY/this.img2.height,
                        widthP:(e.offsetX-this.startX)/this.img2.width,
                        heightP:(e.offsetY-this.startY)/this.img2.height,
                        typeName:'',
                        linkValue:'',
                    }
                }

            },
            drawExist(){
                this.ctx.clearRect(0,0,2000,2000)
                this.ctx.drawImage(this.img2,0,0,this.img2.width,this.img2.height)
                var ex=this.comData_.hot
                this.ctx.strokeStyle = 'red';
                this.ctx.lineWidth = 1
                if (ex!==undefined && ex!=null){
                    var iw=this.img2.width
                    var ih=this.img2.height
                    this.ctx.font = "12px sans-serif";
                    this.ctx.fillStyle = "red";
                    for (var i=0;i<ex.length;i++){
                        this.ctx.fillStyle = 'red'
                        this.ctx.strokeRect(iw*ex[i].startXp,ih*ex[i].startYp, iw*ex[i].widthP,ih*ex[i].heightP)
                        this.ctx.fillStyle = 'rgb(0,0,0,0.1)'
                        this.ctx.fillRect(iw*ex[i].startXp,ih*ex[i].startYp, iw*ex[i].widthP,ih*ex[i].heightP)
                        this.ctx.fillStyle = 'rgba(238,0,9,1)'
                        this.ctx.fillRect(iw*ex[i].startXp,ih*ex[i].startYp, 24,24)
                        this.ctx.fillStyle = 'white'
                        this.ctx.fillText((i+1), iw*ex[i].startXp+8, ih*ex[i].startYp+15);
                    }
                }
            },
            toSelectGoods(id2,type){
                this.hotGoodsSelectIndex=id2
                this.goodsSelectFor=type
                this.showGoodsSelect=true
            },
            toEditUrl(id2){
                this.showUrlEdit=true
                this.hotUrlIndex=id2
                if (this.comData_.hot[id2].linkType===2){
                    this.url=this.comData_.hot[id2].linkValue
                }else {
                    this.url=''
                }

            },
            delHot(index2){
                var t=[]
                for (var i=0;i<this.comData_.hot.length;i++){
                    if (i!=index2){
                        t.push(this.comData_.hot[i])
                    }
                }
                this.comData_.hot=t
            },
            urlConfirm(e){
                this.comData_.hot[this.hotUrlIndex].linkType=2
                this.comData_.hot[this.hotUrlIndex].typeName='小程序页面'
                this.comData_.hot[this.hotUrlIndex].linkValue=e
                this.url=''
                this.showUrlEdit=false

            },
        },

    }
</script>

<style scoped>
    .hot-img-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 800px;
        width: 800px;
        background-color: white;
        border: #bcbcbc solid 1px;
        z-index: 101;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0px #c0c0c0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>