<template>
    <div>木木夕木目心</div>
</template>

<script>
    export default {
        name: "Wxlink",
        mounted() {
            location.href="weixin://dl/business/?appid=wx52283fd1e73f2f81&path=pages/home/home"
        }
    }
</script>

<style scoped>

</style>