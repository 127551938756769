<template>
    <div class="dialog-class">
        <div>导购选择</div>
        <div style="border-bottom: #bcbcbc solid 1px;margin-top: 10px;margin-bottom: 10px"></div>
        <div style="text-align: left">
            <el-form :inline="true" :model="pm" class="demo-form-inline">
                <el-form-item label="导购代码">
                    <el-input v-model="pm.guider_code" placeholder="导购代码" clearable />
                </el-form-item>
                <el-form-item label="导购姓名">
                    <el-input v-model="pm.guider_name" placeholder="导购姓名" clearable />
                </el-form-item>
                <el-form-item label="导购手机号">
                    <el-input v-model="pm.guider_mobile" placeholder="导购手机号" clearable />
                </el-form-item>
                <el-form-item label="门店">
                    <el-input clearable  readonly :model-value="pm.guider_shop_code+pm.guider_shop_name">
                        <template #append>
                            <el-button @click="toSelectShop" icon="ArrowDown" />
                        </template>
                    </el-input>
                    <shop-select-com v-if="showShopSelect" v-model="showShopSelect" @confirm="storeSelectConfirm" :multipleSelect="true"></shop-select-com>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="get">查询</el-button>
                    <el-button @click="clear">清空</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div style="margin-top: 10px;height: 480px;overflow: scroll" v-loading="showloading">
            <el-checkbox-group v-model="checkList" @change="checkChange">
                <table>
                    <thead>
                    <th>代码</th>
                    <th>名称</th>
                    <th>所属门店</th>
                    <th>手机</th>
                    <th>状态</th>
                    <th>企微客户数</th>
                    <th>企微账号</th>
                    </thead>
                    <tbody>
                    <tr v-for="(it,index) in tableData">
                        <td style="text-align: left;">
                            <el-checkbox :label="it.guider_code" style="margin-left: 10px">
                            </el-checkbox>
                        </td>
                        <td>{{it.guider_name}}</td>
                        <td>{{it.guider_shop_name}}</td>
                        <td>{{it.guider_mobile}}</td>
                        <td>{{it.disable==1?'启用':'停用'}}</td>
                        <td>{{it.qw_external_user_num}}</td>
                        <td>{{it.qw_userid}}</td>
                    </tr>
                    </tbody>
                </table>
            </el-checkbox-group>
        </div>
        <el-pagination
                v-model:current-page="pm.pageNum"
                v-model:page-size="pm.pageSize"
                :page-sizes="[20,50, 100, 200, 400]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pm.totalNum"
                :pager-count="9"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
        <div style="padding: 5px;text-align: right">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" style="margin-right: 20px;margin-left: 20px" @click="confirm">确定</el-button>
        </div>
    </div>
</template>

<script>
    import {postRequest} from "../../utils/api";
    import ShopSelectCom from "./shop-select-com";

    export default {
        name: "guider-select-com",
        components: {ShopSelectCom},
        emits:['confirm','update:modelValue'],
        props:{
            multipleSelect:{
                type:Boolean,
                defualt:false
            }
        },
        data(){
            return{
                tableData:[],
                pm:{
                    pageNum:1,
                    pageSize:20,
                    totalNum:0,
                    guider_shop_code:'',
                    guider_shop_name:''
                },
                checkList:[],
                selectedList:[],
                showloading:false,
                showShopSelect:false
            }
        },
        methods:{
            toSelectShop(){
                this.showShopSelect=true
            },
            storeSelectConfirm(e){
                this.pm.guider_shop_code=e[0].shop_code
                this.pm.guider_shop_name=e[0].shop_name
            },
            clear(){
                this.pm['guider_shop_code']=''
                this.pm['guider_shop_name']=''
                this.pm['guider_code']=''
                this.pm['guider_name']=''
                this.pm['guider_mobile']=''
            },
            handleSizeChange(){
                this.pm.pageNum = 1
                this.get()
            },
            handleCurrentChange(e){
                this.pm.pageNum = e
                this.get()
            },
            get(){
                this.showLoading=true
                postRequest('wecom/getGuiderList',this.pm).then(res=>{
                    this.showLoading=false
                    this.tableData=res.data.data.list
                    this.pm.totalNum=res.data.data.total
                })
            },
            cancel(){
                this.$emit('update:modelValue',false)
            },
            confirm(){
                if (this.selectedList.length>0){
                    this.$emit('confirm',JSON.parse(JSON.stringify(this.selectedList)))
                }
                this.$emit('update:modelValue',false)
            },
            checkChange(e){
                if (this.multipleSelect==true){
                    this.selectedList=[]
                    for (var i=0;i<this.tableData.length;i++){
                        for (var c=0;c<this.checkList.length;c++){
                            if (this.tableData[i].guider_code==this.checkList[c]){
                                this.selectedList.push(this.tableData[i])
                            }
                        }
                    }
                }else{
                    var t=''
                    for (var i=0;i<this.checkList.length;i++){
                        var ex=false
                        for (var t=0;t<this.selectedList.length;t++){
                            if (this.checkList[i]==this.selectedList[t].guider_code){
                                ex=true
                            }
                        }
                        if (ex==false){
                            t=this.checkList[i]
                        }
                    }
                    this.checkList=[t]
                    for (var i=0;i<this.tableData.length;i++){
                        if (this.tableData[i].guider_code==t){
                            this.selectedList=[this.tableData[i]]
                        }
                    }
                }
            }
        },
        mounted() {
            this.get()
        }
    }
</script>

<style scoped>
    .dialog-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 700px;
        width: 1000px;
        background-color: #ffffff;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 5px 2px #c0c0c0;
        padding: 20px;
        z-index: 1000;
    }
    table, td, tr, th {
        font-size: small;
        line-height: 20px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        /*padding: 5px;*/
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
</style>