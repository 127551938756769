<template>
    <div style="background-color: whitesmoke">
        <div style="background-color: white;display: flex;flex-direction: row;justify-content: right;">
            <span style="margin-right: auto">积分抽奖:{{act.id}}</span>
            <el-button :disabled="readonly" type="primary" style="width: 90px" @click="saveEdit">保存</el-button>
            <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
        </div>
        <div v-loading="showloading" style="background-color: whitesmoke;padding: 10px">
            <div style="background-color: white;padding: 30px">
                <div class="row-class" >
                    <span>活动名称</span>
                    <el-input v-model="act.activity_name"></el-input>
                </div>
                <div class="row-class">
                    <span>活动名称备注</span>
                    <el-input v-model="act.activity_note"></el-input>
                </div>
                <div class="row-class" style="width: 600px;">
                    <span>活动时间</span>
                    <el-date-picker
                            v-model="act.validity_date"
                            type="datetimerange"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="起始日期"
                            end-placeholder="截至日期"
                    />
                </div>
                <div class="row-class">
                    <span>抽奖礼品:</span>
                    <span style="width: 500px;text-align: left">
                        <el-input :value="goodsInfo.goods_code+'|'+goodsInfo.goods_name" readonly>
                            <template v-if="!readonly" #append>
                                <el-button @click="toSelectGoods(1)" style="color: blue">选择礼品<el-icon><ArrowDown /></el-icon></el-button>
                            </template>
                        </el-input>
                    </span>
                </div>
                <div class="row-class">
                    <span>保底礼品:</span>
                    <span style="width: 500px;text-align: left">
                        <el-input :value="guaranteeGoodsInfo.goods_code!=null?guaranteeGoodsInfo.goods_code+'|'+guaranteeGoodsInfo.goods_name:''" readonly>
                            <template v-if="!readonly" #append>
                                <el-button @click="toSelectGoods(2)" style="color: blue">选择礼品<el-icon><ArrowDown /></el-icon></el-button>
                            </template>
                        </el-input>
                    </span>
                </div>
                <div class="row-class" >
                    <span>花费积分</span>
                    <el-input v-model="act.points_amount"></el-input>
                </div>
                <div class="row-class" >
                    <span>最大活动名额</span>
                    <el-input v-model="act.max_num"></el-input>
                </div>
            </div>
        </div>
        <goods-select-com v-model="showGoodsSelect" @confirm="goodsSelected"></goods-select-com>
        <div v-if="act.id!=-1" style="text-align: left;padding: 10px;background-color: white">
            <div>获奖明细:</div>
            <div style="margin-top: 10px;background-color: white;overflow: scroll;max-width:100%;margin-bottom: 5px;width: 50%">
                <table>
                    <thead>
                    <th>获奖人</th>
                    <th>奖品订单号</th>
                    <th>奖品代码</th>
                    <th>奖品名称</th>
                    </thead>
                    <tbody>
                    <tr v-for="it in luckyList">
                        <td>{{it.vip_code}}</td>
                        <td>{{it.order_id}}</td>
                        <td>{{it.goods_code}}</td>
                        <td>{{it.goods_name}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <el-pagination
                    v-model:current-page=pageNum
                    v-model:page-size=pageSize
                    :page-sizes="[10,100, 200, 300, 400]"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total=totalNum
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
    import {debounce} from "../../utils/DebounceFn";
    import {importVip} from "../../utils/VipImport";
    import {getRequest, postRequest} from "../../utils/api";
    import GoodsSelectCom from "../common/goods-select-com";
    import {getUserId} from "../../utils/auth";

    export default {
        name: "IntegralDrawEdit",
        components: {GoodsSelectCom},
        data(){
            return{
                pageNum:1,
                pageSize:10,
                totalNum:1,
                luckyList:[],
                showloading:false,
                readonly:false,
                vipSearchList:[],
                vipLevelList:[],
                vipQueryValue:'',
                goods_select_type:1,
                showVipSearch:false,
                showGoodsSelect:false,
                showVipLevelSelect:false,
                goodsInfo:{
                    goods_code:'',
                    goods_name:''
                },
                guaranteeGoodsInfo:{
                    goods_code:'',
                    goods_name:''
                },
                act:{
                    activity_name:'',
                    activity_note:'',
                    gift_code:'',
                    validity_date:[],
                    guarantee_goods_code:'',
                    points_amount:100000,
                    max_num:50,
                    shipping_type_selfpickup:true,
                    shipping_type_express:true
                }
            }
        },
        methods:{
            backHome(){
                this.$router.back()
            },
            saveEdit(){
                this.act.creator=getUserId()
                if (this.act.validity_date.length<2){
                    this.$alert('请填写活动日期!')
                    return
                }
                this.act.start_time=this.act.validity_date[0]
                this.act.end_time=this.act.validity_date[1]
                console.log(this.act)
                this.showLoading=true
                postRequest('activity/addIntegralDraw',this.act).then(res=>{
                    if (res.data.flag){
                        this.$alert(res.data.message)
                        this.readonly=true
                        this.getDetail(res.data.data)
                    }else {
                        this.$alert(res.data.message)
                    }

                })
            },
            getDetail(id){
                this.showLoading=true
                getRequest('activity/getIntegralDrawById?id='+id).then(res=>{
                    this.showLoading=false
                    this.getList()
                    console.log(res)
                    if (res.data.flag){

                        this.act=res.data.data
                        this.act.validity_date=[res.data.data.start_time,res.data.data.end_time]
                        this.goodsInfo.goods_code=res.data.data.gift_code
                        this.goodsInfo.goods_name=res.data.data.gift_name
                        this.guaranteeGoodsInfo.goods_code=res.data.data.guarantee_code
                        this.guaranteeGoodsInfo.goods_name=res.data.data.guarantee_name
                    }else {
                        this.$alert(res.data.message)
                    }

                })
            },
            vipSelected(it){
                this.act.vip_list.push(it)
                this.vipQueryValue=''
                this.vipSearchList=[]
                this.showVipSearch=false
            },
            getvip_listShow(){
                var t=''
                for (var i=0;i<this.act.vip_list.length;i++){
                    t=t+this.act.vip_list[i].vip_code+'|'+this.act.vip_list[i].name+','
                }
                return t
            },
            getvip_levelShow(){
                var t=''
                for (var i=0;i<this.act.vip_levels.length;i++){
                    t=t+this.act.vip_levels[i].level_code+'|'+this.act.vip_levels[i].level_name+','
                }
                return t
            },
            clearVip(){
                this.act.vip_list=[]
            },
            toSelectVip(){
                if (this.showVipSearch){
                    this.showVipSearch=false
                }else {
                    this.showVipSearch=true
                }
            },
            vpvChange(e){
                this.searchVipDf()
            },
            toImport(){
                this.showLoading=true
                console.log(this.$refs.fileRef.files[0])
                importVip(this.$refs.fileRef.files[0]).then(res=>{
                    this.showLoading=false
                    this.act.vip_list=res
                    this.$refs.fileRef.value=null
                })
            },
            toSelectLevel(){
                if (this.showVipLevelSelect){
                    this.showVipLevelSelect=false
                }else {
                    this.getVipLevel()
                    this.showVipLevelSelect=true
                }
            },
            vipLevelSelected(index){
                if (this.vipLevelList[index].check){
                    this.vipLevelList[index].check=false
                }else {
                    this.vipLevelList[index].check=true
                }
                this.act.vip_levels=[]
                for (var i=0;i<this.vipLevelList.length;i++){
                    if (this.vipLevelList[i].check){
                        this.act.vip_levels.push(this.vipLevelList[i])
                    }
                }
            },
            getVipLevel(){
                getRequest('vip/getVipLevelList').then(res=>{
                    this.vipLevelList=res.data.data
                    for (var i=0;i<this.vipLevelList.length;i++){
                        for (var a=0;a<this.act.vip_levels.length;a++){
                            if (this.act.vip_levels[a].level_code==this.vipLevelList[i].level_code){
                                this.vipLevelList[i].check=true
                            }
                        }
                    }
                })
            },
            toSelectGoods(e){
                this.goods_select_type=e
                this.showGoodsSelect=true
            },
            goodsSelected(e){
                console.log(e)
                if (this.goods_select_type===1){
                    this.goodsInfo.goods_code=e[0].goods_code
                    this.act.gift_code=e[0].goods_code
                    this.goodsInfo.goods_name=e[0].goods_name
                    this.goodsInfo.price=e[0].real_price
                    this.goodsInfo.real_price=e[0].real_price
                }
                if (this.goods_select_type===2){
                    this.guaranteeGoodsInfo.goods_code=e[0].goods_code
                    this.act.guarantee_code=e[0].goods_code
                    this.guaranteeGoodsInfo.goods_name=e[0].goods_name
                    this.guaranteeGoodsInfo.price=e[0].real_price
                    this.guaranteeGoodsInfo.real_price=e[0].real_price
                }
                // this.getGoodsInfo()
            },
            handleSizeChange(e){
                console.log(e)
                this.getList()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.getList()
            },
            getList(){
                getRequest('activity/getIntegralDrawLuckyListByActivityId?id='+this.act.id+"&pageNum="+this.pageNum+"&pageSize="+this.pageSize).then(res=>{
                    this.luckyList=res.data.data.list
                    this.totalNum=res.data.data.total
                })
            }
        },
        mounted() {
            this.searchVipDf=debounce(this.serchVip,200)
            this.act.id=this.$route.params.id
            if (this.act.id!=-1){
                this.readonly=true
                this.getDetail(this.act.id)
            }
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 20px;
    }
    .row-class span{
        width: 200px;
    }
    .row-class .el-input{
        width: 400px;
    }
    table,th td{
        border: whitesmoke solid 1px;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        font-size: 13px;
    }
    td,th{
        border: whitesmoke solid 1px;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>