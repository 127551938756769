<template>
    <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>会员管理</el-breadcrumb-item>
        <el-breadcrumb-item>生日送礼列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="background-color: #eeeeee;padding: 10px">
        <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
            <el-form :inline="true" :model="conditionForm" class="demo-form-inline">
                <el-form-item label="名称">
                    <el-input v-model="conditionForm.page_name" placeholder="名称" clearable />
                </el-form-item>
                <el-form-item label="创建日期">
                    <el-date-picker
                            v-model="conditionForm.dateValue"
                            type="daterange"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="起始日期"
                            end-placeholder="截至日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
    <div style="background-color: #eeeeee;padding: 10px;height: 30px;background-color: white;margin-bottom: 10px;text-align: left">
        <el-button @click="toAdd" type="success">新建活动</el-button>
    </div>
    <div style="margin-top: 10px;background-color: white;height: 550px;overflow: scroll;max-width:90%" v-loading="showLoading">
        <table>
            <thead>
            <th>活动ID</th>
            <th>活动名称</th>
            <th>创建日期</th>
            <th>优活动备注</th>
            <th>活动开始时间</th>
            <th>活动结束时间</th>
            <th>状态</th>
            <th>操作</th>
            </thead>
            <tbody>
            <tr v-for="it in activityList">
                <td>{{it.id}}</td>
                <td>{{it.activity_name}}</td>
                <td>{{it.create_time}}</td>
                <td>{{it.activity_note}}</td>
                <td>{{it.start_time}}</td>
                <td>{{it.end_time}}</td>
                <td>{{it.enabled==true?'启用':'未启用'}}</td>
                <td>
                    <span @click="toView(it)">查看明细</span>
                    <span @click="enabledAc(it)" v-if="it.enabled==false">启用</span>
                    <span @click="disabledAc(it)" v-if="it.enabled==true">停用</span>
                    <span @click="delAc(it)" style="color: red;">删除</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";
    import {ElMessageBox} from "element-plus";

    export default {
        name: "BirthdayGiftList",
        data(){
            return{
                conditionForm:{},
                activityList:[],
                showLoading:false
            }
        },
        methods:{
            toView(it){
                this.$router.push('BirthdayGiftEdit/'+it.id)
            },
            toAdd(){
                this.$router.push('BirthdayGiftEdit/-1')
            },
            getList(){
                this.showLoading=true
                getRequest('activity/getBirthdayGiftList').then(res=>{
                    this.showLoading=false
                    this.activityList=res.data.data
                })
            },
            enabledAc(it){
                getRequest('activity/updateBirthdayGiftEnable?enabled=true&id='+it.id).then(res=>{
                    console.log(res)
                    this.getList()
                })
            },
            disabledAc(it){
                getRequest('activity/updateBirthdayGiftEnable?enabled=false&id='+it.id).then(res=>{
                    console.log(res)

                    this.getList()
                })
            },
            delAc(it){
                ElMessageBox.confirm('确定删除该活动?')
                    .then(() => {
                        console.log(1)
                        getRequest('activity/delBirthdayGift?id='+it.id).then(res=>{
                            console.log(res)
                            this.getList()
                        })
                    })
                    .catch(() => {
                        // catch error
                    })
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 30px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>