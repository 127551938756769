<template>
    <img style="margin: 3px;width: 98%" :src="getVideoImg('16-9')" />
</template>

<script>
    export default {
        name: "video-com-16-9",
        setup(){
            function getVideoImg(vtype) {
                if (vtype === '16-9'){
                    return require('@/assets/image/video-preview-16-9.png')
                }
            }
            return{
                getVideoImg,
            }
        }
    }
</script>

<style scoped>

</style>