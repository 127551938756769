<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>手工发券列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: #eeeeee;padding: 10px">
            <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
                <el-form :inline="true" :model="conditionForm" class="demo-form-inline">
                    <el-form-item label="名称">
                        <el-input v-model="conditionForm.page_name" placeholder="名称" clearable />
                    </el-form-item>
                    <el-form-item label="创建日期">
                        <el-date-picker
                                v-model="conditionForm.dateValue"
                                type="daterange"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                unlink-panels
                                range-separator="-"
                                start-placeholder="起始日期"
                                end-placeholder="截至日期"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmitQuery">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div style="background-color: #eeeeee;padding: 10px;height: 30px;background-color: white;margin-bottom: 10px;text-align: left">
            <el-button @click="toAdd" type="success">新建活动</el-button>
        </div>
        <div style="margin-top: 10px;background-color: white;height: 550px;overflow: scroll">
            <table>
                <thead>
                <th>活动ID</th>
                <th>活动名称</th>
                <th>优惠券备注</th>
                <th>创建日期</th>
                <th>创建人</th>
                <th>审批</th>
                <th>审批人</th>
                <th>审批日期</th>
                <th>终止</th>
                <th>操作</th>
                </thead>
                <tbody>
                <tr v-for="it in activityList">
                    <td>{{it.id}}</td>
                    <td>{{it.activity_name}}</td>
                    <td>{{it.coupon_note}}</td>
                    <td>{{it.create_time}}</td>
                    <td>{{it.creator_name}}</td>
                    <td>{{it.approve==1?'已审批':''}}</td>
                    <td>{{it.approver}}</td>
                    <td>{{it.approve_time}}</td>
                    <td>{{it.aborted==1?'已终止':''}}</td>
                    <td>
                        <span @click="toView(it)">查看明细</span>
                        <span @click="toAborted(it)" v-if="it.aborted==0 && it.approve!=1">终止</span>
                        <span v-if="it.aborted==0 && it.approve!=1" @click="submitApprove(it.id)">审批</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <el-pagination
                v-model:current-page="pageNum"
                v-model:page-size="pageSize"
                :page-sizes="[10,50, 200, 500, 1000]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
                :pager-count="15"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";
    import {getUserId} from "../../utils/auth";

    export default {
        name: "SendCouponList",
        data(){
            return{
                conditionForm:{},
                activityList:[],
                pageNum:1,
                pageSize:10,
                totalNum:0
            }
        },
        methods:{
            onSubmitQuery(){
                var start_time=null
                var end_time=null
                if (this.conditionForm.dateValue!=null){
                    start_time=this.conditionForm.dateValue[0]
                    end_time=this.conditionForm.dateValue[1]
                }
                getRequest('activity/getActivityList?pageSize='+this.pageSize+'&pageNum='+this.pageNum+'&start_time='+start_time+"&end_time="+end_time).then(res=>{
                    this.activityList=res.data.data.list
                    this.totalNum=res.data.data.total
                })
            },
            submitApprove(id){
                getRequest("activity/submitActivityApprove?activity_id=" + id+"&approver="+getUserId()).then(res=>{
                    this.$alert(res.data.message)
                    this.onSubmitQuery()
                })
            },
            toAborted(it){
                getRequest("activity/abortedActivity?activity_id=" + it.id).then(res=>{
                    this.$alert(res.data.message)
                    this.onSubmitQuery()
                })
            },
            toAdd(){
                this.$router.push('/SendCoupon')
            },
            handleSizeChange(){
                this.pageNum = 1
                this.onSubmitQuery()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.onSubmitQuery()
            },
            toView(it){
                console.log(it)
                this.$router.push('SendCouponDetail/'+it.id)
            }
        },
        mounted() {
            this.onSubmitQuery()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>