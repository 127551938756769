import QueryTraces from './vue-file/query-traces'
import {createApp} from 'vue'
function QueryTracesApi(customerName,shipperCode,logisticCode) {
    const div=document.createElement('querytraces')
    document.body.appendChild(div);
    const app =createApp(QueryTraces,{
        customerName,shipperCode,logisticCode,
        onTclose(){
            app.unmount()
            div.remove()
        }
    });
    app.mount(div)
}
export default QueryTracesApi;