<template>
    <div style="font-size: 13px" v-loading="showloading">
        <div class="th">
            <div class="m-tr" style="flex: 2">
                <div class="m-tr" >订单信息</div>
                <div class="m-tr cell-max-width" >单价X数量</div>
            </div>
            <div class="m-tr cell-max-width" >实收金额</div>
            <div class="m-tr cell-max-width" >快递费</div>
            <div class="m-tr">收货信息</div>
            <div class="m-tr cell-max-width" >配送方式</div>
            <div class="m-tr">快递单号</div>
            <div class="m-tr cell-max-width" >导购</div>
            <div class="m-tr cell-max-width" >分享者</div>
            <div class="m-tr">会员信息</div>
            <div class="m-tr">订单状态</div>
            <div class="m-tr">操作</div>
        </div>
        <div>
            <div v-for="(it,index) in tableData" style="display: flex;flex-direction: column;">
                <div style="margin-top: 20px;border-bottom: #d3d3d3 solid 1px;background-color: rgba(0,167,167,0.05);height: 30px;display: flex;align-items: center">
                    <span>订单号</span>
                    <span style="color: rgba(0,0,226,0.79);">{{it.order_id}}</span>
                    <span style="margin-left: 100px">订单日期</span>
                    <span>{{it.create_time}}</span>
                    <span style="margin-left: 100px">买家备注:</span>
                    <span style="color: red">{{it.buyer_notes}}</span>
                </div>
                <div class="th">
                    <div style="display: flex;flex-direction: column;flex: 2">
                        <div v-for="(it2,index2) in it.details" style="display: flex;flex-direction: row;height: 100%">
                            <div class="m-tr" style="display: flex;flex-direction: row;">
                                <el-image :src="it2.pic_url" style="width: 60px;height: 60px;border-radius: 3px;margin: 5px"></el-image>
                                <div style="display: flex;flex-direction: column;margin-left: 10px;word-break: break-all">
                                    <div>{{it2.goods_name}}</div>
                                    <div>{{it2.sku}}</div>
                                    <div>{{it2.goods_attribute1_name}}  {{it2.goods_attribute2_name}}</div>
                                </div>
                            </div>
                            <div class="m-tr cell-max-width" >{{it2.pay_price}} * {{it2.num}}</div>
                        </div>
                    </div>
                    <div class="m-tr cell-max-width" >{{it.goods_real_amount}}</div>
                    <div class="m-tr cell-max-width" >{{it.freight}}</div>
                    <div class="m-tr" style="display: flex;flex-direction: column">
                        <div>{{it.receiver_name}}</div>
                        <div>{{it.receiver_mobile}}</div>
                        <div>{{it.receiver_province_name+it.receiver_city_name+it.receiver_county_name+it.receiver_address}}</div>
                    </div>
                    <div class="m-tr cell-max-width" >{{it.receiver_type}}</div>
                    <div class="m-tr"></div>
                    <div class="m-tr cell-max-width" ></div>
                    <div class="m-tr cell-max-width" ></div>
                    <div class="m-tr" style="display: flex;flex-direction: column;">
                        <div>{{it.vip_name}}</div>
                        <div>{{it.vip_mobile}}</div>
                        <div>{{it.vip_nickname}}</div>
                        <div>{{it.vip_code}}</div>
                    </div>
                    <div class="m-tr" style="display: flex;flex-direction: column;">
                        <div>{{it.status_name}}</div>
                    </div>
                    <div class="m-tr" style="color: blue;display: flex;flex-direction: column;">
                        <div @click="takingOrder(it)" v-if="it.taking_order==0 && it.pay_flag==1" style="cursor: pointer;color: green">接单</div>
                        <div @click="toAssign(it.order_id)" v-if="it.taking_order==1 && it.assigned!=2" style="margin-top: 10px;cursor: pointer">派单</div>
                        <div @click="toReturn(it.order_id)" v-if="it.pay_flag==1 && it.sales_return!=2" style="margin-top: 10px;cursor: pointer">发起退款</div>
                        <div @click="todetail(it)" style="margin-top: 10px;cursor: pointer">详情</div>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination
                v-model:current-page="pageNum"
                v-model:page-size="pageSize"
                :page-sizes="[10,20, 40, 60, 100]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
                :pager-count="5"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../../utils/api";
    import {getUserId} from "../../../utils/auth";

    export default {
        name: "completed-order-com",
        emits:['toAssign','toReturn'],
        props:['cdf'],
        data(){
            return{
                tableData:[],
                pageNum:1,
                totalNum: 0,
                pageSize: 10,
                showloading:false
            }
        },
        computed:{
        },
        methods:{
            get(){
                this.showloading=true
                var pm=JSON.parse(JSON.stringify( this.cdf))
                pm['pageNum']=this.pageNum
                pm['pageSize']=this.pageSize
                if (this.cdf.dateValue!=null){
                    pm['start_time']=this.cdf.dateValue[0]
                    pm['end_time']=this.cdf.dateValue[1]
                }else {
                    pm['start_time']=null
                    pm['end_time']=null
                }
                pm['completed']=1
                postRequest('mall/getOrderList',pm).then(res=>{
                    this.showloading=false
                    this.tableData=res.data.data.list
                    this.totalNum=res.data.data.total
                })
            },
            handleSizeChange(){
                this.pageNum = 1
                this.get()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.get()
            },
            takingOrder(it){
                console.log(1)
                getRequest('mall/takingOrder?order_id=' + it.order_id+'&userid=' + getUserId()).then(res=>{
                    this.$alert(res.data.message)
                    if (res.data.flag){
                        for(var i=0;i<this.tableData.length;i++){
                            if (this.tableData[i].order_id==it.order_id){
                                this.tableData[i].taking_order=1
                            }
                        }
                    }
                })
            },
            toAssign(id){
                this.$emit('toAssign',id)
            },
            toReturn(id){
                this.$emit('toReturn',id)
            },
            todetail(it){
                this.$emit('toDetail',it)
            }

        },
        mounted() {
            // this.get()
        }
    }
</script>

<style scoped>
    .th{
        display: flex;
        word-break: break-all;
    }
    .m-tr{
        flex: 1;
        border: #d3d3d3 solid 1px;
        border-top: none;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
        word-break: break-all;
    }
    .m-tr:first-child{
        border-left: #d3d3d3 solid 1px;
    }
    .th:first-child .m-tr{
        border: none;
        background-color: powderblue;
        height: 40px;
    }
    .cell-max-width{
        max-width: 70px;
    }
</style>