<template>
    <div v-if="modelValue" class="mmc-box-class"></div>
    <div v-if="modelValue" class="mmc-dialog-class" draggable="true">
        <div class="root-group-class">
            <div
                    @click="activeChange(index)"
                    v-for="(item,index) in rootGroup"
                    :key="index"
                    :style="active == index ? 'color:#09CCC7;border-bottom: #09CCC7 solid 2px;margin:10px':'border: none;margin:10px'"
            >
                {{item}}
            </div>
        </div>
        <div style="width: 100%;border-bottom: #d1d1d1 solid 1px"></div>
        <div style="display: flex;flex-direction: row;">
            <div style="width: 150px;background-color: rgba(0,255,255,0.05);height: 600px;position: relative;text-align: left">
                <el-tree node-key="group_id" highlight-current :data="groupdata" :props="defaultProps" @node-click="handleNodeClick" default-expand-all/>
                <el-button @click="addGroup" icon="Plus"  style="position: absolute;bottom: 0;width: 100%">添加分组</el-button>
            </div>
            <div style="flex: 1;margin:5px;background-color: #f6f6f6;margin-bottom: 0">
                <div style="border-bottom: #999999 solid 1px;display: flex;">
                    <el-input
                            v-model="serchValue"
                            placeholder="输入文件名搜索"
                            class="input-with-select"
                    >
                        <template #append>
                            <el-button icon="Search" @click="getFileByGroup(1)" style="width: 80px;"/>
                        </template>
                    </el-input>
                    <el-button style="margin-left: 50px;" type="success" @click="toupload">上传图片或视频</el-button>
                </div>
                <div style="height: 528px;overflow: scroll" v-loading="showLoaddingImage">
                    <div style="display: flex;flex-direction: row;flex-wrap: wrap">
                        <div v-for="(it,index) in fileItems" style="overflow: hidden;position: relative;font-size: 12px;margin: 10px">
                            <div v-if="checkSelect(index)" class="selected-icon">
                                <el-icon :size="20"  style="transform: rotate(-45deg);color: white;margin-left: 20px;margin-top: 5px;position: relative;bottom: -4px"><Select/></el-icon>
                            </div>
                            <div @click="itClick(index)" class="card-item-class" style="background-color: rgba(0,216,216,0.09);text-align: center">
                                <el-image :src="it.url" style="height: 100px;width: 100px;margin-top: 5px">
                                    <template #error>
                                        <div class="image-slot">
                                            <el-icon><Picture /></el-icon>
                                        </div>
                                    </template>
                                </el-image>
                                <div style="overflow: hidden;height: 35px">{{it.file_name}}</div>
                            </div>
                            <div>
                                <span>改名</span>
                                <span @click="copyUrl(it.url)">链接</span>
                                <span>删除</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <el-pagination
                            :page-size="15"
                            :pager-count="11"
                            layout="prev, pager, next"
                            :total="totalNum"
                            v-model:current-page="currentPage"
                            @current-change="currentChange"
                    />
                </div>

            </div>
        </div>
        <div style="width: 100%;height: 46px;background-color: rgba(27,209,255,0);text-align: right">
            <div style="margin-right: 20px;margin-top: 8px">
                <el-button style="margin-right: 50px;border: #79ac1c solid 1px" @click="cancel">取消</el-button>
                <el-button type="success" @click="submitSelected">确定</el-button>
            </div>
        </div>

        <el-dialog v-model="showAddGroup" title="新增文件分组">
            <div style="display: flex">
                <div style="width: 100px;line-height: 40px">分组名称</div>
                <el-input v-model="group_name" autocomplete="off" placeholder="请输入分组名称"/>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="showAddGroup = false">取消</el-button>
                <el-button type="primary" @click="submitAddFileGroup">
                  新增
                </el-button>
              </span>
            </template>
        </el-dialog>

        <el-dialog v-model="showFileChoice" title="选择文件" width="600px">
            <div>
               <input type="file" multiple id="file" v-on:change="choiceChange" class="file-upload-input" accept=".JPG, .JPEG, .PNG, .MP4, .webp"/>
            </div>
            <div style="height: 400px;display: flex;flex-direction: row;flex-wrap: wrap;background-color: rgba(238,238,238,0.6);overflow: scroll">
                <div v-for="(it,index) in fileList" style="background-color: white;margin: 11px;width: 120px;height: 140px;position: relative;">
                    <el-icon v-if="!it.upload" :size="20" class="delete-choice" @click="removeChoice(index)"><Close/></el-icon>
                    <div style="overflow: hidden;position: relative">
                        <div v-if="it.upload" class="selected-icon">
                            <el-icon :size="20" style="transform: rotate(-45deg);color: white;margin-left: 10px;margin-top: 5px;position: relative;bottom: -4px"><Select/></el-icon>
                        </div>
                        <img :src="it.url" style="width: 120px;height: 120px"/>

                        <div class="choice-file-name" style="overflow: auto;height: 20px;font-size: 10px;">{{it.name}}</div>
                    </div>

                </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="showFileChoice = false">取消</el-button>
                <el-button type="primary" @click="toSubmitAddFile">上传</el-button>
              </span>
            </template>
        </el-dialog>
    </div>
    <div v-if="showUploading" class="up-box-class"></div>
    <div v-if="showUploading" class="up-dialog-class">
        <div style="width: 100%;text-align: left;margin-left: 40px;font-size: 14px">
           <div >共 计 : {{fileList.length}}</div>
           <div style="margin-top: 20px;margin-bottom: 20px;">已 完 成: {{uploadingIndex}}</div>
            <div>正在上传 <span style="font-size: 20px;color: black">{{upLoadingText}}</span></div>
        </div>
    </div>
</template>

<script>
    import {filePostRequest, getRequest, postRequest} from "../../utils/api";
    import {ElMessageBox} from "element-plus";

    export default {
        name: "media-material-com",
        emits: ['confirm','update:modelValue'],
        props:['multipleSelect','modelValue'],
        data(){
            return{
                showCom_:true,
                showLoaddingImage: false,
                currentPage: 1,
                upLoadingTimer:null,
                upLoadingText: '',
                showUploading:false,
                totalNum: 0,
                uploadingIndex:0,
                fileItems:[],
                fileList: [],
                successNum: 0,
                selectedGruop:0,
                showAddGroup:false,
                showFileChoice: false,
                group_name: '',
                active:-1,
                rootGroup:['公共资源','商城资源'],
                serchValue:'',
                pageSize4:30,
                selectedArr:[],
                groupdata:[
                    {
                        group_id: 0,
                        group_name: '全部',
                        childrenList: [
                            {
                                group_id: 1,
                                group_name: '未分组',
                                childrenList: null
                            },
                            {
                                group_id: 2,
                                group_name: '未组1',
                                childrenList: null
                            },
                        ],
                    },
                ],
                defaultProps:{
                    children: 'childrenList',
                    label: 'group_name',
                }
            }
        },
        watch:{
            active(newActive){
                this.selectedGruop = -1
                setTimeout(()=>{
                    this.selectedGruop = 0
                },10)
                this.getFileGroup()
            },
            selectedGruop(v){
                console.log(this.active)
                if (v!=-1){
                    this.currentPage = 1
                    this.serchValue = ''
                    this.getFileByGroup(1)
                }
            },
            showcom(v){
                if (v==false){
                    this.selectedArr = []
                }
            },
            modelValue(){
                this.active=0
            }
        },
        methods:{
            getTabId(index){
                return 'tab_'+index
            },
            activeChange(index){
                console.log(index)
                this.active=index
                console.log(this.active)
            },
            handleNodeClick(e){
                console.log(e)
                this.selectedGruop = e.group_id
            },
            cancel(){
                this.selectedArr=[]
                this.$emit("update:modelValue",false)
                // this.showCom_ = false
            },
            checkSelect(idx){
                var exit=false
                this.selectedArr.forEach((v,i)=>{
                    if (v==idx){
                        exit=true
                    }
                })
                return exit
            },
            itClick(idx){
                if (!this.multipleSelect){
                    this.selectedArr = [idx]
                }else {
                    var ex=false;
                    this.selectedArr.forEach((v,i)=>{
                        if (v==idx){
                            ex=true
                        }
                    })
                    if (ex){
                        var t=[]
                        this.selectedArr.forEach((v,i)=>{
                            if (v!=idx){
                                t.push(v)
                            }
                        })
                        this.selectedArr=t
                    }else {
                        this.selectedArr.push(idx)
                    }
                }

            },
            getFileGroup(){
                getRequest('file/getFileGroup?parentid=' + (this.active+1)).then(res=>{
                    // console.log(res.data)
                    this.groupdata = [{
                        group_id: 0,
                        group_name: '全部',
                        childrenList: res.data.data
                    }]
                })
            },
            addGroup(){
               this.showAddGroup= true
            },
            submitAddFileGroup() {
                if (this.group_name.length < 1) {
                    ElMessageBox.alert('请输入分组名称')
                    return
                }
                postRequest('file/addFileGroup', {
                    'group_name': this.group_name,
                    'parent_id': this.active + 1
                }).then(res => {
                    // console.log(res.data)
                    this.showAddGroup = false
                    ElMessageBox.alert(res.data.message)
                    this.getFileGroup()
                })
            },
            toSubmitAddFile(){
                var tnum=0
                var that= this
                this.upLoadingTimer = setInterval(()=>{
                    var txt=''
                    var n=tnum%4
                    for (var i=0;i<n;i++){
                        txt=txt+ ' . '
                    }
                    tnum=tnum+1
                    that.upLoadingText = txt
                },500)
                this.showUploading = true
                this.submitAddFile(0)
            },
            submitAddFile(i){
                console.log(i)
                if (i<this.fileList.length){
                    if (this.fileList[i].upload==false){
                        let formData = new FormData()
                        var item = this.fileList[i]
                        var lv2=this.selectedGruop
                        // console.log(lv2)
                        formData.append('file', item.file,item.name)
                        if ( lv2== 0 && this.active==0){
                            lv2=3
                        }
                        if (lv2 == 0 && this.active==1){
                            lv2=4
                        }
                        // console.log(lv2)
                        filePostRequest('file/uploadFile?lv1=' + (this.active+1) + '&lv2=' + lv2, formData, {'Content-Type': 'multipart/form-data'}).then(res => {
                            if (res.data.flag){
                                this.fileList[i].upload = true
                            }
                            this.uploadingIndex = i+1
                            this.submitAddFile(this.uploadingIndex)
                        })
                    }else {
                        this.uploadingIndex = i+1
                        this.submitAddFile(this.uploadingIndex)
                    }

                }else {
                    this.showUploading = false
                    this.getFileByGroup(1)
                    clearInterval(this.upLoadingTimer)
                    this.upLoadingTimer = null
                    ElMessageBox.alert('上传完毕！')
                }

            },
            choiceChange(){
                var f=document.getElementById('file').files
                var URL = window.URL || window.webkitURL
                for(var i=0;i<f.length;i++){
                    this.fileList.push({'upload': false,'name': f[i].name, 'url':URL.createObjectURL(f[i]),'file':f[i]})
                }
                console.log(this.fileList)
            },
            toupload(){
                this.showFileChoice =true
            },
            removeChoice(id){
                console.log(id)
                var t=[]
                for(var i=0;i<this.fileList.length;i++)
                {
                    if (i!=id){
                        t.push(this.fileList[i])
                    }
                }
                this.fileList=t;
            },
            getFileByGroup(pageNum){
                this.showLoaddingImage = true
                this.selectedArr = []
                var url
                if (this.serchValue.length>0){
                    url='file/getFileByGroup?lv1=' + (this.active+1)+'&lv2=' + this.selectedGruop+'&pageNum=' + pageNum+'&pageSize=15' + '&qv=' + this.serchValue
                }else {
                    url='file/getFileByGroup?lv1=' + (this.active+1)+'&lv2=' + this.selectedGruop+'&pageNum=' + pageNum+'&pageSize=15'
                }
                getRequest(url).then(res=>{
                    // console.log(res)
                    this.fileItems = res.data.data.list
                    this.totalNum=res.data.data.total
                    this.showLoaddingImage = false
                })
            },
            currentChange(e){
                this.getFileByGroup(e)
            },
            submitSelected(){
                var t=[]
                for (var i=0;i<this.selectedArr.length;i++){
                    console.log(this.fileItems[this.selectedArr[i]].file_id)
                    t.push(JSON.parse(JSON.stringify(this.fileItems[this.selectedArr[i]])))
                }
                if (t.length>0){
                    this.$emit('confirm',t)
                }
                this.selectedArr=[]
                this.$emit("update:modelValue",false)
                // this.showCom = this.showCom_
            },
            copyUrl(ur){
                this.$copyText(ur).then(
                    res => {
                        this.$notify({type: 'success', message: '链接已复制'})
                    }
                )
            }
        },
        created() {
            // if (this.modelValue){
            //     this.active=0
            // }
            // this.getFileByGroup(0)
        }
    }
</script>

<style scoped>
    .mmc-box-class{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #999999;
        z-index: 100;
        opacity: 0.5;
    }
    .mmc-dialog-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 700px;
        width: 1000px;
        background-color: white;
        z-index: 101;
        border-radius: 8px;
        /*overflow: hidden;*/
        /*resize: both;*/
    }
    .root-group-class{
        display: flex;
    }
    .root-group-class:hover{
        cursor: pointer;
    }
    /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
        background-color: #38f3ff !important;
    }
    span{
        margin: 5px;
        color: blue;
    }
    span:hover{
        cursor: pointer;
    }
    .selected-icon{
        position: absolute;
        right: 0;
        background-color: #79ac1c;
        width: 60px;
        top:-5px;
        right: -20px;
        transform: rotate(45deg);
        z-index: 200;
    }
    .card-item-class{
        width: 140px;
        font-size: 12px;
        height: 130px;
        position: relative;
        overflow: hidden;
        border: rgba(0,216,216,0.09) solid 1px;
    }
    .card-item-class:hover{
        cursor: pointer;
        border: #00d8d8 solid 1px;
    }
    .file-upload-input::file-selector-button {
        background-color: white;
        cursor: pointer;
        border: #bcbcbc solid 1px;
        height: 35px;
        font-size: 15px;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    [type=file] {
        color: red;
        margin-left: 10px;
        /* font-size: 0; */
    }
    /deep/ .el-dialog__body{
        padding: 2px;
    }
    .delete-choice{
        color: rgba(255,43,33,0.39);
        position: absolute;
        top: -10px;
        right: -10px
    }
    .delete-choice:hover{
        color:white;
        background-color: rgb(255, 42, 32);
    }
    .choice-file-name::-webkit-scrollbar {
        display: none
    }
    .image-slot {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: var(--el-fill-color-light);
        color: var(--el-text-color-secondary);
        font-size: 30px;
    }
    .up-dialog-class{
        position: fixed;
        display: flex;
        align-items: center;
        top: 100px;
        left:0;
        right:0;
        margin:0 auto;
        width: 200px;
        height: 200px;
        background-color: white;
        z-index: 104;
        border-radius: 8px;
    }
    .up-box-class{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #999999;
        z-index: 103;
        opacity: 0.5;
    }
</style>