<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>导出</el-breadcrumb-item>
            <el-breadcrumb-item>导出任务</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: #eeeeee;padding: 10px">
            <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
                <el-form :inline="true" :model="conditionForm" class="demo-form-inline">
<!--                    <el-form-item label="名称">-->
<!--                        <el-input v-model="conditionForm.page_name" placeholder="名称" clearable />-->
<!--                    </el-form-item>-->
                    <el-form-item label="创建日期">
                        <el-date-picker
                                v-model="conditionForm.dateValue"
                                type="daterange"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                unlink-panels
                                range-separator="-"
                                start-placeholder="起始日期"
                                end-placeholder="截至日期"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="get">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div style="margin-top: 10px;background-color: white">
            <table>
                <thead>
                    <th>ID</th>
                    <th>创建日期</th>
                    <th>创建人</th>
                    <th>创建人名字</th>
                    <th>文件名</th>
                    <th>状态</th>
                    <th>下载</th>
                </thead>
                <tbody>
                <tr v-for="it in tableData">
                    <td>{{it.id}}</td>
                    <td>{{it.create_time}}</td>
                    <td>{{it.creator}}</td>
                    <td>{{it.creator_name}}</td>
                    <td>{{it.file_name}}</td>
                    <td :style="it.status=='导出完成'?'color:green':''">{{it.status}}</td>
                    <td>
                        <el-button @click="downloadFile(it)" size="small">下载</el-button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <el-pagination
                v-model:current-page="pageNum"
                v-model:page-size="pageSize"
                :page-sizes="[16,100, 200, 300, 400]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
                :pager-count="15"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";

    export default {
        name: "ExportTask",
        data(){
            return{
                conditionForm:{},
                totalNum:0,
                pageSize: 10,
                pageNum: 1,
                tableData:[]
            }
        },
        methods:{
            handleSizeChange(){
                this.pageNum = 1
                this.get()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.get()
            },
            get(){
                getRequest('file/getExportList?pageNum='+this.pageNum+'&pageSize='+this.pageSize).then(res=>{
                    console.log(res)

                    if (res.data.flag){
                        this.tableData=res.data.data.list
                        this.totalNum=res.data.data.total
                    }else {
                        this.$msgbox.alert(res.data.message)
                    }
                })
            },
            downloadFile(it){
                getRequest('file/downloadFile?fileid='+it.file_id).then(res=>{
                    console.log(res)
                    window.location.href=res.data.data
                })
            }
        },
        mounted() {
            this.get()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>