<template>
    <div v-loading="showLoading">
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
            <span style="margin-right: auto">编辑运费模板:<span style="color: blue"></span></span>
            <el-button type="primary" style="width: 90px" @click="saveFreightEdit">保存</el-button>
            <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
        </div>
        <div>
            <div style="display: flex;flex-direction: row">
                <div style="width: 200px">运费模板</div>
                <el-input v-model="template.name" style="width: 500px"/>
            </div>
        </div>
        <div>
            <div style="display: flex;flex-direction: row;margin-top: 20px;align-items: center">
                <div style="width: 200px">运费类型</div>
                <el-radio-group v-model="template.ftype">
                    <el-radio label="1">自定义模板</el-radio>
                    <el-radio label="2">包邮免运费</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div>
            <div style="display: flex;flex-direction: row;margin-top: 20px;align-items: center">
                <div style="width: 200px">运费规则</div>
                <div>按件数:</div>
                <el-input v-model="template.start_max_num" style="width: 70px;margin-left: 20px"></el-input>
                <div>件内</div>
                <el-input v-model="template.start_fee" style="width: 70px;margin-left: 20px"></el-input>
                <div>元,每增加</div>
                <el-input v-model="template.add_step" style="width: 70px;margin-left: 20px"></el-input>
                <div>件，运费增加</div>
                <el-input v-model="template.add_fee" style="width: 70px;margin-left: 20px"></el-input>
                <div>元</div>
            </div>
        </div>
        <div v-if="template.ftype==1">
            <div style="display: flex;flex-direction: row;margin-top: 20px;align-items: center">
                <div style="width: 200px">指定地区运费规则</div>
                <el-button v-on:click="andCityFright">添加</el-button>
            </div>
            <div v-for="(it1,index1) in template.freight_rules">
                <div v-if="it1.rule_type==1" style="border-radius: 5px;display: flex;flex-direction: row;margin-top: 20px;align-items: center;background-color: rgba(0,0,0,0.07);padding: 10px;width: 850px;margin-left: 200px">
                    <div @click="toSelectRegion(index1)" style="margin-right: 5px;color: blue;cursor:pointer">已选城市{{it1.cityNum}}  个</div>
                    <div>按件数:</div>
                    <el-input v-model="it1.start_max_num" style="width: 70px;margin-left: 20px"></el-input>
                    <div>件内</div>
                    <el-input v-model="it1.start_fee" style="width: 70px;margin-left: 20px"></el-input>
                    <div>元,每增加</div>
                    <el-input v-model="it1.add_step" style="width: 70px;margin-left: 20px"></el-input>
                    <div>件，运费增加</div>
                    <el-input v-model="it1.add_fee" style="width: 70px;margin-left: 20px"></el-input>
                    <div>元</div>
                    <div @click="deleteFreightRules(index1)" style="margin-left: 70px;color: red;cursor:pointer">删除</div>
                </div>
            </div>
        </div>
        <div v-if="template.ftype==1">
            <div style="display: flex;flex-direction: row;margin-top: 20px;align-items: center">
                <div style="width: 200px">地区包邮规则</div>
                <el-button v-on:click="addCityNoFright">添加</el-button>
            </div>
            <div v-for="(it2,index2) in template.freight_rules">
                <div v-if="it2.rule_type==2" style="display: flex;flex-direction: row;margin-top: 20px;align-items: center;background-color: rgba(0,0,0,0.07);padding: 10px;width: 600px;margin-left: 200px">
                    <div @click="toSelectRegion(index2)" style="color: blue;margin-right: 5px;cursor:pointer">已选城市 {{it2.cityNum}} 个</div>
                    <el-select v-model="it2.no_freight_type">
                        <el-option label="按金额" :value="1" />
                        <el-option label="按数量" :value="2" />
                    </el-select>
                    <div>满</div>
                    <el-input v-model="it2.no_freight_value" style="width: 100px;margin-left: 20px"></el-input>
                    <div>包邮</div>
                    <div @click="deleteFreightRules(index2)" style="margin-left: 50px;color: red;cursor:pointer">删除</div>
                </div>
            </div>
        </div>
        <region-select-com v-if="showRegionSelect" v-model="showRegionSelect" @confirm="regionConfirm" :disabled-region="disabledRegion" :selected-region="selectedRegion"></region-select-com>
    </div>
</template>

<script>
    import RegionSelectCom from "../common/region-select-com";
    import {getRequest, postRequest} from "../../utils/api";
    export default {
        name: "FreightTemplateEdit",
        components: {RegionSelectCom},
        data(){
            return{
                regionSelectType:1,
                showRegionSelect:false,
                SelectRegionIndex:0,
                SelectRegionForNFrIndex:0,
                disabledRegion:[],
                selectedRegion:[],
                template:{},
                showLoading:false

            }
        },
        methods:{
            saveFreightEdit(){
                // console.log(this.template)
                this.showLoading=true
                postRequest('mall/savaFreightRule',this.template).then(res=>{
                    this.showLoading=false
                    if (res.data.flag && this.template.id==-1){
                        this.$router.back()
                    }else {
                        this.getTemplate()
                    }
                    this.$alert(res.data.message)
                })
            },
            backHome(){
                this.$router.back()
            },
            andCityFright(){
                this.template.freight_rules.push(
                    {
                        rule_type:1,
                        start_max_num:0,
                        start_fee:0,
                        add_step:0,
                        add_fee:0,
                        cityNum:0,
                        no_freight_type:1,
                        no_freight_value:0,
                        regions:[]
                    }
                )
            },
            addCityNoFright(){
                this.template.freight_rules.push(
                    {
                        rule_type:2,
                        start_max_num:'',
                        start_fee:0,
                        add_step:0,
                        add_fee:0,
                        cityNum:0,
                        no_freight_type:1,
                        no_freight_value:0,
                        regions:[]
                    }
                )
            },
            toSelectRegion(index){
                if (this.template.freight_rules[index].regions==null){
                    this.selectedRegion=[]
                }else {
                    this.selectedRegion=this.template.freight_rules[index].regions
                }

                console.log(this.selectedRegion)
                this.disabledRegion=[]
                for (var i=0;i<this.template.freight_rules.length;i++){
                    if (i!=index && this.template.freight_rules[i].regions!=null){
                        for (var r=0;r<this.template.freight_rules[i].regions.length;r++){
                            this.disabledRegion.push(this.template.freight_rules[i].regions[r].region_id)
                            for (var s=0;s<this.template.freight_rules[i].regions[r].checkedList.length;s++){
                                this.disabledRegion.push(this.template.freight_rules[i].regions[r].checkedList[s].region_id)
                            }
                        }
                    }

                }
                this.SelectRegionIndex=index
                this.showRegionSelect=true
            },
            regionConfirm(e){
                this.template.freight_rules[this.SelectRegionIndex].regions=e
                var n=0
                for (var i=0;i<this.template.freight_rules[this.SelectRegionIndex].regions.length;i++){
                    n=n+this.template.freight_rules[this.SelectRegionIndex].regions[i].checkedList.length
                }
                this.template.freight_rules[this.SelectRegionIndex].cityNum=n
                console.log(this.template)
            },
            deleteFreightRules(index){
                var t=[]
                for (var i=0;i<this.template.freight_rules.length;i++){
                    if (i!=index){
                        t.push(this.template.freight_rules[i])
                    }
                }
                this.template.freight_rules=t
            },
            getTemplate(){
                this.showLoading=true
                getRequest('mall/getFreightTemplateById?id=' + this.$route.params.id).then(res=>{
                    this.showLoading=false
                    if (res.data.flag){
                        this.template=res.data.data
                    }
                })
            }
        },
        mounted() {
            var id=this.$route.params.id
            if (id==-1){
                console.log(1)
                this.template={
                    id:-1,
                    ftype:'1',
                    start_max_num:0,
                    start_fee:0,
                    add_step:0,
                    add_fee:0,
                    cityNum:0,
                    no_freight_type:1,
                    no_freight_value:0,
                    freight_rules:[]
                }
            }else {
                this.getTemplate()
            }
        }
    }
</script>

<style scoped>

</style>