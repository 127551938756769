<template>
    <div v-if="modelValue" class="dialog-class">
        <div style="display: flex;flex-direction: row;font-size: 15px">
            <div style="display: flex;flex-direction: row;align-items: center;">
                <span style="width: 150px">商品代码</span>
                <el-input size="small" v-model="goods_code" placeholder="多商品用逗号分隔,例:00000034，00000035"></el-input>
            </div>
            <div style="display: flex;flex-direction: row;align-items: center;margin-left: 10px">
                <span style="width: 150px">商品名称</span>
                <el-input size="small" v-model="goods_name">></el-input>
            </div>
            <el-button icon="search" style="margin-left: 10px" type="primary" @click="get">搜索</el-button>
        </div>
        <div style="width: 100%;border-bottom: #bcbcbc solid 1px;margin-top: 10px;margin-bottom: 10px"></div>
        <div v-loading="showLoading" style="height: 580px;overflow: scroll">
            <el-checkbox-group v-model="checkList" @change="checkChange">
                <table>
                    <thead>
                    <th>序号</th>
                    <th>商品代码</th>
                    <th>商品原名</th>
                    <th>商品名称</th>
                    <th>图片</th>
                    </thead>
                    <tr v-for="(it,index) in goods">
                        <td>{{index+1}}</td>
                        <td><el-checkbox :label="it.goods_code" /></td>
                        <td>{{it.source_name}}</td>
                        <td>{{it.goods_name}}</td>
                        <td><el-image :src="it.pic_url" style="width: 40px;height: 40px"></el-image></td>
                    </tr>
                </table>
            </el-checkbox-group>
        </div>
        <el-pagination
                v-model:current-page="pageNum"
                v-model:page-size="pageSize"
                :page-sizes="[9,100, 200, 300, 400]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
                :pager-count="5"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
        <div style="padding: 5px;text-align: right">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" style="margin-right: 20px;margin-left: 20px" @click="confirm">确定</el-button>
        </div>
    </div>
</template>

<script>
    import {postRequest} from "../../utils/api";

    export default {
        name: "goods-select-com",
        emits: ['confirm','update:modelValue'],
        props:{
            multipleSelect:{
                type:Boolean,
                defualt:false
            },
            modelValue:{
                type:Boolean,
                defualt:false
            }},
        data(){
            return{
                pageNum:1,
                pageSize: 9,
                totalNum: 0,
                goods_code:'',
                goods_name:'',
                goods:[],
                checkList:[],
                showLoading:false,
                confirmData:[]
            }
        },
        methods:{
            cancel(){
                this.checkList = []
                this.confirmData = []
                this.$emit('update:modelValue',false)
            },
            confirm(){
                if (this.confirmData.length>0){
                    this.$emit('confirm',JSON.parse(JSON.stringify(this.confirmData)))
                }
                this.checkList = []
                this.confirmData = []
                this.$emit('update:modelValue',false)
            },
            get(){
                this.showLoading = true
                var pm={'pageNum':this.pageNum,'pageSize': this.pageSize}
                var goods_codes=[]
                if (this.goods_code.length>0){
                    goods_codes= this.goods_code.split(",")
                }
                if (goods_codes.length>1){
                    pm['codes']=goods_codes
                    pm['code']=null
                }else {
                    pm['codes']=null
                    pm['code']=goods_codes[0]
                }
                if (this.goods_name.length>0){
                    pm['name']=this.goods_name
                }
                postRequest('goods/getCommdityList',pm).then(res=>{
                    this.showLoading = false
                    this.goods=res.data.data.list
                    this.totalNum=res.data.data.total
                })
            },
            handleSizeChange(){
                this.pageNum = 1
                this.get()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.get()
            },
            checkChange(e){
                if (this.multipleSelect){
                    var t=[]
                    if (this.confirmData.length>this.checkList.length){
                        for (var i=0;i<this.confirmData.length;i++){
                            var ex=false
                            for (var e=0;e<this.checkList.length;e++){
                                if (this.confirmData[i].goods_code==this.checkList[e]){
                                    ex=true
                                }
                            }
                            if (ex){
                                t.push(JSON.parse(JSON.stringify(this.confirmData[i])))
                            }
                        }
                        this.confirmData=t

                    }else{
                        for (var i=0;i<this.checkList.length;i++){
                            var ex=false
                            for (var e=0;e<this.confirmData.length;e++){
                                if (this.confirmData[e].goods_code==this.checkList[i]){
                                    ex=true
                                }
                            }
                            if (!ex){
                                for (var d=0;d<this.goods.length;d++){
                                    if(this.checkList[i]==this.goods[d].goods_code){
                                        this.confirmData.push(JSON.parse(JSON.stringify(this.goods[d])))
                                    }
                                }
                            }
                        }
                    }
                }else {
                    var t=[]
                    for(var i=0;i<this.checkList.length;i++){
                        var ex=false
                        for (var e=0;e<this.confirmData.length;e++){
                            if (this.checkList[i]==this.confirmData[e].goods_code){
                                ex=true
                            }
                        }
                        if (!ex){
                            t.push(this.checkList[i])
                            for (var d=0;d<this.goods.length;d++){
                                if(this.checkList[i]==this.goods[d].goods_code){
                                    this.confirmData=[JSON.parse(JSON.stringify(this.goods[d]))]
                                }
                            }
                            this.checkList=t
                            break
                        }
                    }
                }
            }
        },
        created() {
            this.get()
        }
    }
</script>

<style scoped>
    .dialog-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 700px;
        width: 700px;
        background-color: #ffffff;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
        padding: 20px;
        z-index: 1000;
    }
    table, td, tr, th {
        font-size: small;
        line-height: 15px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        padding: 10px;
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
        border-left: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
        border-left: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>