<template>
    <div v-if="modelValue" class="dialog-class">
        <div style="display: flex;flex-direction: row;font-size: 15px">
            <div style="display: flex;flex-direction: row;align-items: center;">
                <span style="width: 150px">查询值</span>
                <el-input size="small" v-model="queryValue" placeholder="id/名称/url 的模糊搜索"></el-input>
            </div>
            <el-button icon="search" style="margin-left: 10px" type="primary" @click="get">搜索</el-button>
        </div>
        <div style="width: 100%;border-bottom: #bcbcbc solid 1px;margin-top: 10px;margin-bottom: 10px"></div>
        <div v-loading="showLoading" style="height: 380px;overflow: scroll">
            <table>
                <thead>
                <th>id</th>
                <th>名称</th>
                <th>URL</th>
                <th>图片</th>
                </thead>
                <tr v-for="(it,index) in urls">
                    <td>{{it.id}}</td>
                    <td>{{it.url_name}}</td>
                    <td>{{it.url}}</td>
                    <td>
                        <span style="color:blue;cursor: pointer" @click="selected(it)">选择</span>
                    </td>
                </tr>
            </table>
        </div>
        <el-pagination
                v-model:current-page="pageNum"
                v-model:page-size="pageSize"
                :page-sizes="[10,100, 200, 300, 400]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
                :pager-count="5"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                style="margin-top: 10px"
        />
        <div style="background-color: rgba(177,204,223,0.45);height: 180px;margin-top: 10px;border-radius: 6px;position: relative;padding: 20px">
            <div v-if="selectedUrl!=null">
                <div style="display: flex;flex-direction: row;align-items: center;">
                    <span style="width: 100px">{{selectedUrl.url_name}}</span>
                    <span>{{selectedUrl.url}}</span>
                </div>
                <div  v-if="selectedUrl.param1_name!=null" style="display: flex;margin-top: 20px;margin-bottom: 20px">
                    <span style="width: 100px">{{selectedUrl.param1_name}}</span>
                    <el-input size="small" style="width: 200px" @change="paramChange" v-model="selectedUrl.param1_value" :placeholder="selectedUrl.param1_name"></el-input>
                </div>
                <div  v-if="selectedUrl.param2_name!=null" style="display: flex;">
                    <span style="width: 100px">{{selectedUrl.param2_name}}</span>
                    <el-input size="small" style="width: 200px" @change="paramChange" v-model="selectedUrl.param2_value" :placeholder="selectedUrl.param2_name"></el-input>
                </div>
            </div>

            <div style="display: flex;margin-top: 20px">
                <span style="width: 100px;">最终URL</span>
                <span>{{url}}</span>
            </div>
            <div style="padding: 5px;text-align: right;position: absolute;bottom: 0;display: flex;width: 100%">
                <el-button @click="cancel" style="margin-left: auto">取消</el-button>
                <el-button type="primary" style="margin-right: 50px;margin-left: 20px" @click="confirm">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";

    export default {
        name: "wepage-url-select",
        emits: ['confirm','update:modelValue'],
        props:{
            multipleSelect:{
                type:Boolean,
                defualt:false
            },
            modelValue:{
                type:Boolean,
                defualt:false
            }
        },
        data(){
            return{
                showLoading:false,
                pageNum:1,
                pageSize:10,
                totalNum:1,
                urls:[],
                queryValue:[],
                selectedUrl:null,
                url:''
            }
        },
        methods:{
            handleSizeChange(){
                this.pageNum = 1
                this.get()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.get()
            },
            get(){
                getRequest('wepage/getWepageUrlList?queryValue='+this.queryValue+'&pageNum='+this.pageNum+'&pageSize='+this.pageSize).then(res=>{
                    console.log(res)
                    if (res.data.flag){
                        this.urls=res.data.data.list
                        this.totalNum=res.data.data.total
                    }
                })
            },
            cancel(){
                this.$emit('update:modelValue',false)
            },
            paramChange(e){
                var url=this.selectedUrl.url
                console.log(this.selectedUrl)
                if (this.selectedUrl.param1_value!=null && this.selectedUrl.param1_value!='' && this.selectedUrl.param2_value!=null && this.selectedUrl.param2_value!=''){
                    console.log(1)
                    url=url+"?"+this.selectedUrl.param1_code+"="+this.selectedUrl.param1_value
                    +"&"+this.selectedUrl.param2_code+"="+this.selectedUrl.param2_value
                }

                if (this.selectedUrl.param1_value!=null && this.selectedUrl.param1_value!=''
                    && (this.selectedUrl.param2_value==null || this.selectedUrl.param2_value=='')
                ){
                    url=url+"?"+this.selectedUrl.param1_code+"="+this.selectedUrl.param1_value
                }

                if ((this.selectedUrl.param2_value!=null && this.selectedUrl.param2_value!='')
                    && (this.selectedUrl.param1_value==null || this.selectedUrl.param1_value=='')
                ){
                    url=url+"?"+this.selectedUrl.param2_code+"="+this.selectedUrl.param2_value
                }
                this.url=url
            },
            confirm(){
                if (this.selectedUrl!=null){

                    this.$emit('confirm',this.url)
                }
                this.$emit('update:modelValue',false)
            },
            selected(r){
                this.selectedUrl=r
                this.url=this.selectedUrl.url
            }
        },mounted() {
            this.get()
        }
    }
</script>

<style scoped>
    .dialog-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 700px;
        width: 600px;
        background-color: white;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
        padding: 20px;
    }
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
        font-size: 13px;
    }
    td{
        border-top: none;
        /*padding: 5px;*/
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>div>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>