<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>导购</el-breadcrumb-item>
            <el-breadcrumb-item>导购及客户</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="text-align: left">
            <el-form :inline="true" :model="pm" class="demo-form-inline">
                <el-form-item label="导购代码">
                    <el-input v-model="pm.guider_code"  placeholder="导购代码" clearable />
                </el-form-item>
                <el-form-item label="导购姓名">
                    <el-input v-model="pm.guider_name" placeholder="导购姓名" clearable />
                </el-form-item>
                <el-form-item label="导购手机号">
                    <el-input v-model="pm.guider_mobile" placeholder="导购手机号" clearable />
                </el-form-item>
                <el-form-item label="门店">
                    <el-input clearable  readonly :model-value="pm.guider_shop_code+pm.guider_shop_name">
                        <template #append>
                            <el-button @click="toSelectShop" icon="ArrowDown" />
                        </template>
                    </el-input>
                    <shop-select-com v-if="showShopSelect" v-model="showShopSelect" @confirm="storeSelectConfirm" :multipleSelect="true"></shop-select-com>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmitQuery">查询</el-button>
                    <el-button @click="clear">清空</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="position: relative;margin-top: 10px">
            <div v-loading="showLoading" style="height: 600px;overflow: scroll;border-bottom: #bcbcbc solid 1px;margin-bottom: 20px">
                <table>
                    <thead>
                    <th>代码</th>
                    <th>名称</th>
                    <th>所属门店</th>
                    <th>手机</th>
                    <th>状态</th>
                    <th>企微客户数</th>
                    <th>企微账号</th>
                    <th>操作</th>
                    </thead>
                    <tbody>
                    <tr v-for="(it,index) in tableData">
                        <td>{{it.guider_code}}</td>
                        <td>{{it.guider_name}}</td>
                        <td>{{it.guider_shop_name}}</td>
                        <td>{{it.guider_mobile}}</td>
                        <td>{{it.disable==1?'启用':'停用'}}</td>
                        <td>{{it.qw_external_user_num}}</td>
                        <td>{{it.qw_userid}}</td>
                        <td>
                            <span @click="tosync(it)">同步客户</span>
                            <span @click="toassign(it)">分配客户</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <el-pagination
                v-model:current-page="pm.pageNum"
                v-model:page-size="pm.pageSize"
                :page-sizes="[20,50, 100, 200, 400]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pm.totalNum"
                :pager-count="15"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
        <el-dialog v-model="showAsDg">
            <div>
                <div style="text-align: left">
                    <el-form :inline="true" :model="pm" class="demo-form-inline">
                        <el-form-item label="导购" size="small">
                            <el-input readonly :model-value="asit.guider_code + '['+asit.guider_name+']'" style="width: 300px" placeholder="导购代码" clearable />
                        </el-form-item>
                        <el-form-item label="会员名" size="small">
                            <el-input v-model="aspm.vip_name" placeholder="会员名" clearable />
                        </el-form-item>
                        <el-form-item label="会员手机号" size="small">
                            <el-input v-model="aspm.vip_mobile" placeholder="会员手机号" clearable />
                        </el-form-item>
                        <el-form-item label="会员代码" size="small">
                            <el-input v-model="aspm.vip_code" placeholder="会员代码" clearable />
                        </el-form-item>
                        <el-form-item size="small">
                            <el-button type="primary" @click="getExAcount">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div style="border-bottom: #bcbcbc solid 1px;margin-top: 10px;margin-bottom: 10px"></div>
                <div style="display: flex;align-items: center;color: red">
                    <span>接收导购:</span>
                    <el-input :model-value="acceptGuider.guider_code+'['+acceptGuider.guider_name+']'" style="width: 200px;margin-left: 20px">
                        <template #append>
                            <el-button @click="toSelectGuider" icon="MoreFilled"></el-button>
                        </template>
                    </el-input>
                    <el-button @click="assignAll" style="margin-left: 30px">全量分配</el-button>
                </div>
                <guider-select-com v-model="showGuiderSelect" v-if="showGuiderSelect" @confirm="guiderSelected"></guider-select-com>
                <div style="height: 400px;overflow: scroll;margin-top: 10px">
                    <el-checkbox-group v-model="checkList">
                        <table>
                            <thead>
                            <th style="text-align: left">企微名</th>
                            <th>会员名</th>
                            <th>会员代码</th>
                            <th>手机</th>
                            </thead>
                            <tbody>
                            <tr v-for="(it,index) in customerTable">
                                <td style="text-align: left"><el-checkbox :label="it.external_userid">{{it.qw_name}}</el-checkbox></td>
                                <td>{{it.vip_name}}</td>
                                <td>{{it.vip_code}}</td>
                                <td>{{it.vip_mobile}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </el-checkbox-group>
                </div>
                <el-pagination
                        v-model:current-page="aspm.pageNum"
                        v-model:page-size="aspm.pageSize"
                        :page-sizes="[20,50, 100, 200, 400]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="aspm.totalNum"
                        :pager-count="15"
                        @size-change="ashandleSizeChange"
                        @current-change="ashandleCurrentChange"
                />
            </div>
            <div style="text-align: right;margin-right: 50px">
                <el-button @click="assignSelect" style="margin-left: 30px" type="primary">分配已选客户</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import GuiderSelectCom from "../common/guider-select-com";
    import ShopSelectCom from "../common/shop-select-com";

    export default {
        name: "GuiderList",
        components: {ShopSelectCom, GuiderSelectCom},
        data(){
           return{
               pm:{
                   pageNum:1,
                   pageSize:20,
                   totalNum:0,
                   guider_shop_code:'',
                   guider_shop_name:'',
               },
               aspm:{
                   pageNum:1,
                   pageSize:10,
                   totalNum:0,
               },
               tableData:[],
               customerTable:[],
               showLoading:false,
               showAsDg:false,
               asit:{},
               checkList:[],
               showGuiderSelect:false,
               acceptGuider:{
                   guider_code:'',
                   guider_name:'请选择接收导购'
               },
               showShopSelect:false
           }
        },
        methods:{
            clear(){
                this.pm['guider_shop_code']=''
                this.pm['guider_shop_name']=''
                this.pm['guider_code']=''
                this.pm['guider_name']=''
                this.pm['guider_mobile']=''
            },
            toSelectShop(){
                this.showShopSelect=true
            },
            storeSelectConfirm(e){
                this.pm.guider_shop_code=e[0].shop_code
                this.pm.guider_shop_name=e[0].shop_name
            },
            onSubmitQuery(){
                this.showLoading=true
                this.checkList=[]
                postRequest('wecom/getGuiderList',this.pm).then(res=>{
                    this.showLoading=false
                    this.tableData=res.data.data.list
                    this.pm.totalNum=res.data.data.total
                })
            },
            getExAcount(){
                this.aspm['userid']=this.asit.qw_userid
                this.customerTable=[]
                postRequest('wecom/getGuiderQwAccountByUserid',this.aspm).then(res=>{
                    this.customerTable=res.data.data.list
                    this.aspm.totalNum=res.data.data.total
                })
            },
            handleSizeChange(){
                this.pm.pageNum = 1
                this.onSubmitQuery()
            },
            handleCurrentChange(e){
                this.pm.pageNum = e
                this.onSubmitQuery()
            },
            ashandleSizeChange(){
                this.aspm.pageNum = 1
                this.onSubmitQuery()
            },
            ashandleCurrentChange(e){
                this.aspm.pageNum = e
                this.onSubmitQuery()
            },
            toassign(it){
                this.asit=it
                this.checkList=[]
                this.getExAcount()
                this.showAsDg=true
            },

            tosync(it){
               getRequest('wecom/syncGuiderAccount?guider_code='+it.guider_code).then(res=>{
                   this.$alert(res.data.message)
               })
            },
            toSelectGuider(){
                this.showGuiderSelect=true
            },
            guiderSelected(e){
                console.log(e)
                this.acceptGuider=e[0]

            },
            assignAll(){
                if (this.acceptGuider.guider_code==''){
                    this.$alert('请选择接收导购')
                    return
                }
                getRequest('wecom/assignAllGuiderExternalAcount?handover_guider_code='+this.asit.guider_code+'&takeover_guider_code='+this.acceptGuider.guider_code).then(res=>{
                    this.$alert(res.data.message)
                })
            },
            assignSelect(){
                if (this.acceptGuider.guider_code==''){
                    this.$alert('请选择接收导购')
                    return
                }
                if (this.checkList.length<1){
                    this.$alert('请选择客户')
                    return
                }
                var pm={}
                pm['handover_guider_code']=this.asit.guider_code
                pm['takeover_guider_code']=this.acceptGuider.guider_code
                pm['accounts']=this.checkList
                postRequest('wecom/assignGuiderExternalAcounts',pm).then(res=>{
                    this.$alert(res.data.message)
                })
            }
        },
        mounted() {
          this.onSubmitQuery()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 20px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 20px;
        color: blue;
        cursor: pointer;
    }
</style>