import Cookies from 'js-cookie'

const loginFlag = 'loginflag'
const auths = 'auths'
const username = 'username'
const userid = 'userid'
const token = 'token'


export function removeUser () {
}
export function getLoginFlag () {
  return Cookies.get(loginFlag)
}
export function setLoginFlag (fg) {
  return Cookies.set(loginFlag, fg)
}

export function getAuths () {
  return Cookies.get(auths)
}
export function setAuths (auth) {
  return Cookies.set(auths, JSON.stringify(auth))
}

export function getUserName () {
  return Cookies.get(username)
}
export function setUserName (v) {
  return Cookies.set(username,v )
}

export function getUserId () {
  return Cookies.get(userid)
}
export function setUserId(v) {
  return Cookies.set(userid,v )
}

export function getToken () {
  return Cookies.get(token)
}
export function setToken(v) {
  return Cookies.set(token,v )
}




export function checkAuth(auth){
  var authed=false
  var auts = JSON.parse(getAuths())
  for(var i=0;i<auts.length;i++){
    if (auts[i]==auth){
      authed =true
      break
    }
  }
  return authed;
}