
import {getLoginFlag} from '../../utils/auth'
const user = {
  state: {
    logined: getLoginFlag()
  },
  mutations: {
  }
}

export default user
