<template>
    <div style="padding: 10px;text-align: left">
        <h4>视频模板配置</h4>
        <div>视频地址</div>
        <div style="margin-top: 10px">
            <video :src="videoUrl_" controls="controls" style="width: 200px;height: 100px">
                您的浏览器不支持 video 标签。
            </video>
            <el-input disabled placeholder="请输入内容" v-model="videoUrl_" class="input-with-select">
                <template v-slot:append>
                    <el-button @click="toSelect(1)"><el-icon><Plus /></el-icon></el-button>
                </template>
            </el-input>
        </div>
        <div style="margin-top: 10px">封面图片</div>
        <img :src="coverUrl_" style="width: 200px;height: 100px">
        <media-material-com v-model="showSelect" @confirm="mediaConfrim"></media-material-com>
        <div style="margin-top: 10px">
            <el-input disabled placeholder="请输入内容" v-model="coverUrl_" class="input-with-select">
                <template v-slot:append>
                    <el-button @click="toSelect(2)"><el-icon><Plus /></el-icon></el-button>
                </template>
            </el-input>
        </div>
        <div style="margin-top: 10px">播放设置</div>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap">
            <el-checkbox v-model="silence_" label="静音播放"></el-checkbox>
            <el-checkbox v-model="loop_" label="循环播放"></el-checkbox>
            <el-checkbox v-model="aotuPlay_" label="自动播放"></el-checkbox>
        </div>
    </div>
</template>

<script>
    import MediaMaterialCom from "../../common/media-material-com";
    export default {
        name: "video-com-config",
        components: {MediaMaterialCom},
        props:{
            comData: {
                silence: Boolean,
                aotuPlay: Boolean,
                loop: Boolean,
                coverId: String,
                coverUrl: String,
                videoId: String,
                videoUrl: String
            }
        },
        watch:{
            silence_(){
              this.comData_.silence = this.silence_
            },
            aotuPlay_(){
                this.comData_.aotuPlay = this.aotuPlay_
            },
            loop_(){
                this.comData_.loop = this.loop_
            },
            videoUrl_(){
                this.comData_.videoUrl = this.videoUrl_
            },
            coverId_(){
                this.comData_.coverId = this.coverId_
            }
        },
        data(){
            return{
                comData_: null,
                silence_: true,
                aotuPlay_: false,
                loop_: false,
                coverUrl_: 'String',
                videoUrl_: 'String',
                showSelect:false,
                selectType: 1
            }
        },
        methods:{
            toSelect(t){
                this.selectType = t
                this.showSelect = true
            },
            mediaConfrim(e){
                console.log(e)
                if (this.selectType == 1){
                    this.comData_.videoId=e[0].file_id
                    this.videoUrl_=e[0].url
                }else {
                    this.comData_.coverId=e[0].file_id
                    this.comData_.coverUrl=e[0].url
                    this.coverUrl_=e[0].url
                }
                console.log(this.comData_)
            }
        },
        mounted() {
            this.comData_ = this.comData
            console.log(this.comData.silence)
            this.silence_ = this.comData.silence
            this.aotuPlay_ = this.comData.aotuPlay
            this.loop_ = this.comData.loop
            this.coverImg_ = this.comData.coverImg
            this.videoUrl_ = this.comData.videoUrl
            console.log(this.comData_)
        }
    }
</script>

<style scoped>
    .el-checkbox{
        margin-top: 10px;
    }
</style>