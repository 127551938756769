<template>
    <div>
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
            <span style="margin-right: auto">欢迎语:{{act.id}}</span>
            <el-button type="primary" style="width: 90px" @click="saveGiftEdit">保存</el-button>
            <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
        </div>
        <div style="background-color: white;padding: 10px" v-loading="showLoading">
            <div>
                <div class="row-class">
                    <span>标题</span>
                    <el-input v-model="act.title"></el-input>
                </div>
                <div class="row-class">
                    <span>消息类型</span>
                    <el-select
                            v-model="act.type_code"
                            placeholder="消息类型"
                            :disabled="readonly"
                    >
                        <el-option label="企微加好友" :value="1" />
                        <el-option label="公众号关注" :value="2" />
                    </el-select>
                </div>
                <div class="row-class">
                    <span>欢迎语内容</span>
                    <el-input v-model="act.welcome_text" type="textarea"  input-style="width: 500px;height: 150px"></el-input>
                </div>
                <div class="row-class">
                    <span>链接标题</span>
                    <el-input v-model="act.link_title"></el-input>
                </div>
                <div class="row-class">
                    <span>链接地址</span>
                    <el-input v-model="act.link_url"></el-input>
                </div>
                <div class="row-class">
                    <span>链接封面图</span>
                    <media-material-com v-model="showImgSelect" @confirm="imgConfirmHandler" :multipleSelect="false"></media-material-com>
                    <img style="width: 100px;height: 100px" :src="act.link_media_file_url"/>
                    <el-button  icon="plus" style="height: 100px" @click="toshowImgSelect">选择封面</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MediaMaterialCom from "../common/media-material-com";
    import {getRequest, postRequest} from "../../utils/api";
    export default {
        name: "WelcomeEdit",
        components: {MediaMaterialCom},
        data(){
            return{
                act:{
                    title:'',
                    type_code:'',
                    type_name:'',
                    welcome_text:'',
                    link_title:'',
                    link_url:'',
                    link_media_file_url:'',
                    link_media_file_id:'',
                },
                file:null,
                readonly:false,
                showImgSelect:false,
                showLoading:false
            }
        },
        methods:{
            backHome(){
                this.$router.back()
            },
            saveGiftEdit(){
                if (this.act.id==-1){
                    if(this.act.type_code===1){
                        this.act.type_name='企微加好友'
                    }
                    if(this.act.type_code===2){
                        this.act.type_name='公众号关注'
                    }
                    postRequest('wecom/submitWelcome',this.act).then(res=>{
                        this.$alert(res.data.message)
                        this.backHome()
                    })
                }else {
                    postRequest('wecom/submitWelcome',this.act).then(res=>{
                        this.$alert(res.data.message)
                        this.get()
                    })
                }

            },
            imgConfirmHandler(e){
                console.log(e)
                this.act.link_media_file_url=e[0].url
                this.act.link_media_file_id=e[0].file_id
            },
            toshowImgSelect(){
                this.showImgSelect=true
            },
            get(){
                this.showLoading=true
                getRequest('wecom/getWelcomeById?id='+this.act.id).then(res=>{
                    this.act=res.data.data
                    this.showLoading=false
                })
            }
        },
        mounted() {
            this.act.id = this.$route.params.id
            if (this.act.id!=-1){
                this.readonly=true
                this.get()
            }
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 20px;
    }
    .row-class span{
        width: 200px;
    }
    .row-class .el-input{
        width: 400px;
    }
</style>