import axios from 'axios'
import {Message} from "element3";
import {getToken} from "@/utils/auth";

axios.interceptors.response.use(success => {
  // console.log(1)
  // console.log(success)
  if (success.status && success.status === 200) {
    if (success.data.code === 500 || success.data.code === 401) {
      Message('请求失败')
      return
    }
  }
  return success
}, error => {
  console.log(error)
  console.log(error.response.status)
  if (error.response.status === 504 || error.response.status === 404) {
    Message('服务器爆炸了，请联系管理员')
  } else if (error.response.status === 401) {
    Message('尚未登录，请登录')
    this.$router.replace('/login')
  } else {
    console.log('未知错误')
  }
})

axios.interceptors.request.use((config) => {
  // if (store.getters.token) {
    config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  // } else {
  //   this.$router.replace('/login')
  // }
  return config
}, error => {
  Message(error)
})
let base = '/adminApi/'
export const postRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params
  })
}
export const getRequest = (url, params) => {
  // console.log(url)
  return axios({
    method: 'get',
    url: `${base}${url}`,
    parms: params
  })
}

export const filePostRequest = (url, params, header) => {
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params,
    headers: header
  })
}
