<template>
    <div style="background-color: #eeeeee">
        <div style="text-align: left;display: flex;flex-direction: row;background-color: white;padding: 10px;margin-top: 10px;align-items: center">
            <el-button @click="backHome" icon="el-icon-back" style="margin-right: auto;margin-left: 10px">返回</el-button>
            <span style="color: #005700">微页名称</span>
            <el-input v-model="pageData.name" style="width: 200px;margin-right: 20px;margin-left: 10px"></el-input>
            <span style="color: green">微页备注</span>
            <el-input v-model="pageData.note" style="width: 300px;margin-right: 20px;margin-left: 10px"></el-input>
            <el-button @click="viewQr" style="border: #1000ff solid 1px;color: #1000ff">预览</el-button>
            <el-button style="border: #dd7a08 solid 1px;color: #c97513" @click="saveWePage">保存</el-button>
<!--            <el-button style="border: #79ac1c solid 1px;color: #79ac1c;margin-right: 20px">发布</el-button>-->
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 20px;height: 830px ">
            <div style="display: flex;flex-direction: column;">
                <div class="coms-box">
                    <div>基础模板:</div>
                    <div style="display: flex;flex-direction: row;flex-wrap: wrap">
                        <mall-com-list-item v-on:click="addCom('commdity')" com-text="商品模板" icon-class="iconfont mmx-icon3dtouchmaishouzhuye"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('tabCom')" com-text="商品分组" icon-class="iconfont mmx-iconfenlei"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('recommend')" com-text="系统推荐" icon-class="iconfont mmx-icontuijian"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('imgCom')" com-text="图片模板" icon-class="iconfont mmx-icontupian"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('videoCom')" com-text="视频模板" icon-class="iconfont mmx-iconVideo"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('swipe')" com-text="轮播模板" icon-class="iconfont mmx-iconlunbotuguanli"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('noticeBar')" com-text="广播模板" icon-class="iconfont mmx-iconlaba"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('searchCom')" com-text="搜索模板" icon-class="iconfont mmx-iconchaxun"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('fullScreenImg')" com-text="全屏图组" icon-class="iconfont mmx-iconquanping"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('imgCommodity')" com-text="带图商品" icon-class="iconfont mmx-iconbeijingtu"></mall-com-list-item>
                        <mall-com-list-item v-on:click="addCom('placeholder')" com-text="占位组件" icon-class="iconfont mmx-iconzhanweifu"></mall-com-list-item>
                    </div>
                    <div>营销模板:</div>
                </div>
            </div>

            <div id="preview-box" class="preview-box">
                <div>
                    <el-image :src="getPhoneTop()"></el-image>
                    <div style="width: 100%;height: 35px;background-color: white"></div>
                </div>

                <div
                        class="com-item-class"
                        v-on:click="mallComClick(item,index)"
                        v-for="(item,index) in pageData.page"
                        :key="index"
                        draggable="true"
                        @dragstart="dragstart($event, index)"
                        @dragenter="dragenter($event, index)"
                        @dragend="dragend($event, index)"
                        @dragover="dragover"
                        :style="index==selectedIndex?'border: blue solid 1px;box-sizing: border-box':'border: #ededed solid 1px;box-sizing: border-box'"
                >
                    <div class="myclose-class" v-on:click="removeItem(index)">
                        <el-icon><Close /></el-icon>
                    </div>
                    <mall-home-com-item :com-data="item" ></mall-home-com-item>
                </div>
            </div>
            <div class="attribute-editing-box">
                <div v-for="(item,index) in pageData.page" :key="index">
                    <commodity-config v-if="index === selectedIndex && item.commdity !=null" :commdity="item.commdity" :slecet-index="index"></commodity-config>
                    <imgcom-config v-if="index === selectedIndex && item.imgCom !=null" :com-data="item.imgCom" :slecet-index="index"></imgcom-config>
                    <recommend-config v-if="index === selectedIndex && item.recommend !=null" :com-data="item.recommend" :slecet-index="index"></recommend-config>
                    <videocom-config v-if="index === selectedIndex && item.videoCom!=null" :com-data="item.videoCom" :slecet-index="index"></videocom-config>
                    <swipe-config v-if="index === selectedIndex && item.swipe !=null" :com-data="item.swipe" :slecet-index="index"></swipe-config>
                    <commodity-group-config v-if="index === selectedIndex && item.tabCom !=null" :com-data="item.tabCom" :slecet-index="index"></commodity-group-config>
                    <notice-bar-config v-if="index === selectedIndex && item.noticeBar !=null" :com-data="item.noticeBar" :slecet-index="index"></notice-bar-config>
                    <search-com-config v-if="index === selectedIndex && item.searchCom !=null" :com-data="item.searchCom" :slecet-index="index"></search-com-config>
                    <full-screen-img-config v-if="index === selectedIndex && item.fullScreenImg !=null" :com-data="item.fullScreenImg" :slecet-index="index"></full-screen-img-config>
                    <img-commodity-config v-if="index === selectedIndex && item.imgCommodity !=null" :com-data="item.imgCommodity" :slecet-index="index"></img-commodity-config>
                    <palceholder-config v-if="index === selectedIndex && item.placeholder !=null" :com-data="item.placeholder" :slecet-index="index"></palceholder-config>
                </div>
            </div>
        </div>
        <el-dialog v-model="showQr" style="width: 500px;height: 500px">
            <div v-loading="showQrLoading" style="width: 100%;height: 100%">
                <img :src="qrUrl" style="width: 400px;height: 400px"/>
                <div style="text-align: right">
                    <span @click="rebuildQr" style="color: blue;cursor: pointer">刷新</span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import MallComListItem from "./coms/mall-com-list-item";
    import MallHomeComItem from "./coms/mall-home-com-item";
    import CommodityConfig from "./coms/commodity-config";
    import ImgcomConfig from "./coms/imgcom-config";
    import VideocomConfig from "./coms/video-com-config";
    import SwipeConfig from "./coms/swipe-config";
    import CommodityGroupConfig from "./coms/commodity-group-config";
    import NoticeBarConfig from "./coms/notice-bar-config";
    import SearchComConfig from "./coms/search-com-config";
    import {getRequest, postRequest} from "../../utils/api";
    import FullScreenImgConfig from "./coms/full-screen-img-config";
    import RecommendConfig from "./coms/recommend-config";
    import ImgCommodityConfig from "./coms/img-commodity-config";
    import PalceholderConfig from "./coms/palceholder-config";
    export default {
        name: "WePageEdit",
        components: {
            PalceholderConfig,
            ImgCommodityConfig,
            RecommendConfig,
            FullScreenImgConfig,
            SearchComConfig,
            NoticeBarConfig,
            CommodityGroupConfig,
            SwipeConfig, VideocomConfig, ImgcomConfig, CommodityConfig, MallHomeComItem, MallComListItem},
        data() {
            return{
                showQr:false,
                showQrLoading:false,
                qrUrl:'',
                dragIndex: 0,
                selectedIndex: -1,
                id:'',
                scrollTop:0,
                initHomeData:{
                    swipe: {
                        interval: 2,
                        fullScreen:false,
                        height: 200,
                        data:[]
                    },
                    imgCom: {
                        showType: 1,
                        margin: 5,
                        radius: 5,
                        imgHeight:600,
                        autoHeight:false,
                        imgWidth:375,
                        data: []
                    },
                    videoCom: {
                        silence: false,
                        aotuPlay: false,
                        loop: true,
                        coverId: 'String',
                        coverUrl: 'String',
                        videoId: 'String',
                        videoUrl: 'String'
                    },
                    commdity:{
                        showType: 2,
                        priceColor: 'black',
                        priceFontSize: 12,
                        showLable: false,
                        showPrice:false,
                        lableColor: '#ffffff',
                        lableBackgroundColor: '#219dac',
                        lableTxt: '',
                        dataNum:3,
                        data: []
                    },
                    imgCommodity:{
                        priceColor: 'black',
                        priceFontSize: 12,
                        showLable: false,
                        showPrice:false,
                        lableColor: '#ffffff',
                        lableBackgroundColor: '#219dac',
                        background_image_id:'',
                        background_image_url:'',
                        imgHeight:600,
                        lableTxt: '',
                        dataNum:Number,
                        hot:[],
                        data: []
                    },
                    placeholder:{
                        height:30,
                        backgroundColor: '#E63535'
                    },
                    recommend:{
                        commdityNum:6,
                        showType: 3,
                        priceColor: '#000000',
                        priceFontSize: 10,
                        showPrice: false
                    },
                    tabCom:{
                        changeNum:0,
                        showType: 3,
                        margin: 1,
                        priceColor: '#219dac',
                        priceFontSize: 10,
                        showLable: 1,
                        showPrice: Boolean,
                        lableColor: '#44FF00',
                        lableBackgroundColor: '#219dac',
                        lableTxt: '',
                        data:[]
                    },
                    noticeBar: {
                        textColor: '#333333',
                        backgroundColor: '#ededed',
                        text: ''
                    },
                    searchCom: {
                        boxColor: '#FFFFFF',
                        backgroundColor: '#EAEAEA',
                        defaultText: '',
                        inputRadius: 20
                    },
                    fullScreenImg: {
                        data:[]
                    }
                },
                pageData:{
                    page:[]
                }
            }
        },
        watch: {
            // // 每当 question 改变时，这个函数就会执行
            // selectedIndex(newSelectedIndex) {
            //     // 在组件实例创建时会立即调用
            //     this.pageData.page.forEach((it,id) => {
            //         if (newSelectedIndex === id){
            //             var dc= document.getElementById('comAt_'+id)
            //             dc.setAttribute("style",'border: blue solid 1px;')
            //         }else {
            //             var dc2 = document.getElementById('comAt_'+id)
            //             dc2.setAttribute("style",'rgba(0, 179, 164, 0.23) solid 1px')
            //         }
            //     })
            // }
        },
        methods:{
            getPhoneTop(){
                return require('@/assets/image/phoneTop.png')
            },
            mallComClick(item,index){
                this.selectedIndex = index

            },
            getComId(index){
                return 'comAt_' + index
            },
            removeItem(id){
                var temp = []
                this.pageData.page.forEach((it,index) => {
                    if (id !== index){
                        temp.push(it)
                    }
                })
                this.pageData.page=[]
                var that=this
                var div = document.getElementById('preview-box');
                var scrollTop= div.scrollTop;
                setTimeout(()=>{
                    that.pageData.page = temp
                    setTimeout(()=>{
                        div.scrollTop=scrollTop
                    },50)
                },50)
                console.log(id)
            },
            addCom (adType){
                var temp={}
                console.log(temp)
                console.log(this.initHomeData)
                for(let key in this.initHomeData){
                    console.log(key)
                    if (key !== adType){
                        temp[key] = null
                    }else {
                        temp[key] = JSON.parse(JSON.stringify(this.initHomeData[key]))
                    }
                }
                console.log(temp)
                if (this.pageData.page==null){
                    this.pageData.page=[temp]
                }else {
                    var t=this.pageData.page
                    t.splice(this.selectedIndex+1,0,temp)
                    var div = document.getElementById('preview-box');
                    var scrollTop= div.scrollTop;
                    this.pageData.page=[]
                    setTimeout(()=>{
                        this.pageData.page=t
                        setTimeout(()=>{
                            div.scrollTop=scrollTop
                        },100)
                        this.selectedIndex = this.selectedIndex+1
                    },100)
                    console.log(this.pageData.page)
                }

                console.log(this.pageData.page)


            },
            dragstart(e, index) {
                e.stopPropagation()
                // this.dragIndex = index
                setTimeout(() => {
                    e.target.classList.add('moveing')
                }, 0)
            },
            dragenter(e, index) {
                e.preventDefault()
                // 拖拽到原位置时不触发
                // if (this.dragIndex !== index) {
                //     const source = this.pageData.page[this.dragIndex];
                //     this.pageData.page.splice(this.dragIndex, 1);
                //     this.pageData.page.splice(index, 0, source);
                //     // 更新节点位置
                // }
                this.dragIndex = index
            },
            dragover(e) {
                e.preventDefault()
                e.dataTransfer.dropEffect = 'move'
            },
            dragend(e,index) {
                // 拖拽到原位置时不触发
                if (this.dragIndex !== index) {
                    const source = this.pageData.page[index];
                    var t=JSON.parse(JSON.stringify(this.pageData.page))
                    this.pageData.page=[]
                    t.splice(index, 1);
                    t.splice(this.dragIndex, 0, source);
                    // 更新节点位置
                    var div = document.getElementById('preview-box');
                    var scrollTop= div.scrollTop;
                    setTimeout(()=>{
                        this.pageData.page=t
                        setTimeout(()=>{
                            div.scrollTop=scrollTop
                        },100)
                    },50)
                }
                e.target.classList.remove('moveing')
            },
            changeEvenHandler (index,data){
                console.log(index)
                console.log(data)
            },
            backHome(){
                this.$router.back()
            },
            saveWePage(){
                postRequest('wepage/updateWePage',this.pageData).then(res=>{
                    console.log(res)
                    if(res.data.flag){
                        this.$alert(res.data.message)
                    }
                })
            },
            get(){
                getRequest('wepage/getWePageDetailById?id='+this.id).then(res=>{
                    console.log(res)
                    this.pageData=res.data.data
                    this.selectedIndex = 0
                    this.qrUrl=res.data.data.qr_url
                })
            },
            viewQr(){
                this.showQr=true
            },
            rebuildQr(){
                this.showQrLoading=true
                getRequest('wepage/rebuildWePageQR?id='+this.pageData.id).then(res=>{
                    this.showQrLoading=false
                    console.log(res)
                    this.qrUrl=res.data.data
                })
            }
        },
        mounted() {
            this.id=this.$route.params.id
            this.get()
        }
    }
</script>

<style scoped>
    .coms-box{
        width: 300px;
        background-color: white;
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        overflow: scroll;
        align-content: flex-start;
        height:800px
    }
    .preview-box{
        height:800px;
        flex: 1;
        margin: auto;
        margin-top: 0px;
        max-width: 375px;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        overflow: scroll;
        align-content: flex-start;
        background-color: white;
    }
    .myclose-class{
        z-index: 100;
        position: absolute;
        top:0;
        right: 0;
        width: 20px;
        height: 20px;
    }
    .myclose-class:hover{
        background-color: #00d8d8;
        color: white;
    }
    .attribute-editing-box{
        height:800px;
        width: 400px;
        background-color: white;
        margin-right: 10px;
        overflow: scroll;
        position: relative;
    }
    ::-webkit-scrollbar {
        width: 0px;
    }
    .com-item-class{
        margin-top: 0px;
        position: relative;
        width: 100%;
        min-height: 30px;
        border: rgba(0, 179, 164, 0.23) solid 1px;
    }
</style>