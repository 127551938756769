<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>售后管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: #eeeeee;padding: 10px">
            <div style="background-color: white;padding: 10px">
                <el-form :inline="true" :model="conditionForm" style="display: flex;flex-direction: row;">
                    <div style="flex: 1;display: flex;flex-direction: row;flex-wrap: wrap;justify-content: flex-start;">
                        <el-form-item label="订单号">
                            <el-input size="small" v-model="conditionForm.order_id" placeholder="订单号" clearable />
                        </el-form-item>
                        <el-form-item label="退单号">
                            <el-input size="small" v-model="conditionForm.return_id" placeholder="退单号" clearable />
                        </el-form-item>
                        <el-form-item label="退单日期">
                            <el-date-picker
                                    v-model="conditionForm.returnDateValue"
                                    type="daterange"
                                    value-format="YYYY-MM-DD HH:mm:ss"
                                    unlink-panels
                                    range-separator="-"
                                    start-placeholder="起始日期"
                                    end-placeholder="截至日期"
                                    size="small"
                            />
                        </el-form-item>
                        <el-form-item label="商品代码">
                            <el-input size="small" v-model="conditionForm.goods_code" placeholder="商品代码" clearable />
                        </el-form-item>
                        <el-form-item label="订单日期">
                            <el-date-picker
                                    v-model="conditionForm.orderDateValue"
                                    type="daterange"
                                    value-format="YYYY-MM-DD HH:mm:ss"
                                    unlink-panels
                                    range-separator="-"
                                    start-placeholder="起始日期"
                                    end-placeholder="截至日期"
                                    size="small"
                            />
                        </el-form-item>
                        <el-form-item label="VIP卡号">
                            <el-input size="small" v-model="conditionForm.vip_code" placeholder="VIP卡号" clearable />
                        </el-form-item>
                        <el-form-item label="手机号">
                            <el-input size="small" v-model="conditionForm.vip_mobile" placeholder="手机号" clearable />
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item>
                            <el-button type="primary" @click="get">查询</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </div>
        <div style="font-size: 13px;position: relative">
            <div class="mtr" style="position: absolute;top: 0;width: 100%;z-index: 900">
                <div class="mtd" style="flex: 2">
                    <div class="mtd">商品信息</div>
                    <div class="mtd">退货单价X数量</div>
                </div>
                <div class="mtd cell-max-width">退货金额</div>
                <div class="mtd cell-max-width">退回运费</div>
                <div class="mtd">顾客信息</div>
                <div class="mtd">导购信息</div>
                <div class="mtd">退款日期</div>
                <div class="mtd">状态</div>
                <div class="mtd">退款方式</div>
                <div class="mtd">操作</div>
                <div style="width: 16px"></div>
            </div>
            <div style="height: 700px;overflow: scroll;" v-loading="showloading">
                <div class="mtr" style="margin-top: 2px">
                    <div class="mtd" style="flex: 2">
                        <div class="mtd">商品信息</div>
                        <div class="mtd">退货单价X数量</div>
                    </div>
                    <div class="mtd cell-max-width">退货金额</div>
                    <div class="mtd cell-max-width">退回运费</div>
                    <div class="mtd">顾客信息</div>
                    <div class="mtd">导购信息</div>
                    <div class="mtd">退款日期</div>
                    <div class="mtd">状态</div>
                    <div class="mtd">操作</div>
                </div>

                <div v-for="(it,index) in tableData" style="display: flex;flex-direction: column;">
                    <div style="margin-top: 20px;border-bottom: #d3d3d3 solid 1px;background-color: rgba(0,167,167,0.05);height: 30px;display: flex;align-items: center">
                        <span>退单号</span>
                        <span style="color: blue;">{{it.return_id}}</span>
                        <span style="margin-left: 50px">订单号</span>
                        <span style="color: rgba(0,0,217,0.42);">{{it.order_id}}</span>
                        <span style="margin-left: 100px">退单日期</span>
                        <span>{{it.create_time}}</span>
                        <span style="margin-left: 100px"></span>
                        <span style="color: red"></span>
                    </div>
                    <div class="mtr">
                        <div style="display: flex;flex-direction: column;flex: 2">
                            <div v-for="(it2,index2) in it.details" style="display: flex;flex-direction: row;height: 100%">
                                <div class="mtd" style="display: flex;flex-direction: row;">
                                    <el-image :src="it2.pic_url" style="width: 50px;height: 50px;border-radius: 3px;margin: 5px"></el-image>
                                    <div style="display: flex;flex-direction: column;margin-left: 10px;word-break: break-all">
                                        <div>{{it2.goods_code}}</div>
                                        <div>{{it2.sku}}</div>
                                        <div>{{it2.attribute1_name}} / {{it2.attribute2_name}}</div>
                                    </div>
                                </div>
                                <div class="mtd" >{{it2.pay_amount}} * {{it2.num}}</div>
                            </div>
                        </div>
                        <div class="mtd cell-max-width" style="color: red">￥{{it.refund_amount}}</div>
                        <div class="mtd cell-max-width" style="color: red">￥{{it.freight}}</div>
                        <div class="mtd" style="display: flex;flex-direction: column">
                            <div>{{it.vip_name}}</div>
                            <div>{{it.vip_mobile}}</div>
                            <div>{{it.vip_code}}</div>
                        </div>
                        <div class="mtd"></div>
                        <div class="mtd">{{it.payouts_time}}</div>
                        <div class="mtd" style="display: flex;flex-direction: column;">
                            <div v-if="it.status==701">待受理</div>
                            <div v-if="it.status==702">拒绝退款</div>
                            <div v-if="it.status==703">待买家发货</div>
                            <div v-if="it.status==704">待卖家收货</div>
                            <div v-if="it.status==705">待打款</div>
                            <div v-if="it.status==706">待到账</div>
                            <div v-if="it.status==707">退款完成</div>
                            <div v-if="it.status==708">顾客取消退款</div>
                        </div>
                        <div class="mtd">{{it.return_goods==1?'退货退款':'仅退款'}}</div>
                        <div class="mtd" style="display: flex;flex-direction: column;color: blue">
                            <div @click="toagree(it)" v-if="it.status==701" style="cursor: pointer;color: green;margin-top: 10px;">同意</div>
                            <div @click="torefuse(it)" v-if="it.status==701 || it.status==703 || it.status==704 || it.status==705" style="cursor: pointer;color: red;margin-top: 10px;">拒绝</div>
                            <div @click="received(it)" v-if="it.status==704" style="margin-top: 10px;cursor: pointer">确认收货</div>
                            <div @click="toPayouts(it)" v-if="it.status==705" style="margin-top: 10px;cursor: pointer">打款</div>
                            <div @click="toDetail(it)" style="margin-top: 10px;cursor: pointer">详情</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination
                v-model:current-page="pageNum"
                v-model:page-size="pageSize"
                :page-sizes="[10,20, 40, 60, 100]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
                :pager-count="5"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
        <el-dialog v-model="showAgreeDialog" :title="agreeform.agreeValue==1?'同意退货':'拒绝退货'" width="300px">
            <el-form :model="agreeform">
                <el-form-item label="备注">
                    <el-input v-model="agreeform.notes" />
                </el-form-item>
            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="showAgreeDialog = false">取消</el-button>
                <el-button type="primary" @click="submitAgree">
                  确认
                </el-button>
              </span>
            </template>
        </el-dialog>
        <payouts-com v-if="showPayouts" v-model="showPayouts" :returnid="returnId"></payouts-com>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import {getUserId} from "../../utils/auth";
    import { ElMessageBox } from 'element-plus'
    import PayoutsCom from "./coms/payouts-com";

    export default {
        name: "ReturnManage",
        components: {PayoutsCom},
        data(){
            return{
                conditionForm:{},
                tableData:[],
                pageNum:1,
                pageSize: 10,
                totalNum:1,
                showAgreeDialog:false,
                agreeform:{
                    agreeValue:0,
                    agreeId:'',
                    notes:''
                },
                showPayouts:false,
                returnId:'',
                showloading:false
            }
        },
        methods:{
            onSubmitQuery(){},
            handleSizeChange(){
                this.pageNum = 1
                this.get()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.get()
            },
            get(){
                this.showloading=true
                this.conditionForm['pageNum']=this.pageNum
                this.conditionForm['pageSize']=this.pageSize
                postRequest('mall/getReturnOderList',this.conditionForm).then(res=>{
                    console.log(res)
                    this.showloading=false
                    this.tableData = res.data.data.list
                    this.totalNum = res.data.data.total
                })
            },
            toagree(it){
                this.agreeform.agreeValue=1
                this.agreeform.agreeId=it.return_id
                this.showAgreeDialog=true
            },
            torefuse(it){
                this.agreeform.agreeValue=2
                this.agreeform.agreeId=it.return_id
                this.showAgreeDialog=true
            },
            submitAgree(){
                console.log(this.agreeform)
                getRequest('mall/updateAgreeReturn?return_id=' + this.agreeform.agreeId +'&user_id=' + getUserId() + '&agree_flag=' + this.agreeform.agreeValue+'&notes=' + this.agreeform.notes).then(res=>{
                    this.get()
                    this.$alert(res.data.message)
                    this.showAgreeDialog=false
                })
            },
            received(it){
                ElMessageBox.confirm('确认已经收到货品?')
                    .then(() => {
                        getRequest('mall/receivedReturn?return_id=' + it.return_id + '&user_id=' + getUserId()).then(res=>{
                            this.get()
                            this.$alert(res.data.message)
                        })
                    })
                    .catch(() => {
                        // catch error
                    })

            },
            toPayouts(it){
                console.log(it)
                this.returnId = it.return_id
                this.showPayouts = true
            },
            toDetail(it){
                // const {href} = this.$router.resolve({
                //     name: "ReturnOrderDetail",
                //     params:{
                //         return_id:it.return_id
                //     }
                // });
                // window.open(href, '_blank')
                this.$router.push({
                    name: "ReturnOrderDetail",
                        params:{
                            return_id:it.return_id
                    }
                })
            }
        },
        mounted() {
            this.get()
        }
    }
</script>

<style scoped>
    .mtr{
        display: flex;
        word-break: break-all;
    }
    .mtd{
        flex: 1;
        border: #d3d3d3 solid 1px;
        border-top: none;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
        word-break: break-all;
    }
    .mtd:first-child{
        border-left: #d3d3d3 solid 1px;
    }
    .mtr:first-child .mtd{
        border: none;
        background-color: powderblue;
        height: 40px;
    }
    .cell-max-width{
        max-width: 70px;
    }
    .el-form-item{
        margin-bottom: 5px;
    }
</style>