<template>
    <div class="comd-card-box" v-for="(item,index) in commditys" :key="index">
        <div v-if="commdity.showLable === 1" class="lable-class" :style="{color: commdity.lableColor,backgroundColor: commdity.lableBackgroundColor}">{{commdity.lableTxt}}</div>
        <img style="object-fit: fill" :src="item.pic_url"/>
        <div class="comdinfo">
            <div style="text-indent: 5px;text-align: left;font-size: 13px;height: 15px;overflow: hidden">{{item.goods_name}}</div>
            <div style="text-indent: 5px;text-align: left;font-size: 12px;margin-top: 10px">{{item.goods_code}}</div>
            <div class="price" :style="{color: commdity.priceColor}">
                <div style="display: flex;flex-direction: column">
                    <div v-if="commdity.showPrice" style="text-align: left;text-decoration:line-through;color: #bcbcbc" :style="{fontSize: commdity.priceFontSize + 'px'}">
                        ￥
                        <i :style="{fontSize: this.commdity.priceFontSize * + 'px'}">{{item.price}}</i>
                    </div>
                    <div :style="{fontSize: commdity.priceFontSize + 'px'}">
                        ￥
                        <i :style="{fontSize: this.commdity.priceFontSize * 1.5 + 'px'}">{{item.real_price}}</i>
                    </div>
                </div>
                <li class="iconfont mmx-iconicon_caigou" :style="{fontSize: commdity.priceFontSize*2.5 + 'px'}" style="margin-left: auto;"></li>
            </div>
        </div>
    </div>
</template>

<script>
    import {postRequest} from "../../../utils/api";

    export default {
        name: "commodity-card-item2",
        props:{
            commdity:{
                showType: Number,
                priceColor: String,
                priceFontSize: Number,
                showLable: Number,
                showPrice: Boolean,
                lableColor: String,
                lableBackgroundColor: String,
                lableTxt: String,
                dataNum:Number,
                data:Array
            }
        },
        watch:{
            'commdity.dataNum':{
                handler() {
                    // console.log(this.commdity.data)
                    this.getComdityData()
                }
            }
        },
        data(){
            return{
                commditys: []
            }
        },
        methods:{
            getComdityData(){
                postRequest('goods/getCommdityInfoListByCodes',{'codes':this.commdity.data}).then(res=>{
                    this.commditys=res.data.data
                })
            },
            // getPriceStyle(){
            //     console.log('fontSize: ' + this.commdity.conf.priceFontSize * 1.5 + 'px')
            //     return 'fontSize: ' + this.commdity.conf.priceFontSize * 1.5 + 'px'
            // }
        },
        mounted() {
            this.getComdityData()
        }
    }
</script>

<style scoped>
    .price{
        text-align: left;
        display: flex;
        list-style: none;
        align-items: center;
        color: red;
        margin-top: auto;
        margin-bottom: 10px;
    }
    .comd-card-box{
        width: 164px;
        height: 270px;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        /*margin: 10px;*/
        border-radius: 6px;
        background-color: white;
    }
    img{
        width: 100%;
        height: 165px;
        border-radius: 6px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        object-fit: fill;
    }
    .lable-class{
        position: absolute;
        left: 1px;
        top: 1px;
        font-size: 13px;
    }
</style>