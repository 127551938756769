<template>
<!--    :style="{height:comData.height,backgroundColor:comData.backgroundColor}"-->
    <div class="defualt-class" :style="{height:comData.height+'px',backgroundColor:comData.backgroundColor}">

    </div>
</template>

<script>
    export default {
        name: "placeholder-item",
        props:{
            comData:{
                height:Number,
                backgroundColor: String
            }
        },
        mounted() {
            console.log(this.comData)
        }
    }
</script>

<style scoped>
.defualt-class{
    width: 100%;
}
</style>