<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: #eeeeee;padding: 10px">
            <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
                <el-form :inline="true" :model="conditionForm" class="demo-form-inline">
                    <el-form-item label="订单号">
                        <el-input v-model="conditionForm.order_id" placeholder="订单号" clearable />
                    </el-form-item>
                    <el-form-item label="VIP卡号">
                        <el-input v-model="conditionForm.vip_code" placeholder="VIP卡号" clearable />
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="conditionForm.vip_mobile" placeholder="VIP手机号" clearable />
                    </el-form-item>
                    <el-form-item label="商品代码">
                        <el-input v-model="conditionForm.goods_code" placeholder="商品代码" clearable />
                    </el-form-item>
                    <el-form-item label="订单日期">
                        <el-date-picker
                                v-model="conditionForm.dateValue"
                                type="daterange"
                                value-format="YYYY-MM-DD"
                                unlink-panels
                                range-separator="-"
                                start-placeholder="起始日期"
                                end-placeholder="截至日期"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmitQuery">查询</el-button>
                        <el-button @click="exportAllPage">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div style="background-color: #eeeeee;padding: 10px">
            <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center;min-width: 400px">
                <el-tabs  v-model="activeName"  @tab-change="tabchange">
                    <el-tab-pane :label="'全部订单('+countInfo.all_num+')'" name="all">
                        <order-all-com  :cdf="conditionForm" ref="all" @toAssign="toAssing" @toReturn="toRetrun" @toDetail="toDetail"></order-all-com>
                    </el-tab-pane>
                    <el-tab-pane :label="'待接单('+countInfo.pending_taking_num+')'" name="pending_taking">
                        <pending-taking-com  :cdf="conditionForm" ref="pending_taking" @toAssign="toAssing" @toReturn="toRetrun" @toDetail="toDetail"></pending-taking-com>
                    </el-tab-pane>
                    <el-tab-pane :label="'待派单('+countInfo.pending_assign_num+')'" name="pending_assign">
                        <pending-assigned-com  :cdf="conditionForm" ref="pending_assign" @toAssign="toAssing" @toReturn="toRetrun" @toDetail="toDetail"></pending-assigned-com>
                    </el-tab-pane>
                    <el-tab-pane :label="'部分派单('+countInfo.partial_assign_num+')'" name="partial_assign">
                        <partial-assign-com :cdf="conditionForm" ref="partial_assign" @toAssign="toAssing" @toReturn="toRetrun" @toDetail="toDetail"></partial-assign-com>
                    </el-tab-pane>
                    <el-tab-pane :label="'待发货('+countInfo.pending_shipped_num+')'"  name="pending_shipped">
                        <pending-shipped-com  :cdf="conditionForm" ref="pending_shipped" @toAssign="toAssing" @toReturn="toRetrun" @toDetail="toDetail"></pending-shipped-com>
                    </el-tab-pane>
                    <el-tab-pane :label="'部分发货('+countInfo.partial_shipped_num+')'"  name="partial_shipped">
                        <partial-shipped-com :cdf="conditionForm" ref="partial_shipped" @toAssign="toAssing" @toReturn="toRetrun" @toDetail="toDetail"></partial-shipped-com>
                    </el-tab-pane>
                    <el-tab-pane :label="'已关闭('+countInfo.closed_num+')'"  name="closed">
                        <closed-order-com  :cdf="conditionForm" ref="closed" @toAssign="toAssing" @toReturn="toRetrun" @toDetail="toDetail"></closed-order-com>
                    </el-tab-pane>
                    <el-tab-pane :label="'交易完成('+countInfo.completed_num+')'"  name="completed">
                        <completed-order-com :cdf="conditionForm" ref="completed" @toAssign="toAssing" @toReturn="toRetrun" @toDetail="toDetail"></completed-order-com>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div>
            <assign-com v-if="showAssign" v-model="showAssign" :orderid="AssignId" @getlist="getlist"></assign-com>
            <create-return-com v-if="showReturn" v-model="showReturn" :orderid="returnId"></create-return-com>
        </div>
    </div>
</template>

<script>
    import OrderAllCom from "./coms/order-all-com";
    import AssignCom from "./coms/assign-com";
    import CreateReturnCom from "./coms/create-return-com";
    import PendingTakingCom from "./coms/pending-taking-com";
    import PendingAssignedCom from "./coms/pending-assigned-com";
    import PendingShippedCom from "./coms/pending-shipped-com";
    import {postRequest} from "../../utils/api";
    import PartialAssignCom from "./coms/partial-assign-com";
    import PartialShippedCom from "./coms/partial-shipped-com";
    import ClosedOrderCom from "./coms/closed-order-com";
    import CompletedOrderCom from "./coms/completed-order-com";
    import {getUserId} from "../../utils/auth";
    export default {
        name: "OrderManage",
        components: {
            CompletedOrderCom,
            ClosedOrderCom,
            PartialShippedCom,
            PartialAssignCom,
            PendingShippedCom, PendingAssignedCom, PendingTakingCom, CreateReturnCom, AssignCom, OrderAllCom},
        data(){
            return{
                conditionForm:{
                    order_id:null,
                    vip_code:null,
                    vip_mobile:null,
                    dateValue:null,

                },
                activeName:'pending_taking',
                AssignId:'',
                showAssign:false,
                showReturn:false,
                returnId:'',
                tableSet:new Set(),
                countInfo:{
                    all_num:0,
                    pending_taking_num:0,
                    pending_assign_num:0,
                    partial_assign_num:0,
                    pending_shipped_num:0,
                    partial_shipped_num:0,
                    closed_num:0,
                    completed_num:0,
                },
            }
        },
        methods:{
            onSubmitQuery(){
                if (this.conditionForm.dateValue!=null){
                    this.conditionForm.start_time=this.conditionForm.dateValue[0]
                    this.conditionForm.end_time=this.conditionForm.dateValue[1]
                }
                this.getCount()
                this.$refs[this.activeName].get()
            },
            getCount(){
                postRequest('mall/getOrderListCount',this.conditionForm).then(res=>{
                   console.log(res)
                    this.countInfo=res.data.data
                    console.log(this.countInfo)
                })
            },
            tabchange(e){
                if (!this.tableSet.has(e)){
                    this.tableSet.add(e)
                }
                this.onSubmitQuery()
            },
            toAssing(e){
                this.AssignId=e
                this.showAssign=true
            },
            getlist(e){
                this.onSubmitQuery()
            },
            toRetrun(e){
                this.returnId=e
                this.showReturn = true
            },
            getDefualtDate(){
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [this.$dayjs(start).format('YYYY-MM-DD'),this.$dayjs(end).format('YYYY-MM-DD')]
            },
            toDetail(it){
                // const {href} = this.$router.resolve({
                //     name: "ReturnOrderDetail",
                //     params:{
                //         return_id:it.return_id
                //     }
                // });
                // window.open(href, '_blank')
                this.$router.push({
                    name: "OrderDetail",
                    params:{
                        order_id:it.order_id
                    }
                })
            },

            exportAllPage(){
                var pm=JSON.parse(JSON.stringify(this.conditionForm))
                pm['pageNum']=1
                pm['pageSize']=0
                if (pm.dateValue!=null){
                    pm['start_time']=pm.dateValue[0]
                    pm['end_time']=pm.dateValue[1]
                }else {
                    pm['start_time']=null
                    pm['end_time']=null
                }
                pm['user_id']=getUserId()
                console.log(pm)
                postRequest('file/exportOrderList',pm).then(res=>{
                    console.log(res)
                    if(res.data.flag){
                        this.$msgbox.alert(res.data.message)
                        this.$router.push('/ExportTask')
                    }else {
                        this.$msgbox.alert(res.data.message)
                    }

                })
            }
        },
        mounted() {
            // this.$refs.orall.get()
            this.conditionForm.dateValue = this.getDefualtDate()
            console.log(this.conditionForm.dateValue )
            this.tableSet.add('pending_taking')
            this.onSubmitQuery()
        }
    }
</script>

<style scoped>

</style>