<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商城管理</el-breadcrumb-item>
            <el-breadcrumb-item>快递公司</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: #eeeeee;padding: 10px">
            <div style="background-color: white;text-align: left;margin-top: 10px;padding-bottom: 10px;padding: 10px">
                <el-button type="success" style="padding: 10px" @click="toAdd">新增快递公司</el-button>
            </div>
        </div>
        <div>
            <table>
                <thead>
                <th>代码</th>
                <th>名称</th>
                <th>备注</th>
                <th>操作</th>
                </thead>
                <tbody>
                <tr v-for="(it,index) in exps">
                    <td>{{it.company_code}}</td>
                    <td>{{it.company_name}}</td>
                    <td>{{it.notes}}</td>
                    <td style="color: blue;">
                        <span @click="viewExp(index)" style="cursor: pointer">查看</span>
                        <span @click="toEditExp(index)" style="cursor: pointer;margin-left: 20px;margin-right: 20px">编辑</span>
                        <span @click="delExp(it.id)" style="cursor: pointer">删除</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <el-dialog
                v-model="showDialog"
                width="500px"
        >
            <el-form :model="editExp">
                <el-form-item label="代码" label-width="50">
                    <el-input v-model="editExp.company_code" autocomplete="off" :readonly="readonly"/>
                </el-form-item>
                <el-form-item label="名称" label-width="50">
                    <el-input v-model="editExp.company_name" autocomplete="off" :readonly="readonly"/>
                </el-form-item>
                <el-form-item label="备注" label-width="50" style="display: flex;flex-direction: row">
                    <el-input v-model="editExp.notes" autocomplete="off" :readonly="readonly"/>
                </el-form-item>
            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="showDialog = false">取消</el-button>
                <el-button type="primary" @click="saveExp">
                  确认
                </el-button>
              </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";

    export default {
        name: "ExpressCompany",
        data(){
            return{
                exps:[],
                showDialog:false,
                editExp:{},
                readonly:false,
                defExp:{
                    company_code:'',
                    company_name:'',
                    notes:''
                },
                editType:''
            }
        },
        methods:{
            toAdd(){
                this.editExp=JSON.parse(JSON.stringify(this.defExp))
                this.showDialog=true
                this.readonly=false
                this.editType='add'
            },
            viewExp(index){
                this.editExp=this.exps[index]
                this.showDialog=true
                this.readonly=true
                this.editType='view'
            },
            get(){
                getRequest('mall/getExpressCompanyList').then(res=>{
                    this.exps=res.data.data
                })
            },
            toEditExp(index){
                this.editExp=this.exps[index]
                this.showDialog=true
                this.readonly=false
                this.editType='edit'
            },
            delExp(id){
              getRequest('mall/deleteExpressCompany?id=' + id).then(res=>{
                  this.$alert(res.data.message)
                  this.get()
              })
            },
            saveExp(){
                if (this.editType=='add'){
                    postRequest('mall/addExpressCompany',this.editExp).then(res=>{
                        this.$alert(res.data.message)
                        this.showDialog=false
                        this.get()
                    })
                }
                if (this.editType=='edit'){
                    postRequest('mall/updateExpressCompany',this.editExp).then(res=>{
                        this.$alert(res.data.message)
                        this.showDialog=false
                        this.get()
                    })
                }
                if (this.editType=='add'){
                    this.showDialog=false
                }
            }
        },
        mounted() {
            this.get()
        }

    }
</script>

<style scoped>
    table, td, tr, th {
        font-size: small;
        line-height: 30px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        padding: 10px;
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
</style>