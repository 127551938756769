<template>
    <div class="container">
        <div class="swipe" @mousedown="mousedown" @mousemove="mousemove" @mouseup="mouseup" @mouseout="mouseout">
            <div style="height: 100%;" v-for="(it,id) in comData.data">
                <img @dragstart.prevent style="height: 100%;width: 100%;object-fit: cover;" :src="it.pic_url" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "full-screen-img-item",
        props:{
            comData: {
                data:Array
            },
        },
        data(){
            return{
                current:0,
                translateV:0,
                startPy:0,
                mouseDowning:false
            }
        },
        methods:{
            mousedown(e){
                // console.log(e)
                this.startPy=e.pageY
                this.mouseDowning=true
            },
            mouseup(e){
                this.mouseDowning=false
                if (e.pageY-this.startPy>0){
                    this.current=Math.max(0,this.current-1)
                }else if(e.pageY-this.startPy<0) {
                    this.current=Math.min(this.comData.data.length-1,this.current+1)
                }
                var translateV=this.current*(-700)
                this.translateV=translateV
                var sw= document.getElementsByClassName("swipe")
                sw[0].style.transform='translateY('+translateV+'px)';
            },
            mouseout(e) {
                if (this.mouseDowning) {
                    this.mouseDowning = false
                    var translateV = this.current  *(-700)
                    this.translateV = translateV
                    var sw = document.getElementsByClassName("swipe")
                    sw[0].style.transform = 'translateY(' + translateV + 'px)';
                }
            },
            mousemove(e){
                if (this.mouseDowning){
                    console.log(e)
                    var translateV=this.translateV-(this.startPy-e.pageY)
                    var sw= document.getElementsByClassName("swipe")
                    sw[0].style.transform='translateY('+translateV+'px)';
                }
            }
        },
        mounted() {
            console.log(this.comData)
        }
    }
</script>

<style scoped>
    .container{
        width: 100%;
        height: 700px;
        overflow: hidden;
        position: absolute;
        top: 0px;
        /*background-color: red;*/
    }
    .swipe{
        height: 700px;
        transition-duration: 0.5s;
        transform: translateY(0px);
    }
</style>