<template>
    <div style="margin-top: 50px;text-align: center">授权</div>
</template>

<script>
    import {getRequest} from "../../utils/api";

    export default {
        name: "QwOauth2",
        mounted() {
            // getRequest("https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww1ec21c1ad056ec3c&redirect_uri="+encodeURIComponent('http://mqw.mmxfs.com:441/adminApi/wxapi/wxofa/oauth2')+"&response_type=code&scope=snsapi_base&agentid=1000021&state=#wechat_redirect").then(res=>{
            //     this.$alert(res.data.message)
            // })

            window.location.href ="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwdfac30d10bcf1476&redirect_uri="+encodeURIComponent('http://www.mmxfs.shop/adminApi/wxapi/wework/oauth2')+"&response_type=code&scope=snsapi_privateinfo&agentid=1000003&state=#wechat_redirect"
        }
    }
</script>

<style scoped>

</style>