<template>
    <div style="padding: 10px;text-align: left">
        <h4>搜索模板配置</h4>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px;margin-top: 5px">
            <div>框体颜色</div>
            <input v-model="value.boxColor" style="width: 150px;height: 18px;margin-left: 10px">
            <el-color-picker color-format="hex"  v-model="value.boxColor" style="margin-left: 20px"></el-color-picker>
        </div>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px;margin-top: 5px">
            <div>背景颜色</div>
            <input v-model="value.backgroundColor" style="width: 150px;height: 18px;margin-left: 10px">
            <el-color-picker color-format="hex"  v-model="value.backgroundColor" style="margin-left: 20px"></el-color-picker>
        </div>
        <div class="slider-class">
            <div>圆角</div>
            <el-slider style="width: 80%;margin-left: 20px" v-model="value.inputRadius" show-input :max=100></el-slider>
        </div>

    </div>
</template>

<script>
    export default {
        name: "search-com-config",
        props:{
            comData:{
                boxColor: String,
                backgroundColor: String,
                defaultText: String,
                inputRadius: Number
            }
        },
        data(){
            return {
                comData_:null,
                value:{
                    boxColor: '',
                    backgroundColor: '',
                    defaultText: '',
                    inputRadius: 0
                },


            }
        },
        watch:{
        },
        methods:{

        },
        mounted() {
            this.value = this.comData
        }
    }
</script>

<style scoped>

</style>