<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>企业微信及公众号</el-breadcrumb-item>
            <el-breadcrumb-item>欢迎语列表</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <div style="background-color: whitesmoke;padding: 10px">
        <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
            <el-form :inline="true" :model="pm" class="demo-form-inline">
                <el-form-item label="名称">
                    <el-input v-model="pm.activity_name" placeholder="名称" clearable />
                </el-form-item>
                <el-form-item label="创建日期">
                    <el-date-picker
                            v-model="pm.dateValue"
                            type="daterange"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="起始日期"
                            end-placeholder="截至日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="background-color: #eeeeee;padding: 10px;height: 30px;background-color: white;margin-bottom: 10px;text-align: left">
            <el-button @click="toAdd" type="success">新建欢迎语</el-button>
        </div>
        <div style="background-color: white;margin-top: 10px">
            <div>
                <table>
                    <thead>
                        <th>ID</th>
                        <th>标题</th>
                        <th>创建日期</th>
                        <th>类型</th>
                        <th>欢迎语</th>
                        <th>启用</th>
                        <th>操作</th>
                    </thead>
                    <tbody>
                        <tr v-for="it in list">
                            <td>{{it.id}}</td>
                            <td>{{it.title}}</td>
                            <td>{{it.create_time}}</td>
                            <td>{{it.type_name}}</td>
                            <td>{{it.welcome_text}}</td>
                            <td>{{it.enabled==true?'已启用':'未启用'}}</td>
                            <td>
                                <span @click="toEidt(it.id)">查看</span>
                                <span v-if="it.enabled==false" @click="toEnabled(it.id)">启用</span>
                                <span v-if="it.enabled==true" @click="toDisabled(it.id)">停用</span>
                                <span @click="toDel(it.id)">删除</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";
    import { ElMessage, ElMessageBox } from 'element-plus'
    export default {
        name: "WelcomeList",
        data(){
            return{
                pm:{},
                list:[]
            }
        },
        methods:{
            getList(){
                getRequest('wecom/getWelcomeMsgList').then(res=>{
                    console.log(res)
                    if(res.data.flag){
                        this.list=res.data.data
                    }else{
                        this.$alert(res.data.message)
                    }

                })
            },
            toAdd(){
                this.$router.push('WelcomeEdit/-1')
            },
            toEidt(id){
                this.$router.push('WelcomeEdit/'+id)
            },
            toEnabled(id){
                getRequest('wecom/updateWelcomeEnabled?enabled=true&id='+id).then(res=>{
                    this.$alert(res.data.message)
                    this.getList()
                })
            },
            toDisabled(id){
                getRequest('wecom/updateWelcomeEnabled?enabled=false&id='+id).then(res=>{
                    this.$alert(res.data.message)
                    this.getList()
                })
            },
            toDel(id){
                ElMessageBox.confirm(
                    '确认删除?',
                    'Warning',
                    {
                        confirmButtonText: '删除',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                )
                    .then(() => {
                        getRequest('wecom/delWelcome?id='+id).then(res=>{
                            this.$alert(res.data.message)
                            this.getList()
                        })
                    })

            }
        },
        mounted() {
          this.getList()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 30px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>