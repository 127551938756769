<template>
    <div @click="gotoPage" class="card-class">
        <div :class="icon" style="font-size: 50px;margin-left: 10px;color: #009e9e"></div>
        <div style="text-align: left;margin-left: 10px">
            <div style="font-weight: bold;font-size: 14px">{{title}}</div>
            <div style="margin-top: 6px;color: #9e9e9e;font-size: 13px">{{note}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "market-card-item",
        props:['route','icon','title','note'],
        methods:{
            gotoPage(){
                this.$router.push("/"+this.route)
            }
        }
    }
</script>

<style scoped>
    .card-class{
        border: #c9c9c9 solid 1px;
        margin-left: 50px;
        display: flex;
        flex-direction: row;
        width: 300px;
        height: 80px;
        align-items: center;
        border-radius: 6px;
        background-color: rgba(0, 255, 255, 0.01);
        margin-top: 50px;
    }
    .card-class:hover{
        cursor: pointer;
        border: rgba(0, 115, 115, 0.88) solid 1px;
    }
</style>