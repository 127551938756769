<template>
    <div>
        <div class="row-class">
            <span>活动名称</span>
            <el-input v-model="act.activity_name"></el-input>
        </div>
        <div class="row-class">
            <span>优惠券备注</span>
            <el-input v-model="act.coupon_note"></el-input>
        </div>
        <div class="row-class">
            <span>会员</span>
            <div>
                <el-radio-group v-model="act.vip_type" style="text-align: left;flex-direction: column">
                    <el-radio  :label="1" style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px;">
                        <div style="display: flex;flex-direction: row;align-items: center;">
                            <div>具体会员</div>
                            <div style="text-align: left;display: flex;flex-direction: column;position: relative;margin-left: 20px">
                                <el-input readonly :model-value="getvip_listShow()" style="width: 500px;">
                                    <template  #append>
                                        <span @click="clearVip" style="color: blue;cursor: alias;width: 50px;">清空</span>
                                        <span style="margin-right: 20px;width: 5px">|</span>
                                        <el-button @click="toSelectVip" icon="ArrowDown">选择</el-button>
                                    </template>
                                </el-input>
                                <div v-if="showVipSearch" style="border: #c9c9c9 solid 1px;position: absolute;top: 40px;background-color: white;z-index: 999;width: 500px;">
<!--                                    <div style="position: absolute;width: 10px;height: 10px;border: #bebebe solid 1px;transform: rotate(45deg);left: 100px;top: -5px;overflow: hidden;z-index: 0"></div>-->
                                    <div @click="showVipSearch=false" style="text-align: right;font-size: 20px;cursor: default"><el-icon><Close /></el-icon></div>
                                    <div style="margin-top: 5px;margin-left: 10px">
                                        会员搜索
                                        <el-input clearable @input="vpvChange" v-model="vipQueryValue" size="small" style="width: 200px" placeholder="输入手机号或者会员代码"></el-input>
                                    </div>
                                    <div style="height: 300px;overflow: scroll">
                                        <div v-for="it in vipSearchList" style="display: flex;flex-direction: row;border-bottom: whitesmoke solid 1px;padding: 5px">
                                            <div>{{it.vip_code}}</div>
                                            <div style="margin-left: 10px;margin-right: 10px">{{it.name}}</div>
                                            <div>{{it.mobile}}</div>
                                            <div @click="vipSelected(it)" style="margin-left: 20px;color: blue;cursor: pointer">选择</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="act.vip_type==1" style="margin-top: 8px">
                                    <span>导入：</span>
                                    <input v-show="true" ref="fileRef" type="file" @change="toImport">
                                    <el-link href="/static/会员导入模板.xlsx">
                                        <el-button size="small" type="success">导入模板下载</el-button>
                                    </el-link>
                                </div>
                            </div>
                        </div>
                    </el-radio>
                    <el-radio  :label="2" style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px;margin-top: 20px">
                        <div style="display: flex;flex-direction: row;align-items: center;position: relative">
                            <div>会员等级</div>
                            <div style="text-align: left;display: flex;flex-direction: column;position: relative;margin-left: 20px">
                                <el-input readonly :model-value="getvip_levelShow()" style="width: 500px;">
                                    <template  #append>
                                        <span @click="clearVip" style="color: blue;cursor: alias;width: 50px;">清空</span>
                                        <span style="margin-right: 50px;width: 5px">|</span>
                                        <el-button @click="toSelectLevel" icon="ArrowDown">选择</el-button>
                                    </template>
                                </el-input>
                                <div v-if="showVipLevelSelect" style="border: #c9c9c9 solid 1px;position: absolute;top: 40px;background-color: white;z-index: 999;width: 500px;">
                                    <div @click="showVipLevelSelect=false" style="text-align: right;font-size: 20px;cursor: default"><el-icon><Close /></el-icon></div>
                                    <div style="height: 300px;overflow: scroll">
                                        <div  @click="vipLevelSelected(index)" v-for="(it,index) in vipLevelList" style="display: flex;flex-direction: row;border-bottom: whitesmoke solid 1px;padding: 5px" :style="{color: it.check==true?'blue':'black'}">
                                            <div>{{it.level_code}}</div>
                                            <div style="margin-left: 20px;margin-right: 20px">{{it.level_name}}</div>
                                            <div>{{it.mobile}}</div>
                                            <div v-if="it.check" style="margin-left: 20px;cursor: pointer">✓</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="row-class">
            <span>优惠券</span>
            <div style="text-align: left;">
                <div v-for="(it,index) in act.coupons">
                    <div style="margin-bottom: 20px;border: #c8c8c8 solid 1px;padding: 10px;position: relative">
                        <div @click="delCoupon(index)" class="del-class"><el-icon><Close /></el-icon></div>
                        <div style="margin-bottom: 20px">
                            <span style="margin-right: 30px;" :style="{color: it.coupon_group==null?'red':''}">优惠券</span>
                            <coupon-group-select v-model="showCouponSelect" @confirm="couponSelectConfirm"></coupon-group-select>
                            <el-input :model-value="showCoupon(it)" readonly  placeholder="选择优惠券" clearable >
                                <template  #append>
                                    <el-button @click="toSelectCoupon(index)" icon="ArrowDown"></el-button>
                                </template>
                            </el-input>
                        </div>
                        <div style="background-color: whitesmoke;padding: 20px">
                            <div style="margin-bottom: 10px">优惠券有效期:</div>
                            <div style="display: flex;">
                                <div @click="changeValidityType(index,1)" style="display: flex;flex-direction: row;align-items: center;margin-right: 20px;cursor: pointer">
                                    <div style="width: 10px;height: 10px;border: #999999 solid 1px;border-radius: 30px;">
                                        <div style="width: 10px;height: 10px;border-radius: 20px;border: none" :style="{backgroundColor: it.validity_type==1?'#3ffaff':''}"></div>
                                    </div>
                                    <div style="margin-left: 5px">指定具体日期</div>
                                </div>
                                <el-date-picker
                                        v-model="it.validity_date"
                                        type="daterange"
                                        value-format="YYYY-MM-DD HH:mm:ss"
                                        unlink-panels
                                        range-separator="-"
                                        start-placeholder="起始日期"
                                        end-placeholder="截至日期"
                                />
                            </div>
                            <div style="display: flex;flex-direction: row;margin-top: 10px">
                                <div @click="changeValidityType(index,2)" style="display: flex;flex-direction: row;align-items: center;margin-right: 20px;cursor: pointer">
                                    <div style="width: 10px;height: 10px;border: #999999 solid 1px;border-radius: 30px;">
                                        <div style="width: 10px;height: 10px;border-radius: 20px;border: none" :style="{backgroundColor: it.validity_type==2?'#3ffaff':''}"></div>
                                    </div>
                                    <div style="margin-left: 5px">指定有效天数</div>
                                </div>
                                <div style="display: flex;flex-direction: row;align-items: center">
                                    <div>从第</div>
                                    <el-input v-model="it.start_day" style="width: 50px"></el-input>
                                    <span style="width: fit-content">后</span>
                                    <el-input v-model="it.end_day" style="width: 50px"></el-input>
                                    <span>天内有效</span>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 20px">
                            <span>发券数量 </span>
                            <el-input-number v-model="it.num" :min="1"></el-input-number>
                            <span> 张</span>
                        </div>
                    </div>
                </div>
                <el-button @click="addCoupon">添加优惠券</el-button>
            </div>
        </div>
        <div style="margin-top: 50px;text-align: left;margin-left: 200px">
            <el-button @click="submit" type="success">创建活动</el-button>
        </div>
    </div>
</template>

<script>
    import CouponGroupSelect from "../common/coupon-group-select";
    import {getRequest, postRequest} from "../../utils/api";
    import {getUserId} from "../../utils/auth";
    import {debounce} from "../../utils/DebounceFn";
    import {importVip} from "../../utils/VipImport";
    export default {
        name: "SendCoupon",
        components: {CouponGroupSelect},
        data(){
            return{
                act:{
                    activity_type:1,
                    vip_type:1,
                    activity_name:'',
                    coupon_note:'',
                    coupons:[],
                    vip_list:[],
                    vip_levels:[]
                },
                showCouponSelect:false,
                couponIndex:0,
                validity_time_radio:"",
                showVipSearch:false,
                showVipLevelSelect:false,
                vipSearchList:[],
                vipQueryValue:'',
                searchVipDf:null,
                vipLevelList:[]
            }
        },
        methods:{
            addCoupon(){
                this.act.coupons.push(
                    {
                        coupon_group:null,
                        coupon_group_name:null,
                        validity_type:1,
                        validity_date:null,
                        start_date:null,
                        end_date:null,
                        start_day:0,
                        end_day:1,
                        num:0
                    }
                )
            },
            changeValidityType(id,t){
                this.act.coupons[id].validity_type=t
            },
            couponSelectConfirm(e){
                this.act.coupons[this.couponIndex].coupon_group=e[0].id
                this.act.coupons[this.couponIndex].coupon_group_name=e[0].name
            },
            toSelectCoupon(i){
                this.couponIndex=i
                this.showCouponSelect = true
            },
            submit(){
                console.log(this.act.coupons)
                if (this.act.activity_name.length<1){
                    this.$alert("请输活动名称!")
                    return
                }
                if (this.act.vip_type==1 && this.act.vip_list.length<1){
                    this.$alert("请输入会员!")
                    return
                }
                if (this.act.vip_type==2 && this.act.vip_levels.length<1){
                    this.$alert("请输入会员!")
                    return
                }
                if (this.act.coupons.length<1){
                    this.$alert("请填写优惠券!")
                    return
                }
                var flag=true
                var errMsg=''
                for (var i=0;i<this.act.coupons.length;i++){
                    if (this.act.coupons[i].validity_type==1 && this.act.coupons[i].validity_date!=null){
                        this.act.coupons[i].start_date=this.act.coupons[i].validity_date[0]
                        this.act.coupons[i].end_date=this.act.coupons[i].validity_date[0]
                    }
                    if (this.act.coupons[i].coupon_group==null){
                        flag=false
                        errMsg='优惠券不能为空'
                    }
                    if (this.act.coupons[i].validity_type==1 && this.act.coupons[i].validity_date==null){
                        flag=false
                        errMsg='请填写具体日期'
                    }
                    if (this.act.coupons[i].validity_type==2 && this.act.coupons[i].end_day<1){
                        flag=false
                        errMsg='请完善有效天数'
                    }
                }
                if(!flag){
                    this.$alert(errMsg)
                    return
                }
                var pm=this.act
                pm['creator']=getUserId()
                if (pm.vip_type==2){
                    pm.vip_list=pm.vip_levels
                }
                postRequest("activity/addActivity",pm).then(res=>{
                    console.log(res)
                    if (res.data.flag){
                        this.$router.push("/SendCouponList")
                    }
                })
            },
            toSelectVip(){
                if (this.showVipSearch){
                    this.showVipSearch=false
                }else {
                    this.showVipSearch=true
                }
            },
            serchVip(){
                console.log(this.vipQueryValue)
                if (this.vipQueryValue.length>0){
                    getRequest('vip/searchVip?queryValue=' + this.vipQueryValue).then(res=>{
                        this.vipSearchList=res.data.data
                    })
                }else {
                    this.vipSearchList=[]
                }

            },
             vpvChange(e){
                this.searchVipDf()
            },
            vipSelected(it){
                this.act.vip_list.push(it)
                this.vipQueryValue=''
                this.vipSearchList=[]
                this.showVipSearch=false
            },
            getvip_listShow(){
                var t=''
                for (var i=0;i<this.act.vip_list.length;i++){
                    t=t+this.act.vip_list[i].vip_code+'|'+this.act.vip_list[i].name+','
                }
                return t
            },
            showCoupon(it){
                if (it.coupon_group!=null){
                    return it.coupon_group+'|'+it.coupon_group_name
                }else {
                    return ''
                }
            },
            delCoupon(id){
                var t=[]
                for (var i=0;i<this.act.coupons.length;i++){
                    if (i!=id){
                        t.push(this.act.coupons[i])
                    }
                }
                this.act.coupons=t
            },
            clearVip(){
                this.act.vip_list=[]
            },
            getVipLevel(){
                getRequest('vip/getVipLevelList').then(res=>{
                    this.vipLevelList=res.data.data
                    for (var i=0;i<this.vipLevelList.length;i++){
                        for (var a=0;a<this.act.vip_levels.length;a++){
                            if (this.act.vip_levels[a].level_code==this.vipLevelList[i].level_code){
                                this.vipLevelList[i].check=true
                            }
                        }
                    }
                })
            },
            toSelectLevel(){
                if (this.showVipLevelSelect){
                    this.showVipLevelSelect=false
                }else {
                    this.getVipLevel()
                    this.showVipLevelSelect=true
                }
            },
            vipLevelSelected(index){
                if (this.vipLevelList[index].check){
                    this.vipLevelList[index].check=false
                }else {
                    this.vipLevelList[index].check=true
                }
                this.act.vip_levels=[]
                for (var i=0;i<this.vipLevelList.length;i++){
                    if (this.vipLevelList[i].check){
                        this.act.vip_levels.push(this.vipLevelList[i])
                    }
                }
            },
            getvip_levelShow(){
                var t=''
                for (var i=0;i<this.act.vip_levels.length;i++){
                    t=t+this.act.vip_levels[i].level_code+'|'+this.act.vip_levels[i].level_name+','
                }
                return t
            },
            toImport(){
                this.showLoading=true
                console.log(this.$refs.fileRef.files[0])
                importVip(this.$refs.fileRef.files[0]).then(res=>{
                    this.showLoading=false
                    this.act.vip_list=res
                    this.$refs.fileRef.value=null
                })
            },
        },
        mounted() {
            this.searchVipDf=debounce(this.serchVip,200)
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 20px;
    }
    .row-class span{
        width: 200px;
    }
    .row-class .el-input{
        width: 400px;
    }
    table,th td{
        border: whitesmoke solid 1px;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
    }
    td,th{
        border: whitesmoke solid 1px;
    }
    ::-webkit-scrollbar {
        display:none
    }
    .del-class{
        position: absolute;
        top: 0;right: 0
    }
    .del-class:hover{
        background-color: #00d8d8;
        color: white;
    }
</style>