<template>

    <div style="padding: 10px;text-align: left">
        <h4>占位组件配置</h4>
        <div  style="display: flex;align-items: center">
            <div style="margin-right: 20px">组件高度</div>
            <el-input-number :min="1" size="small"  v-model="height" />
        </div>
        <div style="padding: 10px;display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-top: 10px">
            <div>背景色</div>
            <input v-model="backgroundColor"  style="width: 150px;height: 18px;margin-left: 10px">
            <el-color-picker color-format="hex" @change="colorchange" v-model="backgroundColor" style="margin-left: 20px"></el-color-picker>
        </div>
    </div>
</template>

<script>
    export default {
        name: "palceholder-config",
        props:{
            comData:{
                height:Number,
                backgroundColor: String
            }
        },
        data(){
            return{
                comData_:{},
                height:5,
                backgroundColor: "",
                colorSelect:false,
            }
        },
        watch :{
            height(){
                this.comData_.height = this.height
            },
            backgroundColor(){
                this.comData_.backgroundColor = this.backgroundColor
            }
        },
        methods:{
            colorchange(e){

                this.comData_.backgroundColor=e
            },
        },
        mounted() {
            this.comData_=this.comData
            this.height=this.comData.height
            this.backgroundColor=this.comData.backgroundColor
            console.log(this.comData_)
        }
    }
</script>

<style scoped>

</style>