<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>积分商城</el-breadcrumb-item>
            <el-breadcrumb-item>礼品列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="text-align: left">
            <el-form :inline="true" :model="conditionForm" class="demo-form-inline">
                <el-form-item label="礼品代码">
                    <el-input v-model="conditionForm.goods_code" style="width: 300px" placeholder="多商品用逗号分隔,例:00000034，00000035" clearable />
                </el-form-item>
                <el-form-item label="礼品名称">
                    <el-input v-model="conditionForm.gift_name" placeholder="礼品名称" clearable />
                </el-form-item>
                <el-form-item label="上架状态">
                    <el-select
                            v-model="conditionForm.listing_flag"
                            placeholder="上架状态"
                    >
                        <el-option label="全部" :value="-1" />
                        <el-option label="上架" :value="1" />
                        <el-option label="未上架" :value="0" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmitQuery">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="text-align: left">
            <el-checkbox label="全选" @change="allChange"/>
            <el-button  style="margin-left: 20px;margin-right: 20px" @click="batchListing">批量上架</el-button>
            <el-button  @click="batchunListing">批量下架</el-button>
            <el-button type="success" @click="toAdd" style="margin-left: 30px">新增礼品</el-button>
        </div>

        <div style="position: relative;margin-top: 10px">
            <div v-loading="showLoading" style="height: 600px;overflow: scroll;border-bottom: #bcbcbc solid 1px;margin-bottom: 20px">
                <el-checkbox-group v-model="checkList">
                    <table>
                        <thead id="floatth">
                        <th class="mth">序号</th>
                        <th class="mth">图片</th>
                        <th class="mth">礼品代码</th>
                        <th class="mth">礼品名称</th>
                        <th class="mth">原价</th>
                        <th class="mth">售价</th>
                        <th class="mth">积分价格</th>
                        <th class="mth">上架</th>
                        <th class="mth">上架日期</th>
                        <th class="mth">编辑</th>
                        </thead>
                        <thead id="sourceth">
                        <th class="mth">序号</th>
                        <th class="mth">图片</th>
                        <th class="mth">礼品代码</th>
                        <th class="mth">礼品名称</th>
                        <th class="mth">原价</th>
                        <th class="mth">售价</th>
                        <th class="mth">积分价格</th>
                        <th class="mth">上架</th>
                        <th class="mth">上架日期</th>
                        <th class="mth">编辑</th>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in tableData">
                                <td>{{index+1}}</td>
                                <td><el-image :preview-src-list="[item.pic_url]" :src="item.pic_url" style="width: 40px;object-fit: fill"></el-image></td>
                                <td><el-checkbox :label="item.goods_code"/></td>
                                <td>{{item.gift_name}}</td>
                                <td>{{item.price}}</td>
                                <td>{{item.real_price}}</td>
                                <td>{{item.points}}</td>
                                <td>
                                    <el-switch
                                            v-model="item.listing_flag"
                                            disabled>
                                    </el-switch>
                                </td>
                                <td>{{item.listing_date}}</td>
                                <td style="color: blue">
                                    <span  v-if="!item.listing_flag" @click="Listing(item.goods_code)">上架</span>
                                    <span v-if="item.listing_flag" style="color: #ff2b21" @click="unListing(item.goods_code)">下架</span>
                                    <span @click="toEditgift(item.goods_code)" style="margin-left: 20px">编辑</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-checkbox-group>

            </div>
        </div>
        <el-pagination
                v-model:current-page="pageNum"
                v-model:page-size="pageSize"
                :page-sizes="[16,100, 200, 300, 400]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
                :pager-count="15"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
        <el-dialog v-model="showListingDate" title="选择上架日期">
            <span>上架时间:</span>
            <el-date-picker
                    v-model="listing_date"
                    type="date"
                    placeholder="选择日期"
                    :shortcuts="shortcuts"
                    value-format="YYYY-MM-DD"
            />
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="showListingDate = false">取消</el-button>
                <el-button type="primary" @click="submitUpdateListing">
                  上架
                </el-button>
              </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {postRequest} from "../../utils/api";

    export default {
        name: "GiftList",
        data(){
            return{
                checkList:[],
                conditionForm:{
                    goods_code: '',
                    gift_name: '',
                    listing_flag: -1,
                },
                totalNum: 0,
                tableData:[],
                updategift:[],
                pageSize: 10,
                pageNum: 1,
                showLoading:false,
                showListingDate:false,
                listing_date:this.formatDate(new Date()),
                shortcuts: [
                    {
                        text: '今天',
                        value: new Date(),
                    },
                    {
                        text: '明天',
                        value: () => {
                            const date = new Date()
                            date.setTime(date.getTime() + 3600 * 1000 * 24)
                            return date
                        },
                    },
                    {
                        text: '一周后',
                        value: () => {
                            const date = new Date()
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
                            return date
                        },
                    },
                ]
            }
        },
        methods:{
            onSubmitQuery(){
                this.showLoading = true
                this.conditionForm.goods_code=this.conditionForm.goods_code.replace("，",",")
                var pm={}
                pm['pageNum']=this.pageNum
                pm['pageSize']=this.pageSize
                if (this.conditionForm.listing_flag>-1){
                    pm['listing_flag']=this.conditionForm.listing_flag
                }
                var goods_codes=[]
                if (this.conditionForm.goods_code!=null){
                    goods_codes= this.conditionForm.goods_code.split(",")
                }
                if (goods_codes.length>1){
                    pm['goods_code'] =null
                    pm['goods_codes']=goods_codes
                }else {
                    pm['goods_code']=goods_codes[0]
                    pm['goods_codes']=null
                }
                postRequest('pointsSys/getGiftList',pm).then(res =>{
                    this.showLoading = false
                    this.tableData = res.data.data.list
                    this.totalNum = res.data.data.total
                    this.setWidth()
                })
            },
            setWidth(){
                if (this.$route.path=='/GiftList'){
                    setTimeout(()=>{
                        var sh=  document.getElementById('sourceth').children
                        var fh=  document.getElementById('floatth').children
                        for (var i=0;i<sh.length;i++){
                            fh[i].style.width=sh[i].offsetWidth -1 + 'px';
                        }
                    },200)
                }


            },
            handleSizeChange(){
                this.pageNum = 1
                this.onSubmitQuery()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.onSubmitQuery()
            },
            toEditgift(giftcode){
                this.$router.push('GiftEidt/'+giftcode)
            },
            Listing(gift){
                this.showListingDate = true
                this.updategift = [gift]

            },
            unListing(gift){
                this.updategift = [gift]
                postRequest('pointsSys/updateGiftListing',{
                    'goods_codes': this.updategift,
                    'listing_date': null,
                    'listing_flag': false
                }).then(res=>{
                    this.$alert(res.data.message)
                    this.showListingDate = false
                    if (res.data.flag){
                        for (var i = 0;i<this.tableData.length;i++){
                            if (this.tableData[i].goods_code==this.updategift[0]){
                                this.tableData[i].listing_flag=false
                                this.tableData[i].listing_date=''
                            }
                        }
                    }
                })
            },
            submitUpdateListing(){
                postRequest('pointsSys/updateGiftListing',{
                    'goods_codes': this.updategift,
                    'listing_date': this.listing_date,
                    'listing_flag': true
                }).then(res=>{
                    this.$alert(res.data.message)
                    this.showListingDate = false
                    if (res.data.flag){
                        for (var i = 0;i<this.tableData.length;i++){
                            for (var u = 0;u<this.updategift.length;u++){
                                if (this.tableData[i].goods_code==this.updategift[u]){
                                    this.tableData[i].listing_flag=true
                                    this.tableData[i].listing_date=this.listing_date
                                }
                            }
                        }
                    }
                })
            },
            batchListing(){
                if(this.checkList.length<1){
                    this.$alert('请选择商品!')
                    return
                }
                this.updategift = this.checkList
                this.showListingDate = true

            },
            batchunListing(){
                if(this.checkList.length<1){
                    this.$alert('请选择商品!')
                    return
                }
                this.updategift = this.checkList
                postRequest('pointsSys/updateGiftListing',{
                    'goods_codes': this.updategift,
                    'listing_date': null,
                    'listing_flag': false
                }).then(res=>{
                    this.$alert(res.data.message)
                    this.showListingDate = false
                    if (res.data.flag){
                        for (var i = 0;i<this.tableData.length;i++){
                            for (var u = 0;u<this.updategift.length;u++){
                                if (this.tableData[i].goods_code==this.updategift[u]){
                                    this.tableData[i].listing_flag=false
                                    this.tableData[i].listing_date=''
                                }
                            }
                        }
                    }
                })
            },
            formatDate (date) {
                return `${date.getFullYear()}-${('0'+(date.getMonth() + 1).toString()).substring(-2)}-${date.getDate()}`
            },
            allChange(e){
                this.checkList=[]
                if (e==true){
                    for (var i = 0;i<this.tableData.length;i++){
                        this.checkList.push(this.tableData[i].goods_code)
                    }
                }
            },
            toAdd(){
                this.$router.push('GiftEidt/-1')
            }
        },
        mounted() {
            this.setWidth()
            window.addEventListener('resize',this.setWidth)
            this.onSubmitQuery()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    .mth:first-child{
        /*border-radius: 5px 0 0 0;*/
        border-left: #d8d8d8 solid 1px;
    }
    .mth:last-child{
        /*border-radius: 0 5px 0 0;*/
        border-left: none;
    }
    .mth{
        margin: 0;
        border-left: none;
        border: none;
        flex: 1;
    }
    td{
        border: none;
        border-bottom: #eeeeee solid 1px;
    }
    #floatth{
        position: absolute;
        top: 0px;
        margin: 0;
        background-color: rgb(226, 228, 238);
        opacity: 1;
        /*border-radius: 5px;*/
        line-height: 40px;
        border: none;
        z-index: 100;
    }
    #sourceth{
        margin: 0;
        border: none;
    }
    span:hover{
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
    tr:nth-child(1){
        background: #ffffff;
    }
    tr:nth-child(2n){
        background: #f7f9f9;
    }
    /deep/ .el-checkbox{
        user-select: auto;
    }
</style>