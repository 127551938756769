<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商城管理</el-breadcrumb-item>
            <el-breadcrumb-item>运费模板</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: #eeeeee;padding: 10px">
            <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
                <el-form :inline="true" :model="conditionForm" class="demo-form-inline">
                    <el-form-item label="名称">
                        <el-input v-model="conditionForm.name" placeholder="名称" clearable />
                    </el-form-item>
                    <el-form-item label="创建日期">
                        <el-date-picker
                                v-model="conditionForm.dateValue"
                                type="daterange"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                unlink-panels
                                range-separator="-"
                                start-placeholder="起始日期"
                                end-placeholder="截至日期"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmitQuery">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="background-color: white;text-align: left;margin-top: 10px;padding-bottom: 10px;padding: 10px">
                <el-button type="success" style="padding: 10px" @click="toAdd">新增模板</el-button>
            </div>
        </div>
        <div>
            <table>
                <thead>
                    <th>ID</th>
                    <th>名称</th>
                    <th>类型</th>
                    <th>默认</th>
                    <th>操作</th>
                </thead>
                <tbody>
                    <tr v-for="(it,index) in templateList">
                        <td>{{it.id}}</td>
                        <td>{{it.name}}</td>
                        <td>{{it.ftype==2?'包邮':'不包邮'}}</td>
                        <td>{{it.isdefault?'是':'否'}}</td>
                        <td>
                            <span @click="toEdit(it.id)">编辑</span>
                            <span v-if="!it.isdefault" @click="setDefault(it.id)">设未默认</span>
                            <span @click="del(it.id)" style="color: red">删除</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";
    import {ElMessageBox} from "element-plus";

    export default {
        name: "FreightTempleteList",
        data(){
            return{
                conditionForm:{
                    page_name:'',
                    dateValue:null
                },
                templateList:[]
            }
        },
        methods:{
            onSubmitQuery(){
                getRequest('mall/getFreightList').then(res=>{
                    console.log(res)
                    if (res.data.flag){
                        this.templateList=res.data.data
                    }else {
                        ElMessageBox.alert(res.data.message)
                    }

                })
            },
            toAdd(){
                this.$router.push('FreightTempleteEdit/-1')
            },
            toEdit(id){
                this.$router.push('FreightTempleteEdit/' + id)
            },
            setDefault(id){
                getRequest('mall/setDefaultTemplate?id=' + id).then(res=>{
                    this.onSubmitQuery()
                    this.$alert(res.data.message)
                })
            },
            del(id){
                getRequest('mall/delTemplate?id=' + id).then(res=>{
                    this.onSubmitQuery()
                    this.$alert(res.data.message)
                })
            }

        },
        mounted() {
            this.onSubmitQuery()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
</style>