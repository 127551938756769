<template>
    <div id="itb">
        <div class="item-box-class">
            <li :class="iconClass"/>
            <div style="font-size: 12px;margin-top: 5px">{{comText}}</div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "mall-com-list-item",
        props:{
            iconClass:String,
            comText: String
        },
        methods:{
        }
    }
</script>

<style scoped>
    li{
        font-size: 40px;
        color: #00d8d8;
        margin-top: 10px;
        list-style: none
    }
    .item-box-class{
        width:80px;
        height: 80px;
        border: #eeeeee solid 1px;
        margin: 5px;
    }
    #itb :hover{
        border-color: cyan;
    }
</style>