<template>
    <div>
        <div class="block">
            <el-carousel :height="getHeight" :interval="swipe.interval*1000">
                <el-carousel-item v-for="(item,index) in swipe.data" :key="index">
                    <img :src="item.pic_url" :style="swipe.fullScreen==true?'object-fit: cover;height:800px':'object-fit: cover;'"/>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
    export default {
        name: "swipe-item",
        props:{
            swipe:{
                interval:Number,
                height: Number,
                fullScreen: Boolean,
                data: Array
            }
        },
        computed:{
            getHeight(){
                if (this.swipe.fullScreen){
                    return  800 + 'px'
                }else {
                    return  (375/750)*this.swipe.height + 'px'
                }
            }
        }
    }
</script>

<style scoped>
    img{
        width: 100%;
    }
</style>