<template>
    <div style="background-color: whitesmoke;padding: 10px">
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
            <span style="margin-right: auto">注册送礼</span>
            <el-button :disabled="readonly" type="primary" style="width: 90px" @click="saveGiftEdit">保存</el-button>
            <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
        </div>
        <div style="background-color: white;padding: 10px">
            <div class="row-class">
                <span>活动名称</span>
                <el-input v-model="act.activity_name"></el-input>
            </div>
            <div class="row-class" style="width: 600px;">
                <span>活动时间</span>
                <el-date-picker
                        v-model="act.validity_date"
                        type="daterange"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="起始日期"
                        end-placeholder="截至日期"
                />
            </div>
            <div class="row-class">
                <span>活动备注</span>
                <el-input v-model="act.activity_note" type="textarea" style="width: 500px;"></el-input>
            </div>
        </div>
        <div style="background-color: white;padding: 20px;margin-top: 20px">
            <div style="text-align: left">活动内容:</div>
            <div style="text-align: left;display: flex;margin-top: 20px;margin-left: 30px">
                <span style="width: 100px">赠送积分</span>
                <el-input v-model="act.bestow_integral" style="width:500px"></el-input>
            </div>
            <div style="text-align: left;margin-left: 30px;margin-top: 30px">赠送优惠券:</div>
            <div style="margin-top: 10px;background-color: white;">
                <table style="max-width: 800px;margin-left: 30px">
                    <thead>
                    <th>券ID</th>
                    <th>优惠券名称</th>
                    <th>发券后X天内有效</th>
                    <th>发券数量</th>
                    <th v-if="!readonly">删除</th>
                    </thead>
                    <tbody>
                    <tr v-for="it in act.coupons">
                        <td>{{it.coupon_group_id}}</td>
                        <td>{{it.coupon_group_name}}</td>
                        <td><el-input-number v-model="it.valid_days" :min="1"></el-input-number></td>
                        <td><el-input-number v-model="it.num" :min="1"></el-input-number></td>
                        <td v-if="!readonly">
                            <span @click="delCoupon(it)">删除</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <coupon-group-select v-model="showCouponSelect" multiple-select="true" @confirm="couponSelectConfirm"></coupon-group-select>
            <div style="text-align: left;margin-left: 30px;margin-top: 20px;"><el-button @click="toSelectCoupon" style="border: rgba(0,0,255,0.5) solid 1px;color: rgba(0,0,255,0.5)">添加优惠券</el-button></div>
        </div>
    </div>
</template>

<script>
    import CouponGroupSelect from "../common/coupon-group-select";
    import {getRequest, postRequest} from "../../utils/api";
    export default {
        name: "RegisterGiftEdit",
        components: {CouponGroupSelect},
        data(){
            return{
                act:{
                    id:-1,
                    coupons:[]
                },
                showCouponSelect:false,
                readonly:false
            }
        },
        methods:{
            backHome(){
                this.$router.back()
            },
            toSelectCoupon(){
              this.showCouponSelect=true
            },
            couponSelectConfirm(e){
                console.log(e)
                var flag=flag
                for (var i=0;i<e.length;i++){
                    flag=false
                    for (var a=0;a<this.act.coupons.length;a++){
                        if (e[i].id==this.act.coupons[a].coupon_group_id){
                            flag=true
                        }
                    }
                    if (!flag){
                        this.act.coupons.push({
                            coupon_group_id:e[i].id,
                            coupon_group_name:e[i].name,
                            valid_days:90,
                            num:1
                        })
                    }
                }
            },
            delCoupon(it){
                var t=[]
                for (var i=0;i<this.act.coupons.length;i++){
                    if (this.act.coupons[i].coupon_group_id!=it.coupon_group_id){
                        t.push(this.act.coupons[i])
                    }
                }
                this.act.coupons=t

            },
            saveGiftEdit(){
                if (this.act.validity_date==null){
                    this.$alert('请填写活动时间！')
                    return
                }
                this.act.start_time=this.act.validity_date[0]
                this.act.end_time=this.act.validity_date[1]
                if (this.act.id==-1){
                    postRequest('activity/sumbitRegisterGift',this.act).then(res=>{
                        console.log(res)
                        if (res.data.flag){
                            this.$alert(res.data.message)
                            this.$router.back()
                        }
                    })
                }
            },
            getDetail(){
                getRequest('activity/getRegisterGiftDetailById?id='+this.act.id).then(res=>{
                    this.act=res.data.data
                    this.act.validity_date=[this.act.start_time,this.act.end_time]
                })
            }
        },
        mounted() {
            this.act.id = this.$route.params.activity_id
            if (this.act.id!=-1){
                this.readonly=true
                this.getDetail()
            }
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 20px;
    }
    .row-class span{
        width: 200px;
    }
    .row-class .el-input{
        width: 400px;
    }
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>