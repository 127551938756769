<template>
    <div v-loading="showloading">
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
            <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
        </div>
        <div class="row-class" >
            <span>活动名称</span>
            <el-input v-model="act.activity_name"></el-input>
        </div>
        <div class="row-class">
            <span>优惠券备注</span>
            <el-input v-model="act.coupon_note"></el-input>
        </div>
        <div class="row-class">
            <span>会员</span>
            <div>
                <el-radio-group v-model="act.vip_type" style="text-align: left;flex-direction: column">
                    <el-radio  label="1" style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px;">
                        <div style="display: flex;flex-direction: row;align-items: center">
                            <div>具体会员</div>
                            <div v-if="act.vip_list!=null" style="text-align: left;display: flex;flex-direction: column;position: relative;margin-left: 20px">
                                <el-input readonly :model-value="getvip_listShow()" style="width: 500px;">
                                </el-input>
                            </div>
                        </div>
                    </el-radio>
                    <el-radio  label="2" style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                        <div style="display: flex;flex-direction: row;align-items: center;position: relative">
                            <div>会员等级</div>
                            <div v-if="act.level_list!=null" style="text-align: left;display: flex;flex-direction: column;position: relative;margin-left: 20px">
                                <el-input readonly :model-value="getvip_levelShow()" style="width: 500px;">
                                </el-input>
                            </div>
                        </div>
                    </el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="row-class">
            <span>优惠券</span>
            <div style="text-align: left;">
                <div v-for="(it,index) in act.coupons">
                    <div style="margin-bottom: 20px;border: #c8c8c8 solid 1px;padding: 10px;position: relative">
                        <div style="margin-bottom: 20px">
                            <span style="margin-right: 30px;" :style="{color: it.coupon_group==null?'red':''}">优惠券</span>
                            <el-input readonly  placeholder="优惠券" :model-value="it.coupon_group+'|' + it.coupon_group_name" clearable >
                            </el-input>
                        </div>
                        <div style="background-color: whitesmoke;padding: 20px">
                            <div style="margin-bottom: 10px">优惠券有效期:</div>
                            <div style="display: flex;">
                                <div style="display: flex;flex-direction: row;align-items: center;margin-right: 20px;cursor: pointer">
                                    <div style="width: 10px;height: 10px;border: #999999 solid 1px;border-radius: 30px;">
                                        <div style="width: 10px;height: 10px;border-radius: 20px;border: none" :style="{backgroundColor: it.validity_type==1?'#3ffaff':''}"></div>
                                    </div>
                                    <div style="margin-left: 5px">指定具体日期</div>
                                </div>
                                <el-date-picker
                                        v-model="it.validity_date"
                                        type="daterange"
                                        value-format="YYYY-MM-DD HH:mm:ss"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        unlink-panels
                                        range-separator="-"
                                        start-placeholder="起始日期"
                                        end-placeholder="截至日期"
                                />
                            </div>
                            <div style="display: flex;flex-direction: row;margin-top: 10px">
                                <div style="display: flex;flex-direction: row;align-items: center;margin-right: 20px;cursor: pointer">
                                    <div style="width: 10px;height: 10px;border: #999999 solid 1px;border-radius: 30px;">
                                        <div style="width: 10px;height: 10px;border-radius: 20px;border: none" :style="{backgroundColor: it.validity_type==2?'#3ffaff':''}"></div>
                                    </div>
                                    <div style="margin-left: 5px">指定有效天数</div>
                                </div>
                                <div style="display: flex;flex-direction: row;align-items: center">
                                    <div>从第</div>
                                    <el-input v-model="it.start_day" style="width: 50px"></el-input>
                                    <span style="width: fit-content">后</span>
                                    <el-input v-model="it.end_day" style="width: 50px"></el-input>
                                    <span>天内有效</span>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 20px">
                            <span>发券数量 </span>
                            <el-input-number v-model="it.num" :min="1"></el-input-number>
                            <span> 张</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";

    export default {
        name: "SendCouponDetail",
        data(){
            return{
                activity_id:'',
                act:{
                    coupons:[],
                    vip_list:[],
                    level_list:[]
                },
                showloading:false
            }
        },
        methods:{
            get(){
                this.showloading=true
                getRequest('activity/getActivityDetail?activity_id='+this.activity_id).then(res=>{
                    console.log(res)
                    this.showloading=false
                    this.act=res.data.data
                    if (this.act.coupons!=null){
                        for (var i=0;i<this.act.coupons.length;i++){

                            if (this.act.coupons[i].validity_type=='1'){
                                this.act.coupons[i].validity_date=[this.act.coupons[i].start_date,this.act.coupons[i].end_date]
                            }
                        }
                    }
                })
            },
            getvip_listShow(){
                var t=''
                for (var i=0;i<this.act.vip_list.length;i++){
                    t=t+this.act.vip_list[i].vip_code+'|'+this.act.vip_list[i].name+','
                }
                return t
            },
            getvip_levelShow(){
                var t=''
                for (var i=0;i<this.act.level_list.length;i++){
                    t=t+this.act.level_list[i].level_code+'|'+this.act.level_list[i].level_name+','
                }
                return t
            },
            backHome(){
                this.$router.back()
            },
        },
        mounted() {
            this.activity_id=this.$route.params.activity_id
            this.get()
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 20px;
    }
    .row-class span{
        width: 200px;
    }
    .row-class .el-input{
        width: 400px;
    }
    table,th td{
        border: whitesmoke solid 1px;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
    }
    td,th{
        border: whitesmoke solid 1px;
    }
    ::-webkit-scrollbar {
        display:none
    }
    .del-class{
        position: absolute;
        top: 0;right: 0
    }
    .del-class:hover{
        background-color: #00d8d8;
        color: white;
    }
</style>