<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>商城管理</el-breadcrumb-item>
            <el-breadcrumb-item>商品分组</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: left;padding: 10px">
            <div style="display: flex;flex-direction: row">
                <div style="display: flex;flex-direction: row;align-items: center">
                    <span style="width: 150px">分组名称</span>
                    <el-input v-model="gname"></el-input>
                </div>
                <el-button icon="search" style="margin-left: 10px" type="primary" @click="getGoodsGroup">搜索</el-button>
            </div>
        </div>
        <div style="text-align: left">
            <el-button type="success" @click="addRootGroup">添加分组</el-button>
<!--            <el-button type="primary" style="margin-left: 100px">保存</el-button>-->
        </div>
        <div style="width: 100%;margin-top: 20px;margin-bottom: 20px;border-top: #bcbcbc solid 1px"></div>
        <div>
            <table>
                <thead>
                    <th>排序</th>
                    <th>分组名称</th>
                    <th>图片</th>
                    <th>是否显示</th>
                    <th>操作</th>
                </thead>
                <tbody>
                    <tr v-for="r in gdata">
                        <td style="max-width: 50px"><el-icon style="cursor: grab"><Rank/></el-icon></td>
                        <td>
                            <div style="display: flex;flex-direction: row">
                                <el-icon  size="25" v-show="r.fold==true" @click="toUnfold(r)"><ArrowRight/></el-icon>
                                <el-icon  size="25" v-show="r.fold==false" @click="tofold(r)"><ArrowDown/></el-icon>
<!--                                <span v-if="r.group_type==3" style="color: black;">{{r.parent_name}} -> </span>-->
                                <span style="color: #999999">{{r.group_id}}</span>
                                <el-input  v-model="r.group_name" style="width: 200px;margin-left: 10px" @change="updateGroup(r)"></el-input>
                            </div>
                        </td>
                        <td>
                            <div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;color: white">
                                <el-image :preview-src-list="[r.pic_url]" style="object-fit: cover;height: 100%;width: 55px;border-radius: 3px" :src="r.pic_url"></el-image>
                                <div style="background-color: #4b4b4b;width: 55px;line-height: 20px" @click="changePic(r)">修改图片</div>
                            </div>
                        </td>
                        <td><el-switch v-model="r.show_flag" @change="updateGroup(r)"/></td>
                        <td>
                            <div style="display: flex;">
                                <span v-if="(r.parent_id==1 ||r.parent_id==2 ) && r.group_id>3" style="margin-left: 10px;color: blue;cursor: pointer;">小程序地址:</span>
                                <el-input @change="updateGroup(r)" v-model="r.link_url" v-if="(r.parent_id==1 ||r.parent_id==2 ) && r.group_id>3" style="width: 200px"></el-input>
                                <span v-if="r.group_type==1 || r.group_type==2"  @click="addChildrenGroup(r)" style="margin-left: 10px;color: blue;cursor: pointer;">添加子分组</span>
                                <!--                                <span>上移</span>-->
                                <!--                                <span>下移</span>-->
                                <span v-if="r.group_type!=1" @click="delGroup(r)" style="margin-left: 10px;color: blue;cursor: pointer;color: #999999">删除分组</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <media-material-com v-model="showPicSelect" @confirm="piceConfirm"></media-material-com>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import MediaMaterialCom from "../common/media-material-com";
    import {ElMessageBox} from "element-plus";

    export default {
        name: "GoodsGroupMange",
        components: {MediaMaterialCom},
        data(){
            return{
                gname: '',
                gdata:[],
                gSourcedata:[],
                showPicSelect:false,
                changePicItem:null,
            }
        },
        methods:{
            getGoodsGroup(){
                getRequest('goods/getGoodsGroup').then(res=>{
                    var t=res.data.data
                    console.log(t)
                    for (var i=0;i<t.length;i++){
                        t[i]['fold']=true
                    }
                    this.gSourcedata=t
                    this.getData()
                })
            },
            getData(){
                this.gdata=[]
                for(var i=0;i<this.gSourcedata.length;i++){
                    // this.gSourcedata[i].fold=true
                    if (this.gSourcedata[i].group_type!=3){
                        this.gdata.push(this.gSourcedata[i])
                    }
                    if (this.gSourcedata[i].fold==false && this.gSourcedata[i].childrenList!=null){
                        for (var c=0;c<this.gSourcedata[i].childrenList.length;c++){
                            this.gdata.push(this.gSourcedata[i].childrenList[c])
                        }
                    }
                }
            },
            toUnfold(r){
                var t=[]
                for (var i=0;i<this.gSourcedata.length;i++){
                    if (r.group_id==this.gSourcedata[i].group_id || r.group_id==this.gSourcedata[i].parent_id){
                        this.gSourcedata[i].fold=false
                        // for (var c=0;c<this.gdata[i].childrenList.length;c++){
                        //     t.push(this.gdata[i].childrenList[c])
                        // }
                    }
                }
                this.getData()

            },
            tofold(r){
                var t=[]
                for (var i=0;i<this.gdata.length;i++){
                    if (r.group_id==this.gdata[i].group_id){
                        this.gdata[i].fold=true
                    }
                    if (r.group_id!=this.gdata[i].parent_id){
                        t.push(this.gdata[i])
                    }
                }
                this.getData()
            },
            addRootGroup(){
                var temp={
                    group_id: this.gSourcedata.length+1,
                    group_name: '新分组',
                    parent_id: null,
                    childrenList:[],
                    group_type: '2',
                    sorting:this.gSourcedata.length+1,
                    show_flag:true,
                    pic_id:'',
                    pic_url:'',
                    fold:true
                }
                postRequest('goods/addGoodsGroup',temp).then(res=>{
                    if (res.data.flag){
                        this.getGoodsGroup()
                    }else {
                        ElMessageBox.alert(res.data.message)
                    }

                })
            },
            addChildrenGroup(r){
                var temp={
                    group_id: this.gSourcedata.length+1,
                    group_name: '新分组',
                    parent_id: r.group_id,
                    parent_name: r.group_name,
                    childrenList:[],
                    group_type: '3',
                    sorting:this.gSourcedata.length+1,
                    show_flag:true,
                    pic_id:'',
                    pic_url:'',
                    fold:false
                }
                postRequest('goods/addGoodsGroup',temp).then(res=>{
                    if (res.data.flag){
                        this.getGoodsGroup()
                    }else {
                        ElMessageBox.alert(res.data.message)
                    }

                })
                // this.getData()
            },
            updateGroup(dto){
                setTimeout(()=>{
                    postRequest('goods/updateGoodsGroup',dto).then(res=>{
                        // this.getGoodsGroup()
                        if (!res.data.flag){
                            ElMessageBox.alert(res.data.message)
                        }
                    })
                },100)

            },
            delGroup(r){
                r.del=true
                postRequest('goods/updateGoodsGroup',r).then(res=>{
                    // this.getGoodsGroup()
                    if (!res.data.flag){
                        ElMessageBox.alert(res.data.message)
                    }
                    if (res.data.flag){
                        var t=[]
                        for (var i=0;i<this.gSourcedata.length;i++){
                            if (this.gSourcedata[i].childrenList!=null){
                                var t2=[]
                                for (var c=0;c<this.gSourcedata[i].childrenList.length;c++){
                                    if (this.gSourcedata[i].childrenList[c].group_id!=r.group_id){
                                        t2.push(this.gSourcedata[i].childrenList[c])
                                    }
                                }
                                this.gSourcedata[i].childrenList=t2
                            }
                            if (this.gSourcedata[i].parent_id!=r.group_id && this.gSourcedata[i].group_id!=r.group_id){
                                t.push(this.gSourcedata[i])
                            }
                        }
                        this.gSourcedata=t
                        this.getData()
                    }
                })
            },
            changePic(r){
              this.changePicItem =r
              this.showPicSelect = true
            },
            piceConfirm(e){
                console.log(e)
                for(var i=0;i<this.gSourcedata.length;i++){
                    if (this.gSourcedata[i].childrenList!=null){
                        for (var c=0;c<this.gSourcedata[i].childrenList.length;c++){
                            if (this.gSourcedata[i].childrenList[c].group_id==this.changePicItem.group_id){
                                this.gSourcedata[i].childrenList[c].pic_id=e[0].file_id
                                this.gSourcedata[i].childrenList[c].pic_url=e[0].url
                            }
                        }
                    }
                    if(this.gSourcedata[i].group_id==this.changePicItem.group_id){
                        this.gSourcedata[i].pic_id=e[0].file_id
                        this.gSourcedata[i].pic_url=e[0].url
                        this.updateGroup(this.gSourcedata[i])
                    }
                }
                this.getData()
            }
        },
        mounted() {
            this.getGoodsGroup()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>div>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
</style>