<template>
    <div class="box-class" :style="{height: comData.imgHeight+'px'}">
        <img :src="comData.background_image_url" style="width: 100%;height: 100%;object-fit: cover;position: absolute;top: 0;left: 0;z-index: 3"/>
        <div style="display: flex;flex-direction: row;flex-wrap: nowrap;position: absolute;overflow: scroll;bottom: 18px;z-index: 40;width: 100%">
            <div class="comd-card-box" v-for="(item,index) in commditys" :key="index">
                <div class="lable-class" :style="{color: comData.lableColor,backgroundColor: comData.lableBackgroundColor}">{{comData.lableTxt}}</div>
                <img style="object-fit: cover;height: 178px;"  :src="item.pic_url"/>
                <div class="comdinfo">
                    <div>
                        <div style="text-indent: 5px;text-align: left;font-size: 13px;height: 15px;overflow: hidden;">{{item.goods_name}}</div>
                        <!--                <div style="text-indent: 5px;text-align: left;font-size: 12px;margin-top: 10px;">{{item.goods_code}}</div>-->
                        <div class="price">
                            <div  :style="{color: comData.priceColor}" style="display: flex;flex-direction: column">
                                <div v-if="comData.showPrice" style="text-align: left;text-decoration:line-through;color: #bcbcbc" :style="{fontSize: comData.priceFontSize + 'px'}">
                                    ￥
                                    <i :style="{fontSize: comData.priceFontSize + 'px'}">{{item.price}}</i>
                                </div>
                                <p :style="{fontSize: comData.priceFontSize + 'px'}">
                                    ￥
                                    <i :style="{fontSize: comData.priceFontSize + 'px'}">{{item.real_price}}</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {postRequest} from "../../../utils/api";

    export default {
        name: "img-commodity-item",
        props:{
            comData:{
                priceColor: String,
                priceFontSize: Number,
                showLable: Number,
                showPrice: Boolean,
                lableColor: String,
                lableBackgroundColor: String,
                lableTxt: String,
                dataNum:Number,
                data:Array,
                background_image_id:String,
                background_image_url:String,
                imgHeight:Number,
            }
        },
        data(){
            return{
                commditys: []
            }
        },
        watch:{
            'comData.dataNum':{
                handler() {
                    // console.log(this.commdity.data)
                    this.getComdityData()
                }
            }
        },
        methods:{
            getComdityData(){
                postRequest('goods/getCommdityInfoListByCodes',{'codes':this.comData.data}).then(res=>{
                    this.commditys=res.data.data
                })
            },
            // getPriceStyle(){
            //     console.log('fontSize: ' + this.commdity.conf.priceFontSize * 1.5 + 'px')
            //     return 'fontSize: ' + this.commdity.conf.priceFontSize * 1.5 + 'px'
            // }
        },
        mounted() {
            this.getComdityData()
        }
    }
</script>

<style scoped>
    .comd-card-box{
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        /*border-radius: 6px;*/
        background-color: white;
        box-sizing: border-box;
        border: #bcbcbc solid 1px;
        flex-shrink: 0;
        width: 125px;
        margin-left: 15px;
    }
    .comd-card-box:last-child{
        border-left: #bcbcbc solid 1px;
    }
    .lable-class{
        position: absolute;
        left: 1px;
        top: 1px;
        font-size: 13px;
    }
    li{
        list-style-type:none;
    }
    .price{
        text-align: left;
        display: flex;
        list-style: none;
        align-items: center;
        color: red;
        margin-top: auto;
    }
    .comdinfo{
        padding: 8px;
    }
    .box-class{
        position: relative;
    }
</style>