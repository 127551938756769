<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>功能菜单管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="margin-bottom: 20px;text-align: left;margin-top: 10px">
            <el-button type="success" @click="addRoot">添加根菜单</el-button>
        </div>
        <div style="border-bottom: #eeeeee solid 1px;width: 100%;margin-bottom: 20px;text-align: left;">
        </div>
        <div style="border: #eeeeee solid 1px;border-radius: 10px;height: 500px;overflow: scroll;padding: 50px">
            <el-tree
                    :data="menuData"
                    node-key="menu_id"
                    :expand-on-click-node="false"
                    :default-checked-keys="enabledMenu"
                    :props="defaultProps"
            >
                <template #default="{ node, data }">
                    <div class="custom-tree-node">
                        <span>{{ data.menu_id}}</span>
                        <span style="margin-left: 10px">{{ node.label }}</span>
                        <span style="margin-left: 30px;flex: 1">
                            <a @click="append(data)" style="color: #669900">添加</a>
                            <a v-if="data.enabled" style="margin-left: 20px;color: rgba(0,139,88,0.84)" @click="disabled(data)">停用</a>
                            <a v-if="!data.enabled" style="margin-left: 20px;color:rgba(255,0,0,0.74) " @click="disabled(data)">启用</a>
                        </span>
                    </div>
                </template>
            </el-tree>
        </div>
        <el-dialog v-model="showAdd" title="请输入菜单名称" style="width: 300px;">
            <el-input v-model="addMenuName"/>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="showAdd = false">取消</el-button>
                <el-button type="primary" @click="submitAdd">
                  添加
                </el-button>
              </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import { ElMessage, ElMessageBox } from 'element-plus'
    import MediaMaterialCom from "../common/media-material-com";
    export default {
        name: "MenuManage",
        components: {MediaMaterialCom},
        data(){
            return{
                menuData:[],
                addData: null,
                enabledMenu:[],
                showAdd: false,
                addMenuName: '',
                defaultProps: {
                    children: 'childrenList',
                    label: 'menu_name',
                }
            }
        },
        methods:{
            append(e){
                this.addData = e
                console.log(this.addData)
                this.showAdd = true
            },
            disabled(e){
                var en=!e.enabled
                getRequest('user/updateMenuEnabled?menuid=' + e.menu_id+ '&enabled=' + en).then(res=>{
                    ElMessageBox.alert(res.data.message)
                    this.getAllMenu()
                })
            },
            addRoot(){
                this.addData =null
                this.showAdd = true
            },
            submitAdd(){
                if (this.addMenuName.length<1){
                    ElMessageBox.alert('请输入菜单名称')
                    return
                }
                var parent_id;
                console.log(this.addData)
                if (this.addData == null){
                    parent_id=null
                }else {
                    parent_id=this.addData.menu_id
                }
                postRequest('user/addMenu',{'menu_name': this.addMenuName,'parent_menu': parent_id}).then(res => {
                    if (res.data.flag){
                        this.getAllMenu()
                        ElMessageBox.alert(res.data.message)
                        this.showAdd =false
                        this.addData = null
                    }else{
                        ElMessageBox.alert(res.data.message)
                    }
                })
            },
            getAllMenu(){
                this.enabledMenu = []
                getRequest('user/getAllMenus').then(res => {
                    this.menuData = res.data.data
                    // this.getEnabledMenu(this.menuData)
                    // console.log(this.enabledMenu)
                })
            },
            getEnabledMenu(temp){
                for(var i1=0;i1<temp.length;i1++){
                    var t=temp[i1]
                    if (t.enabled && t.childrenList==null){
                        this.enabledMenu.push(t.menu_id)
                    }
                    if (t.childrenList!=null){
                        this.getEnabledMenu(t.childrenList)
                    }
                }
            },
            cancelHandler(e){
                console.log(e)
            }
        },
        mounted() {
            this.getAllMenu()
        }
    }
</script>

<style scoped>
    .custom-tree-node{
        border-bottom: #dbdbdb solid 1px;
    }
    /deep/ .el-tree-node__content{
        height: 35px;
    }
</style>