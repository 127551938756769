<template>
    <div :style="imgCom.singleLine===true ?
    'display: flex;flex-direction: row;flex-wrap: nowrap;overflow: scroll;Box-sizing:border-box;':'display: flex;flex-direction: row;flex-wrap: wrap;Box-sizing:border-box;'">
        <img class="img-box"  v-for="(item,index) in data_" :key="index" :src="item.pic_url" :style="{width:imgCom.imgWidth+'px',objectFit:imgCom.autoHeight==true?'contain':'cover',
        height:imgCom.autoHeight==true?'auto':imgCom.imgHeight+'px',margin:imgCom.singelLine==true?imgCom.margin+'px':'auto',padding:imgCom.singelLine==true?'auto':imgCom.margin+'px'}"/>
    </div>
</template>

<script>
    export default {
        name: "img-com-item1",
        props:{
            imgCom: {
                singelLine: Boolean,
                showType: Number,
                margin: Number,
                radius: Number,
                imgHeight:Number,
                imgWidth:Number,
                autoHeight:Boolean,
                data:Array
            }
        },
        watch:{
        },
        data(){
            return{
                data_:[]
            }
        },
        mounted() {
          // console.log(this.imgCom)
            this.data_=this.imgCom.data
            // console.log(this.data_)
        },
        computed: {
            getImgH () {
                return 'height:'+ this.imgHeight[this.imgCom.showType-1] + 'px;'
            },
            getWith () {
                if (this.imgCom.singleLine==true){
                    return 'width: ' + 370/this.imgCom.showType+'px;height: '
                        + 370/this.imgCom.showType*1.44 +'px;border: white solid '+this.imgCom.margin+'px;border-radius:'+ this.imgCom.radius +'px;box-sizing: border-box;'
                }else {
                    return 'width: ' + 100/this.imgCom.showType +'%;border: white solid '
                        +this.imgCom.margin+'px;border-radius:'+ this.imgCom.radius +'px;box-sizing: border-box;'
                }

            }
        },
        methods:{


        }
    }
</script>

<style scoped>
    .img-box :not(:first-child){
        border-left: none !important;
    }

    img{
        Box-sizing:border-box;
        flex-shrink: 0;
    }
</style>