<template>
    <div class="dialog-class" v-loading="showloading">
        <div @click="closeAssign" style="position: absolute;top: 10px;right: 10px;height: 20px;width: 20px;cursor: pointer"><el-icon><Close /></el-icon></div>
        <div>订单详情:</div>
        <div class="detail-class">
            <div>订单号:<span>{{orderDetail.order_id}}</span></div>
            <div>订单日期:<span>{{orderDetail.create_time}}</span></div>
            <div>会员代码:<span>{{orderDetail.vip_code}}</span></div>
            <div>会员姓名:<span>{{orderDetail.vip_name}}</span></div>
            <div>收货人:<span>{{orderDetail.receiver_name}}</span></div>
            <div>收货人电话:<span>{{orderDetail.receiver_mobile}}</span></div>
            <div>收货地址:<span>{{orderDetail.receiver_province_name+orderDetail.receiver_city_name+orderDetail.receiver_county_name+orderDetail.receiver_address}}</span></div>
            <div>配送方式:<span style="color: red">{{orderDetail.receiver_type}}</span></div>
        </div>
        <div style="width: 100%;border-bottom: #bcbcbc solid 1px;margin-top: 20px;margin-bottom: 20px"></div>
        <div style="display: flex;flex-direction: row">
            <div>
                <div style="padding-left: 20px">
                    <span>商品详情</span>
                    <el-button @click="unfreeze" size="small" style="margin-left: 100px;" type="warning">解除退货数量限制</el-button>
                </div>
                <div style="font-size: 13px;padding-left: 20px;margin-left: 20px;margin-top: 20px;background-color: white;min-height: 560px;width: 400px;overflow: scroll">
                    <table>
                        <thead>
                            <th>图片</th>
                            <th>商品信息</th>
                            <th style="width: 30px">购买数量</th>
                            <th style="width: 30px">已退数量</th>
                            <th style="width: 30px">已派数量</th>
                            <th>派单数量</th>
                        </thead>
                       <tbody>
                           <tr v-for="(it) in orderDetail.details">
                               <td><el-image :src="it.pic_url" style="width: 60px;height: 60px;border-radius: 3px;margin: 5px"></el-image></td>
                               <td>
<!--                                   <div>{{it.goods_name}}</div>-->
                                   <div>{{it.sku}}</div>
                                   <div>{{it.goods_attribute1_name}}  {{it.goods_attribute2_name}}</div>
                               </td>
                               <td>{{it.num}}</td>
                               <td>{{it.return_num}}</td>
                               <td>{{it.assgined_num}}</td>
                               <td style="padding: 5px;color: red"><el-input-number size="small"  v-model="it.assgin_num" :min="0" :max="Math.max(0,it.num-it.assgined_num-it.return_num)"/></td>
                           </tr>
                       </tbody>
                    </table>

                </div>
            </div>
            <div style="border-right: #bcbcbc solid 1px;margin-left: 20px;margin-right: 20px"></div>
            <div style="flex: 1">
                <div>库存查询</div>
                <div style="display: flex;flex-direction: row;align-items: center;margin-top: 10px">
                    <div>仓库: </div>
                    <el-input  v-model="storeValue" placeholder="仓库名称/代码" clearable style="width: 200px;margin-left: 10px"/>
                    <el-button @click="getStocks" style="margin-left: auto;margin-right: 50px" type="primary">查询</el-button>
                </div>
                <div style="width: 100%;border-bottom: #dbdbdb solid 1px;margin-top: 10px;"></div>
                <div class="sth">
                    <div class="mtd">店仓</div>
                    <div class="mtd">商品</div>
                    <div class="mtd">CRM库存</div>
                    <div class="mtd">操作</div>
                    <div style="width: 17px"></div>
                </div>
                <div style="height: 500px;overflow: scroll" v-loading="showLoadingStocks">
                    <div class="sth" v-for="(it,index) in stocks">
                        <div class="mtd">{{it.store_name}}</div>
                        <div class="mtd" style="flex: 2;display: flex;flex-direction: column;border: none;align-items: normal">
                            <div v-for="(it2,index) in it.skus" style="display: flex;flex-direction: row;height: 40px">
                                <div class="mtd">{{it2.sku}}</div>
                                <div class="mtd">{{it2.num}}</div>
                            </div>
                        </div>
                        <div  @click="submitAssign(it)" class="mtd" style="cursor: pointer;color: blue">派单</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../../utils/api";
    import {getUserId} from "../../../utils/auth";
    import { ElMessage, ElMessageBox } from 'element-plus'
    export default {
        name: "assign-com",
        emits:['close','update:modelValue','getlist'],
        props:['orderid'],
        data(){
            return{
                orderDetail:{},
                storeValue:'',
                stocks:[],
                showLoadingStocks:false,
                showloading: false
            }
        },
        methods:{
            closeAssign(){
                this.$emit('update:modelValue',false)
            },
            getOrderDetail(){
                getRequest('mall/getOrderDetailById?order_id=' + this.orderid).then(res=>{
                    console.log(res)
                    this.orderDetail=res.data.data
                    var that=this
                    setTimeout(()=>{that.getStocks()},10)
                })
            },
            getStocks(){
                var skus=[]
                this.stocks=[]
                for (var i=0;i<this.orderDetail.details.length;i++){
                    console.log(this.orderDetail.details[i].assgin_num)
                    if (this.orderDetail.details[i].assgin_num>0){
                        skus.push({sku:this.orderDetail.details[i].sku,num:this.orderDetail.details[i].assgin_num})
                    }
                }
                if (skus.length<1){
                    this.$message({message:'请填写派单数量',type: 'warning'})
                    return
                }
                this.showLoadingStocks=true
                postRequest('mall/getStocksForAssign',{skus:skus,store:this.storeValue}).then(res=>{
                    this.showLoadingStocks=false
                    console.log(res)
                    this.stocks=res.data.data
                    console.log(this.stocks)
                })
            },
            submitAssign(it){
                var skus=[]
                for (var i=0;i<this.orderDetail.details.length;i++){
                    console.log(this.orderDetail.details[i].assgin_num)
                    if (this.orderDetail.details[i].assgin_num>0){
                        skus.push({
                            sku:this.orderDetail.details[i].sku,
                            goods_code:this.orderDetail.details[i].goods_code,
                            num:this.orderDetail.details[i].assgin_num,
                            attribute1_code:this.orderDetail.details[i].goods_attribute1,
                            attribute2_code:this.orderDetail.details[i].goods_attribute2
                        })
                    }
                }
                if (skus.length<1){
                    this.$alert('请填写派单数量')
                    return
                }
                console.log(skus)
                this.showloading=true
                postRequest('mall/submitAssign',{order_id:this.orderDetail.order_id,skus:skus,shipping_store:it.store_code,creator:getUserId()}).then(res=>{
                    this.showloading=false
                    if (res.data.code==200 || res.data.code==20014){
                        this.getOrderDetail()
                        this.$emit('getlist')
                    }
                    this.$alert(res.data.message)
                })
            },
            unfreeze(){
                ElMessageBox.confirm(
                    '解除限制可能会导致发货数大于购买数,确实解除?！',
                    '警告！！！！！！',
                    {
                        confirmButtonText: '确认解除',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                )
                    .then(() => {
                       for (var i=0;i<this.orderDetail.details.length;i++){
                           this.orderDetail.details[i].return_num=0
                       }
                    })
                    .catch(() => {
                    })
            }
        },
        mounted() {
            this.getOrderDetail()
        }
    }
</script>

<style scoped>
    .dialog-class{
        position: fixed;
        left: 0;
        right: 0;
        top:10px;
        margin:0 auto;
        height: 800px;
        width: 1100px;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
        padding: 10px;
        text-align: left;
        background-color:whitesmoke;
    }
    .detail-class{
         display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-radius: 5px;
        padding: 5px;
        color: #656565;
        font-size: 14px;
        background-color: white;
    }
    .detail-class >div{
        margin-left: 20px;
        width: 300px;
        margin-top: 10px;
    }
    .detail-class >div >span{
        margin-left: 10px;
        color: black;
    }
    .detail-class >div:last-child{
        margin-left: 20px;
        width: 600px;
        margin-top: 10px;
    }
    .detail-class >div{
        margin-left: 20px;
    }
    table, td, tr, th {
        border-bottom:solid #b2b2b2;
        border-width: 1px;
        font-size: x-small;
        word-break: break-all;
        text-align: center;
    }
    table {
        border-collapse: collapse;
        width: 100%;
        border-radius: 25px;
    }
    th{
        font-weight: normal;
    }
    .sth{
        display: flex;
        text-align: center;
        font-size: 12px;
        background-color: white;
    }
    .mtd{
        flex: 1;
        border: rgba(0, 0, 0, 0.21) solid 1px;
        word-break: break-all;
        border-left: none;
        border-top: none;
        align-items: center;
        justify-content:center;
        display: flex;
    }
    .sth> .mtd:first-child{
        border-left: rgba(0, 0, 0, 0.21) solid 1px;
    }
    .sth:nth-child(2n+1){
        background-color: rgba(29, 94, 211, 0.03);
    }
    /*.sth:first-child{*/
    /*    background-color: #00d8d8;*/
    /*    color: red;*/
    /*}*/
    /*::-webkit-scrollbar {*/
    /*    display:none*/
    /*}*/

    /deep/ .el-input--small .el-input__inner{
        color: red;
           }
</style>