<template>
    <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>商城管理</el-breadcrumb-item>
        <el-breadcrumb-item>微页面</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="background-color: #eeeeee;padding: 10px">
        <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
            <el-form :inline="true" :model="conditionForm" class="demo-form-inline">
                <el-form-item label="名称">
                    <el-input v-model="conditionForm.page_name" placeholder="名称" clearable />
                </el-form-item>
                <el-form-item label="创建日期">
                    <el-date-picker
                            v-model="conditionForm.dateValue"
                            type="daterange"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="起始日期"
                            end-placeholder="截至日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmitQuery">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="background-color: white;text-align: left;margin-top: 10px;padding-bottom: 10px;padding: 10px">
            <el-button type="success" style="padding: 10px" @click="toAdd">新增微页面</el-button>
        </div>
        <div style="margin-top: 10px;background-color: white">
            <table>
                <thead>
                    <th>iD</th>
                    <th>名称</th>
                    <th>备注</th>
                    <th>创建日期</th>
                    <th>操作</th>
                </thead>
                <tbody>
                    <tr v-for="it in pages">
                        <td>{{it.id}}</td>
                        <td>{{it.name}}</td>
                        <td>{{it.note}}</td>
                        <td>{{it.create_time}}</td>
                        <td>
                            <span @click="toEdit(it)">编辑</span>
                            <span @click="viewQr(it)">预览</span>
                            <span @click="setHomePage(it.id)" v-if="it.home_page==0">设为首页</span>
                            <span style="color: red">删除</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <el-dialog v-model="showAddDialog" title="新增微页面" width="500">
            <el-form :model="form">
                <el-form-item label="页面名称" :label-width="100">
                    <el-input v-model="addform.name" autocomplete="off" />
                </el-form-item>
                <el-form-item label="备注" :label-width="100">
                    <el-input type="textarea" v-model="addform.note" autocomplete="off" />
                </el-form-item>
            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="showAddDialog = false">取消</el-button>
                <el-button type="primary" @click="submitAdd">
                  新增
                </el-button>
              </span>
            </template>
        </el-dialog>
        <el-dialog v-model="showQr" style="width: 500px;height: 500px">
            <div v-loading="showQrLoading" style="width: 100%;height: 100%">
                <img :src="qrUrl" style="width: 400px;height: 400px"/>
                <div style="text-align: right">
                    <span @click="rebuildQr" style="color: blue;cursor: pointer">刷新</span>
                </div>
            </div>
        </el-dialog>
        <el-pagination
                v-model:current-page="pageNum"
                v-model:page-size="pageSize"
                :page-sizes="[16,100, 200, 300, 400]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNum"
                :pager-count="15"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import {ElMessageBox} from "element-plus";

    export default {
        name: "WePageList",
        data(){
            return{
                showAddDialog: false,
                conditionForm:{
                    page_name:'',
                    page_id:'',
                    dateValue:null
                },
                addform:{
                    name:'',
                    note:''
                },
                pages:[],
                showQr:false,
                showQrLoading:false,
                qrUrl:'',
                totalNum:0,
                pageSize: 10,
                pageNum: 1,

            }
        },
        methods:{
            onSubmitQuery(){
                var date=''
                // console.log(this.conditionForm.dateValue[0])
                if (this.conditionForm.dateValue!=null){
                    console.log(this.conditionForm.dateValue[0])
                    date=this.conditionForm.dateValue[0]+'&jsrq='+this.conditionForm.dateValue[1]
                }
                console.log(date)
                getRequest('wepage/getWePageList?name=' + this.conditionForm.page_name +'&ksrq='+date +'&pageNum='+this.pageNum +'&pageSize='+this.pageSize).then(res=>{
                    if (res.data.flag){
                        this.pages=res.data.data.list
                        this.totalNum=res.data.data.total
                        console.log(this.pages)
                    }else {
                        ElMessageBox.alert(res.data.message)
                    }

                })
            },
            toAdd(){
                this.addform={
                    name:'',
                    note:''
                }
                this.showAddDialog = true
            },
            submitAdd(){
                if (this.addform.name.length<1){
                    this.$alert('请输入名称!')
                    return
                }
                postRequest('wepage/addWePage',this.addform).then(res=>{
                    if (res.data.flag){
                        this.onSubmitQuery()
                        this.showAddDialog = false
                        this.$alert(res.data.message)
                    }else {
                        ElMessageBox.alert(res.data.message)
                    }
                })
            },
            toEdit(it){
                this.$router.push('/WePageEdit/'+ it.id)
            },
            setHomePage(id){
                getRequest('wepage/setHomePage?id=' + id).then(res=>{
                    this.onSubmitQuery()
                    this.$alert(res.data.message)
                })
            },
            viewQr(it){
                console.log(it)
                this.qrUrl=it.qr_url
                this.qrId=it.id
                this.showQr=true
            },
            rebuildQr(){
                this.showQrLoading=true
                getRequest('wepage/rebuildWePageQR?id='+this.qrId).then(res=>{
                    this.showQrLoading=false
                    console.log(res)
                    this.qrUrl=res.data.data
                })
            },
            handleSizeChange(){
                this.pageNum = 1
                this.onSubmitQuery()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.onSubmitQuery()
            },
        },
        mounted() {
            this.onSubmitQuery()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>