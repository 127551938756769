<template>
    <div v-if="modelValue" class="region-select-class">
        <h1>城市选择</h1>
        <div style="border-bottom: whitesmoke solid 1px;margin-top: 10px;margin-bottom: 10px;"></div>
<!--        <div>已选城市数量:1</div>-->
        <div style="display: flex;flex-direction: row;height: 500px;">
            <div style="flex: 1;overflow: scroll;border: whitesmoke solid 1px;padding-left: 50px">
                <div v-on:click="pRowSelect(index)" v-for="(it,index) in provinceList" style="cursor:pointer;display: flex;flex-direction: row;align-items: center" :style="{backgroundColor: pSelectIndex==index?'whitesmoke':''}">
                    <el-checkbox :disabled="it.disabled" @change="checked=>pClick(checked,index)" :indeterminate="!it.allChecked && it.checkedList.length>0" v-model="it.checked" size="large" style="width: 10px">{{it.region_name}}</el-checkbox>
                    <el-icon style="margin-left: auto"><ArrowRight /></el-icon>
                </div>
            </div>
            <div v-loading="showLoading" style="flex: 1;overflow: scroll;border: whitesmoke solid 1px;padding-left: 50px">
                <div  v-for="(it,index) in cityList" style="text-align: left;margin-left: 20px">
                    <el-checkbox :disabled="it.disabled" @change="tClick" v-model="it.checked" :label="it.region_name"  size="large" style="width: 10px"/>
                </div>
            </div>
        </div>
        <div style="padding: 5px;text-align: right">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" style="margin-right: 20px;margin-left: 20px" @click="confirm">确定</el-button>
        </div>
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";

    export default {
        name: "region-select-com",
        emits: ['confirm','update:modelValue'],
        props:['modelValue','disabledRegion','selectedRegion'],
        data(){
            return{
                pCheckList:[],
                tCheckList:[],
                showLoading:false,
                provinceList:[],
                cityList:[],
                pSelectIndex:-1
            }
        },
        methods:{
            confirm(){
                var temp=[]
                for (var i=0;i<this.provinceList.length;i++){
                    if (this.provinceList[i].checkedList.length>0){
                        temp.push(JSON.parse(JSON.stringify(this.provinceList[i])))
                    }
                }
                if (temp.length>0){
                    this.$emit('confirm',temp)
                }
                this.$emit('update:modelValue',false)
            },
            cancel(){
                this.$emit('update:modelValue',false)
            },
            pRowSelect(id){
                if (id!=this.pSelectIndex){
                    this.getCityList(id)
                }
            },
            pClick(e,e2){
              if (e){
                  this.provinceList[e2].allChecked=true
                  if (e2==this.pSelectIndex){
                      this.provinceList[e2].checkedList=this.cityList
                      for (var i=0;i<this.cityList.length;i++){
                          this.cityList[i].checked=true
                      }
                  }
              }else {
                  this.provinceList[e2].allChecked=false
                  this.provinceList[e2].checkedList=[]
                  for (var i=0;i<this.cityList.length;i++){
                      this.cityList[i].checked=false
                  }
              }
              // console.log(this.provinceList)
            },
            tClick(e){
                this.provinceList[this.pSelectIndex].checkedList=[]
                for (var i=0;i<this.cityList.length;i++){
                    if (this.cityList[i].checked){
                        this.provinceList[this.pSelectIndex].checkedList.push(this.cityList[i])
                    }
                }
                // console.log(this.provinceList)
            },
            getCityList(id){
                this.cityList=[]
                this.showLoading=true
                getRequest('common/getCityAndCountyByProvince?id='+this.provinceList[id].region_id).then(res=>{
                    this.cityList=res.data.data
                    this.pSelectIndex=id
                    if (this.provinceList[id].allChecked){
                        this.provinceList[this.pSelectIndex].checkedList=res.data.data
                        for (var i=0;i<this.cityList.length;i++){
                            this.cityList[i].checked=true
                        }
                    }else {
                        for (var p=0;p<this.provinceList[this.pSelectIndex].checkedList.length;p++){
                            for (var i=0;i<this.cityList.length;i++){
                                if (this.cityList[i].region_id==this.provinceList[this.pSelectIndex].checkedList[p].region_id){
                                    this.cityList[i].checked=true
                                }
                            }
                        }
                    }
                    for (var i=0;i<this.cityList.length;i++){
                        for (var s=0;s<this.disabledRegion.length;s++){
                            if (this.cityList[i].region_id==this.disabledRegion[s]){
                                this.cityList[i].disabled=true
                            }
                        }
                    }
                    // console.log(this.cityList)
                    this.showLoading=false
                    // this.provinceList[id].
                })
            }
        },
        mounted() {
            getRequest('common/getProvince').then(res=>{
                this.provinceList=res.data.data
                for (var i=0;i<this.provinceList.length;i++){
                    for (var d=0;d<this.disabledRegion.length;d++){
                        if (this.provinceList[i].region_id==this.disabledRegion[d]){
                            this.provinceList[i].disabled=true
                        }
                    }
                    this.provinceList[i].checkedList=[]
                    // console.log(this.selectedRegion)
                    for (var s=0;s<this.selectedRegion.length;s++){
                        if (this.provinceList[i].region_id==this.selectedRegion[s].region_id && this.selectedRegion[s].checkedList.length>0){
                            this.provinceList[i].checked=true
                            this.provinceList[i].allChecked=false
                            for (var t=0;t<this.selectedRegion[s].checkedList.length;t++){
                                this.provinceList[i].checkedList.push(
                                    {
                                        region_id:this.selectedRegion[s].checkedList[t].region_id,
                                    }
                                )
                            }
                        }
                    }
                }
            })
        }
    }
</script>

<style scoped>
    .region-select-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 700px;
        width: 800px;
        background-color: white;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>