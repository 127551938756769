<template>
    <div v-if="modelValue" class="overlay-class">
        <div>会员等级选择</div>
        <div style="height: 500px;overflow: scroll" v-loading="showLoading">
            <el-checkbox-group v-model="checkList" @change="selectChange">
                <table>
                    <thead>
                    <th>等级代码</th>
                    <th>等级名称</th>
                    </thead>
                    <tbody>
                    <tr v-for="(it,index) in list">
                        <td><el-checkbox :label="it.level_code"></el-checkbox></td>
                        <td>{{it.level_name}}</td>
                    </tr>
                    </tbody>
                </table>
            </el-checkbox-group>

        </div>
        <div style="padding: 5px;text-align: right">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" style="margin-right: 20px;margin-left: 20px" @click="confirm">确定</el-button>
        </div>
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";

    export default {
        name: "vip-level-select",
        emits: ['confirm','update:modelValue'],
        props:{
            multipleSelect: {
                type:Boolean,
                defalut:false
            },
            modelValue:Boolean
        },
        data(){
            return{
                checkList:[],
                selectedCode:'',
                list:[],
                showLoading:false
            }
        },
        methods:{
            cancel(){
                this.$emit('update:modelValue',false)
            },
            confirm(){
                var t=[]
                console.log(this.checkList)
                for (var s=0;s<this.list.length;s++){
                    for (var i=0;i<this.checkList.length;i++){
                        if (this.list[s].level_code==this.checkList[i]){
                            t.push(JSON.parse(JSON.stringify(this.list[s])))
                        }
                    }
                }
                if (t.length>0){
                    this.$emit('confirm',t)
                }
                this.$emit('update:modelValue',false)
            },
            selectChange(e){
                if (!this.multipleSelect){
                    var t=[]
                    for (var i=0;i<this.checkList.length;i++){
                        if (this.checkList[i]!=this.selectedCode){
                            t.push(this.checkList[i])
                            this.selectedCode=this.checkList[i]
                            break
                        }
                    }
                    this.checkList=t
                }
            }
        },
        mounted() {
            this.showLoading=true
            getRequest('vip/getVipLevelList').then(res=>{
                this.showLoading=false
                this.list=res.data.data
            })
        }
    }
</script>

<style scoped>
    .overlay-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 600px;
        width: 400px;
        background-color: white;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
    }
    table, td, tr, th {
        font-size: small;
        line-height: 30px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        padding: 10px;
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>