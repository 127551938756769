
import {createStore} from "vuex";
import user from './modules/user'

const store = createStore({
  state() {
    return{
    }
  },
  modules: {
    user
  },
  getters: {
    token: state => state.user.token,
    userid: state => state.user.userId,
    username: state => state.user.username,
    password: state => state.user.password,
    permission: state => state.user.permission,
    logined: state => state.user.logined,
    tabActiv: state => state.tabActiv,
    addCartSpdm: state => state.addCartSpdm()
  },
  mutations: {
    SET_TabActiv (state, tabName) {
      state.tabActiv = tabName
    },
    SET_addCartSpdm (state, spdm) {
      state.addCartSpdm = spdm
    }
  }
})

export default store
