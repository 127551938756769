<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>定时任务管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
            <el-form :inline="true" :model="conditionForm" class="demo-form-inline">
                <el-form-item label="名称">
                    <el-input v-model="conditionForm.task_name" placeholder="名称" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmitQuery">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="background-color: white;text-align: left;margin-top: 0px;padding-bottom: 10px;padding: 10px">
            <el-button type="success" style="padding: 10px" @click="toAdd">新增任务</el-button>
        </div>
        <div style="height: 600px;overflow: scroll">
            <table>
                <thead>
                <th>ID</th>
                <th>名称</th>
                <th>时间间隔</th>
                <th>运行状态</th>
                <th>最后一次运行时间</th>
                <th>操作</th>
                </thead>
                <tbody>
                <tr v-for="(it,index) in dataList">
                    <td>{{it.tid}}</td>
                    <td>{{it.task_name}}</td>
                    <td>{{it.cron}}</td>
                    <td>{{it.run==1?'运行中':'已暂停'}}</td>
                    <td>{{it.last_run_time}}</td>
                    <td>
                        <span @click="toEdit(it)">编辑</span>
                        <span v-if="it.run==0" @click="start(it)">启动</span>
                        <span v-if="it.run==1" @click="stop(it)">停止</span>
                        <span @click="run(it)">立即执行</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <el-dialog
                v-model="showDialog"
                width="600px"
        >
            <el-form :model="editData">
                <el-form-item label="任务代码" label-width="100">
                    <el-input v-model="editData.task_code" autocomplete="off" :disabled="readonly"/>
                </el-form-item>
                <el-form-item label="任务组" label-width="100">
                    <el-input v-model="editData.job_group" autocomplete="off" :disabled="readonly"/>
                </el-form-item>
                <el-form-item label="名称" label-width="100">
                    <el-input v-model="editData.task_name" autocomplete="off" :disabled="readonly"/>
                </el-form-item>
                <el-form-item label="类名" label-width="100">
                    <el-input v-model="editData.job_class" autocomplete="off" :disabled="readonly"/>
                </el-form-item>
                <el-form-item label="时间间隔" label-width="100">
                    <el-input v-model="editData.cron" autocomplete="off" :readonly="readonly">
                        <template #append>
                            <span @click="tosetcron" style="cursor: pointer">设置</span>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="showDialog = false">取消</el-button>
                <el-button type="primary" @click="saveEdit">
                  确认
                </el-button>
              </span>
            </template>
        </el-dialog>
        <el-dialog v-model="showCron"
                   top="10px">
            <vcrontab :expression="editData.cron" v-if="showCron" @hide="showCron=false" @fill="cronConfirm"></vcrontab>
        </el-dialog>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import Vcrontab from "../Crontab/vcrontab";
    export default {
        name: "ScheduleManage",
        components: {Vcrontab},
        data(){
            return{
                conditionForm:{},
                dataList:[],
                showDialog:false,
                editData:{},
                cronPopover:true,
                showCron:false,
                readonly:true,
                defData:{
                    task_name:'',
                    task_code:'',
                    job_class:'',
                    job_group:'',
                    cron:'',
                }
            }
        },
        methods:{
            onSubmitQuery(){
                postRequest("quartz/getScheduleList",this.conditionForm).then(res=>{
                    this.dataList=res.data.data
                })
            },
            toEdit(it){
                this.readonly=true
                this.editData=it
                this.showDialog=true
            },
            start(it){
                postRequest('quartz/startJob',it).then(res=>{
                    this.$alert(res.data.message)
                    this.onSubmitQuery()
                })
            },
            stop(it){
                postRequest('quartz/stopJob',it).then(res=>{
                    this.$alert(res.data.message)
                    this.onSubmitQuery()
                })
            },
            run(it){
                postRequest('quartz/runJob',it).then(res=>{
                    this.$alert(res.data.message)
                    this.onSubmitQuery()
                })
            },
            toAdd(){
                this.readonly=false
                this.editData=JSON.parse(JSON.stringify(this.defData))
                this.showDialog=true
            },
            saveEdit(){
                if (this.readonly){
                    getRequest('quartz/updateJob?job_name='+this.editData.task_code +'&job_group='+this.editData.job_group+'&job_time='+this.editData.cron).then(res=>{
                        if (res.data.flag){
                            this.showDialog=false
                        }
                        this.$alert(res.data.message)
                    })
                }else {
                    postRequest('quartz/addSchedule',this.editData).then(res=>{
                        this.onSubmitQuery()
                        this.$alert(res.data.message)
                        this.showDialog=false
                    })
                }
            },
            changeCron(e){
                console.log(e)
            },
            tosetcron(){
                this.showCron=true
            },
            cronConfirm(e){
                console.log(e)
                this.editData.cron=e
            }
        },
        mounted() {
            this.onSubmitQuery()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 20px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 20px;
        color: blue;
        cursor: pointer;
    }
</style>