<template>
    <div v-loading="showLoadingGoodsInfo" style="background-color: #e5e5e5;padding: 10px;">
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
            <span style="margin-right: auto">商品编辑:<span style="color: blue">{{goodsInfo.goods_code}}</span></span>
            <el-button type="primary" style="width: 90px" @click="saveGoodsEdit">保存</el-button>
            <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
        </div>
        <div style="height: 700px;overflow: scroll">
            <div  style="background-color: white;margin-top: 10px">
                <div style="height: 10px"></div>
                <div class="label-input-class">
                    <span>商品代码:</span>
                    <span style="width: 500px">
                    <el-input v-model="goodsInfo.goods_code" disabled></el-input>
                </span>
                </div>
                <div class="label-input-class">
                    <span>商品原名:</span>
                    <span style="width: 500px">
                    <el-input v-model="goodsInfo.source_name" disabled></el-input>
                </span>
                </div>
                <div class="label-input-class">
                    <span>商品分组:</span>
                    <span style="width: 500px">
                        <div style="border: #f4f4f4 solid 1px;height: 30px;border-radius: 3px;line-height: 35px;text-align: left;display: flex;align-items: center">
                            <span style="flex: 1;">{{goodsInfo.class_level1_name}} →  {{goodsInfo.class_level2_name}}</span>
                            <el-icon @click="toShowGoodsGroupSelect"><ArrowDown/></el-icon>
                        </div>
                    </span>
                    <GoodsGroupSelectCom v-model="showGoodsGroupSelect" @confirm="goodsGroupSelectConfirm"></GoodsGroupSelectCom>
                </div>
                <div class="label-input-class">
                    <span>商品名称:</span>
                    <span style="width: 500px">
                    <el-input v-model="goodsInfo.goods_name"></el-input>
                </span>
                </div>
                <div class="label-input-class">
                    <span>安全库存:</span>
                    <span style="width: 500px">
                    <el-input v-model="goodsInfo.safety_stock"></el-input>
                </span>
                </div>
                <div class="label-input-class">
                    <span>运费模板:</span>
                    <el-select v-model="goodsInfo.freight_template" style="width: 500px;">
                        <el-option v-for="(it,index) in templateList" :label="it.name" :value="it.id" />
                    </el-select>
                </div>
                <div class="label-input-class">
                    <span>上架时间:</span>
                    <el-date-picker
                            v-model="goodsInfo.listing_date"
                            type="date"
                            placeholder="选择日期"
                            :shortcuts="shortcuts"
                            value-format="YYYY-MM-DD"
                    />
                </div>
                <div class="label-input-class">
                    <span>商品图片:</span>
                    <media-material-com v-model="showImgSelect" @confirm="imgConfirmHandler"></media-material-com>
                    <img style="width: 100px;height: 100px" :src="goodsInfo.pic_url"/>
                    <el-button  icon="plus" style="height: 100px" @click="toshowImgSelect">设置主图</el-button>
                </div>
                <div class="label-input-class">
                    <span>原价:</span>
                    <span style="width: 500px;">
                        <div style="color: blue;border-radius: 3px;text-align: left;line-height: 32px">{{goodsInfo.price}}</div>
                    </span>
                </div>
                <div class="label-input-class">
                    <span>售价:</span>
                    <div>
                        <el-radio-group v-model="real_price_type" style="text-align: left;flex-direction: column">
                            <el-radio  label="1" style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                                折扣
                                <span style="width: 500px">
                                    <el-input v-model="goodsInfo.discount" min="0" @change="discountChange"></el-input>
                                </span>
                            </el-radio>
                            <el-radio  label="2" style="width: 100%;padding: 0;margin: 0">
                                价格
                                  <span style="width: 500px">
                                    <el-input v-model="goodsInfo.real_price" type="number" @change="realPriceChange"></el-input>
                                </span>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="label-input-class">
                    <span>金额位数:</span>
                    <el-select v-model="jews">
                        <el-option label="保留2位" value="2" />
                        <el-option label="保留1位" value="1" />
                        <el-option label="保留整数" value="0" />
                    </el-select>
                </div>
                <div class="label-input-class">
                    <span>优惠券范围:</span>
                    <el-select v-model="goodsInfo.coupon_type" change="changex">
                        <el-option label="所有优惠券" :value='2' />
                        <el-option label="部分优惠券" :value='1' />
                        <el-option label="不能使用优惠券" :value='0' />
                    </el-select>
                </div>
                <coupon-group-select v-model="showCouponSelect" multiple-select="true" @confirm="couponSelectConfirm"></coupon-group-select>
                <div class="label-input-class" v-if="goodsInfo.coupon_type=='1'">
                    <span>优惠券</span>
                    <el-button type="primary" @click="toSelectCoupon">选择优惠券</el-button>
                </div>
                <div v-if="goodsInfo.coupon_type=='1'"  style="width: 600px;text-align: left;margin-left: 100px;margin-top: 10px">
                    <div >
                        <table>
                            <thead>
                                <th>优惠券编号</th>
                                <th>优惠券名称</th>
                                <th>优惠券类型</th>
                                <th>编辑</th>
                            </thead>
                            <tr v-for="it in goodsInfo.coupon_list">
                                <td>{{it.id}}</td>
                                <td>{{it.name}}</td>
                                <td>{{it.coupon_type_name}}</td>
                                <td><span style="color: blue;cursor: pointer" @click="deleteCoupon(it.id)">删除</span></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="label-input-class">
                    <span>发货方式:</span>
                    <span style="width: 200px">
                        <el-checkbox v-model="goodsInfo.shipping_type_express" label="快递发货" size="large" />
                        <el-checkbox v-model="goodsInfo.shipping_type_selfpickup" label="门店自提" size="large" />
                    </span>
                </div>
                <div style="height: 50px"></div>
            </div>
            <div  style="text-align: left;background-color: white;margin-top: 10px">
                <div>规格详情</div>
                <div style="padding: 20px">
                    <table>
                        <thead>
                        <th>上架</th>
                        <th>SKU</th>
                        <th>颜色</th>
                        <th>尺码</th>
                        <th>原价</th>
                        <th>折扣%</th>
                        <th>售价</th>
                        <th>库存</th>
                        </thead>
                        <tr v-for="(it,index) in goodsInfo.skus">
                            <td><el-checkbox v-model="it.listing_flag" size="large" /></td>
                            <td>{{it.sku}}</td>
                            <td>{{it.attribute1_name}}</td>
                            <td>{{it.attribute2_name}}</td>
                            <td style="width: 150px"><el-input v-model="it.price"></el-input></td>
                            <td style="width: 150px"><el-input v-model="it.discount" @change="((val)=>{rowDiscountChange(val, index)})"></el-input></td>
                            <td style="width: 150px"><el-input v-model="it.real_price" @change="((val)=>{rowRealPriceChange(val, index)})"></el-input></td>
                            <td>{{it.stock}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div  style="text-align: left;background-color: white;margin-top: 10px">
                <div style="padding: 10px">图片信息</div>
                <div style="padding: 20px">
                    <table>
                        <thead>
                        <th>颜色</th>
                        <th>图片</th>
                        <th>操作</th>
                        </thead>
                        <tr v-for="(it,index) in goodsInfo.skc_pic_list">
                            <td style="width: 200px;">
                                <el-select v-model="it.code">
                                    <el-option v-for="it2 in goodsInfo.skc_list" :label="it2.name" :value="it2.code" />
                                </el-select>
                            </td>
                            <td style="flex: 1;display: flex;flex-direction: row;">
                                <div v-for="(x,index2) in it.pics" style="margin-left: 5px;position: relative">
                                    <el-icon style="color: red;position: absolute;top: 0;right: 0" @click="deleteSkcPic(index,index2)"><Close /></el-icon>
                                    <img style="width: 60px;height: 60px" :src="x.url">
                                    <div v-if="x.file_id!=goodsInfo.pic_id" style="cursor: pointer;position: absolute;bottom: 0;color: white;background-color: #acacac;font-size: 8px;width: 100%;height: 16px;line-height: 16px;padding: 0;text-align: center;margin: 0" @click="setMainPic(x)">设为主图</div>
                                    <div v-if="x.file_id==goodsInfo.pic_id" style="cursor: pointer;position: absolute;bottom: 0;color: white;background-color: #acacac;font-size: 8px;width: 100%;height: 16px;line-height: 16px;padding: 0;text-align: center;margin: 0">主图</div>
                                </div>
                                <el-button icon="plus" style="margin-left: 5px" @click="toshowSkcImgSelect(index)">添加图片</el-button>
                            </td>
                            <td style="width: 100px;"><span style="color: blue;cursor: pointer" @click="deleteSkcPicList(index)">删除</span></td>
                        </tr>
                    </table>
                    <el-button type="primary" size="small" style="margin-top: 10px" @click="addSkcPicRow">添加行</el-button>
                </div>
                <media-material-com v-model="showSkcImgSelect" @confirm="SkcImgSelectConfirm" multiple-select="true"></media-material-com>
            </div>
            <div style="margin-top: 10px;background-color: white;text-align: left">
                <div style="padding: 10px">商品详情</div>
                <div style="display: flex;padding: 10px">
                    <div style="width: 80px">广告词:</div>
                    <el-input v-model="goodsInfo.ad_text" placeholder="请输入广告词" type="textarea" show-word-limit maxlength="150" :autosize="{ minRows: 5}"></el-input>
                </div>
                <div style="margin-top: 50px"></div>
                <textarea id="ty"></textarea>
                <media-material-com  v-model="showEditImageSelect" multiple-select="false" @confirm="EditImageSelectConfirm"></media-material-com>
            </div>
        </div>
    </div>
</template>

<script>
    import MediaMaterialCom from "../common/media-material-com";
    import {getRequest, postRequest} from "../../utils/api";
    import CouponGroupSelect from "../common/coupon-group-select";
    import GoodsGroupSelectCom from "../common/GoodsGroupSelectCom";
    export default {
        name: "GoodsEdit",
        components: {GoodsGroupSelectCom, CouponGroupSelect, MediaMaterialCom},
        data(){
            return{
                goodsInfo:{
                    goods_code:'',
                    source_name:'',
                    goods_name:'',
                    price:'',
                    real_price:'',
                    safety_stock:'',
                    sorting:'',
                    class_level1:'',
                    class_level1_name:'',
                    class_level2:'',
                    class_level2_name:'',
                    freight_template:'',
                    discount:'',
                    listing_flag:'',
                    listing_date:'',
                    pic_id:'',
                    pic_url:'',
                    rich_text:'',
                    ad_text:'',
                    coupon_type:'',
                    coupon_list:[],
                    skus: []

                },
                couponList:[],
                jews: '2',
                coupon_type: '2',
                showEditImageSelect: false,
                showSkcImgSelect:false,
                showCouponSelect: false,
                showLoadingGoodsInfo:false,
                showGoodsGroupSelect:false,
                skc_pic_list_index:0,
                editor_: null,
                disabledDate: true,
                tinyvalue: '',
                goods_code: '',
                listing_type: '1',
                real_price_type:'1',
                listing_time: new Date(),
                mainImgUrl: '',
                mainImgId: '',
                showImgSelect: false,
                templateList:[],
                init: {
                    height: 400,
                },
                shortcuts: [
                    {
                        text: '今天',
                        value: new Date(),
                    },
                    {
                        text: '明天',
                        value: () => {
                            const date = new Date()
                            date.setTime(date.getTime() + 3600 * 1000 * 24)
                            return date
                        },
                    },
                    {
                        text: '一周后',
                        value: () => {
                            const date = new Date()
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
                            return date
                        },
                    },
                ]
            }
        },
        methods:{
            imgConfirmHandler(e){
                this.goodsInfo.pic_url = e[0].url
                this.goodsInfo.pic_id = e[0].file_id
            },
            toSelectCoupon(){
                this.showCouponSelect= true
            },
            couponSelectConfirm(e){
                for (var i=0;i<e.length;i++){
                    var ex=false
                    if (this.goodsInfo.coupon_list==null){
                        this.goodsInfo.coupon_list=[e[i]]
                    }else {
                        for (var x=0;x<this.goodsInfo.coupon_list.length;x++){
                            if (e[i].id==this.goodsInfo.coupon_list[x].id){
                                ex=true
                            }
                        }
                        if (!ex){
                                this.goodsInfo.coupon_list.push(e[i])
                        }
                    }


                }
                // this.couponList=e
            },
            deleteCoupon(id){
                var t=[]
                for (var x=0;x<this.goodsInfo.coupon_list.length;x++){
                    if (this.goodsInfo.coupon_list[x].id!=id){
                        t.push(this.goodsInfo.coupon_list[x])
                    }
                }
                this.goodsInfo.coupon_list = t
            },
            SkcImgSelectConfirm(e){
                console.log(e)
                for (var i=0;i<e.length;i++){
                    if (this.goodsInfo.skc_pic_list[this.skc_pic_list_index].pics==null){
                        this.goodsInfo.skc_pic_list[this.skc_pic_list_index].pics=[{'file_id':e[i].file_id,'url':e[i].url}]
                    }else {
                        this.goodsInfo.skc_pic_list[this.skc_pic_list_index].pics.push({'file_id':e[i].file_id,'url':e[i].url})
                    }

                }
            },
            toshowSkcImgSelect(id){
                this.skc_pic_list_index=id
                this.showSkcImgSelect = true
            },
            deleteSkcPic(id1,id2){
                var temp=[]
                console.log(this.goodsInfo.skc_pic_list[id1].pics)
                for (var i=0;i<this.goodsInfo.skc_pic_list[id1].pics.length;i++){
                    console.log(i,'-',id2)
                    if (i!=id2){
                        temp.push(this.goodsInfo.skc_pic_list[id1].pics[i])
                    }
                }
                this.goodsInfo.skc_pic_list[id1].pics=temp
            },
            deleteSkcPicList(id){
                var temp=[]
                for (var i=0;i<this.goodsInfo.skc_pic_list.length;i++){
                    if (i!=id){
                        temp.push(this.goodsInfo.skc_pic_list[i])
                    }
                }
                this.goodsInfo.skc_pic_list=temp
            },
            setMainPic(x){
                this.goodsInfo.pic_id=x.file_id
                this.goodsInfo.pic_url=x.url
            },
            toshowImgSelect(){
                this.showImgSelect = true
            },
            EditImageSelectConfirm(e){
                console.log(e)
                for (var i=0;i<e.length;i++){
                    this.editor_.insertContent('<p><img src="'+e[i].url+'"/></p>');
                }
            },
            saveGoodsEdit(){

                var tinymceValue= this.editor_.getContent();
                this.goodsInfo.rich_text=tinymceValue
                console.log(tinymceValue)
                console.log(this.goodsInfo)
                postRequest('goods/updateGoodsMall',this.goodsInfo).then(res=>{
                    this.getGoodsInfo()
                    this.$alert(res.data.message)
                })
            },
            backHome(){
                this.$router.back()
            },
            getGoodsInfo(){
                this.showLoadingGoodsInfo = true
                getRequest('goods/getGoodsInfoByCode?goodsCode=' + this.goods_code).then(res=>{
                    this.goodsInfo = res.data.data
                    console.log(this.goodsInfo)
                    if(this.goodsInfo.rich_text!=null){
                        this.editor_.setContent(this.goodsInfo.rich_text)
                    }
                    this.showLoadingGoodsInfo = false
                })
            },
            getSkuDiscount(it){
                return (it.real_price/it.price).toFixed(2)*100
            },
            addSkcPicRow(){
                if (this.goodsInfo.skc_pic_list==null){
                    this.goodsInfo.skc_pic_list=[{'code':'','name':'','pics':[]}]
                }else{
                    this.goodsInfo.skc_pic_list.push({'code':'','name':'','pics':[]})
                }

            },
            discountChange(e){
                if (this.real_price_type==1){
                    if (Number(this.jews)!=0){
                        e=Number(e)
                        if (e>100){
                            e=100
                        }
                        if (e<0){
                            e=0
                        }
                        var num = (e+0.000001)+'';
                        console.log(num)
                        var discount = num.substring(0,num.indexOf(".")+Number(this.jews)+1);
                        var realPriceStr=(discount*this.goodsInfo.price)/100+0.00001+''
                        var realPrice=realPriceStr.substring(0,realPriceStr.indexOf(".")+Number(this.jews)+1)
                        this.goodsInfo.real_price=realPrice
                        this.goodsInfo.discount=discount
                        for (var i=0;i<this.goodsInfo.skus.length;i++){
                            this.goodsInfo.skus[i].discount=discount
                            this.goodsInfo.skus[i].real_price=realPrice
                        }
                    }else {
                        e=Number(e)
                        if (e>100){
                            e=100
                        }
                        if (e<0){
                            e=0
                        }
                        var num = (e+0.000001)+'';
                        console.log(num)
                        var discount = num.substring(0,num.indexOf("."));
                        var realPriceStr=(discount*this.goodsInfo.price)/100+''
                        var realPrice=realPriceStr.substring(0,realPriceStr.indexOf("."))
                        this.goodsInfo.real_price=realPrice
                        this.goodsInfo.discount=discount
                        for (var i=0;i<this.goodsInfo.skus.length;i++){
                            this.goodsInfo.skus[i].discount=discount
                            this.goodsInfo.skus[i].real_price=realPrice
                        }
                    }

                }
            },
            realPriceChange(e){
                if (this.real_price_type==2){
                    e=Number(e)
                    if (e<0){
                        e=0
                    }
                    if (Number(this.jews)!=0){
                        var discount=1
                        if (this.goodsInfo.price>0){
                            var discountStr=e*100/this.goodsInfo.price+0.0001+''
                            var discount=discountStr.substring(0,discountStr.indexOf(".")+Number(this.jews)+1);
                        }
                        
                        var realPriceStr=e+0.0001+''
                        var realPrice=realPriceStr.substring(0,realPriceStr.indexOf(".")+Number(this.jews)+1)
                        this.goodsInfo.discount=discount
                        this.goodsInfo.real_price=realPrice
                        for (var i=0;i<this.goodsInfo.skus.length;i++){
                            this.goodsInfo.skus[i].discount=discount
                            this.goodsInfo.skus[i].real_price=realPrice
                        }
                        console.log(realPrice)
                    }else {
                        var discount=1
                        if (this.goodsInfo.price>0){
                            var discountStr=e*100/this.goodsInfo.price+0.0001+''
                            discount=discountStr.substring(0,discountStr.indexOf("."));
                        }
                        console.log(discount)
                        var realPriceStr=e+0.0001+''
                        var realPrice=realPriceStr.substring(0,realPriceStr.indexOf("."))
                        this.goodsInfo.discount=discount
                        this.goodsInfo.real_price=realPrice
                        for (var i=0;i<this.goodsInfo.skus.length;i++){
                            this.goodsInfo.skus[i].discount=discount
                            this.goodsInfo.skus[i].real_price=realPrice
                        }
                        console.log(realPrice)
                    }
                }
            },
            rowDiscountChange(e,i){
                if (Number(this.jews)!=0){
                    e=Number(e)
                    if (e>100){
                        e=100
                    }
                    if (e<0){
                        e=0
                    }
                    var num = (e+0.000001)+'';
                    console.log(num)
                    var discount = num.substring(0,num.indexOf(".")+Number(this.jews)+1);
                    var realPriceStr=(discount*this.goodsInfo.price)/100+0.00001+''
                    var realPrice=realPriceStr.substring(0,realPriceStr.indexOf(".")+Number(this.jews)+1)
                    this.goodsInfo.skus[i].discount=discount
                    this.goodsInfo.skus[i].real_price=realPrice
                }else {
                    e=Number(e)
                    if (e>100){
                        e=100
                    }
                    if (e<0){
                        e=0
                    }
                    var num = (e+0.000001)+'';
                    console.log(num)
                    var discount = num.substring(0,num.indexOf("."));
                    var realPriceStr=(discount*this.goodsInfo.price)/100+''
                    var realPrice=realPriceStr.substring(0,realPriceStr.indexOf("."))
                    this.goodsInfo.skus[i].discount=discount
                    this.goodsInfo.skus[i].real_price=realPrice
                }
            },
            rowRealPriceChange(e,i){
                e=Number(e)
                if (e<0){
                    e=0
                }
                if (Number(this.jews)!=0){
                    var discountStr=e*100/this.goodsInfo.price+0.0001+''
                    var discount=discountStr.substring(0,discountStr.indexOf(".")+Number(this.jews)+1);

                    var realPriceStr=e+0.0001+''
                    var realPrice=realPriceStr.substring(0,realPriceStr.indexOf(".")+Number(this.jews)+1)
                    this.goodsInfo.skus[i].discount=discount
                    this.goodsInfo.skus[i].real_price=realPrice
                }else {
                    var discountStr=e*100/this.goodsInfo.price+0.0001+''
                    var discount=discountStr.substring(0,discountStr.indexOf("."));

                    var realPriceStr=e+0.0001+''
                    var realPrice=realPriceStr.substring(0,realPriceStr.indexOf("."))
                    this.goodsInfo.skus[i].discount=discount
                    this.goodsInfo.skus[i].real_price=realPrice
                }
            },
            toShowGoodsGroupSelect(){
                this.showGoodsGroupSelect = true
            },
            goodsGroupSelectConfirm(e){
                this.goodsInfo.class_level1=e.parent_id
                this.goodsInfo.class_level1_name=e.parent_name

                this.goodsInfo.class_level2=e.group_id
                this.goodsInfo.class_level2_name=e.group_name

            },
            changex(){
                console.log(this.goodsInfo)
            },
            getFreightList(){
                getRequest('mall/getFreightList').then(res=>{
                    console.log(res)
                    this.templateList=res.data.data
                })
            },
        },
        mounted() {
            this.getFreightList()
            this.goods_code = this.$route.params.goodscode
            tinymce.remove('#ty')
            var that=this
            tinymce.init({
                selector:'#ty',
                language_url: '/tinymce/langs/zh_CN.js', //汉化路径是自定义的，一般放在public或static里面
                language: 'zh_CN',
                font_family_formats:"微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
                plugins: `accordion advlist anchor autolink autosave charmap code codesample directionality emoticons fullscreen help importcss insertdatetime link lists media nonbreaking pagebreak  preview save searchreplace table Template visualblocks visualchars`,
                toolbar: [`myImage undo redo aligncenter alignjustify alignleft alignnone charmap alignright blockquote backcolor italic bold copy cut blocks fontfamily fontsize fontsizeinput forecolor h1 h2 h3 h4 h5 h6 hr indent  language lineheight newdocument outdent  paste pastetext print remove  removeformat selectall strikethrough styles subscript superscript underline visualaid`],
                block_formats: '段落=p; 正文=div',
                branding: false,
                promotion: false,
                forced_root_block :'div',
                height: 650,
                setup: function (editor) {
                    that.editor_= editor
                    editor.ui.registry.addButton('myImage', {
                        text: '',
                        icon: 'image',
                        onAction: function (_) {
                            that.showEditImageSelect = true
                        }
                    });
                }
            })
            this.getGoodsInfo()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        font-size: small;
        line-height: 30px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        padding: 10px;
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    .label-input-class{
        display: flex;
        align-items: center;
        margin-top: 20px;
        text-align: left;
    }
    .label-input-class>span{
        width: 150px;
        margin-right: 30px;
        text-align: right;
    }
    tr:nth-child(1){
        background: #ffffff;
    }
    tr:nth-child(2n){
        background: #f7f9f9;
    }
    /deep/ input::-webkit-outer-spin-button,
    /deep/input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    /deep/ input[type="number"]{
        -moz-appearance: textfield;
    }
</style>