<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员资料</el-breadcrumb-item>
        </el-breadcrumb>
        <div>
            <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
                <el-form :inline="true" :model="conditionForm" class="demo-form-inline">
                    <el-form-item label="姓名">
                        <el-input v-model="conditionForm.name" placeholder="姓名" clearable />
                    </el-form-item>
                    <el-form-item label="会员代码">
                        <el-input v-model="conditionForm.vip_code" placeholder="会员代码" clearable />
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="conditionForm.mobile" placeholder="手机号" clearable />
                    </el-form-item>
                    <el-form-item label="会员等级">
                        <el-input readonly :model-value="getLevel()" placeholder="会员等级" clearable >
                            <template  #append>
                                <el-button @click="toSelecteVipLevel" icon="ArrowDown"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item  label="所属店铺">
                        <el-input readonly :model-value="getShop()" placeholder="所属店铺" clearable >
                            <template  #append>
                                <el-button @click="toSelecteShop" icon="ArrowDown"></el-button>
                            </template>
                        </el-input>

                    </el-form-item>
                    <el-form-item label="所属导购">
                        <el-input readonly :model-value="getGuider()" placeholder="所属导购" clearable>
                            <template  #append>
                                <el-button @click="toSelecteGuider" icon="ArrowDown"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="注册日期">
                        <el-date-picker
                                v-model="conditionForm.registration_data"
                                type="daterange"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                unlink-panels
                                range-separator="-"
                                start-placeholder="起始日期"
                                end-placeholder="截至日期"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button plain type="primary" @click="clearPm">清空</el-button>
                        <el-button type="primary" @click="onSubmitQuery">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div style="position: relative;">
            <div v-loading="showLoading" style="height: 600px;overflow: scroll;border-bottom: #bcbcbc solid 1px;margin-bottom: 20px;">
                <table>
                    <thead id="floatth">
                    <th class="mth">头像</th>
                    <th class="mth">会员代码</th>
                    <th class="mth">手机</th>
                    <th class="mth">姓名</th>
                    <th class="mth">昵称</th>
                    <th class="mth">等级</th>
                    <th class="mth">注册日期</th>
                    <th class="mth">所属门店</th>
                    <th class="mth">所属导购</th>
                    <th class="mth">明细</th>
                    </thead>
                    <thead id="sourceth">
                    <th class="mth">头像</th>
                    <th class="mth">会员代码</th>
                    <th class="mth">手机</th>
                    <th class="mth">姓名</th>
                    <th class="mth">昵称</th>
                    <th class="mth">等级</th>
                    <th class="mth">注册日期</th>
                    <th class="mth">所属门店</th>
                    <th class="mth">所属导购</th>
                    <th class="mth">明细</th>
                    </thead>
                    <tr v-for="(item,index) in tableData">
                        <td><el-image :src="item.avatar_url" style="width: 40px;height: 40px;border-radius: 100px"></el-image></td>
                        <td>{{item.vip_code}}</td>
                        <td>{{item.mobile}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.nickname}}</td>
                        <td>{{item.level_name}}</td>
                        <td>{{item.registration_date}}</td>
                        <td>{{item.shop_name}}</td>
                        <td>{{item.guider_name}}</td>
                        <td><span @click="toDetail(item.vip_code)" style="color: blue;cursor: pointer">查看</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <el-pagination
            v-model:current-page="pageNum"
            v-model:page-size="pageSize"
            :page-sizes="[10,50, 200, 500, 1000]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalNum"
            :pager-count="15"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
        <vip-level-select v-if="showVipLevelSelect" v-model="showVipLevelSelect" @confirm="vipLevelConfirm" :multiple-select="true"></vip-level-select>
        <shop-select-com v-if="showShopSelect" v-model="showShopSelect" @confirm="shopSelected" :multiple-select="true"></shop-select-com>
        <guider-select-com v-if="showGuiderSelect" v-model="showGuiderSelect" @confirm="guiderSelected" :multiple-select="true"></guider-select-com>
    </div>
</template>

<script>
    import VipLevelSelect from "../common/vip-level-select";
    import {getRequest, postRequest} from "../../utils/api";
    import ShopSelectCom from "../common/shop-select-com";
    import GuiderSelectCom from "../common/guider-select-com";
    export default {
        name: "VipList",
        components: {GuiderSelectCom, ShopSelectCom, VipLevelSelect},
        data(){
            return{
                conditionForm:{},
                tableData:[],
                showLoading:false,
                showVipLevelSelect:false,
                pageNum:1,
                pageSize:10,
                totalNum:0,
                showShopSelect:false,
                showGuiderSelect:false
            }
        },
        methods: {
            clearPm(){
                this.conditionForm={}
            },
            onSubmitQuery(){
                this.showLoading=true
                this.conditionForm['pageNum']=this.pageNum
                this.conditionForm['pageSize']=this.pageSize
                postRequest('vip/getVipList',this.conditionForm).then(res=>{
                    this.showLoading=false
                    this.tableData=res.data.data.list
                    this.totalNum=res.data.data.total
                    this.setWidth()
                })
            },
            setWidth(){
                if (this.$route.path=='/VipList'){
                    setTimeout(()=>{
                        var sh=  document.getElementById('sourceth').children
                        var fh=  document.getElementById('floatth').children
                        for (var i=0;i<sh.length;i++){
                            fh[i].style.width=sh[i].offsetWidth -1 + 'px';
                        }
                    },200)
                }


            },
            toSelecteVipLevel(){
                this.showVipLevelSelect=true
            },
            toSelecteShop(){
                this.showShopSelect=true
            },
            shopSelected(e){
              this.conditionForm.shops=e
            },
            toSelecteGuider(){
                this.showGuiderSelect=true
            },
            guiderSelected(e) {
                this.conditionForm.guiders=e
            },
            vipLevelConfirm(e){
                console.log(e)
                this.conditionForm.levels=e
            },
            getLevel(){
                var t=''
                if (this.conditionForm.levels!=null){
                    for (var i=0;i<this.conditionForm.levels.length;i++){
                        t=t+this.conditionForm.levels[i].level_code+"|"+this.conditionForm.levels[i].level_name+","
                    }
                }

                return t
            },
            getShop(){
                var t=''
                if (this.conditionForm.shops!=null){
                    for (var i=0;i<this.conditionForm.shops.length;i++){
                        t=t+this.conditionForm.shops[i].shop_code+"|"+this.conditionForm.shops[i].shop_name+","
                    }
                }

                return t
            },
            getGuider(){
                var t=''
                if (this.conditionForm.guiders!=null){
                    for (var i=0;i<this.conditionForm.guiders.length;i++){
                        t=t+this.conditionForm.guiders[i].guider_code+"|"+this.conditionForm.guiders[i].guider_name+","
                    }
                }

                return t
            },
            handleSizeChange(){
                this.pageNum = 1
                this.onSubmitQuery()
            },
            handleCurrentChange(e){
                this.pageNum = e
                this.onSubmitQuery()
            },
            toDetail(vip_code1){
               this.$router.push("/VipDetail/"+vip_code1)
             }

        },
        mounted() {
            this.setWidth()
            window.addEventListener('resize',this.setWidth)
            this.onSubmitQuery()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border: rgba(0, 254, 254, 0.05) solid 1px;
        font-size: small;
        line-height: 30px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    .mth:first-child{
        /*border-radius: 5px 0 0 0;*/
        border-left: #d8d8d8 solid 1px;
    }
    .mth:last-child{
        /*border-radius: 0 5px 0 0;*/
        border-left: none;
    }
    .mth{
        margin: 0;
        border-left: none;
        border: none;
    }
    td{
        border: none;
        border-bottom: #eeeeee solid 1px;
    }
    #floatth{
        position: absolute;
        top: 0px;
        margin: 0;
        background-color: rgb(226, 228, 238);
        opacity: 1;
        /*border-radius: 5px;*/
        line-height: 40px;
        border: none;
        z-index: 100;
    }
    #sourceth{
        margin: 0;
        border: none;
    }
    span:hover{
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
    tr:nth-child(1){
        background: #ffffff;
    }
    tr:nth-child(2n){
        background: #f7f9f9;
    }
</style>