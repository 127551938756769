<template>
    <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>商城管理</el-breadcrumb-item>
    <el-breadcrumb-item>底部导航</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="display: flex;flex-direction: row;justify-content: right;border: #eeeeee solid 1px;padding: 10px">
        <el-button>返回</el-button>
        <el-button type="primary" @click="updateTabBar">保存</el-button>
    </div>
    <div style="display: flex;flex-direction: row;align-content: flex-start;margin-top: 10px">
        <div id="preview-box" class="preview-box">
            <div>
                <el-image :src="getPhoneTop()"></el-image>
                <div style="width: 100%;height: 35px;background-color: white"></div>
            </div>
            <div class="tab-bar-class">
                <div v-for="(it,index) in tabData.list" style="flex: 1;font-size: 12px" @click="selectChange(index)">
<!--                    <span :class="'iconfont '+it.icon" :style="index==active?'color:'+tabData.selectedColor:tabData.color" style="font-size: 25px"/>-->
                    <img v-if="index!=active" :src="it.icon" style="height: 20px;width: 20px">
                    <img v-if="index==active" :src="it.iconSelect" style="height: 20px;width: 20px">
                    <div :style="index==active?'color:'+tabData.selectedColor:tabData.color">{{it.text}}</div>
                </div>
            </div>
        </div>
        <div style="flex: 1;margin-left: 30px">
            <div style="text-align: left;border: #eeeeee solid 1px">
                <div>
<!--                    <span>选中时颜色</span>-->
<!--                    <el-color-picker color-format="hex"  v-model="tabData.selectedColor" style="margin-left: 20px"></el-color-picker>-->
<!--                    <span style="margin-left: 30px">未中时颜色</span>-->
<!--                    <el-color-picker color-format="hex" v-model="tabData.color" style="margin-left: 20px"></el-color-picker>-->
                </div>
            </div>
            <div style="display: flex;flex-direction: row;flex-wrap: wrap;border: #eeeeee solid 1px;margin-top: 20px;border-radius: 5px">
                <div v-for="(it,index) in tabData.list" style="width: 40%;margin:1%;border: #bcbcbc solid 1px;padding: 15px;border-radius: 10px">
                    <div style="display: flex;flex-direction: row">
                        <span style="width: 100px">名称</span>
                        <el-input style="width: auto" v-model="it.text"></el-input>
                    </div>
                    <div style="display: flex;flex-direction: row">
                        <span style="width: 100px">路径</span>
                        <el-input readonly style="width: auto" v-model="it.pagePath"></el-input>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-top: 15px;">
                            <div style="display: flex;">
                                <span style="width: 100px">选中图标</span>
                                <img :src="it.iconSelect" style="width:20px;height: 20px"/>
                                <el-button @click="toSelectIcon(index,'select')" style="margin-left: 15px">修改图标</el-button>
                            </div>
                            <div style="display: flex;">
                                <span style="width: 100px">未选图标</span>
                                <img :src="it.icon" style="width:20px;height: 20px"/>
                                <el-button @click="toSelectIcon(index,'')" style="margin-left: 15px">修改图标</el-button>
                            </div>

                    </div>
                </div>
            </div>
        </div>
        <media-material-com v-model="showIconSelect" @confirm="selectConfirm"></media-material-com>
<!--        <el-dialog  v-model="showIconSelect" title="选择图标" width="600px" height="400px">-->
<!--            <div style="display: flex;flex-wrap: wrap">-->
<!--                <div @click="iconSelected(index)" v-for="(it,index) in iconlist" :class="'iconfont ' + it" style="color: black;margin-right: 20px;font-size: 40px;cursor: pointer">-->

<!--                </div>-->
<!--            </div>-->
<!--        </el-dialog>-->
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import MediaMaterialCom from "../common/media-material-com";

    export default {
        name: "TabBarManage",
        components: {MediaMaterialCom},
        data(){
            return{
                selectIf:'',
                selectIconIndex:0,
                showIconSelect:false,
                iconlist:['mmx-iconhome1','mmx-iconsearch1','mmx-icongouwu','mmx-iconbasesalescardPackage','mmx-iconwode'],
                active:0,
                tabData:{
                    selectedColor: '#7ADED8',
                    color:'#000000',
                    list: [
                        {
                            pagePath: "/pages/home/home",
                            icon:"",
                            iconSelect:"",
                            text: "首页"
                        },
                        {
                            pagePath: "/pages/catgory/home",
                            icon:"",
                            iconSelect:"",
                            text: "分类"
                        },
                        {
                            icon:"",
                            iconSelect:"",
                            pagePath: "/pages/integral/home",
                            text: "积分"
                        },
                        {
                            icon:"",
                            iconSelect:"",
                            pagePath: "/pages/cart/cart",
                            text: "购物车"
                        },
                        {
                            icon:"",
                            iconSelect:"",
                            pagePath: "/pages/user/home",
                            "text": "我的"
                        }
                    ]
                },
            }
        },
        methods:{
            getPhoneTop(){
                return require('@/assets/image/phoneTop.png')
            },
            selectChange(id){
                console.log(id)
               this.active=id
            },
            updateTabBar(){
                var pm={
                    selectedColor: this.tabData.selectedColor,
                    color: this.tabData.color,
                    list: this.tabData.list,
                    id:1
                }
                postRequest('wepage/updateTabBar',pm).then(res=>{
                    this.$alert(res.data.message)
                })
            },
            get(){
                getRequest('wepage/getTabBar').then(res=>{
                    this.tabData=res.data.data
                })
            },
            toSelectIcon(i,s){
                this.selectIconIndex=i
                this.showIconSelect=true
                this.selectIf=s
            },
            iconSelected(i){
                console.log( this.tabData)
                this.tabData.list[this.selectIconIndex].icon=this.iconlist[i]
                this.showIconSelect=false
            },
            selectConfirm(e){
                console.log(e)
                if (this.selectIf=='select'){
                    this.tabData.list[this.selectIconIndex].iconSelect=e[0].url
                    this.showIconSelect=false
                }else {
                    this.tabData.list[this.selectIconIndex].icon=e[0].url
                    this.showIconSelect=false
                }
            }
        },
        mounted() {
            this.get()
        }
    }
</script>

<style scoped>
    .preview-box{
        height:600px;
        flex: 1;
        margin: auto;
        margin-top: 0px;
        max-width: 370px;
        position: relative;
        border: #bcbcbc solid 1px;
        background-color: white;
        background-color: white;
    }
    .tab-bar-class{
        display: flex;
        flex-direction: row;
        border-top: #bcbcbc solid 1px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background:white;
    }
</style>