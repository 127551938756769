<template>
    <div>
        <div class="commdity-list" v-if="comData.commdity !==null">
            <commodity-card-item1 :commdity="comData.commdity"></commodity-card-item1>
<!--            <commodity-card-item2 v-if="comData.commdity.showType === 2" :commdity="comData.commdity" ></commodity-card-item2>-->
<!--            <commodity-card-item3 v-if="comData.commdity.showType === 3" :commdity="comData.commdity"></commodity-card-item3>-->
        </div>
        <div v-if="comData.imgCom !==null">
            <img-com-item1 :img-com="comData.imgCom"/>
        </div>
        <div v-if="comData.videoCom !==null">
            <video-com-16-9></video-com-16-9>
        </div>
        <div v-if="comData.swipe !==null">
            <swipe-item :swipe="comData.swipe"></swipe-item>
        </div>
        <div v-if="comData.tabCom !==null">
            <commodity-group-item :comd-data="comData.tabCom"></commodity-group-item>
        </div>
        <div v-if="comData.noticeBar !==null">
            <notice-item :com-data="comData.noticeBar"></notice-item>
        </div>
        <div v-if="comData.searchCom !==null">
            <search-item :com-data="comData.searchCom"></search-item>
        </div>
        <div v-if="comData.fullScreenImg !==null">
            <full-screen-img-item :com-data="comData.fullScreenImg"></full-screen-img-item>
        </div>
        <div v-if="comData.recommend !==null">
            <recommend-item :com-data="comData.recommend"></recommend-item>
        </div>
        <div v-if="comData.imgCommodity !==null">
            <img-commodity-item :com-data="comData.imgCommodity"></img-commodity-item>
        </div>
        <div v-if="comData.placeholder !==null">
            <placeholder-item :com-data="comData.placeholder"></placeholder-item>
        </div>
    </div>

</template>

<script>
    import CommodityCardItem1 from "./commodity-card-item1";
    import CommodityCardItem2 from "./commodity-card-item2";
    import CommodityCardItem3 from "./commodity-card-item3";
    import ImgComItem1 from "./img-com-item1";
    import SwipeItem from "./swipe-item";
    import VideoCom169 from "./video-com-16-9";
    import CommodityGroupItem from "./commodity-group-item";
    import NoticeItem from "./notice-item";
    import SearchItem from "./search-item";
    import FullScreenImgItem from "./full-screen-img-item";
    import RecommendItem from "./recommend-item";
    import ImgCommodityItem from "./img-commodity-item";
    import PlaceholderItem from "./placeholder-item";
    export default {
        name: "mall-home-com-item",
        components: {
            PlaceholderItem,
            ImgCommodityItem,
            RecommendItem,
            FullScreenImgItem,
            SearchItem,
            NoticeItem,
            CommodityGroupItem,
            VideoCom169,
            SwipeItem
        , ImgComItem1, CommodityCardItem1,CommodityCardItem2,CommodityCardItem3},
        data(){
            return{
            }
        },
        computed: {
        },
        props:{
            comData: Object
        },
        methods:{
            // getWidth(){
            //     return 100/2 +"%"
            // }
        }
    }
</script>

<style scoped>
    .commdity-list{
        margin-top: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }
</style>