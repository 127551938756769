<template>
    <div class="dialog-class">
        <div>仓库选择</div>
        <div style="border-bottom: #bcbcbc solid 1px;margin-top: 10px;margin-bottom: 10px"></div>
        <div style="display: flex;flex-direction: row;align-items: center">
            <div style="width: 70px">仓库名称</div>
            <el-input @keyup.enter.native="get"  v-model="formData.store_name" style="width: 200px"></el-input>
            <div style="width: 70px;margin-left: 20px">仓库代码</div>
            <el-input  @keyup.enter.native="get" v-model="formData.store_code" style="width: 200px"></el-input>
            <el-button  @click="get" type="primary" style="margin-left: auto">查询</el-button>
        </div>

        <div style="margin-top: 10px;height: 600px;overflow: scroll" v-loading="showloading">
            <el-checkbox-group v-model="checkList" @change="checkChange">
                <table>
                    <thead>
                    <th>仓库代码</th>
                    <th>仓库名称</th>
                    </thead>
                    <tbody>
                    <tr v-for="(it,index) in stores">
                        <td >{{it.store_name}}</td>
                        <td style="text-align: left;width: 300px"><el-checkbox :label="it.store_code"></el-checkbox></td>
                    </tr>
                    </tbody>
                </table>
            </el-checkbox-group>
        </div>
        <div style="padding: 5px;text-align: right">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" style="margin-right: 20px;margin-left: 20px" @click="confirm">确定</el-button>
        </div>
    </div>
</template>

<script>
    import {postRequest} from "../../utils/api";

    export default {
        name: "store-select-com",
        emits:['confirm','update:modelValue'],
        props:{
            multipleSelect:{
                type:Boolean,
                defualt:false
            }
        },
        data(){
            return{
                stores:[],
                formData:{
                    store_name:'',
                    store_code:'',
                    disuse:0
                },
                checkList:[],
                selectedList:[],
                showloading:false
            }
        },
        methods:{
            get(){
                this.showloading=true
                console.log(this.formData)
                postRequest('mall/getStoreList',this.formData).then(res=>{
                    this.showloading=false
                    this.stores=res.data.data
                })
            },
            cancel(){
              this.$emit('update:modelValue',false)
            },
            confirm(){
                if (this.selectedList.length>0){
                    this.$emit('confirm',JSON.parse(JSON.stringify(this.selectedList)))
                }
                this.$emit('update:modelValue',false)
            },
            checkChange(e){
                if (this.multipleSelect==true){
                    this.selectedList=[]
                    for (var i=0;i<this.stores.length;i++){
                        for (var c=0;c<this.checkList.length;c++){
                            if (this.stores[i].store_code==this.checkList[c]){
                                this.selectedList.push(this.stores[i])
                            }
                        }
                    }
                }else{
                    var t=''
                    for (var i=0;i<this.checkList.length;i++){
                        var ex=false
                        for (var t=0;t<this.selectedList.length;t++){
                            if (this.checkList[i]==this.selectedList[t].store_name){
                                ex=true
                            }
                        }
                        if (ex==false){
                            t=this.checkList[i]
                        }
                    }
                    this.checkList=[t]
                    for (var i=0;i<this.stores.length;i++){
                        if (this.stores[i].store_code==t){
                            this.selectedList=[this.stores[i]]
                        }
                    }
                }
            }
        },
        mounted() {
            this.get()
        }
    }
</script>

<style scoped>
    .dialog-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 700px;
        width: 700px;
        background-color: #ffffff;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
        padding: 20px;
        z-index: 1000;
    }
    table, td, tr, th {
        font-size: small;
        line-height: 30px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        padding: 10px;
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
</style>