<template>
    <div v-if="modelValue" class="goods-group-select-class">
        <div style="height: 650px;width: 600px;position: relative;overflow: scroll">
            <table>
                <thead>
                <th>分组名称</th>
                <th>图片</th>
                <th>选择</th>
                </thead>
                <tbody>
                <tr v-for="r in gdata">
                    <td>
                        <div style="display: flex;flex-direction: row">
                            <el-icon  size="25" v-show="r.group_type==2 && r.fold==true" @click="toUnfold(r)"><ArrowRight/></el-icon>
                            <el-icon  size="25" v-show="r.group_type==2 && r.fold==false" @click="tofold(r)"><ArrowDown/></el-icon>
                            <!--                                <span v-if="r.group_type==3" style="color: black;">{{r.parent_name}} -> </span>-->
                            <el-input :disabled="r.group_type==1" v-model="r.group_name" style="width: 200px;margin-left: 10px" @change="updateGroup(r)"></el-input>
                        </div>
                    </td>
                    <td>
                        <div v-if="r.group_type==3" style="display: flex;flex-direction: column;align-items: center;cursor: pointer;color: white">
                            <el-image   style="width: 55px;object-fit: cover;border-radius: 3px" :src="r.pic_url"></el-image>
                        </div>
                    </td>
                    <td>
                        <span style="color:blue;cursor: pointer" v-if="r.group_type==3" @click="selected(r)">选择</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style="text-align: right;margin-top: 20px">
            <el-button @click="cancel">取消</el-button>
        </div>
    </div>
</template>

<script>
    import {getRequest} from "../../utils/api";

    export default {
        name: "GoodsGroupSelectCom",
        emits: ['confirm','update:modelValue'],
        props:['multipleSelect','modelValue'],
        data(){
            return{
                gdata: [],
                gSourcedata:[]
            }
        },
        methods:{
            cancel(){
                this.$emit('update:modelValue',false)
            },
            selected(r){
                this.$emit('confirm',r)
                this.$emit('update:modelValue',false)
            },
            getGoodsGroup(){
                getRequest('goods/getGoodsGroup').then(res=>{
                    var t=res.data.data
                    for (var i=0;i<t.length;i++){
                        t[i]['fold']=true
                    }
                    this.gSourcedata=t
                    this.getData()
                })
            },
            getData(){
                this.gdata=[]
                for(var i=0;i<this.gSourcedata.length;i++){
                    // this.gSourcedata[i].fold=true
                    if (this.gSourcedata[i].group_type!=3){
                        this.gdata.push(this.gSourcedata[i])
                    }
                    if (this.gSourcedata[i].fold==false && this.gSourcedata[i].childrenList!=null){
                        for (var c=0;c<this.gSourcedata[i].childrenList.length;c++){
                            this.gdata.push(this.gSourcedata[i].childrenList[c])
                        }
                    }
                }
            },
            toUnfold(r){
                var t=[]
                for (var i=0;i<this.gSourcedata.length;i++){
                    if (r.group_id==this.gSourcedata[i].group_id || r.group_id==this.gSourcedata[i].parent_id){
                        this.gSourcedata[i].fold=false
                        // for (var c=0;c<this.gdata[i].childrenList.length;c++){
                        //     t.push(this.gdata[i].childrenList[c])
                        // }
                    }
                }
                this.getData()

            },
            tofold(r){
                var t=[]
                for (var i=0;i<this.gdata.length;i++){
                    if (r.group_id==this.gdata[i].group_id){
                        this.gdata[i].fold=true
                    }
                    if (r.group_id!=this.gdata[i].parent_id){
                        t.push(this.gdata[i])
                    }
                }
                this.getData()
            },
        },
        mounted() {
            this.getGoodsGroup()
        },
        created () {
            this.getGoodsGroup()
        }
    }
</script>

<style scoped>
    .goods-group-select-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 700px;
        width: 600px;
        background-color: white;
        z-index: 101;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
        padding: 20px;
    }
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>div>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>