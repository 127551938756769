import {postRequest} from "@/utils/api";

function fixdata(data) {
    var o = ''
    var l = 0
    var w = 10240
    for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)))
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
    return o
}
function doimport(file) {
    return new Promise((resolve, reject)=>{
        var vips=[]
        var f = file

        var rABS = false // 是否将文件读取为二进制字符串
        var reader = new FileReader()
        // if (!FileReader.prototype.readAsBinaryString) {

        FileReader.prototype.readAsBinaryString = function (f) {
            var binary = ''

            var rABS = false // 是否将文件读取为二进制字符串

            var wb // 读取完成的数据

            var skudata
            var bmddata

            var reader = new FileReader()

            reader.onload = function (e) {
                var bytes = new Uint8Array(reader.result)

                var length = bytes.byteLength

                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i])
                }

                var XLSX = require('xlsx')

                if (rABS) {
                    wb = XLSX.read(btoa(this.fixdata(binary)), { // 手动转化

                        type: 'base64'

                    })
                } else {
                    wb = XLSX.read(binary, {

                        type: 'binary',
                        cellDates: true
                    })
                }

                // outdata就是你想要的东西 excel导入的数据
                var outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
                // skudata = XLSX.utils.sheet_to_json(wb.Sheets['SKU'])
                // excel 数据再处理
                outdata.map(v => {
                    var x = {'vip_code': v.会员代码}
                    vips.push(x)
                })
                // console.log(vips)
                if (vips.length<1){
                    resolve([])
                }
                var pm={
                    pageNum:0,
                    pageSize:0,
                    vips:vips
                }
                postRequest('vip/getVipList', pm).then(res => {
                    console.log(res)
                    if (res.data.flag){
                        resolve(res.data.data.list)
                    }else{
                        resolve([])
                    }
                })
            }
            reader.readAsArrayBuffer(f)
        }
        if (rABS) {
            reader.readAsArrayBuffer(f)
        } else {
            reader.readAsBinaryString(f)
        }
    })
}
export async function importVip(file) {
    return await doimport(file)
}
