<template>
    <div style="padding: 10px;text-align: left">
        <h4>商品模板配置</h4>
        <div style="display: flex;align-items: center;margin-bottom: 10px">
            <div>单行模式:</div>
            <el-checkbox v-model="commdity.singleLine" size="large" style="margin-left: 20px"></el-checkbox>
        </div>
        <div>添加商品</div>
        <div>
            <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div v-for="(item,index) in commditys" :key="index" style="margin: 10px">
                    <div class="close-icon-class">
                        <div class="el-icon-close" v-on:click="removeCommdity(index)" style="z-index: 1000"><el-icon><Close /></el-icon></div>
                    </div>
                    <img style="width: 70px;height: 70px" :src="item.pic_url"/>
                    <div style="font-size: 12px">{{item.goods_code}}</div>
                </div>
            </div>
            <el-button icon="el-icon-plus" style="width: 100%" @click="toAddGoods">添加商品</el-button>
            <div style="margin-top: 20px">行样式</div>
            <div style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 10px">
                <div style="margin: 10px;" v-for="(item,index) in btText" :key="index">
                    <el-button  @click="commdityLineStyleSelect(index)" :id="getCommdityLineStyleBt(index)">{{item}}</el-button>
                </div>
            </div>
            <div>
                <div>显示原价</div>
                <el-switch v-model="showPrice_" />
            </div>
            <div style="margin-top: 20px;">售价格式</div>
            <div style="background-color: rgba(122,224,205,0.22);border-radius: 5px">
                <div style="padding: 10px;display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-top: 10px">
                    <div>颜色值</div>
                    <input v-model="priceColorSelect"  style="width: 150px;height: 18px;margin-left: 10px">
                    <el-color-picker color-format="hex" @change="colorchange" v-model="priceColorSelect" style="margin-left: 20px"></el-color-picker>
                </div>
                <div class="slider-class" style="padding: 10px;">
                    <div>字体大小</div>
                    <el-slider style="width: 80%;margin-left: 20px" v-model="priceFontSize_" show-input :max=40></el-slider>
                </div>
            </div>

            <div style="margin-top: 20px;display: flex;align-items: center">
                <div>标签设置</div>
                <div style="margin-left: 10px;margin-right: 20px">显示标签: </div>
                <el-switch v-model="showLable_" />
            </div>
            <div style=";border-radius: 5px;margin-top: 10px;background-color: #eeeeee;padding: 20px;font-size: 13px">
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px">
                    <div>标签内容</div>
                    <input v-model="lableText" style="width: 150px;height: 18px;margin-left: 10px">
                </div>
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px;margin-top: 5px">
                    <div>标签颜色</div>
                    <input v-model="labelColorSelect" style="width: 150px;height: 18px;margin-left: 10px">
                    <el-color-picker color-format="hex" @change="labecolorchange" v-model="labelColorSelect" style="margin-left: 20px"></el-color-picker>
                </div>
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px">
                    <div>背景颜色</div>
                    <input v-model="labelBackgroundColorSelect" style="width: 150px;height: 18px;margin-left: 10px">
                    <el-color-picker color-format="hex" @change="labelBackgoundColorchange" v-model="labelBackgroundColorSelect"  style="margin-left: 20px"></el-color-picker>
                </div>
            </div>
        </div>
        <goods-select-com v-model="showGoodsSelect" @confirm="goodsSelected" :multiple-select="true"></goods-select-com>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../../utils/api";
    import GoodsSelectCom from "../../common/goods-select-com";

    export default {
        name: "commodity-config",
        components: {GoodsSelectCom},
        props:{
            slecetIndex: Number,
            commdity:{
                singelLine:Boolean,
                showType: Number,
                priceColor: String,
                priceFontSize: Number,
                showLable: Boolean,
                showPrice: Boolean,
                lableFontColor: String,
                lableBackgroundColor: String,
                lableTxt: String,
                dataNum:Number,
                data:Array
            }
        },
        data(){
            return{
                showGoodsSelect:false,
                priceFontSize_: 12,
                lableText: '',
                showPrice_:false,
                showLable_:false,
                labelColorSelect: null,
                priceColorSelect: null,
                labelBackgroundColorSelect: null,
                commdity_: null,
                btText:['一行一个','一行两个','一行三个'],
                commditys:[]
            }
        },
        mounted() {
            this.commdity_ = this.commdity
            this.showPrice_=this.commdity.showPrice
            this.showLable_=this.commdity.showLable
            this.setBtBorder(this.commdity.showType)
            this.priceColorSelect = this.commdity.priceColor
            this.lableText = this.commdity.lableTxt
            this.labelColorSelect = this.commdity.lableColor
            this.labelBackgroundColorSelect = this.commdity.lableBackgroundColor
            this.priceFontSize_ = this.commdity.priceFontSize
            this.getComdityData()
        },
        watch :{
            priceColorSelect(){
                this.commdity_.priceColor = this.priceColorSelect
            },
            lableText(){
                this.commdity_.lableTxt = this.lableText
            },
            labelColorSelect(){
                this.commdity_.lableColor = this.labelColorSelect
            },
            labelBackgroundColorSelect(){
                this.commdity_.lableBackgroundColor = this.labelBackgroundColorSelect
            },
            priceFontSize_(){
                this.commdity_.priceFontSize = this.priceFontSize_
            },
            showPrice_(){
                this.commdity_.showPrice = this.showPrice_
            },
            showLable_(){
                this.commdity_.showLable = this.showLable_
            },
            'commdity.dataNum':{
                handler(){
                    // console.log(1)
                    this.getComdityData()
                }
            }
        },
        methods:{
            getComdityData(){
                // console.log(this.commdity.data)
                postRequest('goods/getCommdityInfoListByCodes',{'codes':this.commdity_.data}).then(res=>{
                    this.commditys=res.data.data
                })
            },
            commdityLineStyleSelect(index){
                this.commdity_.showType = index+1
                this.setBtBorder(index+1)
                console.log(this.commdity_)
            },
            getCommdityLineStyleBt(index){
              return 'commdityLineStyleBt_' + (index+1)
            },
            setBtBorder(id){
                this.btText.forEach((item,index) =>{
                    if (id===index+1){
                        document.getElementById('commdityLineStyleBt_'+(index+1)).setAttribute('style','border: #00b9b9 solid 1px')
                    }else {
                        document.getElementById('commdityLineStyleBt_'+(index+1)).setAttribute('style','#999999 solid 1px')
                    }
                })
                //
            },
            removeCommdity(id){
                var temp = []
                var td=[]
                this.commditys.forEach((item,index)=>{
                    if (index !== id) {
                        temp.push(item)
                        td.push(item.goods_code)
                    }
                })
                this.commditys = temp
                this.commdity.data=td
                this.commdity.dataNum = temp.length
            },
            colorchange(e){
                // console.log(e)
                this.commdity_.priceColor=e
            },
            labecolorchange (e){
                this.commdity_.lableColor = e
            },
            labelBackgoundColorchange(e){
                this.commdity_.lableBackgroundColor = e
            },
            goodsSelected(e){
                // console.log(e)
                var t=this.commdity_.data
                // console.log(t)
                for (var i=0;i<e.length;i++){
                    var ex=false
                    for (var j=0;j<t.length;j++){
                        if (e[i].goods_code==t[j]){
                            ex=true
                        }
                    }
                    if (!ex){
                        t.push(e[i].goods_code)
                    }
                }
                console.log(t)
                this.commdity_.data=t
                this.commdity_.dataNum=t.length
                console.log(this.commdity_.data)
            },
            toAddGoods(){
                this.showGoodsSelect=true
            }
        },
    }
</script>

<style scoped>
    .close-icon-class{
      position: relative;
    }
    .close-icon-class :hover{
        color: #00fefe;
    }
    .el-icon-close{
        position: absolute;
        right: 0px;
        top:0px;
        font-size: 20px;
    }
</style>