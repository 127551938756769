import SingleImgPreview from './vue-file/SingleImgPreview'
import {createApp} from 'vue'
function SingleImgPreviewApi(imgUrl) {
    const div=document.createElement('SingleImgPreview')
    document.body.appendChild(div);
    const app =createApp(SingleImgPreview,{
        imgUrl,
        onTclose(){
            app.unmount()
            div.remove()
        }
    });
    app.mount(div)
}
export default SingleImgPreviewApi;