<template>
    <div style="background-color: whitesmoke;" v-loading="showloading">
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>商城参数</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
            <span style="margin-right: auto"><span style="color: blue"></span></span>
            <el-button type="primary" style="width: 90px" @click="save">保存</el-button>
        </div>
        <div style="text-align: left;background-color: white;margin: 10px;padding: 10px;font-size: 14px">
            <div style="display: flex;flex-direction: row;align-items: center;">
                <div style="margin-right: 10px">默认退货门店</div>
                <div class="mt-4">
                    <el-input readonly :model-value="form.default_return_store_code+' | '+form.default_return_store_name" placeholder="点击按钮选择">
                        <template #append>
                            <el-button @click="toSelectStore" icon="MoreFilled"></el-button>
                        </template>
                    </el-input>
                </div>
                <div style="margin-left: 10px;color: blue">重置</div>
                <store-select-com v-model="showStoreSelect" v-if="showStoreSelect" @confirm="StoreSelectConfirm"></store-select-com>
            </div>
            <div style="display: flex;align-items: center;margin-top: 20px">
                <div>订单支付超时时间:</div>
                <div style="margin-left: 40px">下单后:</div>
                <el-input v-model="form.order_pay_timeout" style="width: 100px;"></el-input>
                <div>分钟内未支付自动取消订单</div>
            </div>
            <div style="display: flex;align-items: center;margin-top: 20px">
                <div>自定确认收货时间:</div>
                <div style="margin-left: 40px">订单发货</div>
                <el-input v-model="form.order_auto_confirm_days" style="width: 100px;"></el-input>
                <div>天后,自动确认收货</div>
            </div>
            <div style="display: flex;align-items: center;margin-top: 20px">
                <div>积分生效时间:</div>
                <div style="margin-left: 40px">购买 </div>
                <el-input v-model="form.integral_effective_date" style="width: 100px;"></el-input>
                <div> 天后积分生效</div>
            </div>
            <div style="display: flex;align-items: center;margin-top: 20px">
                <div>积分有效期:</div>
                <el-input v-model="form.integral_validity" style="width: 100px;margin-left: 50px"></el-input>
                <div>天</div>
            </div>
        </div>
        <div style="text-align: left">退货地址</div>
        <div @click="addAddr" style="text-align: left;margin: 10px"><el-button>新增</el-button></div>
        <div>
            <table>
                <thead>
                    <th>联系人</th>
                    <th>联系电话</th>
                    <th>省</th>
                    <th>市</th>
                    <th>区</th>
                    <th>详细地址</th>
                    <th>默认地址</th>
                    <th>操作</th>
                </thead>
                <tbody>
                    <tr v-for="(it,index) in form.return_address">
                        <td>{{it.receiver_name}}</td>
                        <td>{{it.receiver_mobile}}</td>
                        <td>{{it.province_name}}</td>
                        <td>{{it.city_name}}</td>
                        <td>{{it.county_name}}</td>
                        <td>{{it.receiver_address}}</td>
                        <td><el-checkbox @change="defChange(index)" v-model="it.default_address" size="large"/></td>
                        <td style="color: blue">
                            <span @click="editAddr(index)" style="cursor: pointer">编辑</span>
                            <span @click="delAddr(index)" style="margin-left: 20px;cursor: pointer">删除</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <el-dialog
                v-model="showDialog"
                width="1000px"
        >
            <el-form :model="addr">
                <el-form-item label="联系人" label-width="150">
                    <el-input v-model="addr.receiver_name" autocomplete="off" />
                </el-form-item>
                <el-form-item label="联系人电话" label-width="150">
                    <el-input v-model="addr.receiver_mobile" autocomplete="off" />
                </el-form-item>
                <el-form-item label="收货地址" label-width="150" style="display: flex;flex-direction: row">
                    <el-select v-model="addr.rovince_name" placeholder="选择省份" @change="provinceChange">
                        <el-option v-for="it in province" :label="it.region_name" :value="it.region_id" />
                    </el-select>
                    <el-select v-model="addr.city_name" placeholder="选择市" @change="cityChange">
                        <el-option v-for="it in city" :label="it.region_name" :value="it.region_id" />
                    </el-select>
                    <el-select v-model="addr.county_name" placeholder="选择区" @change="countyChange">
                        <el-option v-for="it in county" :label="it.region_name" :value="it.region_id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="详细地址" label-width="150">
                    <el-input v-model="addr.receiver_address" autocomplete="off" />
                </el-form-item>
                <el-checkbox v-model="addr.default_address" label="默认地址" size="large" style="margin-left: -560px" disabled/>
            </el-form>
        <template #footer>
              <span class="dialog-footer">
                <el-button @click="showDialog = false">取消</el-button>
                <el-button type="primary" @click="saveAddr">
                  确认
                </el-button>
              </span>
        </template>
        </el-dialog>
    </div>
</template>

<script>
    import StoreSelectCom from "../common/store-select-com";
    import {getRequest, postRequest} from "../../utils/api";
    import {ElMessageBox} from "element-plus";
    export default {
        name: "ReturnConfig",
        components: {StoreSelectCom},
        data(){
            return{
                form:{
                    default_return_store_code:'',
                    default_return_store_name:'',
                    order_pay_timeout:'',
                    order_auto_confirm_days:'',
                    return_address:[],
                    integral_validity:'',
                    integral_effective_date:''
                },
                showStoreSelect:false,
                showDialog:false,
                addr:{},
                defAddr:{
                    receiver_name:'',
                    receiver_mobile:'',
                    province_code:'',
                    province_name:'',
                    city_code:'',
                    city_name:'',
                    county_code:'',
                    county_name:'',
                    receiver_address:'',
                    default_address:false
                },
                province:[],
                city:[],
                county:[],
                editIndex:-1,
                showloading:false
            }
        },
        methods:{
            StoreSelectConfirm(e){
                this.form.default_return_store_code=e[0].store_code
                this.form.default_return_store_name=e[0].store_name
                console.log(this.form)
            },
            toSelectStore(){
                this.showStoreSelect=true
            },
            save(){
                postRequest('mall/saveMallSysConfig',this.form).then(res=>{
                    this.$alert(res.data.message)
                })
            },
            getProvince(){
                getRequest('common/getProvince').then(res=>{
                    this.province=res.data.data
                })
            },
            getCitys(e){
                getRequest('common/getCitysByPrcovince?province_code='+e).then(res=>{
                    this.city=res.data.data
                })
            },
            getCounty(e){
                getRequest('common/getCountysByCity?city_code='+e).then(res=>{
                    this.county=res.data.data
                })
            },
            provinceChange(e){
                console.log(e)
                for (var i=0;i<this.province.length;i++){
                    if (e==this.province[i].region_id){
                        this.addr.province_code=e
                        this.addr.province_name=this.province[i].region_name
                    }
                }
                this.city=[]
                this.county=[]
                this.addr.city_code=''
                this.addr.city_name=''
                this.addr.county_code=''
                this.addr.county_name=''
                this.getCitys(e)
            },
            cityChange(e){
                for (var i=0;i<this.city.length;i++){
                if (e==this.city[i].region_id){
                    this.addr.city_code=e
                    this.addr.city_name=this.city[i].region_name
                }}
                this.getCounty(e)
            },
            countyChange(e){
                for (var i=0;i<this.county.length;i++){
                if (e==this.county[i].region_id){
                    this.addr.county_code=e
                    this.addr.county_name=this.county[i].region_name
                }}
            },
            addAddr(){
                this.editIndex=-1
                this.addr=JSON.parse(JSON.stringify(this.defAddr))
                this.showDialog=true
            },
            delAddr(index){
                var t=[]
                for (var i=0;i<this.form.return_address.length;i++){
                    if (i!=index) {
                        t.push(this.form.return_address[i])
                    }
                }
                this.form.return_address=t
            },
            editAddr(index){
                this.editIndex=index
                this.addr=this.form.return_address[index]
                this.showDialog=true
            },
            saveAddr(){
                if (this.editIndex==-1){
                    console.log(this.addr)
                    this.form.return_address.push(JSON.parse(JSON.stringify(this.addr)))
                }
                this.showDialog=false
            },
            defChange(index){
                console.log(index)
                if (this.form.return_address[index].default_address){
                    for (var i=0;i<this.form.return_address.length;i++){
                        if (i!=index){
                            this.form.return_address[i].default_address=false
                        }
                    }
                }

            }
        },
        mounted() {
            this.getProvince()
            this.showloading=true
            getRequest('mall/getMallSysConfig').then(res=>{
                console.log((res))
                if (res.data.flag){
                    this.showloading=false
                    this.form=res.data.data
                }else {
                    ElMessageBox.alert(res.data.message)
                }

            })
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        font-size: small;
        line-height: 30px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        padding: 10px;
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
</style>