<template>
    <div>
        <el-container style="height: max-content; border: 1px solid #eee">
            <el-header style="text-align: right; font-size: 12px;background-color: #00e0e0;display: flex;flex-direction: row-reverse;height: 70px;position: relative;">
                <div id="userInfo" style="width: 200px;">
                    <div v-on:click="toShowUserInfoMenu" style="display: flex;flex-direction: row;">
                        <div style="border: white solid 2px;height: 50px;width: 50px;border-radius: 100px;margin: 5px">
                            <el-image style="width: 50px;height: 50px;border-radius: 50px;" :src="usericon" />
                        </div>
                        <div style="width: 100px;text-align: center;line-height: 60px">{{username}}</div>
                        <el-icon style="line-height: 50px;width: 50px;height: 50px;text-align: center">
                            <ArrowDown/>
                        </el-icon>
                    </div>
                    <div v-if="showUserInfoMenu" style="text-align: center;z-index: 99999;cursor: pointer;border:  #00e0e0 solid 1px;border-top: none" @mouseleave="toShowUserInfoMenu">
                        <li @click="toBindWx" style="margin: 10px">微信授权</li>
                        <li @click="showChangePassword=true" style="margin: 10px">密码修改</li>
                        <li style="margin: 10px">个人信息</li>
                        <li @click="logout" style="margin: 10px">退出登录</li>
                    </div>
                </div>
<!--                <div @click="toShowMsg"  class="iconfont mmx-iconmessage" style="font-size: 40px;margin-top: 15px;margin-right: 50px;color: white;position: relative;cursor: pointer">-->
<!--                    <div v-if="msgNum>0" class="msg-dot">{{msgNum}}</div>-->
<!--                </div>-->
            </el-header>
        </el-container>
        <el-container style="min-height: calc(100vh - 100px)">
            <el-aside  width="250px" style="background-color: rgb(238, 241, 246);text-align: left">
                <el-menu @select="menuSelect" unique-opened>
                    <el-sub-menu index="1">
                        <template v-slot:title> <el-icon class="ic-class"><Tools /></el-icon>系统</template>
                        <el-menu-item v-if="checkAuth(1)" index="UserManage">用户管理</el-menu-item>
                        <el-menu-item v-if="checkAuth(6)" index="RoleManage">角色管理</el-menu-item>
                        <el-menu-item v-if="checkAuth(9)" index="MenuManage">功能菜单</el-menu-item>
                        <el-menu-item v-if="checkAuth(19)" index="ScheduleManage">定时任务</el-menu-item>
                        <el-menu-item v-if="checkAuth(19)" index="ExportTask">导出任务</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="2">
                        <template v-slot:title><el-icon class="ic-class"><Shop /></el-icon>商城管理</template>
                        <el-menu-item v-if="checkAuth(23)" index="FreightTempleteList">运费模板</el-menu-item>
                        <el-menu-item v-if="checkAuth(28)" index="WePageList">微页面</el-menu-item>
                        <el-menu-item v-if="checkAuth(33)" index="GoodsManage">商品管理</el-menu-item>
                        <el-menu-item v-if="checkAuth(37)" index="GoodsGroupMange">商品分组</el-menu-item>
                        <el-menu-item @click="toShowMedia" index="no">媒体资料</el-menu-item>
                        <el-menu-item v-if="checkAuth(40)" index="TabBarManage">底部导航</el-menu-item>
                        <el-menu-item v-if="checkAuth(42)" index="ReturnConfig">商城参数</el-menu-item>
                        <el-menu-item v-if="checkAuth(45)" index="ExpressCompany">快递公司</el-menu-item>
                        <el-menu-item v-if="checkAuth(122)" index="CsmSetting">商城客服</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="3">
                        <template v-slot:title><el-icon class="ic-class"><User /></el-icon>会员管理</template>
                        <el-menu-item index="VipList">会员资料</el-menu-item>
                        <el-menu-item v-if="checkAuth(50)" index="Market">营销活动</el-menu-item>
                        <el-menu-item v-if="checkAuth(109)" index="LevelUpConditionList">会员升级策略</el-menu-item>
                        <el-menu-item v-if="checkAuth(109)" index="GuiderTask">导购任务</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="4">
                        <template v-slot:title><el-icon class="ic-class"><ShoppingCartFull /></el-icon>订单管理</template>
                        <el-menu-item v-if="checkAuth(81)" index="OrderManage">订单管理</el-menu-item>
                        <el-menu-item v-if="checkAuth(86)" index="ReturnManage">售后管理</el-menu-item>
                        <!--                        <el-menu-item index="">手动派单仓</el-menu-item>-->
                        <!--                        <el-menu-item index="">自动动派单仓</el-menu-item>-->
                    </el-sub-menu>
                    <el-sub-menu index="5">
                        <template v-slot:title><el-icon class="ic-class"><Coin /></el-icon>积分商城</template>
                        <el-menu-item v-if="checkAuth(91)" index="GiftList">礼品管理</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="6">
                        <template v-slot:title><el-icon class="ic-class"><ChatRound /></el-icon>企微及公众号</template>
                        <el-menu-item v-if="checkAuth(96)" index="GuiderList">导购及客户</el-menu-item>
                        <el-menu-item v-if="checkAuth(98)" index="WelcomeList">欢迎语</el-menu-item>
                        <el-menu-item v-if="checkAuth(98)" index="OfaMenu">公众号菜单</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="7">
                        <template v-slot:title><el-icon class="ic-class"><ChatRound /></el-icon>营销助手</template>
                        <el-menu-item v-if="checkAuth(96)" index="">助手角色</el-menu-item>
                        <el-menu-item v-if="checkAuth(98)" index="">助手权限</el-menu-item>
                    </el-sub-menu>
                </el-menu>
            </el-aside>
            <el-container>
                <el-main>
                    <router-view>
                    </router-view>
                </el-main>
            </el-container>
        </el-container>
        <media-material-com v-model="showMedia"></media-material-com>
        <el-dialog
                v-model="showChangePassword"
                width="400px"
                style="height: 330px;"
        >
            <div style="text-align: left">
                <div>输入原密码</div>
                <el-input type="password" v-model="oldPwd"/>
            </div>
            <div style="text-align: left;margin-top: 20px;margin-bottom: 20px">
                <div>输入新密码</div>
                <el-input type="password" v-model="newPwd1"/>
            </div>
            <div style="text-align: left">
                <div>再输入新密码</div>
                <el-input type="password" v-model="newPwd2"/>
            </div>
            <div style="margin-top: 20px">
                <el-button @click="showChangePassword=false">取消</el-button>
                <el-button @click="submitChangePassword" style="margin-left: 50px" type="primary">修改</el-button>
            </div>
        </el-dialog>
        <el-dialog
                v-model="showAuth2Bind"
        >
<!--            <div>微信扫码绑定</div>-->
            <div id="qrCodeBox"></div>
        </el-dialog>
        <el-dialog
                v-model="showMsgBox"
                width="60%"
                style="height: 700px;"
                :close-on-click-modal="false"
                draggable
                @close="chatCosed"
        >
        <div style="display: flex;flex-direction: row;height: 100%">
            <div style="height: 600px;width: 300px;border-right: #bcbcbc solid 1px;overflow: visible">
                <div style="position: relative">
                    <el-input clearable v-model="imUserSearchValue" @focusin="searchImUserDb()" @input="imUserSearchChange" placeholder="搜索联系人"/>
                    <div v-if="showUserSearchedPanel" class="user-searched-panel-class">
                        <div @click="searchedUserClick(it)" v-for="(it,index) in imUserSearchedList"  class="userSearchedList-class">
                            <div style="text-align: left;font-size: 13px">{{it.user_name}}</div>
                            <div style="text-align: right;color: #7c7c7c;font-size: 11px">{{it.user_id}}</div>
                        </div>
                    </div>
                </div>
                <div id="contact-body" style="overflow: scroll;height: 90%" class="msg-dg">
                    <div @click="userClick(it)" v-for="(it,index) in recentContactList" class="contact-list" :style="{backgroundColor: it.sender===chating.sender?'rgba(0, 101, 101, 0.18)':''}">
                        <div style="border-radius: 6px;display: flex;align-items: center;justify-content: center;position: relative;width: 40px;height: 40px;background-color: rgb(5, 163, 163);">
                            <div v-if="it.unread_msg_count>0" class="msg-dot">{{it.unread_msg_count}}</div>
                            <img v-if="it.sender_avatar_type==='img'" style="width: 40px;height: 40px;border-radius: 6px" :src="it.sender_avatar_url"/>
                            <view v-if="it.sender_avatar_type==='text'" style="font-size: 15px;color: white;">{{it.sender_avatar_url}}</view>
                        </div>
                        <div style="flex: 1;margin-left: 20px">
                            <div style="display: flex;flex-direction: row;margin-right: 20px">
                                <div style="font-size: 14px;text-overflow: ellipsis;">{{it.sender_name}}</div>
                                <div style="margin-left: auto;color: #bcbcbc;font-size: 12px">{{it.max_time}}</div>
                            </div>
                            <div style="margin-top: 5px;font-size: 12px;color: #bcbcbc;width: 150px;text-align: left;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{it.max_msg}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="flex: 1">
                <div style="text-align: left;margin-left: 20px;font-size: 15px;font-weight:bold;display: flex">
                    {{chating.sender_name}}
                    <div style="color: #898989;font-size: 12px;margin-top: auto;font-weight: normal"> [{{chating.sender}}]</div>
                </div>
                <div id="body-div" v-loading="loadingMsg" class="msg-dg" style="flex: 1;overflow: scroll;background-color: whitesmoke;margin-top: 10px;font-size: 15px;height: 400px">
                    <div v-for="(item,index) in msgList">
                        <div v-if="item.msg_type==='dt'" style="margin-top: 10px;display: flex;align-items: center;justify-content: center;width: 100%;">
                            <div  style="color: rgb(122, 122, 122);padding-left: 20px;padding-right: 20px;font-size: 12px;background-color: white;width: fit-content;">{{item.msg}}</div>
                        </div>
                        <div v-if="item.sender==chating.sender && item.msg_type!='dt'"  style="display: flex;margin-top: 20px;">
                            <div class="msg-avatar" style="margin-left: 5px;border-radius: 6px;">
                                <img v-if="item.sender_avatar_type==='img'" :src="item.sender_avatar_url" style="width: 40px;height: 40px;border-radius: 6px;"/>
                                <div v-if="item.sender_avatar_type==='text'" style="border-radius: 6px;background-color: rgb(5, 163, 163);font-size: 15px;color: white;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;">{{item.sender_avatar_url}}</div>
                            </div>
                            <el-image
                                    v-if="item.msg_type==='img'"
                                    style="width: 100px; height: 100px;margin-left: 20px"
                                    :src="item.file_url"
                                    :zoom-rate="1.2"
                                    :max-scale="7"
                                    :min-scale="0.2"
                                    :preview-src-list="[item.file_url]"
                                    :initial-index="0"
                                    fit="cover"
                            />
                            <div class="msg-box" v-if="item.msg_type==='text'" style="	margin-left: 20px;">
                                <view   style="z-index: 2;min-width: 100px;">{{item.msg}}</view>

                            </div>
                        </div>
                        <div v-if="item.sender!=chating.sender && item.msg_type!='dt'"  style="display: flex;flex-direction: row-reverse;margin-top: 20px;">
                            <div class="msg-avatar" style="margin-right: 5px;border-radius: 6px;">
                                <img v-if="item.sender_avatar_type==='img'" :src="item.sender_avatar_url" style="width: 40px;height: 40px;border-radius: 6px;"/>
                                <div v-if="item.sender_avatar_type==='text'" style="border-radius: 6px;background-color: rgb(5, 163, 163);font-size: 15px;color: white;width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;">{{item.sender_avatar_url}}</div>
                            </div>
                            <el-image
                                    v-if="item.msg_type==='img'"
                                    style="width: 100px; height: 100px;margin-right: 20px"
                                    :src="item.file_url"
                                    :zoom-rate="1.2"
                                    :max-scale="7"
                                    :min-scale="0.2"
                                    :preview-src-list="[item.file_url]"
                                    :initial-index="0"
                                    fit="cover"
                            />
                            <div v-if="item.msg_type==='text'" class="msg-box" style="	margin-right: 20px;color: white;background-color: rgb(4, 177, 177);">
                                <view   style="z-index: 2;min-width: 100px;">{{item.msg}}</view>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;height: 30px;"></div>
                </div>
                <div style="padding: 10px">
                    <div style="display: flex;margin: 5px">
                        <div @click="choseImg" class="iconfont mmx-iconimage" style="font-size: 20px">
                            <input v-show="false" ref="fileRef" type="file" @change="fileChange" accept="image/*">
                        </div>
                    </div>
                    <textarea @keyup.enter.exact="sendChatMsg"  @keyup.ctrl.enter="lineFeed()" placeholder="请输入消息" v-model="msg" style="width: 100%;height: 120px;resize: none;outline:none;border: none;">
                    </textarea>
                    <div style="text-align: right;display: flex">
                        <div style="color: #bcbcbc">Enter发送,Ctr+Enter换行</div>
                        <el-button type="primary" @click="sendChatMsg" style="margin-left: auto;background-color: #04B1B1;border: none">发送</el-button>
                    </div>
                </div>
            </div>
        </div>
        </el-dialog>
        <div>
            <el-link href="https://beian.miit.gov.cn/">备案号：滇ICP备2023010981号-1</el-link>
        </div>
    </div>
</template>

<script>
    import {
        checkAuth,
        getToken,
        getUserId,
        getUserName,
        setAuths,
        setLoginFlag,
        setUserId,
        setUserName
    } from './utils/auth';
    import MediaMaterialCom from "./components/common/media-material-com";
    import {filePostRequest, getRequest} from "./utils/api";
    import QRCode from 'qrcodejs2-fix'
    import {debounce} from "./utils/DebounceFn";
    import SingleImgPreviewApi from "./api-com/SingleImgPreview";
    export default {
        name: "AdminHome",
        components: {MediaMaterialCom},
        data() {
            return {
                showMedia:false,
                username:'',
                usericon: require('../src/assets/logo.jpg'),
                showUserInfoMenu: false,
                oldPwd:'',
                newPwd1:'',
                newPwd2:'',
                showChangePassword:false,
                showAuth2Bind:false,
                bindInterval:null,
                bindKey:'',
                msgNum:0,
                websocket: null,
                showMsgBox:false,
                recentContactList:[],
                chating:{},
                pageSize:10,
                pageNum:0,
                msgList:[],
                totalPage:1,
                msg:'',
                debGetPageMsg:null,
                msgViewH:0,
                loadingMsg:false,
                imUserSearchValue:'',
                imUserSearchedList:[],
                searchImUserDb:null,
                showUserSearchedPanel:false
            }
        },
        methods: {
            toShowUserInfoMenu(){
                if (this.showUserInfoMenu) {
                    this.showUserInfoMenu = false
                }else {
                    this.showUserInfoMenu = true
                }

            },
            menuSelect(e) {
                if (e!=='no'){
                    this.$router.push('/' + e)
                }

            },
            checkAuth(auth){
                return checkAuth(auth)
            },
            toShowMedia(){
                this.showMedia = true
            },
            logout(){
                setLoginFlag(false)
                setAuths([])
                setUserName('')
                setUserId('')
                this.$router.replace('/login')
            },
            submitChangePassword(){
                if (this.newPwd1.length<1){
                    this.$msgbox.alert("请输入密码")
                    return
                }
                if (this.newPwd1!== this.newPwd2){
                    this.$msgbox.alert("新密码不一致！")
                    return
                }
                {
                    getRequest('user/changeSelfPassword?user_id='+getUserId()+'&old_password='+this.oldPwd+'&new_password='+this.newPwd1).then(res=>{
                        this.showChangePassword=false
                        this.$msgbox.alert(res.data.message)
                    })
                }

            },
            toBindWx(){
                this.showAuth2Bind=true
                getRequest('wxapi/weofa/getOauth2BindUrl?userid='+getUserId()).then(res=>{
                    this.showAuth2Bind=true
                    this.bindKey=res.data.data.bindKey
                    var userId=getUserId()
                  setTimeout(()=>{
                      // document.getElementById("qrCodeBox").innerHTML = ""
                      // var t= document.getElementById("qrCodeBox")
                      // var qrcode = new QRCode(t, {
                      //     text: res.data.data.url,
                      //     width: 200,
                      //     height: 200,
                      //     colorDark : "#000000",
                      //     colorLight : "#ffffff",
                      //     correctLevel : QRCode.CorrectLevel.M
                      // });
                      var obj = new WxLogin({
                          self_redirect:true,
                          id: 'qrCodeBox',
                          appid: 'wxbf6ff8db9ad7ad0e',
                          scope: 'snsapi_login',
                          redirect_uri: 'https://www.mmxfs.shop/adminApi/wxapi/weofa/oauth2Bind',
                          state:  userId,
                          style: 'black',
                          href: ''
                      })
                      console.log(obj)
                  },100)
                    this.getBindStatus()
                })
            },
            getBindStatus(){
                var that=this
                if (that.showAuth2Bind){
                    getRequest('wxapi/weofa/getOauth2BindStatus?bindKey='+that.bindKey).then(res=>{
                        if (res.data.data==1){
                            that.showAuth2Bind=false
                            that.$alert("绑定成功！")
                        }
                        if (res.data.data==2){
                            that.showAuth2Bind=false
                            that.$alert("绑定失败！")
                        }
                        if (res.data.data==0){
                            setTimeout(()=>{
                                that.getBindStatus()
                            },100)
                        }
                    })
                }
            },
            //setupWebSocket start
            setupWebSocket() {
                console.log(1)
                // this.websocket = new WebSocket("wss://www.mmxfs.shop/imApi"); // 创建WebSocket连接
                this.websocket = new WebSocket("ws://125.125.125.188:15088/imApi"); // 创建WebSocket连接
                this.websocket.onopen = this.onWebSocketOpen; // WebSocket连接打开时的处理函数
                this.websocket.onmessage = this.onWebSocketMessage; // 收到WebSocket消息时的处理函数
                // this.websocket.onclose = this.onWebSocketClose; // WebSocket连接关闭时的处理函数
            },
            onWebSocketOpen() {
                console.log("链接成功");
                this.skSend(10001,'')
            },
            closeWebSocket() {
                if (this.websocket) {
                    this.websocket.close(); // 关闭WebSocket连接
                }
            },
            onWebSocketMessage(e){
                var msg=JSON.parse(e.data)
               // console.log(msg)
                if ("unreadMsgCount"===msg.msg_type){
                    this.msgNum=msg.msg
                }
                if ("recentContactList"===msg.msg_type){
                    if (msg.msg.pageNum===1){
                        this.recentContactList=[]
                        this.recentContactList=msg.msg.list
                        if (this.showMsgBox){
                            setTimeout(()=>{
                                var d=document.getElementById('contact-body')
                                d.scrollTop=0
                            },100)
                        }
                    }
                }
                if(msg.msg_type==="pageMsg"){
                    if (msg.user_code==this.chating.sender){
                        if(msg.msg.pageNum==1){
                            this.pageNum=1
                            this.msgList=msg.msg.list
                            this.totalPage=msg.msg.pages
                            setTimeout(()=>{
                                this.pageScrollToBottom()
                                this.loadingMsg=false
                            },100)
                        }else{

                            var b=document.getElementById('body-div')
                            var vh=b.scrollHeight
                            var t=msg.msg.list.concat(this.msgList)
                            this.msgList=t
                            this.totalPage=msg.msg.pages
                            setTimeout(()=>{
                                var b2=document.getElementById('body-div')
                                b2.scrollTop=b2.scrollHeight-vh
                                this.loadingMsg=false
                            },100)
                        }
                        var t=[]
                        var uid=getUserId()
                        for (var i=0;i<msg.msg.list.length;i++){
                            if (msg.msg.list[i].receiver===uid &&  msg.msg.list[i].read_flag===0)
                            t.push(msg.msg.list[i].id)
                        }
                        if (t.length>0){
                            this.websocket.send(JSON.stringify(
                                {
                                    msg_code:10007,
                                    sender:getUserId(),
                                    receiver:this.chating.sender,
                                    readed:t,
                                    token:getToken()
                                }
                            ))
                        }

                    }
                }
            },
            skSend(msg_code,msg){
                this.websocket.send(
                    JSON.stringify( {
                        msg_code:msg_code,
                        sender:getUserId(),
                        sender_type:"admin",
                        receiver:'',
                        msg:msg,
                        token:getToken()
                    })
                )
            },
            userClick(it){
                if (it.sender!=this.chating.sender){
                    this.chating=it
                    this.pageNum=0
                    this.totalPage=1
                    this.msgList=[]
                    this.getPageMsg()
                }
            },
            chatCosed(){
                this.chating={}
                this.msgList=[]
            },
            sendChatMsg(){
                this.msg = this.msg.replace(/^\s+|\s+$/g,'')
                if(this.msg.length>0 && (this.chating.sender!==undefined)){
                    this.websocket.send(JSON.stringify(
                        {
                            msg_code:10004,
                            sender:getUserId(),
                            receiver:this.chating.sender,
                            msg:this.msg,
                            token:getToken()
                        }
                    ))
                    this.msg=''
                }

            },
            getPageMsg(){
                this.pageNum=this.pageNum+1
                // console.log(this.totalPage>=this.pageNum)
                if(this.totalPage>=this.pageNum && this.showMsgBox){
                    this.loadingMsg=true
                    this.websocket.send(JSON.stringify(
                        {
                            msg_code:10005,
                            sender:getUserId(),
                            receiver:this.chating.sender,
                            pageSize:this.pageSize,
                            pageNum:this.pageNum,
                            token:getToken()
                        }
                    ))
                }
            },
            pageScrollToBottom(){
                var that=this
                var b=document.getElementById('body-div')
                b.scrollTop=1000000
                var that=this
                b.addEventListener('scroll', function(e) {
                    if (e.target.scrollTop<10 && that.showMsgBox===true){
                        that.debGetPageMsg()
                    }
                })
            },
            lineFeed(){
                this.msg=this.msg+'\n'
            },
            choseImg(){
                this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
            },
            fileChange(res){
                console.log(this.$refs.fileRef.files)
                let formData = new FormData()
                if (this.$refs.fileRef.files.length===0){
                    return
                }
                var item = this.$refs.fileRef.files[0]
                formData.append('file', item,item.name)
                // console.log(lv2)
                console.log(formData)
                if (this.chating.sender!=undefined){
                    filePostRequest('tkAuth/im/uploadImFile?sender=' + getUserId() + '&receiver=' + this.chating.sender+'&file_type=img', formData, {'Content-Type': 'multipart/form-data','token':getToken()}).then(res => {
                        console.log(res)
                        this.pageNum=0
                        this.totalPage=1
                        this.getPageMsg()
                    })
                }
            },
            imgPreview(url){
                SingleImgPreviewApi(url)
            },
            imUserSearchChange(e){
                console.log(e)
                this.searchImUserDb()

            },
            searchImUser(){
                if (this.imUserSearchValue.length>0){
                    getRequest('tkAuth/im/imUserSearch?search='+this.imUserSearchValue).then(res=>{
                        console.log(res)
                        this.imUserSearchedList=res.data.data
                        if (this.imUserSearchedList.length>0){
                            this.showUserSearchedPanel=true

                        }else {
                            this.showUserSearchedPanel=false
                        }
                    })
                }else {
                    this.imUserSearchedList=[]
                    this.showUserSearchedPanel=false
                }

            },
            searchedUserClick(it){
                console.log(it)
                this.imUserSearchedList=[]
                this.showUserSearchedPanel=false
                this.userClick({
                    sender:it.user_id,
                    sender_name:it.user_name
                })
            },
            toShowMsg(){
                this.showMsgBox=true
                setTimeout(()=>{
                    var d=document.getElementById('contact-body')
                    d.scrollTop=0
                },100)
            }
            //setupWebSocket end

        },
        watch:{
            showUserInfoMenu(v){
                var dc=document.getElementById('userInfo')
                if (v){
                    dc.setAttribute('style','width: 200px;right: 1px;background-color: white;border: #eeeeee solid 1px')
                }else {
                    dc.setAttribute('style','width: 200px;right: 1px;')
                }
            }
        },
        mounted() {
            this.username = getUserName()
            // this.setupWebSocket()
            this.searchImUserDb=debounce(this.searchImUser,500)
            this.debGetPageMsg=debounce(this.getPageMsg,500)
            this.searchImUserDb()
            // const script = document.createElement('script')
            // script.type = 'text/javascript'
            // script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
            // document.body.appendChild(script)
        }
    }
</script>

<style scoped>
    .el-menu-item{
        text-indent: 30px;
        font-size: 13px;
    }
    .ic-class{
        font-size: 50px !important;
        color: rgba(8, 174, 177, 0.84) !important;
    }
    .msg-dot{
        height: 15px;
        min-width: 15px;
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: 10px;
        color: white;
        background-color: red;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .contact-list{
        display: flex;
        flex-direction: row;
        margin-top: 1px;
        cursor: pointer;
        padding: 10px;
    }
    .contact-list:hover{
        background-color: rgba(0, 101, 101, 0.18);
    }
    .msg-avatar{
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 40px;
        height: 40px;

    }
    .msg-box{
        z-index: 3;
        position: relative;
        padding: 10px;
        width: fit-content;
        max-width: 70%;
        border-radius: 10px;
        background-color: white;
    }
    .msg-dg::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    .userSearchedList-class{
        padding: 10px;
        border-bottom: #eaeaea solid 1px;
        cursor: pointer
    }
    .userSearchedList-class:hover{
        background-color: rgba(0, 101, 101, 0.18);
    }
    .user-searched-panel-class{
        position: absolute;
        top: calc(100% + 10px);
        background-color: rgb(193, 223, 255);
        z-index: 999;
        width: 300px;
        border-radius: 5px;
    }
    .user-searched-panel-class::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    .user-searched-panel-class::after{
        content: ' ';
        width: 30px;
        height: 30px;
        position: absolute;
        background-color: rgb(193, 223, 255);
        left: 10px;
        top: -5px;
        z-index: -1;
        transform: rotate(45deg);
    }
</style>