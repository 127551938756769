<template>
    <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
        <span style="margin-right: auto">积分兑券:{{act.id}}</span>
        <el-button :disabled="readonly" type="primary" style="width: 90px" @click="saveEdit">保存</el-button>
        <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
    </div>
    <div style="background-color: whitesmoke;padding: 10px" v-loading="showLoading">
        <div style="background-color: white;padding: 10px;">
            <div style="background-color: white;padding: 10px;">
                <div class="row-class">
                    <span>活动名称</span>
                    <el-input v-model="act.activity_name"></el-input>
                </div>
                <div class="row-class" style="width: 600px;">
                    <span>活动时间</span>
                    <el-date-picker
                            v-model="act.validity_date"
                            type="daterange"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="起始日期"
                            end-placeholder="截至日期"
                    />
                </div>
                <div class="row-class">
                    <span>活动备注</span>
                    <el-input v-model="act.activity_note" type="textarea" style="width: 400px;"></el-input>
                </div>
                <div class="row-class">
                    <span>会员条件</span>
                    <div style="text-align: left;display: flex;flex-direction: column;position: relative;cursor: pointer">
                        <el-input readonly :model-value="getvip_levelShow()">
                            <template  #append>
                                <el-button @click="toSelectLevel" icon="ArrowDown">选择</el-button>
                            </template>
                        </el-input>
                        <div v-if="showVipLevelSelect" style="border: #c9c9c9 solid 1px;position: absolute;top: 40px;background-color: white;z-index: 999;width: 300px">
                            <div @click="showVipLevelSelect=false" style="text-align: right;font-size: 20px;cursor: default"><el-icon><Close /></el-icon></div>
                            <div style="height: 300px;overflow: scroll">
                                <div  @click="vipLevelSelected(index)" v-for="(it,index) in vipLevelList" style="display: flex;flex-direction: row;border-bottom: whitesmoke solid 1px;padding: 5px" :style="{color: it.check==true?'blue':'black'}">
                                    <div>{{it.level_code}}</div>
                                    <div style="margin-left: 20px;margin-right: 20px">{{it.level_name}}</div>
                                    <div>{{it.mobile}}</div>
                                    <div v-if="it.check" style="margin-left: 20px;cursor: pointer">✓</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-class">
                    <span>总量限制</span>
                    <el-input-number v-model="act.max_send_num" :min="0"></el-input-number>
                    <span style="width: 300px;">不填或者填0则不限制发放总量</span>
                </div>
                <div class="row-class">
                    <span>兑换限制</span>
                    <div style="border: whitesmoke solid 1px;padding: 10px;border-radius: 5px;width: 600px;text-align: left">
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            <span>每人共可对兑</span>
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="act.max_total_num" :min="0"></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">次</span>
                        </div>
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            每人每天可兑
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="act.buy_num_per_day" :min="0" ></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">次</span>
                        </div>
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            每人每月可兑
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="act.buy_num_per_month" :min="0"></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">次</span>
                        </div>
                        <div style="text-align: left;color: #8a8a8a">每一项限制条件不填或者添0则不限制</div>
                    </div>
                </div>
                <div class="row-class" style="align-items: start">
                    <span style="line-height: 35px">支付方式:</span>
                    <div style="border: whitesmoke solid 1px;padding: 10px;border-radius: 5px;width: 600px;text-align: left">
                        <el-radio-group v-model="act.pay_type" style="text-align: left;flex-direction: column">
                            <el-radio  :label="1" style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                                积分支付
                                <span style="width: 500px;margin-left: 20px">
                                    <el-input type="number" v-model="act.points" min="0"></el-input>
                                </span>
                                <span style="color: black;margin-left: 20px">积分</span>
                            </el-radio>
                            <el-radio  :label="2" style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                                现金支付
                                <span style="width: 500px;margin-left: 20px">
                                    <el-input type="number" v-model="act.real_price" min="0" ></el-input>
                                </span>
                                <span style="color: black;margin-left: 20px">元现金</span>
                            </el-radio>
                            <el-radio  :label="3" style="width: 100%;padding: 0;margin: 0">
                                组合支付
                                <span style="width: 500px;margin-left: 20px;color: black">
                                    使用<el-input type="number" v-model="act.points" min="0" style="width: 150px;"></el-input>
                                </span>
                                <span style="width: 500px;margin-top: 20px;color: black">
                                    积分+<el-input type="number" v-model="act.real_price" min="0" style="width: 150px;"></el-input>元现金
                                </span>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="row-class">
                    <span>优惠券:</span>
                    <div style="width: 70%;">
                        <div style="margin-top: 10px;background-color: white;">
                            <table>
                                <thead>
                                <th>券ID</th>
                                <th>优惠券名称</th>
                                <th>发券后X天内有效</th>
                                <th>发券数量</th>
                                <th v-if="!readonly">删除</th>
                                </thead>
                                <tbody>
                                <tr v-for="it in act.coupons">
                                    <td>{{it.coupon_group_id}}</td>
                                    <td>{{it.coupon_group_name}}</td>
                                    <td><el-input-number v-model="it.valid_days" :min="1"></el-input-number></td>
                                    <td><el-input-number v-model="it.num" :min="1"></el-input-number></td>
                                    <td v-if="!readonly">
                                        <span @click="delCoupon(it)">删除</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <coupon-group-select v-model="showCouponSelect" multiple-select="true" @confirm="couponSelectConfirm"></coupon-group-select>
                        <div style="text-align: left;margin-top: 20px;"><el-button @click="toSelectCoupon" style="border: rgba(0,0,255,0.5) solid 1px;color: rgba(0,0,255,0.5)">添加优惠券</el-button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";
    import CouponGroupSelect from "../common/coupon-group-select";

    export default {
        name: "IntegralBuyCouponEdit",
        components: {CouponGroupSelect},
        data(){
            return{
                showLoading:false,
                act:{
                    id:-1,
                    activity_name:'',
                    validity_date:null,
                    activity_note:'',
                    vip_levels:[],
                    max_send_num:0,
                    max_total_num:0,
                    buy_num_per_day:0,
                    buy_num_per_month:0,
                    pay_type:1,
                    points:100,
                    real_price:0,
                    coupons:[]
                },
                readonly:false,
                showVipLevelSelect:false,
                showCouponSelect:false
            }
        },
        methods:{
            backHome(){
              this.$router.back()
            },
            saveEdit(){
                if (this.act.validity_date==null){
                    this.$alert('请填写活动时间！')
                    return
                }
                if (this.act.vip_levels==null || this.act.vip_levels.length<1){
                    this.$alert('请填写会员条件！')
                    return
                }
                if (this.act.coupons==null || this.act.coupons.length<1){
                    this.$alert('请填写优惠券！')
                    return
                }
                this.act.start_time=this.act.validity_date[0]
                this.act.end_time=this.act.validity_date[1]
                if (this.act.id==-1){
                    postRequest('activity/submitIntegralBuyCoupon',this.act).then(res=>{
                        console.log(res)
                        if (res.data.flag){
                            this.$alert(res.data.message)
                            this.$router.back()
                        }
                    })
                }
            },
            getvip_levelShow(){
                var t=''
                if (this.act.vip_levels!=null){
                    for (var i=0;i<this.act.vip_levels.length;i++){
                        t=t+this.act.vip_levels[i].level_code+'|'+this.act.vip_levels[i].level_name+','
                    }
                }
                return t
            },
            toSelectLevel(){
                if (this.showVipLevelSelect){
                    this.showVipLevelSelect=false
                }else {
                    this.getVipLevel()
                    this.showVipLevelSelect=true
                }
            },
            vipLevelSelected(index){
                if (this.vipLevelList[index].check){
                    this.vipLevelList[index].check=false
                }else {
                    this.vipLevelList[index].check=true
                }
                this.act.vip_levels=[]
                for (var i=0;i<this.vipLevelList.length;i++){
                    if (this.vipLevelList[i].check){
                        this.act.vip_levels.push(this.vipLevelList[i])
                    }
                }
            },
            getVipLevel(){
                getRequest('vip/getVipLevelList').then(res=>{
                    this.vipLevelList=res.data.data
                    for (var i=0;i<this.vipLevelList.length;i++){
                        for (var a=0;a<this.act.vip_levels.length;a++){
                            if (this.act.vip_levels[a].level_code==this.vipLevelList[i].level_code){
                                this.vipLevelList[i].check=true
                            }
                        }
                    }
                })
            },
            clearVip(){},
            getDetail(){
                this.showLoading=true
                getRequest('activity/getIntegralBuyCouponDetailById?id='+this.act.id).then(res=>{
                    this.showLoading=false
                    this.act=res.data.data
                    this.act.validity_date=[this.act.start_time,this.act.end_time]
                })
            },
            toSelectCoupon(){
                this.showCouponSelect=true
            },
            couponSelectConfirm(e){
                console.log(e)
                var flag=false
                for (var i=0;i<e.length;i++){
                    flag=false
                    for (var a=0;a<this.act.coupons.length;a++){
                        if (e[i].id==this.act.coupons[a].coupon_group_id){
                            flag=true
                        }
                    }
                    if (!flag){
                        this.act.coupons.push({
                            coupon_group_id:e[i].id,
                            coupon_group_name:e[i].name,
                            valid_days:31,
                            num:1
                        })
                    }
                }
            },
            delCoupon(it){
                var t=[]
                for (var i=0;i<this.act.coupons.length;i++){
                    if (this.act.coupons[i].coupon_group_id!=it.coupon_group_id){
                        t.push(this.act.coupons[i])
                    }
                }
                this.act.coupons=t

            },
        },
        mounted() {
            this.getVipLevel()
            this.act.id=this.$route.params.id
            if (this.act.id!=-1){
                this.readonly=true
                this.getDetail()
            }
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 20px;
        flex-direction: row;
    }
    .row-class span{
        width: 200px;
    }
    .row-class .el-input{
        width: 400px;
    }
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>