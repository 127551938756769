<template>
    <div style="display: flex;align-items: center;margin-bottom: 5px;flex-direction: row;">
        <div style="min-width: 80px;text-align: right;margin-right: 10px">{{lable}}:</div>
        <div >{{value}}</div>
    </div>
</template>

<script>
    export default {
        name: "mmx-label-com",
        props:['lable','value']
    }
</script>

<style scoped>

</style>