<template>
    <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>会员管理</el-breadcrumb-item>
        <el-breadcrumb-item>自助领券列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="background-color: #eeeeee;padding: 10px">
        <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
            <el-form :inline="true" :model="pm" class="demo-form-inline">
                <el-form-item label="名称">
                    <el-input v-model="pm.activity_name" placeholder="名称" clearable />
                </el-form-item>
                <el-form-item label="创建日期">
                    <el-date-picker
                            v-model="pm.dateValue"
                            type="daterange"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            unlink-panels
                            range-separator="-"
                            start-placeholder="起始日期"
                            end-placeholder="截至日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="background-color: #eeeeee;padding: 10px;height: 30px;background-color: white;margin-bottom: 10px;text-align: left">
            <el-button @click="toAdd" type="success">新建活动</el-button>
        </div>
        <div style="margin-top: 10px;background-color: white;height: 550px;overflow: scroll;max-width:100%;margin-bottom: 5px" v-loading="showLoading">
            <table>
                <thead>
                <th>活动ID</th>
                <th>创建人</th>
                <th>活动名称</th>
                <th>创建日期</th>
                <th>活动备注</th>
                <th>活动开始时间</th>
                <th>活动结束时间</th>
                <th>状态</th>
                <th>操作</th>
                </thead>
                <tbody>
                <tr v-for="it in activityList">
                    <td>{{it.id}}</td>
                    <td>{{it.creator_name}}</td>
                    <td>{{it.activity_name}}</td>
                    <td>{{it.create_time}}</td>
                    <td>{{it.activity_note}}</td>
                    <td>{{it.start_time}}</td>
                    <td>{{it.end_time}}</td>
                    <td :style="it.enable==true?'color:green':''">{{it.enable==true?'启用':'未启用'}}</td>
                    <td>
                        <span @click="toView(it)">查看明细</span>
                        <span @click="updateEnable(it,true)" v-if="it.enable==false">启用</span>
                        <span @click="updateEnable(it,false)" v-if="it.enable==true">停用</span>
                        <span @click="executeAct(it)" >开奖</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <el-pagination
                v-model:current-page="pm.pageNum"
                :page-size="pm.pageSize"
                layout="total, prev, pager, next"
                :total="pm.totalNum"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";

    export default {
        name: "IntegralDraw",
        data(){
            return{
                showLoading:false,
                activityList:[],
                pm:{
                    dateValue:[],
                    pageNum:1,
                    pageSize:10,
                    totalNum:1,
                    activity_name:'',
                    dateValue:[]
                }
            }
        },
        methods:{
            toAdd(){
                this.$router.push('IntegralDrawEdit/-1')
            },
            toView(it){
                this.$router.push('IntegralDrawEdit/'+it.id)
            },
            handleSizeChange(){},
            handleCurrentChange(e){
                this.pm.pageNum = e
                this.getList()
            },
            updateEnable(it,e){
                getRequest('activity/updateIntegralDrawEnable?id='+it.id+"&enable="+e).then(res=>{
                    if (res.data.flag){
                        this.getList()
                        this.$alert(res.data.message)
                    }else {
                        this.$alert(res.data.message)
                    }

                })
            },
            getList(){
                if (this.pm.dateValue!=null && this.pm.dateValue.length>0){
                    this.pm.start_time=this.act.dateValue[0]
                    this.pm.end_time=this.act.dateValue[1]
                }
                postRequest('activity/getIntegralDrawList',this.pm).then(res=>{
                    if (res.data.flag){
                        this.pm.totalNum=res.data.data.total
                        this.activityList=res.data.data.list
                    }else {
                        this.$alert(res.data.message)
                    }

                })
            },
            executeAct(it){
                this.showLoading=true
                getRequest('activity/executeIntegralDrawEnable?id='+it.id).then(res=>{
                    this.showLoading=false
                    this.$alert(res.data.message)
                })
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 30px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>