<template>
    <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
        <span style="margin-right: auto">生日送礼:{{act.id}}</span>
        <el-button :disabled="readonly" type="primary" style="width: 90px" @click="saveEdit">保存</el-button>
        <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
    </div>
    <div style="background-color: whitesmoke;padding: 10px" v-loading="showLoading">
        <div style="background-color: white;padding: 10px;">
            <div class="row-class">
                <span>活动名称</span>
                <el-input v-model="act.activity_name"></el-input>
            </div>
            <div class="row-class" style="width: 600px;">
                <span>活动时间</span>
                <el-date-picker
                        v-model="act.validity_date"
                        type="daterange"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="起始日期"
                        end-placeholder="截至日期"
                />
            </div>
            <div class="row-class">
                <span>活动备注</span>
                <el-input v-model="act.activity_note" type="textarea" style="width: 500px;"></el-input>
            </div>
            <div class="row-class">
                <span>发券时间</span>
                <el-radio-group v-model="act.send_time_type" class="ml-4">
                    <el-radio :label="1" size="large">
                        <span>自定发送日期，生日当月</span>
                        <el-input-number v-model="act.month_day" :min="1" :max="27"></el-input-number>
                        <span>号发送</span>
                    </el-radio>
                    <el-radio :label="2" size="large">
                        <span>提前X天发送</span>
                        <el-input-number v-model="act.beforehand_days" :min="0"></el-input-number>
                    </el-radio>
                </el-radio-group>
            </div>
            <div class="row-class">
                <span>会员范围</span>
                <el-radio-group v-model="act.vip_type" class="ml-4">
                    <el-radio :label="1" size="large">所有会员</el-radio>
                    <el-radio :label="2" size="large">部分会员</el-radio>
                </el-radio-group>
            </div>
            <div class="row-class" v-if="act.vip_type==2">
                <span>会员条件</span>
                <div style="text-align: left">
                    <div>
                        <span>会员门店：</span>
                        <el-input readonly :model-value="getShopShow()">
                            <template #append>
                                <el-button @click="toSelectShop" icon="ArrowDown" />
                            </template>
                        </el-input>
                    </div>
                    <shop-select-com v-if="showShopSelect" v-model="showShopSelect" @confirm="storeSelectConfirm" :multipleSelect="true"></shop-select-com>
                    <div style="margin-top: 20px;display: flex;flex-direction: row">
                        <span style="width: auto">会员等级：</span>
                        <div style="text-align: left;display: flex;flex-direction: column;position: relative;cursor: pointer">
                            <el-input readonly :model-value="getvip_levelShow()">
                                <template  #append>
                                    <span @click="clearVip" style="color: blue;cursor: alias;width: 50px;">清空</span>
                                    <span style="margin-right: 50px;width: 5px">|</span>
                                    <el-button @click="toSelectLevel" icon="ArrowDown"></el-button>
                                </template>
                            </el-input>
                            <div v-if="showVipLevelSelect" style="border: #c9c9c9 solid 1px;position: absolute;top: 40px;background-color: white;z-index: 999;width: 300px">
                                <div @click="showVipLevelSelect=false" style="text-align: right;font-size: 20px;cursor: default"><el-icon><Close /></el-icon></div>
                                <div style="height: 300px;overflow: scroll">
                                    <div  @click="vipLevelSelected(index)" v-for="(it,index) in vipLevelList" style="display: flex;flex-direction: row;border-bottom: whitesmoke solid 1px;padding: 5px" :style="{color: it.check==true?'blue':'black'}">
                                        <div>{{it.level_code}}</div>
                                        <div style="margin-left: 20px;margin-right: 20px">{{it.level_name}}</div>
                                        <div>{{it.mobile}}</div>
                                        <div v-if="it.check" style="margin-left: 20px;cursor: pointer">✓</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 20px">
                        <span>会员标签： </span>
                        <el-input>
                            <template #append>
                                <el-button icon="ArrowDown" />
                            </template>
                        </el-input>
                    </div>
                    <div style="margin-top: 20px">
                        <span>消费条件： 近 </span>
                        <el-input-number v-model="act.consume_days" :min="0" style="width: 150px">
                        </el-input-number>
                        <span> 天有过消费</span>
                    </div>
                    <div style="margin-top: 20px">
                        <span>入会天数： 入会天数超过 </span>
                        <el-input-number v-model="act.register_days" :min="0" style="width: 150px">
                        </el-input-number>
                        <span> 天</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 10px;background-color: white">
            <div style="background-color: white;padding: 20px;margin-top: 20px">
                <div style="text-align: left">活动内容:</div>
                <div style="text-align: left;display: flex;margin-top: 20px;margin-left: 30px">
                    <span style="width: 100px">赠送积分</span>
                    <el-input v-model="act.bestow_integral" style="width:500px"></el-input>
                </div>
                <div style="text-align: left;margin-left: 30px;margin-top: 30px">赠送优惠券:</div>
                <div style="margin-top: 10px;background-color: white;">
                    <table style="max-width: 800px;margin-left: 30px">
                        <thead>
                        <th>券ID</th>
                        <th>优惠券名称</th>
                        <th>发券后X天内有效</th>
                        <th>发券数量</th>
                        <th v-if="!readonly">删除</th>
                        </thead>
                        <tbody>
                        <tr v-for="it in act.coupons">
                            <td>{{it.coupon_group_id}}</td>
                            <td>{{it.coupon_group_name}}</td>
                            <td><el-input-number v-model="it.valid_days" :min="1"></el-input-number></td>
                            <td><el-input-number v-model="it.num" :min="1"></el-input-number></td>
                            <td v-if="!readonly">
                                <span @click="delCoupon(it)">删除</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <coupon-group-select v-model="showCouponSelect" multiple-select="true" @confirm="couponSelectConfirm"></coupon-group-select>
                <div style="text-align: left;margin-left: 30px;margin-top: 20px;"><el-button @click="toSelectCoupon" style="border: rgba(0,0,255,0.5) solid 1px;color: rgba(0,0,255,0.5)">添加优惠券</el-button></div>
            </div>
        </div>
    </div>
</template>

<script>
    import ShopSelectCom from "../common/shop-select-com";
    import {getRequest, postRequest} from "../../utils/api";
    import CouponGroupSelect from "../common/coupon-group-select";
    export default {
        name: "BirthdayGiftEdit",
        components: {CouponGroupSelect, ShopSelectCom},
        data(){
            return{
                act:{
                    send_time_type:1,
                    month_day:1,
                    beforehand_days:7,
                    vip_type:2,
                    shops:[],
                    vip_levels:[],
                    coupons:[]
                },
                showShopSelect:false,
                readonly:false,
                vipLevelList:[],
                showVipLevelSelect:false,
                showCouponSelect:false,
                showLoading:false
            }
        },
        methods:{
            backHome(){
                this.$router.back()
            },
            toSelectCoupon(){
                this.showCouponSelect=true
            },
            couponSelectConfirm(e){
                console.log(e)
                var flag=flag
                for (var i=0;i<e.length;i++){
                    flag=false
                    for (var a=0;a<this.act.coupons.length;a++){
                        if (e[i].id==this.act.coupons[a].coupon_group_id){
                            flag=true
                        }
                    }
                    if (!flag){
                        this.act.coupons.push({
                            coupon_group_id:e[i].id,
                            coupon_group_name:e[i].name,
                            valid_days:31,
                            num:1
                        })
                    }
                }
            },
            delCoupon(it){
                var t=[]
                for (var i=0;i<this.act.coupons.length;i++){
                    if (this.act.coupons[i].coupon_group_id!=it.coupon_group_id){
                        t.push(this.act.coupons[i])
                    }
                }
                this.act.coupons=t

            },
            saveEdit(){
                if (this.act.validity_date==null){
                    this.$alert('请填写活动时间！')
                    return
                }
                this.act.start_time=this.act.validity_date[0]
                this.act.end_time=this.act.validity_date[1]
                if (this.act.id==-1){
                    postRequest('activity/sumbitBirthdayGift',this.act).then(res=>{
                        console.log(res)
                        if (res.data.flag){
                            this.$alert(res.data.message)
                            this.$router.back()
                        }
                    })
                }
            },
            storeSelectConfirm(e){
                this.act.shops=e
            },
            toSelectShop(){
                this.showShopSelect=true
            },
            getShopShow(){
                var t=''
                for (var i=0;i<this.act.shops.length;i++){
                    t=t+this.act.shops[i].shop_code+'|'+this.act.shops[i].shop_name+','
                }
                return t
            },
            getvip_levelShow(){
                var t=''
                for (var i=0;i<this.act.vip_levels.length;i++){
                    t=t+this.act.vip_levels[i].level_code+'|'+this.act.vip_levels[i].level_name+','
                }
                return t
            },
            toSelectLevel(){
                if (this.showVipLevelSelect){
                    this.showVipLevelSelect=false
                }else {
                    this.getVipLevel()
                    this.showVipLevelSelect=true
                }
            },
            vipLevelSelected(index){
                if (this.vipLevelList[index].check){
                    this.vipLevelList[index].check=false
                }else {
                    this.vipLevelList[index].check=true
                }
                this.act.vip_levels=[]
                for (var i=0;i<this.vipLevelList.length;i++){
                    if (this.vipLevelList[i].check){
                        this.act.vip_levels.push(this.vipLevelList[i])
                    }
                }
            },
            getVipLevel(){
                getRequest('vip/getVipLevelList').then(res=>{
                    this.vipLevelList=res.data.data
                    for (var i=0;i<this.vipLevelList.length;i++){
                        for (var a=0;a<this.act.vip_levels.length;a++){
                            if (this.act.vip_levels[a].level_code==this.vipLevelList[i].level_code){
                                this.vipLevelList[i].check=true
                            }
                        }
                    }
                })
            },
            clearVip(){},
            getDetail(){
                this.showLoading=true
                getRequest('activity/getBirthdayGiftDetailById?id='+this.act.id).then(res=>{
                    this.showLoading=false
                    this.act=res.data.data
                    this.act.validity_date=[this.act.start_time,this.act.end_time]
                })
            }
        },
        mounted() {
            this.getVipLevel()
          this.act.id=this.$route.params.id
            if (this.act.id!=-1){
                this.readonly=true
                this.getDetail()
            }
        }
    }
</script>

<style scoped>
    .row-class{
        display: flex;
        margin-top: 20px;
        flex-direction: row;
    }
    .row-class span{
        width: 200px;
    }
    .row-class .el-input{
        width: 400px;
    }
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>