<template>
    <div>
        <div style="margin-top: 20px;margin-bottom: 20px">图片列表</div>
        <div>
            <div v-for="(item,index) in comData.data" :key="index" style="border: rgba(0,18,249,0.11) solid 1px;margin-top: 20px;border-radius: 5px;padding: 5px;background-color: whitesmoke">
                <div style="display: flex;flex-direction: row;align-items: center;border: #eeeeee solid 1px;margin-top: 20px;position: relative;">
                    <img :src="item.pic_url" style="width: 100px;height: 100px"/>
                    <div style="word-break:break-all;">链接:  {{item.typeName +' | ' + item.linkValue}}</div>
                    <el-dropdown split-button style="min-width: 100px;margin-left: auto">
                        选择
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="toSelectGoods(index,0,1)">商品</el-dropdown-item>
                                <el-dropdown-item @click="toEditUrl(index,0,1)">小程序</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-icon class="close-class" @click="remove(index)"><el-icon><Close /></el-icon></el-icon>
                </div>
                <div>
                    <div style="display: flex;align-items: center;margin-top: 20px;margin-bottom: 20px">
                        <div>热区</div>
                        <el-button @click="toAddImgHot(index)" style="margin-left: 30px" size="small">新增热区</el-button>
                    </div>
                </div>
                <div>
                    <div v-for="(ht,index2) in item.hot">
                        <div style="display: flex;align-items: center">
                            <div style="color: red;margin-right: 5px">热区{{index2+1}}</div>
                            <div style="word-break:break-all;">链接:  {{ht.typeName +' | ' + ht.linkValue}}</div>
                            <el-dropdown split-button style="min-width: 100px;margin-left: auto">
                                选择
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click="toSelectGoods(index,index2,2)">商品</el-dropdown-item>
                                        <el-dropdown-item @click="toEditUrl(index,index2,2)">小程序</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <div @click="delHot(index,index2)" style="color: rgba(35,39,35,0.57);margin-left: auto;margin-right: 0px;cursor: pointer">删除</div>
                        </div>
                        <div style="border-bottom: #bcbcbc solid 1px;width: 100%;margin-bottom: 20px;margin-top: 10px"></div>
                    </div>
                </div>
            </div>
        </div>
        <el-button icon="el-icon-plus" style="width: 100%;margin-top: 20px" type="primary" @click="toAdd">添加图片</el-button>
        <media-material-com v-model="showAdd" @confirm="addConfirm"></media-material-com>
        <goods-select-com v-model="showGoodsSelect" @confirm="goodsSelectConfirm"></goods-select-com>
        <wepage-url-select v-model="showUrlEdit" @confirm="urlConfirm"></wepage-url-select>
        <div v-show="showHotEdit" class="hot-img-class">
            <div style="text-align: right;width: 100%;display: flex;border-bottom: whitesmoke solid 1px;padding: 10px">
                <div style="margin-left: 50px">按住鼠标左键拖动选择热区</div>
                <el-button @click="showHotEdit=false" style="margin-right: 20px;margin-left: auto">关闭</el-button>
            </div>
            <div style="width: 800px;height: 700px;overflow: scroll;text-align: center"><canvas @mouseout="mouseout" @mousedown="onmousedown" @mouseup="mouseup" @mousemove="mousemove"  id="canvas" style="z-index: 102;opacity: 1"></canvas></div>
            <div style="text-align: right;width: 100%;margin-top: 20px;">
                <el-button @click="hotConfirm" type="primary" style="margin-right: 50px;margin-bottom: 20px">确认</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import GoodsSelectCom from "../../common/goods-select-com";
    import MediaMaterialCom from "../../common/media-material-com";
    import WepageUrlSelect from "../../common/wepage-url-select";

    export default {
        name: "full-screen-img-config",
        components: {WepageUrlSelect, GoodsSelectCom, MediaMaterialCom},
        props:{
            slecetIndex: Number,
            comData:{
                data: Array
            }
        },
        data(){
            return{
                showGoodsSelect:false,
                comData_:null,
                showAdd:false,
                margin: 0,
                radius: 0,
                linkSetIndex:0,
                btText:['一行一个','一行两个','一行三个','一行四个'],
                showUrlEdit:false,
                url:'',
                urlIndex:0,
                showHotEdit:false,
                ctx:null,
                startX:0,
                startY:0,
                startDraw:false,
                img2:null,
                tempHot:null,
                hotImgIndex:0,
                goodsSelectFor:0,
                hotGoodsSelectIndex:0,
                urlEditFor:0,
                hotUrlIndex:0
            }
        },
        methods:{
            LineStyleSelect(index){
                this.comData_.showType = index +1
                console.log(this.comData_)
            },
            getLineStyleBt(index){
                return 'lineStyleBt_' + (index+1)
            },
            toAdd(){
                this.showAdd = true
            },
            addConfirm(e){
                console.log(e)
                this.comData_.data.push(
                    {
                        linkType: '',
                        typeName: '',
                        linkValue: '',
                        linkUrl: '',
                        pic_id: e[0].file_id,
                        pic_url: e[0].url
                    }
                )
            },
            remove(id){
                var t=[]
                var num=this.comData_.data.length
                for(var i=num-1;i>-1;i--){
                    if (i!==id){
                        t.push(this.comData_.data[i])
                    }
                    this.comData_.data.pop()
                }
                this.comData_.data
                for (var i=t.length-1;i>-1;i--){
                    this.comData_.data.push(t[i])
                }

            },
            delHot(index,index2){
                var t=[]
                for (var i=0;i<this.comData_.data[index].hot.length;i++){
                    if (i!=index2){
                        t.push(this.comData_.data[index].hot[i])
                    }
                }
                this.comData_.data[index].hot=t
            },
            toSelectGoods(id,id2,type){
                this.linkSetIndex=id
                this.hotGoodsSelectIndex=id2
                this.goodsSelectFor=type
                this.showGoodsSelect=true
            },
            goodsSelectConfirm(e){
                console.log(e)
                if (this.goodsSelectFor==1){
                    this.comData_.data[this.linkSetIndex].linkType=1
                    this.comData_.data[this.linkSetIndex].typeName='商品'
                    this.comData_.data[this.linkSetIndex].linkValue=e[0].goods_code
                }
                if (this.goodsSelectFor==2){
                    this.comData_.data[this.linkSetIndex].hot[this.hotGoodsSelectIndex].linkType=1
                    this.comData_.data[this.linkSetIndex].hot[this.hotGoodsSelectIndex].typeName='商品'
                    this.comData_.data[this.linkSetIndex].hot[this.hotGoodsSelectIndex].linkValue=e[0].goods_code
                }
            },
            toEditUrl(id,id2,type){
                this.showUrlEdit=true
                this.urlIndex=id
                this.hotUrlIndex=id2
                this.urlEditFor=type
                if (type==1){
                    if (this.comData_.data[this.urlIndex].linkType===2){
                        this.url=this.comData_.data[this.urlIndex].linkValue
                    }else {
                        this.url=''
                    }
                }
                if (type==2){
                    if (this.comData_.data[this.urlIndex].hot[id2].linkType===2){
                        this.url=this.comData_.data[this.urlIndex].hot[id2].linkValue
                    }else {
                        this.url=''
                    }
                }

            },
            urlConfirm(e){
                if (this.urlEditFor==1){
                    this.comData_.data[this.urlIndex].linkType=2
                    this.comData_.data[this.urlIndex].typeName='小程序页面'
                    this.comData_.data[this.urlIndex].linkValue=e
                    this.url=''
                    this.showUrlEdit=false
                }
                if (this.urlEditFor==2){
                    this.comData_.data[this.urlIndex].hot[this.hotUrlIndex].linkType=2
                    this.comData_.data[this.urlIndex].hot[this.hotUrlIndex].typeName='小程序页面'
                    this.comData_.data[this.urlIndex].hot[this.hotUrlIndex].linkValue=e
                    this.url=''
                    this.showUrlEdit=false
                }

            },
            toAddImgHot(id){
                this.hotImgIndex=id
                var w=600
                this.tempHot=null
                var canvas = document.getElementById("canvas");
                // var img = document.getElementById("img");
                // img.src=this.comData_.data[id].pic_url
                this.img2=new Image()
                this.img2.src=this.comData_.data[id].pic_url
                var iw=this.img2.width
                var ih=this.img2.height

                this.img2.width=w
                this.img2.height=ih*(w/iw)

                canvas.width=this.img2.width
                canvas.height=this.img2.height
                canvas.style.width=this.img2.width+'px'
                canvas.style.height=this.img2.height+'px'
                this.ctx = canvas.getContext("2d");
                console.log(canvas)
                canvas.style.backgroundColor="#accdee"
                this.drawExist()
                console.log(this.comData_.data[id].hot)
                this.showHotEdit=true
            },
            onmousedown(e){
                if (this.ctx!=null){
                    this.startDraw=true
                    this.startX=e.offsetX
                    this.startY=e.offsetY
                    console.log(this.startY,this.startX)
                }
            },
            mouseup(e){
                // this.ctx.strokeStyle = '#db0009';
                // this.ctx.strokeRect(this.startY,this.startX, e.offsetY-this.startY,e.offsetX-this.startX )
                this.startDraw=false
            },
            mouseout(){
                this.startDraw=false
            },
            mousemove(e){
                if (this.startDraw){
                    this.drawExist()
                    this.ctx.strokeStyle = 'red';
                    this.ctx.lineWidth = 1
                    this.ctx.strokeRect(this.startX,this.startY, e.offsetX-this.startX,e.offsetY-this.startY )
                    this.tempHot={
                        startXp:this.startX/this.img2.width,
                        startYp:this.startY/this.img2.height,
                        widthP:(e.offsetX-this.startX)/this.img2.width,
                        heightP:(e.offsetY-this.startY)/this.img2.height,
                        typeName:'',
                        linkValue:'',
                    }
                }

            },
            hotConfirm(){
                if (this.tempHot!=null){
                    if (this.comData_.data[this.hotImgIndex].hot==undefined){
                        this.comData_.data[this.hotImgIndex].hot=[]
                    }
                    this.comData_.data[this.hotImgIndex].hot.push(this.tempHot)
                }
                this.showHotEdit=false
            },
            drawExist(){
                this.ctx.clearRect(0,0,2000,2000)
                this.ctx.drawImage(this.img2,0,0,this.img2.width,this.img2.height)
                var ex=this.comData_.data[this.hotImgIndex].hot
                this.ctx.strokeStyle = 'red';
                this.ctx.lineWidth = 1
                if (ex!==undefined && ex!=null){
                    var iw=this.img2.width
                    var ih=this.img2.height
                    this.ctx.font = "12px sans-serif";
                    this.ctx.fillStyle = "red";
                    for (var i=0;i<ex.length;i++){
                        this.ctx.fillStyle = 'red'
                        this.ctx.strokeRect(iw*ex[i].startXp,ih*ex[i].startYp, iw*ex[i].widthP,ih*ex[i].heightP)
                        this.ctx.fillStyle = 'rgb(0,0,0,0.1)'
                        this.ctx.fillRect(iw*ex[i].startXp,ih*ex[i].startYp, iw*ex[i].widthP,ih*ex[i].heightP)
                        this.ctx.fillStyle = 'rgba(238,0,9,1)'
                        this.ctx.fillRect(iw*ex[i].startXp,ih*ex[i].startYp, 24,24)
                        this.ctx.fillStyle = 'white'
                        this.ctx.fillText((i+1), iw*ex[i].startXp+8, ih*ex[i].startYp+15);
                    }
                }
            }
        },
        mounted() {
            this.comData_ = this.comData
            console.log(this.comData_)
        }
    }
</script>

<style scoped>
    .slider-class{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        align-items: center;
    }
    demonstration{
        justify-content: center;
        align-items: center;
    }
    .close-class{
        position:absolute;
        right: 0;
        top: 0;
    }
    .close-class:hover{
        background-color: #00d8d8;
    }
    .hot-img-class{
        position: fixed;
        left: 0;
        right: 0;
        top:50px;
        margin:0 auto;
        height: 800px;
        width: 800px;
        background-color: white;
        border: #bcbcbc solid 1px;
        z-index: 101;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0px #c0c0c0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>