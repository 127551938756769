<template>
    <div :style="commdity.singleLine==true?'display: flex;flex-direction: row;flex-wrap: nowrap;overflow: scroll':'display: flex;flex-direction: row;flex-wrap: wrap;'">
        <div class="comd-card-box" :style="getItemWidth" v-for="(item,index) in commditys" :key="index">
            <div class="lable-class" :style="{color: commdity.lableColor,backgroundColor: commdity.lableBackgroundColor}">{{commdity.lableTxt}}</div>
            <img style="object-fit: cover;"  :style="getImgH" :src="item.pic_url"/>
            <div class="comdinfo">
                <div style="text-indent: 5px;text-align: left;font-size: 13px;height: 15px;overflow: hidden;">{{item.goods_name}}</div>
<!--                <div style="text-indent: 5px;text-align: left;font-size: 12px;margin-top: 10px;">{{item.goods_code}}</div>-->
                <div class="price">
                    <div  :style="{color: commdity.priceColor}" style="display: flex;flex-direction: column">
                        <div v-if="commdity.showPrice" style="text-align: left;text-decoration:line-through;color: #bcbcbc" :style="{fontSize: commdity.priceFontSize + 'px'}">
                            ￥
                            <i :style="{fontSize: commdity.priceFontSize + 'px'}">{{item.price}}</i>
                        </div>
                        <p :style="{fontSize: commdity.priceFontSize + 'px'}">
                            ￥
                            <i :style="{fontSize: commdity.priceFontSize+ 'px'}">{{item.real_price}}</i>
                        </p>
                    </div>
<!--                    <li class="iconfont mmx-iconicon_caigou" :style="{fontSize: commdity.priceFontSize*2.5 + 'px',color: commdity.priceColor}" style="margin-left: auto;"></li>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {postRequest} from "../../../utils/api";
    import GoodsSelectCom from "../../common/goods-select-com";

    export default {
        name: "commodity-card-item1",
        components: {GoodsSelectCom},
        props:{
            commdity:{
                showType: Number,
                priceColor: String,
                priceFontSize: Number,
                showLable: Number,
                showPrice: Boolean,
                lableColor: String,
                lableBackgroundColor: String,
                lableTxt: String,
                dataNum:Number,
                data:Array
            }
        },
        computed:{
            getPriceStyle(){
                // console.log('fontSize: ' + (this.commdity.priceFontSize * 1.5) + 'px')
                return this.commdity.priceFontSize * 1.5
            },
            getImgH () {
                // return 'height:'+ this.imgHeight[this.commdity.showType-1] + 'px;'
                return 'height:'+ 372/this.commdity.showType*1.424 + 'px;'
            },
            getItemWidth(){
                return 'width: ' + 372/this.commdity.showType +'px;'
            }
        },
        watch:{
            'commdity.dataNum':{
                handler() {
                    // console.log(this.commdity.data)
                    this.getComdityData()
                }
          }
        },
        data(){
            return{
                imgHeight:[30,165,106],
                commditys: []
            }
        },
        methods:{
            getComdityData(){
                postRequest('goods/getCommdityInfoListByCodes',{'codes':this.commdity.data}).then(res=>{
                    this.commditys=res.data.data
                })
            },
            // getPriceStyle(){
            //     console.log('fontSize: ' + this.commdity.conf.priceFontSize * 1.5 + 'px')
            //     return 'fontSize: ' + this.commdity.conf.priceFontSize * 1.5 + 'px'
            // }
        },
        mounted() {
            this.getComdityData()
        }

    }
</script>

<style scoped>
    .comd-card-box{
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        /*border-radius: 6px;*/
        background-color: white;
        box-sizing: border-box;
        border: #bcbcbc solid 1px;
        border-left: none;
        flex-shrink: 0;
    }
    .comd-card-box:last-child{
        border-left: #bcbcbc solid 1px;
    }
    .lable-class{
        position: absolute;
        left: 1px;
        top: 1px;
        font-size: 13px;
    }
    li{
        list-style-type:none;
    }
    .price{
        text-align: left;
        display: flex;
        list-style: none;
        align-items: center;
        color: red;
        margin-top: auto;
        margin-bottom: 10px;
    }
</style>