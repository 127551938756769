<template>
    <div class="dialog-overlay-class"></div>
    <div class="dialog-class">
        <div id="img" @dragstart="imgDragst"  @dragend="imgDrag" draggable="true" style="position: absolute;" :style="{width: imgw+'px'}">
            <img :src="imgUrl" style="width: 100%">
        </div>
    </div>
    <div style="display: flex;align-items: center;justify-content: center;position: fixed;bottom: 100px;left: 0;right: 0;margin:0 auto;z-index: 90001">
       <div style="align-items: center;justify-content: center;display: flex;background-color: #999999;border-radius: 50px;padding: 10px">
           <div  @click="plus" class="bt-class">+</div>
           <div  @click="sub" class="bt-class">─</div>
       </div>
    </div>
    <div @click="toclose" style="user-select: none;cursor: pointer;background-color: #9f9f9f;color: white;width: 50px;;height:50px;font-size: 30px;border-radius: 100px;display: flex;align-items: center;justify-content: center;position: fixed;right: 100PX;top: 80px;margin:0 auto;z-index: 90001">
        X
    </div>
</template>

<script>
    import {debounce} from "../../utils/DebounceFn";

    export default {
        name: "SingleImgPreview",
        emits:['tclose'],
        props:['imgUrl'],
        data(){
            return{
                imgw:300,
                setImgPosition:null,
                clientx:0,
                clienty:0,
                imgL:0,
                imgT:0,
            }
        },
        methods:{
            toclose(){
                // console.log(1)
                this.$emit('tclose')
            },
            plus(){
                this.imgw=this.imgw+100
            },
            sub(){
                if (this.imgw>200){
                    this.imgw=this.imgw-100
                }

            },
            imgDrag(e){
               // this.setImgPosition(e.clientX,e.clientY)
                var elem=document.getElementById("img");
                elem.style.position="absolute";
                elem.style.left=e.clientX-this.clientx+this.imgL+"px";
                elem.style.top=e.clientY-this.clienty+this.imgT+"px";
            },
            imgDragst(e){
                this.clientx=e.clientX
                this.clienty=e.clientY
                var elem=document.getElementById("img");
                this.imgL= parseInt(elem.style.left.replace('px',''))
                this.imgT=parseInt(elem.style.top.replace('px',''))
            },
        },
        mounted() {
            this.setImgPosition=debounce(this.setImgPo,100)
            var elem=document.getElementById("img");
            elem.style.position="absolute";
            elem.style.left=1600+"px";
            elem.style.top=1600+"px";
            window.addEventListener('mousewheel',(e)=>{
                if (e.wheelDelta>0){
                    this.plus()
                }else {
                    this.sub()
                }
            });
        }
    }
</script>

<style scoped>
    .dialog-class{
        position: fixed;
        left: -1000px;
        right: 0;
        top: -1500px;
        width: 3000px;
        height: 3000px;
        margin:0 auto;
        z-index: 101;
        padding: 20px;
        z-index: 9000;
    }
    .dialog-overlay-class{
        position: fixed;
        left: 0;
        right: 0;
        top:0px;
        margin:0 auto;
        height: 6000px;
        width: 6000px;
        background-color: #686868;
        padding: 20px;
        z-index: 8000;
        opacity: 0.5;
    }
    .bt-class{
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 60px;
        font-size: 30px;
        background-color: #999999;
        margin-right: 50px;
        margin-left: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        cursor: pointer;
        border: white solid 1px;
        -webkit-user-select: none;  /* 禁止 DIV 中的文本被鼠标选中 */
        -moz-user-select: none;     /* 禁止 DIV 中的文本被鼠标选中 */
        -ms-user-select: none;      /* 禁止 DIV 中的文本被鼠标选中 */
        user-select: none;             /* 禁止 DIV 中的文本被鼠标选中 */
    }
</style>