<template>
    <div style="background-color: #e4e4e4">
        <div style="text-align: left;">小程序</div>
        <div style="display: flex;border-radius: 10px;margin-top: 10px;padding: 20px;">
            <el-icon></el-icon>
            <div class="card-class">微页面</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "t"
    }
</script>

<style scoped>
    .card-class{
        height: 100px;
        width: 200px;
        background-color: white;
        display: flex;
        align-items: center;
    }
</style>