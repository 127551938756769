<template>
    <div style="padding: 10px;text-align: left">
        <h4>分组模板配置</h4>
        <div style="display: flex;align-items: center;margin-bottom: 10px">
            <div>单行模式:</div>
            <el-checkbox v-model="comData.singleLine" size="large" style="margin-left: 20px"></el-checkbox>
        </div>
        <div>添加分组</div>
        <div>
            <div>
                <div class="tab-group" v-for="(item,index) in data_" :key="index">
                    <div class="close-icon-class">
                        <div v-on:click="removeItem(index)" style="z-index: 1000"><el-icon><Close/></el-icon></div>
                    </div>
                    <div class="tab-list">
                        <div>商品来源</div>
                        <input  v-model="item.tabName" readonly="readonly"/>
                    </div>
                    <div class="tab-list">
                        <div>显示名称</div>
                        <input v-model="item.showName"/>
                    </div>
                    <div class="tab-list">
                        <div>显示数量</div>
                        <input type="number" @change="inputChange(index)" v-model="item.commdityNum" />
                    </div>
                </div>
            </div>
            <el-button icon="el-icon-plus" style="width: 100%" @click="toAdd">添加分组</el-button>
            <GoodsGroupSelectCom v-model="showGroupSelect" @confirm="groupSelectConfirm"></GoodsGroupSelectCom>
            <div style="margin-top: 20px">行样式</div>
            <div style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 10px">
                <div style="margin: 10px;" v-for="(item,index) in btText" :key="index">
                    <el-button  @click="commdityLineStyleSelect(index)" :id="getCommdityLineStyleBt(index)">{{item}}</el-button>
                </div>
            </div>
            <div>
                <div>显示原价</div>
                <el-switch v-model="showPrice_" />
            </div>
            <div style="margin-top: 20px;">售价格式</div>
            <div style="background-color: rgba(122,224,205,0.22);">
                <div style="padding: 10px;display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-top: 10px">
                    <div>颜色值</div>
                    <input v-model="priceColorSelect"  style="width: 150px;height: 18px;margin-left: 10px">
                    <el-color-picker color-format="hex" @change="colorchange" v-model="priceColorSelect" style="margin-left: 20px"></el-color-picker>
                </div>
                <div class="slider-class" style="padding: 10px;">
                    <div>字体大小</div>
                    <el-slider style="width: 80%;margin-left: 20px" v-model="priceFontSize_" show-input :max=40></el-slider>
                </div>
            </div>

            <div style="margin-top: 20px">标签设置</div>
            <div style="margin-top: 10px;background-color: #eeeeee;padding: 20px;font-size: 13px">
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px">
                    <div>标签内容</div>
                    <input v-model="lableText" style="width: 150px;height: 18px;margin-left: 10px">
                </div>
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px;margin-top: 5px">
                    <div>标签颜色</div>
                    <input v-model="labelColorSelect" style="width: 150px;height: 18px;margin-left: 10px">
                    <el-color-picker color-format="hex" @change="labecolorchange" v-model="labelColorSelect" style="margin-left: 20px"></el-color-picker>
                </div>
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;margin-left: 10px">
                    <div>背景颜色</div>
                    <input v-model="labelBackgroundColorSelect" style="width: 150px;height: 18px;margin-left: 10px">
                    <el-color-picker color-format="hex" @change="labelBackgoundColorchange" v-model="labelBackgroundColorSelect"  style="margin-left: 20px"></el-color-picker>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GoodsGroupSelectCom from "../../common/GoodsGroupSelectCom";
    export default {
        name: "commodity-group-config",
        components: {GoodsGroupSelectCom},
        props:{
            slecetIndex: Number,
            comData:{
                changeNum:Number,
                showType: Number,
                priceColor: String,
                priceFontSize: Number,
                showLable: Number,
                showPrice: Boolean,
                margin: Number,
                lableColor: String,
                lableBackgroundColor: String,
                lableTxt: String,
                data:Array
            }
        },
        data(){
            return{
                showGroupSelect:false,
                data_:[],
                priceFontSize_: 12,
                showPrice_:false,
                lableText: '',
                labelColorSelect: null,
                priceColorSelect: null,
                labelBackgroundColorSelect: null,
                comData_: null,
                btText:['一行一个','一行两个','一行三个'],
                commditys:[]
            }
        },
        mounted() {
            this.comData_ = this.comData
            this.setBtBorder(this.comData_.showType)
            this.priceColorSelect = this.comData_.priceColor
            this.lableText = this.comData_.lableTxt
            this.labelColorSelect = this.comData_.lableColor
            this.labelBackgroundColorSelect = this.comData_.lableBackgroundColor
            this.priceFontSize_ = this.comData_.priceFontSize
            this.data_ = this.comData_.data
            this.showPrice_=this.comData_.showPrice
        },
        watch :{
            priceColorSelect(){
                this.comData_.priceColor = this.priceColorSelect
            },
            lableText(){
                this.comData_.lableTxt = this.lableText
            },
            labelColorSelect(){
                this.comData_.lableColor = this.labelColorSelect
            },
            labelBackgroundColorSelect(){
                this.comData_.lableBackgroundColor = this.labelBackgroundColorSelect
            },
            priceFontSize_(){
                this.comData_.priceFontSize = this.priceFontSize_
            },
            data_(){
                this.comData_.data = this.data_
                this.comData_.changeNum= this.comData_.changeNum+1
            },
            showPrice_(){
                this.comData_.showPrice = this.showPrice_
            }

        },
        methods:{
            commdityLineStyleSelect(index){
                this.comData_.showType = index+1
                this.setBtBorder(index+1)
            },
            getCommdityLineStyleBt(index){
                return 'commdityLineStyleBt_' + (index+1)
            },
            setBtBorder(id){

                this.btText.forEach((item,index) =>{
                    if (id===index+1){
                        document.getElementById('commdityLineStyleBt_'+(index+1)).setAttribute('style','border: #00b9b9 solid 1px')
                    }else {
                        document.getElementById('commdityLineStyleBt_'+(index+1)).setAttribute('style','#999999 solid 1px')
                    }
                })

                //
            },
            colorchange(e){
                
                this.comData_.priceColor=e
            },
            labecolorchange (e){
                this.comData_.lableColor = e
            },
            labelBackgoundColorchange(e){
                this.comData_.lableBackgroundColor = e
            },
            removeItem(id){
                var temp=[]
                this.data_.forEach((item,index) =>{
                    if (id !== index){
                        temp.push(this.data_[index])
                    }
                })
                this.data_ = temp
                this.comData_.changeNum= this.comData_.changeNum+1
            },
            inputChange(index){
                if (this.data_[index].commdityNum>100){
                    this.data_[index].commdityNum=100
                }
                console.log(this.comData_.changeNum)
                this.comData_.changeNum= this.comData_.changeNum+1
            },
            groupSelectConfirm(e){
                
                var ex=false
                for (var i=0;i<this.comData_.data.length;i++){
                    if (this.comData_.data[i].tabValue==e.group_id){
                        ex=true
                    }
                }
                if (!ex){
                    this.comData_.data.push({
                        commdityNum: 10,
                        tabName: e.group_name,
                        showName: e.group_name,
                        tabValue: e.group_id
                    })
                    this.comData_.changeNum= this.comData_.changeNum+1
                }
            },
            toAdd(){
                console.log(1)
                this.showGroupSelect=true
            }
        }
    }
</script>

<style scoped>
    .close-icon-class{
        position: absolute;
        right: 0;
        font-size: 20px;
    }
    .close-icon-class :hover{
        color: #00fefe;
    }
    .el-icon-close{
        position: absolute;
        right: 0px;
        top:0px;
        font-size: 20px;
    }
    .tab-list{
        display: flex;
        flex-direction: row;
        padding: 10px;
    }
    .tab-list input{
        margin-left: 30px;
    }
    .tab-group{
        border: rgba(168, 168, 168, 0.73) solid 1px;
        margin: 10px;
        border-radius: 6px;
        position: relative;
    }
    .close-icon-class :hover{
        background-color: #00d8d8;
        color: white;
    }
</style>