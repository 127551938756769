<template>
    <div class="search-box" :style="{color: comData.boxColor,backgroundColor: comData.backgroundColor}" >
        <div style="margin-left: 10px"><el-icon><Search /></el-icon></div>
        <input type="search" style="text-indent: 10px;height: 25px;flex: 1;border: none;outline: none;margin-right: 10px;margin-left: 10px" :style="{backgroundColor: comData.boxColor,borderRadius: comData.inputRadius + 'px'}" :placeholder="comData.defaultText" />
        <div style="margin-right:10px;color: black">搜索</div>
    </div>
</template>

<script>
    export default {
        name: "search-item",
        props:{
            comData: {
                boxColor: String,
                backgroundColor: String,
                defaultText: String,
                inputRadius: Number
            }
        },
        mounted() {
            // console.log(this.comData)
        }
    }
</script>

<style scoped>
    .search-box{
        width: 100%;
        height: 23px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }
</style>