<template>
    <div class="dialog-class" v-loading="showLoad">
        <div>退货打款</div>
        <div style="width: 100%;border-bottom: #bcbcbc solid 1px;margin-top: 10px;margin-bottom: 10px"></div>
        <div>
            <div class="item-class">
                <div><span>订单编号:</span><el-input :value="return_order.order_id" style="width: 200px" disabled/></div>
            </div>
            <div class="item-class">
                <div><span>实退金额:</span><el-input :value="return_order.refund_amount" style="width: 200px" disabled/></div>
                <div><span>运费:</span><el-input :value="return_order.freight" style="width: 200px" disabled/></div>
            </div>
            <div class="item-class">
                <div>
                    <span>退款原因:</span>
                    <el-input disabled v-model="return_order.refund_reason" style="width: 200px;"></el-input>
                </div>
                <div>
                    <span>退款备注:</span>
                    <el-input disabled v-model="return_order.refund_note" style="width: 200px;"></el-input>
                </div>
            </div>
        </div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="商品详情" name="goods">
                <table>
                    <thead>
                    <th>图片</th>
                    <th>商品代码</th>
                    <th>颜色</th>
                    <th>尺码</th>
                    <th>sku</th>
                    <th>退货数量</th>
                    <th>吊牌价</th>
                    <th>支付价</th>
                    <th>退款金额</th>
                    </thead>
                    <tbody>
                    <tr v-for="(it,inex) in return_order.details">
                        <td><el-image :src="it.pic_url" style="width: 70px;height: 70px;border-radius: 3px"></el-image></td>
                        <td>{{it.goods_code}}</td>
                        <td>{{it.attribute1_name}}</td>
                        <td>{{it.attribute2_name}}</td>
                        <td>{{it.sku}}</td>
                        <td>{{it.num}}</td>
                        <td>{{it.real_price}}</td>
                        <td>{{it.pay_price}}</td>
                        <td>{{it.pay_amount}}</td>
                    </tr>
                    </tbody>
                </table>
            </el-tab-pane>
            <el-tab-pane label="支付信息" name="payInfo">
                <table>
                    <thead>
                    <th>支付类型</th>
                    <th>支付时间</th>
                    <th>支付金额</th>
                    <th>说明</th>
                    </thead>
                    <tbody>
                    <tr>
                        <td>微信</td>
                        <td>{{return_order.order_info.pay_time}}</td>
                        <td>{{return_order.order_info.pay_amount}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>优惠券</td>
                        <td>{{return_order.order_info.pay_time}}</td>
                        <td>{{return_order.order_info.discount_amount}}</td>
                        <td>{{return_order.order_info.coupon_code}}</td>
                    </tr>
                    </tbody>
                </table>
            </el-tab-pane>
        </el-tabs>
        <div style="position: absolute;width: 98%;text-align: right;bottom: 0;border-top: #bcbcbc solid 1px">
            <el-button style="margin-top: 10px;margin-bottom: 10px;margin-right: 20px" @click="cancelPayouts">取消</el-button>
            <el-button type="primary" @click="submit">确认打款</el-button>
        </div>
    </div>
</template>

<script>
    import {getRequest} from "../../../utils/api";
    import {getUserId} from "../../../utils/auth";

    export default {
        name: "payouts-com",
        emits:['close','update:modelValue'],
        props:['returnid'],
        data(){
            return{
                return_order:{
                    details:[],
                    order_info:{}
                },
                activeName:'goods',
                showLoad:false
            }
        },
        methods:{
            cancelPayouts(){
                this.$emit('update:modelValue',false)
            },
            get(){
                this.showLoad=true
                console.log(this.returnid)
                getRequest('mall/getReturnOrderDetailById?return_id=' + this.returnid).then(res=>{
                    this.showLoad=false
                    console.log(res)
                    this.return_order=res.data.data
                })
            },
            submit(){
                getRequest('mall/submitRefund?return_id=' + this.returnid+'&user_id='+getUserId()).then(res=>{
                    console.log(res)
                    this.$alert(res.data.message)
                    if (res.data.flag){
                        this.$emit('update:modelValue',false)
                    }
                })
            }
        },
        mounted() {
            this.get()
        }
    }
</script>

<style scoped>
    .dialog-class{
        position: fixed;
        left: 0;
        right: 0;
        top:10px;
        margin:0 auto;
        height: 700px;
        width: 800px;
        z-index: 999;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px #c0c0c0;
        padding: 10px;
        text-align: left;
        background-color:whitesmoke;
    }
    .item-class >div{
        display: flex;
        flex-direction: row;
        text-align: right;
        align-items: center
    }
    .item-class >div >span{
        width: 100px;
        margin-right: 10px
    }
    .item-class{
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    }
    table, td, tr, th {
        border-bottom:solid #b2b2b2;
        border-width: 1px;
        font-size: x-small;
        word-break: break-all;
        text-align: center;
    }
    table {
        border-collapse: collapse;
        width: 100%;
        border-radius: 25px;
    }
    td{
        height: 50px;
    }
    /deep/ .el-input.is-disabled .el-input__inner {
        -webkit-text-fill-color: #606060 !important;
        cursor: pointer;
    }
</style>