<template>
    <div v-loading="showLoadingGoodsInfo" style="background-color: #e5e5e5;padding: 10px;">
        <div style="background-color: white;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: right;padding: 10px">
            <span style="margin-right: auto">礼品编辑:<span style="color: blue">{{goodsInfo.goods_code}}</span></span>
            <el-button type="primary" style="width: 90px" @click="saveGiftEdit">保存</el-button>
            <el-button style="border: #bcbcbc solid 1px;width: 90px;margin-right: 60px;margin-left: 50px" @click="backHome">返回</el-button>
        </div>
        <div style="height: 700px;overflow: scroll">
            <div  style="background-color: white;margin-top: 10px">
                <div style="height: 10px"></div>
                <div class="label-input-class">
                    <span>礼品代码:</span>
                    <span style="width: 500px">
                        <el-input v-model="goodsInfo.goods_code" readonly>
                            <template v-if="readonly" #append>
                                <el-button @click="toSelectGoods" style="color: blue">选择礼品<el-icon><ArrowDown /></el-icon></el-button>
                            </template>
                        </el-input>
                    </span>
                    <goods-select-com v-model="showGoodsSelect" @confirm="goodsSelected"></goods-select-com>
                </div>
                <div class="label-input-class">
                    <span>礼品名称:</span>
                    <span style="width: 500px">
                    <el-input v-model="goodsInfo.goods_name"></el-input>
                </span>
                </div>
                <div class="label-input-class">
                    <span>安全库存:</span>
                    <span style="width: 500px">
                    <el-input type="number" min="0" v-model="goodsInfo.safety_stock"></el-input>
                </span>
                </div>
                <div class="label-input-class">
                    <span>运费模板:</span>
                    <el-select v-model="goodsInfo.freight_template" style="width: 500px;">
                        <el-option v-for="(it,index) in templateList" :label="it.name" :value="it.id" />
                    </el-select>
                </div>
                <div class="label-input-class">
                    <span>上架时间:</span>
                    <el-date-picker
                            v-model="goodsInfo.listing_date"
                            type="date"
                            placeholder="选择日期"
                            :shortcuts="shortcuts"
                            value-format="YYYY-MM-DD"
                    />
                </div>
                <div class="label-input-class">
                    <span>礼品图片:</span>
                    <media-material-com v-model="showImgSelect" @confirm="imgConfirmHandler"></media-material-com>
                    <img style="width: 100px;height: 100px" :src="goodsInfo.pic_url"/>
                    <el-button  icon="plus" style="height: 100px" @click="toshowImgSelect">设置主图</el-button>
                </div>
                <div class="label-input-class" style="align-items: start">
                    <span>兑换限制</span>
                    <div style="border: whitesmoke solid 1px;padding: 10px;border-radius: 5px;width: 600px;text-align: left">
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            <span>每人共可对兑</span>
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="goodsInfo.max_total_num" :min="0"></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">次</span>
                        </div>
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            每人每月可兑
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="goodsInfo.buy_num_per_month" :min="0"></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">次</span>
                        </div>
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            每人每天可兑
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="goodsInfo.buy_num_per_day" :min="0" ></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">次</span>
                        </div>
                        <div style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                            每人每次可兑
                            <span style="width: 500px;margin-left: 20px">
                                    <el-input-number type="number" v-model="goodsInfo.buy_num_per_times" :min="0" ></el-input-number>
                                </span>
                            <span style="color: black;margin-left: 20px">件</span>
                        </div>
                        <div style="text-align: left;color: #8a8a8a">每一项限制条件不填或者添0则不限制</div>
                    </div>
                </div>
                <div class="label-input-class">
                    <span>市场价格:</span>
                        <span style="width: 300px">
                        <el-input type="number" min="0" v-model="goodsInfo.price" @change="priceChange"></el-input>
                    </span>
                </div>
                <div class="label-input-class" style="align-items: start">
                    <span style="line-height: 35px">支付方式:</span>
                    <div>
                        <el-radio-group v-model="goodsInfo.pay_type" style="text-align: left;flex-direction: column">
                            <el-radio  :label="1" style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                                积分支付
                                <span style="width: 500px;margin-left: 20px">
                                    <el-input type="number" v-model="goodsInfo.points" min="0" @change="pointsChange"></el-input>
                                </span>
                                <span style="color: black;margin-left: 20px">积分</span>
                            </el-radio>
                            <el-radio  :label="2" style="width: 100%;padding: 0;margin: 0;margin-bottom: 15px">
                                现金支付
                                <span style="width: 500px;margin-left: 20px">
                                    <el-input type="number" v-model="goodsInfo.real_price" min="0"  @change="realPriceChange"></el-input>
                                </span>
                                <span style="color: black;margin-left: 20px">元</span>
                            </el-radio>
                            <el-radio  :label="3" style="width: 100%;padding: 0;margin: 0">
                                组合支付
                                <span style="width: 500px;margin-left: 20px;color: black">
                                    使用<el-input type="number" v-model="goodsInfo.points" @change="pointsChange" min="0" style="width: 150px;"></el-input>
                                </span>
                                <span style="width: 500px;margin-top: 20px;color: black">
                                    积分+<el-input type="number" v-model="goodsInfo.real_price" min="0" @change="realPriceChange" style="width: 150px;"></el-input>元
                                </span>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="label-input-class">
                    <span>发货方式:</span>
                    <span style="width: 200px">
                        <el-checkbox v-model="goodsInfo.shipping_type_express" label="快递发货" size="large" />
                        <el-checkbox v-model="goodsInfo.shipping_type_selfpickup" label="门店自提" size="large" />
                    </span>
                </div>
                <div style="height: 50px"></div>
            </div>
            <div  style="text-align: left;background-color: white;margin-top: 10px">
                <div>规格详情</div>
                <div style="padding: 20px">
                    <table>
                        <thead>
                        <th>上架</th>
                        <th>SKU</th>
                        <th>颜色</th>
                        <th>尺码</th>
                        <th>市场价</th>
                        <th>售价</th>
                        <th>所需积分</th>
                        <th>库存</th>
                        </thead>
                        <tr v-for="(it,index) in goodsInfo.skus">
                            <td><el-checkbox v-model="it.listing_flag" size="large" /></td>
                            <td>{{it.sku}}</td>
                            <td>{{it.attribute1_name}}</td>
                            <td>{{it.attribute2_name}}</td>
                            <td style="width: 150px;text-align: center"><el-input v-model="it.price"></el-input></td>
                            <td style="width: 150px"><el-input v-model="it.real_price"></el-input></td>
                            <td style="width: 150px"><el-input v-model="it.points"></el-input></td>
                            <td>{{it.stock}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div  style="text-align: left;background-color: white;margin-top: 10px">
                <div style="padding: 10px">图片信息</div>
                <div style="padding: 20px">
                    <table>
                        <thead>
                        <th>颜色</th>
                        <th>图片</th>
                        <th>操作</th>
                        </thead>
                        <tr v-for="(it,index) in goodsInfo.skc_pic_list">
                            <td style="width: 200px;">
                                <el-select v-model="it.code">
                                    <el-option v-for="it2 in goodsInfo.skc_list" :label="it2.name" :value="it2.code" />
                                </el-select>
                            </td>
                            <td style="flex: 1;display: flex;flex-direction: row;">
                                <div v-for="(x,index2) in it.pics" style="margin-left: 5px;position: relative">
                                    <el-icon style="color: red;position: absolute;top: 0;right: 0" @click="deleteSkcPic(index,index2)"><Close /></el-icon>
                                    <img style="width: 60px;height: 60px" :src="x.url">
                                    <div v-if="x.file_id!=goodsInfo.pic_id" style="cursor: pointer;position: absolute;bottom: 0;color: white;background-color: #acacac;font-size: 8px;width: 100%;height: 16px;line-height: 16px;padding: 0;text-align: center;margin: 0" @click="setMainPic(x)">设为主图</div>
                                    <div v-if="x.file_id==goodsInfo.pic_id" style="cursor: pointer;position: absolute;bottom: 0;color: white;background-color: #acacac;font-size: 8px;width: 100%;height: 16px;line-height: 16px;padding: 0;text-align: center;margin: 0">主图</div>
                                </div>
                                <el-button icon="plus" style="margin-left: 5px" @click="toshowSkcImgSelect(index)">添加图片</el-button>
                            </td>
                            <td style="width: 100px;"><span style="color: blue;cursor: pointer" @click="deleteSkcPicList(index)">删除</span></td>
                        </tr>
                    </table>
                    <el-button type="primary" size="small" style="margin-top: 10px" @click="addSkcPicRow">添加行</el-button>
                </div>
                <media-material-com v-model="showSkcImgSelect" @confirm="SkcImgSelectConfirm" multiple-select="true"></media-material-com>
            </div>
            <div style="margin-top: 10px;background-color: white;text-align: left">
                <div style="padding: 10px">商品详情</div>
                <div style="margin-top: 10px"></div>
                <textarea id="ty"></textarea>
                <media-material-com  v-model="showEditImageSelect" multiple-select="false" @confirm="EditImageSelectConfirm"></media-material-com>
            </div>
        </div>
    </div>
</template>

<script>
    import MediaMaterialCom from "../common/media-material-com";
    import {getRequest, postRequest} from "../../utils/api";
    import CouponGroupSelect from "../common/coupon-group-select";
    import GoodsGroupSelectCom from "../common/GoodsGroupSelectCom";
    import GoodsSelectCom from "../common/goods-select-com";
    export default {
        name: "GiftEdit",
        components: {GoodsSelectCom, GoodsGroupSelectCom, CouponGroupSelect, MediaMaterialCom},
        data(){
            return{
                goodsInfo:{
                    goods_code:'',
                    goods_name:'',
                    price:'',
                    real_price:'',
                    points:2000,
                    safety_stock:'5',
                    freight_template:'',
                    pay_type:1,
                    listing_flag:false,
                    listing_date:null,
                    shipping_type_express:true,
                    shipping_type_selfpickup:true,
                    pic_id:'',
                    pic_url:'',
                    rich_text:'',
                    ad_text:'',
                    skus: [],
                    skc_list:[],
                    skc_pic_list:[],
                    max_total_num:null,
                    buy_num_per_day:null,
                    buy_num_per_month:null,
                    buy_num_per_times:1,

                },
                templateList:[],
                showEditImageSelect: false,
                showSkcImgSelect:false,
                showLoadingGoodsInfo:false,
                skc_pic_list_index:0,
                editor_: null,
                disabledDate: true,
                tinyvalue: '',
                goods_code: '',
                listing_type: '1',
                real_price_type:'1',
                listing_time: new Date(),
                mainImgUrl: '',
                mainImgId: '',
                showImgSelect: false,
                readonly:true,
                showGoodsSelect:false,
                init: {
                    height: 400,
                },
                shortcuts: [
                    {
                        text: '今天',
                        value: new Date(),
                    },
                    {
                        text: '明天',
                        value: () => {
                            const date = new Date()
                            date.setTime(date.getTime() + 3600 * 1000 * 24)
                            return date
                        },
                    },
                    {
                        text: '一周后',
                        value: () => {
                            const date = new Date()
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
                            return date
                        },
                    },
                ]
            }
        },
        methods:{
            imgConfirmHandler(e){
                this.goodsInfo.pic_url = e[0].url
                this.goodsInfo.pic_id = e[0].file_id
            },
            toSelectCoupon(){
                this.showCouponSelect= true
            },
            couponSelectConfirm(e){
                for (var i=0;i<e.length;i++){
                    var ex=false
                    if (this.goodsInfo.coupon_list==null){
                        this.goodsInfo.coupon_list=[e[i]]
                    }else {
                        for (var x=0;x<this.goodsInfo.coupon_list.length;x++){
                            if (e[i].id==this.goodsInfo.coupon_list[x].id){
                                ex=true
                            }
                        }
                        if (!ex){
                            this.goodsInfo.coupon_list.push(e[i])
                        }
                    }


                }
                // this.couponList=e
            },
            deleteCoupon(id){
                var t=[]
                for (var x=0;x<this.goodsInfo.coupon_list.length;x++){
                    if (this.goodsInfo.coupon_list[x].id!=id){
                        t.push(this.goodsInfo.coupon_list[x])
                    }
                }
                this.goodsInfo.coupon_list = t
            },
            SkcImgSelectConfirm(e){
                console.log(e)
                for (var i=0;i<e.length;i++){
                    if (this.goodsInfo.skc_pic_list[this.skc_pic_list_index].pics==null){
                        this.goodsInfo.skc_pic_list[this.skc_pic_list_index].pics=[{'file_id':e[i].file_id,'url':e[i].url}]
                    }else {
                        this.goodsInfo.skc_pic_list[this.skc_pic_list_index].pics.push({'file_id':e[i].file_id,'url':e[i].url})
                    }

                }
            },
            toshowSkcImgSelect(id){
                if (this.goodsInfo.skc_pic_list[id].code.length<2){
                    this.$message({message:"请填写颜色!",type: 'error'})
                    return
                }
                this.skc_pic_list_index=id
                this.showSkcImgSelect = true
            },
            deleteSkcPic(id1,id2){
                var temp=[]
                console.log(this.goodsInfo.skc_pic_list[id1].pics)
                for (var i=0;i<this.goodsInfo.skc_pic_list[id1].pics.length;i++){
                    if (i!=id2){
                        temp.push(this.goodsInfo.skc_pic_list[id1].pics[i])
                    }
                }
                this.goodsInfo.skc_pic_list[id1].pics=temp
            },
            deleteSkcPicList(id){
                var temp=[]
                for (var i=0;i<this.goodsInfo.skc_pic_list.length;i++){
                    if (i!=id){
                        temp.push(this.goodsInfo.skc_pic_list[i])
                    }
                }
                this.goodsInfo.skc_pic_list=temp
            },
            setMainPic(x){
                this.goodsInfo.pic_id=x.file_id
                this.goodsInfo.pic_url=x.url
            },
            toshowImgSelect(){
                this.showImgSelect = true
            },
            EditImageSelectConfirm(e){
                console.log(e)
                for (var i=0;i<e.length;i++){
                    this.editor_.insertContent('<p><img src="'+e[i].url+'"/></p>');
                }
            },
            saveGiftEdit(){
                if (this.goodsInfo.goods_code==null || this.goodsInfo.goods_code.length<3 || this.goodsInfo.goods_code==-1){
                    this.$alert("请填写礼品代码！")
                    return
                }
                var tinymceValue= this.editor_.getContent();
                this.goodsInfo.rich_text=tinymceValue
                console.log(tinymceValue)
                console.log(this.goodsInfo)
                postRequest('pointsSys/saveGift',this.goodsInfo).then(res=>{
                    if (res.data.flag){
                        this.getgoodsInfoByCode()
                    }
                    this.$alert(res.data.message)
                })
            },
            backHome(){
                this.$router.back()
            },
            getGoodsInfo(){
                this.showLoadingGoodsInfo = true
                this.goodsInfo.skus=[]
                getRequest('goods/getGoodsInfoByCode?goodsCode=' + this.goodsInfo.goods_code).then(res=>{
                    this.showLoadingGoodsInfo = false
                    this.goodsInfo.skc_list=res.data.data.skc_list
                    for (var i=0;i<res.data.data.skus.length;i++){
                        var it={}
                        it['attribute1_code']=res.data.data.skus[i].attribute1_code
                        it['attribute1_name']=res.data.data.skus[i].attribute1_name
                        it['attribute2_name']=res.data.data.skus[i].attribute2_code
                        it['attribute2_code']=res.data.data.skus[i].attribute2_code
                        it['sku']=res.data.data.skus[i].sku
                        it['price']=res.data.data.real_price
                        it['real_price']=res.data.data.real_price
                        it['points']=this.goodsInfo.points
                        it['listing_flag']=false
                        this.goodsInfo.skus.push(it)
                    }
                    console.log(res)
                })
            },
            getgoodsInfoByCode(){
                this.readonly=false
                this.showLoadingGoodsInfo = true
                getRequest('pointsSys/getGiftInfoByCode?goods_code=' + this.goodsInfo.goods_code).then(res=>{
                    console.log(res)
                    this.showLoadingGoodsInfo = false
                    this.goodsInfo=res.data.data
                    if(this.goodsInfo.rich_text!=null){
                        this.editor_.setContent(this.goodsInfo.rich_text)
                    }
                })
            },
            getSkuDiscount(it){
                return (it.real_price/it.price).toFixed(2)*100
            },
            addSkcPicRow(){
                if (this.goodsInfo.skc_pic_list==null){
                    this.goodsInfo.skc_pic_list=[{'code':'','name':'','pics':[]}]
                }else{
                    this.goodsInfo.skc_pic_list.push({'code':'','name':'','pics':[]})
                }

            },
            realPriceChange(e){
                for (var i=0;i<this.goodsInfo.skus.length;i++){
                    this.goodsInfo.skus[i].real_price=e
                }
            },
            toShowGoodsGroupSelect(){
                this.showGoodsGroupSelect = true
            },
            changex(){
                console.log(this.goodsInfo)
            },
            goodsSelected(e){
                console.log(e)
                this.goodsInfo.goods_code=e[0].goods_code
                this.goodsInfo.goods_name=e[0].goods_name
                this.goodsInfo.price=e[0].real_price
                this.goodsInfo.real_price=e[0].real_price
                this.getGoodsInfo()
            },
            toSelectGoods(){
                this.showGoodsSelect=true
            },
            priceChange(e){
                console.log(e)
                for (var i=0;i<this.goodsInfo.skus.length;i++){
                    this.goodsInfo.skus[i].price=e
                }
            },
            pointsChange(e){
                for (var i=0;i<this.goodsInfo.skus.length;i++){
                    this.goodsInfo.skus[i].points=e
                }
            },
            getFreightList(){
                getRequest('mall/getFreightList').then(res=>{
                    console.log(res)
                    this.templateList=res.data.data
                })
            },
            addGift(){

            }
        },
        mounted() {
            this.getFreightList()
            console.log(this.$route.params.giftcode)
            this.goodsInfo.goods_code = this.$route.params.giftcode
            tinymce.remove('#ty')
            var that=this
            tinymce.init({
                selector:'#ty',
                language_url: '/tinymce/langs/zh_CN.js', //汉化路径是自定义的，一般放在public或static里面
                language: 'zh_CN',
                font_family_formats:"微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
                plugins: `accordion advlist anchor autolink autosave charmap code codesample directionality emoticons fullscreen help importcss insertdatetime link lists media nonbreaking pagebreak  preview save searchreplace table Template visualblocks visualchars`,
                toolbar: [`myImage undo redo aligncenter alignjustify alignleft alignnone charmap alignright blockquote backcolor italic bold copy cut blocks fontfamily fontsize fontsizeinput forecolor h1 h2 h3 h4 h5 h6 hr indent  language lineheight newdocument outdent  paste pastetext print remove  removeformat selectall strikethrough styles subscript superscript underline visualaid`],
                branding: false,
                promotion: false,
                height: 650,
                setup: function (editor) {
                    that.editor_= editor
                    editor.ui.registry.addButton('myImage', {
                        text: '',
                        icon: 'image',
                        onAction: function (_) {
                            that.showEditImageSelect = true
                        }
                    });
                }
            })
            if (this.goodsInfo.goods_code!=-1){
                this.getgoodsInfoByCode()
            }
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        font-size: small;
        line-height: 30px;
        border-bottom: #bcbcbc solid 1px;
        text-align: center;
    }
    td{
        padding: 10px;
    }
    th{
        border: #bcbcbc solid 1px;
        border-left: none;
        border-right: none;
    }
    th:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:first-child{
        border-left: #bcbcbc solid 1px;
    }
    td:last-child{
        border-right: #bcbcbc solid 1px;
    }
    th:last-child{
        border-right: #bcbcbc solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    .label-input-class{
        display: flex;
        align-items: center;
        margin-top: 20px;
        text-align: left;
    }
    .label-input-class>span{
        width: 150px;
        margin-right: 30px;
        text-align: right;
    }
    tr:nth-child(1){
        background: #ffffff;
    }
    tr:nth-child(2n){
        background: #f7f9f9;
    }
    /deep/ input::-webkit-outer-spin-button,
    /deep/input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    /deep/ input[type="number"]{
        -moz-appearance: textfield;
    }
</style>