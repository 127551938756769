<template>
    <div>
        <div style="display: flex;flex-direction: row">
            <div id="avatar" style="background-color: rgba(245,245,245,0.2)">
                <el-image style="width: 100px;height: 100px" :src="vipInfo.avatar_url"></el-image>
                <table>
                    <tr>
                        <td>姓名</td>
                        <td>{{vipInfo.name}}</td>
                    </tr>
                    <tr>
                        <td>等级</td>
                        <td>{{vipInfo.level_name}}</td>
                    </tr>
                    <tr>
                        <td>积分</td>
                        <td>{{vipInfo.points}}</td>
                    </tr>
                </table>
            </div>
            <div id="info" style="flex: 1;margin-left: 20px">
                <table>
                    <tr>
                        <td>会员卡</td>
                        <td>{{vipInfo.vip_code}}</td>
                        <td>手机</td>
                        <td>{{vipInfo.mobile}}</td>
                    </tr>
                    <tr>
                        <td>生日</td>
                        <td>{{vipInfo.birthday}}</td>
                        <td>昵称</td>
                        <td>{{vipInfo.nickname}}</td>
                    </tr>
                    <tr>
                        <td>Openid</td>
                        <td>{{vipInfo.open_id}}</td>
                        <td>UnionId</td>
                        <td>{{vipInfo.union_id}}</td>
                    </tr>
                    <tr>
                        <td>门店</td>
                        <td>{{vipInfo.shop_name}}</td>
                        <td>导购</td>
                        <td>{{vipInfo.guider_name}}</td>
                    </tr>
                    <tr>
                        <td>注册日期</td>
                        <td>{{vipInfo.registration_date}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "vip-basic-information-com",
        props:["vipInfo"]
    }
</script>

<style scoped>
    #avatar table{
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    #avatar td{
        border-bottom: #d7d7d7 solid 1px;
    }
    #info table{
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    #info td{
        border-bottom: #d7d7d7 solid 1px;
    }
    #info tr{
        height: 40px;
    }
    #info tr:nth-child(even){
        background-color: rgba(0, 193, 193, 0.03);
    }
</style>