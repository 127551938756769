export const debounce=(fn,delay)=>{
    var t=null
    return function () {
        if (t!=null){
            clearTimeout(t)
        }
       t= setTimeout(()=>{
           fn()
       },delay)
    }
}
