<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>导购任务</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="background-color: #eeeeee;padding: 10px">
            <div style="text-align: left;background-color: white;padding: 10px;align-items: center;align-content: center">
                <el-form :inline="true" :model="pm" class="demo-form-inline">
                    <el-form-item label="任务名称">
                        <el-input v-model="pm.task_name" placeholder="任务名称" clearable />
                    </el-form-item>
                    <el-form-item label="创建日期">
                        <el-date-picker
                                v-model="pm.dateValue"
                                type="daterange"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                unlink-panels
                                range-separator="-"
                                start-placeholder="起始日期"
                                end-placeholder="截至日期"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getList">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="toAdd" type="success">新建任务</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="margin-top: 10px;background-color: white;height: 550px;overflow: scroll;max-width:100%" v-loading="showLoading">
                <table>
                    <thead>
                    <th>任务ID</th>
                    <th>任务名称</th>
                    <th>创建日期</th>
                    <th>创建人</th>
                    <th>起始日期</th>
                    <th>截止日期</th>
                    <th>备注</th>
                    <th>状态</th>
                    <th>操作</th>
                    </thead>
                    <tbody>
                    <tr v-for="it in task_list">
                        <td>{{it.id}}</td>
                        <td>{{it.task_name}}</td>
                        <td>{{it.create_time}}</td>
                        <td>{{it.creator_name}}</td>
                        <td>{{it.start_time}}</td>
                        <td>{{it.end_time}}</td>
                        <td>{{it.task_note}}</td>
                        <td :style="it.enabled==true?'color:green':''">{{it.enabled==true?'启用':'未启用'}}</td>
                        <td>
                            <span @click="toEdit(it)">编辑</span>
                            <span @click="enabledTask(it)" v-if="it.enabled==false">启用</span>
                            <span @click="disabledTask(it)" v-if="it.enabled==true">停用</span>
                            <span @click="delTask(it)" style="color: red;">删除</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <el-pagination
                    v-model:current-page="pm.pageNum"
                    :page-size="pm.pageSize"
                    layout="total, prev, pager, next"
                    :total="pm.totalNum"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
    import {getRequest, postRequest} from "../../utils/api";

    export default {
        name: "GuiderTask",
        data(){
            return{
                showLoading:false,
                task_list:[],
                pm:{
                    task_name:'',
                    dateValue:[],
                    pageNum:1,
                    pageSize:10,
                    totalNum:1,
                }
            }
        },
        methods:{
            handleSizeChange(){},
            handleCurrentChange(e){
                this.pm.pageNum = e
                this.getList()
            },
            getList(){
                if (this.pm.dateValue!=null && this.pm.dateValue.length>1){
                    this.pm.start_time= this.pm.dateValue[0]
                    this.pm.end_time= this.pm.dateValue[1]
                }
                postRequest('vip/getGuiderTaskList',this.pm).then(res=>{
                    if (res.data.flag){
                        this.pm.totalNum=res.data.data.total
                        this.task_list=res.data.data.list
                    }else{
                        this.$alert(res.data.message)
                    }
                })
            },
            toAdd(){
                this.$router.push('GuiderTaskEdit/-1')
            },
            toEdit(it){
                this.$router.push('GuiderTaskEdit/'+it.id)
            },
            enabledTask(it){
                getRequest('vip/updateGuiderTaskEnabled?id='+it.id +'&enabled=1').then(res=>{
                    this.$alert(res.data.message)
                    this.getList()
                })
            },
            disabledTask(it){
                getRequest('vip/updateGuiderTaskEnabled?id='+it.id +'&enabled=0').then(res=>{
                    this.$alert(res.data.message)
                    this.getList()
                })
            },
            delTask(it){
                getRequest('vip/delGuiderTask?id='+it.id).then(res=>{
                    this.$alert(res.data.message)
                    this.getList()
                })
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style scoped>
    table, td, tr, th {
        border:#d8d8d8 solid 1px;
        font-size: small;
        line-height: 30px;
        border-left: none;
        border-right: none;
    }
    td{
        border-top: none;
        padding: 10px;
    }
    tr>td:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    tr>td:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    tr:hover{
        background-color: rgba(177, 204, 223, 0.31);
    }
    th:last-child{
        border-right: #d8d8d8 solid 1px;
    }
    th:first-child{
        border-left: #d8d8d8 solid 1px;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    td>span{
        margin-left: 30px;
        color: blue;
        cursor: pointer;
    }
    ::-webkit-scrollbar {
        display:none
    }
</style>