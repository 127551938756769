<template>
    <div>
        <el-breadcrumb style="padding-bottom: 10px" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>营销活动</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="text-align: left">
            <div style="font-weight: bold;margin-top: 20px">综合营销</div>
            <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <market-card-item title="自助领券" icon="iconfont mmx-iconxiaochengxu" note="领券中心领券,扫码领券" route="PickupCouponList"></market-card-item>
                <market-card-item title="手工批量发券" icon="iconfont mmx-iconnavicon-tp" note="手工发放优惠券" route="SendCouponList"></market-card-item>
                <market-card-item title="注册送礼" icon="iconfont mmx-iconmendian" note="注册时赠送优惠券等" route="RegisterGiftList"></market-card-item>
                <market-card-item title="升级送礼" icon="iconfont mmx-iconpinpaishengji" note="升级时赠送优惠券等" route="LevelUpGiftList"></market-card-item>
                <market-card-item title="生日送礼" icon="iconfont mmx-iconicon_shengri" note="会员生日赠送优惠券等" route="BirthdayGiftList"></market-card-item>
                <market-card-item title="自动营销" icon="iconfont mmx-iconguoqizidongtui" note="自动执行营销任务" route="AutometicMarketingList"></market-card-item>
                <market-card-item title="积分兑券" icon="iconfont mmx-iconjifen" note="使用积分兑换优惠券" route="IntegralBuyCouponList"></market-card-item>
                <market-card-item title="邀请送礼" icon="iconfont mmx-iconyaoqing" note="会员邀请新会员注册赠送优惠券等" route=""></market-card-item>
                <market-card-item title="签到送礼" icon="iconfont mmx-iconqiandao" note="签到赠送优惠券等" route=""></market-card-item>
                <market-card-item title="积分抽奖" icon="iconfont mmx-iconjifenchoujiang" note="积分抽奖" route="IntegralDraw"></market-card-item>
            </div>
        </div>
    </div>
</template>

<script>
    import MarketCardItem from "./market-card-item";
    export default {
        name: "Market",
        components: {MarketCardItem}
    }
</script>

<style scoped>

</style>